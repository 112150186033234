/*
 * @Description: 数据结构
 * @Author: luocheng
 * @Date: 2021-09-06 15:34:39
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-03-09 14:16:13
 */
import { axios } from '../http/request';
/**
 * @desc: 删除search参数中的value为空的筛选
 */
export const removeNullSearch = data => {
	if (!data || typeof data !== 'object') return [];
	for (let key in data) {
		if (key === 'search' && Array.isArray(data?.[key]) && data?.[key].length) {
			let search = [];
			for (let i = 0; i < data[key].length; i++) {
				if (data[key]?.[i]?.value) {
					search.push(data?.[key]?.[i]);
				}
			}
			return {
				...data,
				search
			}
		}
	}
	return data;
}
/**
 * @desc: 获取数据仓库对象
 * @param {Object} { name, code, uuid, page, size }
 */
export const getObjects = (params) => {
	return axios.request({
		url: 'api/metadata/objects',
		params,
		method: 'GET'
	});
};

/**
 * @desc: 获取视图列表
 * @param {Object} params object_uuid 数据仓库对象的uuid
 */
export const getViews = (params) => {
	return axios.request({
		url: 'api/metadata/views',
		params,
		method: 'GET'
	});
};

/**
 * @desc: 获取字段列表
 * @param {Object} { object_uuid } 对象uuid
 */
export const getFields = (params) => {
	return axios.request({
		url: 'api/metadata/objectFields',
		params,
		method: 'GET'
	});
};


/**
 * @desc: 获取关联关系
 * @param {Object} {owner_uuid} 关联的对象uuid
 * @return {*}  
 */
export const getRelations = (params) => {
	return axios.request({
		url: 'metadata/relationships',
		params,
		method: 'GET'
	});
}
/**
 * @desc: 获取实际渲染的数据
 * @param {Object} data
 * __method_name__   类型：dataList —— 列表
 * object_uuid       对象ID
 * view_uuid         视图ID
 * search            筛选项(数组)
 */
export const getRenderData = (data) => {
	return axios.request({
		url: 'api/mapi',
		data: removeNullSearch(data),
		method: 'POST'
	});
};

/**
 * @desc: 用户数据接口
 */
export const dataInterface = (data, url, method) => {
	if (method && method === 'GET') {
		return axios.request({
			url: url || 'api/mapi',
			params: removeNullSearch(data),
			method
		});
	}
	return axios.request({
		url: url || 'api/mapi',
		data: removeNullSearch(data),
		method: method || 'POST'
	});
}

export const phoneSendMsg = (data) => {
	return axios.request({
		url: "api/v4/phoneSendMsg",
		data,
		method: "POST"
	});
}

/**
 * @desc: 缓存
 */
export const globalCache = {
	get: (key) => {
		return axios.request({
			url: 'system/get/global/cache',
			params: { key: key },
			method: 'GET'
		});
	},
	put: (key, value) => {
		return axios.request({
			url: 'system/put/global/cache',
			data: { key: key, value: value },
			method: 'POST'
		});
	}
};
//字段UUID转换
export const transFieldsUUid = (obuuid, formuuid, tag = '') => {
	let requestConfig = {
		url: `api/metadata/transformuuid/${formuuid || obuuid}`,
		method: 'GET'
	}
	if (tag && typeof tag === 'string') {
		requestConfig.params = { tag }
	} else {
		if (tag && typeof tag === 'object') {
			requestConfig.params = tag
		}
	}
	return axios.request(requestConfig);
};

/**
 * @description 获取天气详情
 */
export const getWeather = () => {
	return axios.request({
		url: 'https://v0.yiketianqi.com/api/',
		params: {
			appid: '77521772',
			appsecret: 'Oec5XY9T',
			version: 'v9' //
		},
		method: 'get'
	})
}

/**
 * @description: 甘特图
 * @param {*} url
 * @param {*} data
 * @param {*} id
 * @return {*}
 */
export const getRequestId = (url, data, id) => {
	return axios.request({
		url: 'api/' + url + '/' + id,
		params: data,
		method: 'get'
	})
}