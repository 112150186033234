import { dataInterface } from '@/apis/data/index';
import {flowGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
	addExtendDesign(){
		this.extendDialog = true
	},
	confirmSetting(designData){
		const extendFields = JSON.parse(designData.json)
		this.flowStep.extendFields = extendFields
		this.extendDialog = false
		this.onChange()
	},
	addBtn(){
		const btn = {btn:'按钮',status: 1,show: true,system:0}
		this.btnList.push(btn)
	},
	delBtn(index){
		this.btnList.splice(index,1)
	},
	upGo(index){
		let fieldData = this.btnList;
		if(index!=0){
			fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
		}else{
			fieldData.push(fieldData.shift());
		}
		this.btnList = fieldData;
		this.onChange();
	},
	downGo(index){
		let fieldData = this.btnList;
		if(index != fieldData.length-1){
			fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
		}else{
			fieldData.unshift( fieldData.splice(index,1)[0]);
		}
		this.btnList = fieldData;
		this.onChange();
	},
	onTypeChange(){
		if(this.flowStep.userType === 6 || this.flowStep.sendType === 4){
			this.getArchRole()
		}
		this.onChange();
	},
	getArchRole(flag = 'check'){
		let queryData;
		const archiInfo = this.$GetTargetArchi()
		if(
      (this.flowStep.userType === 4 && this.flowStep.userFrom === 1 && flag === 'check') ||
      (this.flowStep.sendType === 4 && this.flowStep.sendFrom === 1 && flag === 'send')
    ){
			queryData = {
				object_uuid:'object6170c260890d9',
				view_uuid:'view6172842c7f669',
				project_id:archiInfo.archi_id,
				transcode: 0,
				__method_name__:'dataList'
			}
		} else {
			queryData = {
				object_uuid:'object6170c260890d9',
				view_uuid:'view61728398b0390',
				company_id:archiInfo.company_id,
				transcode: 0,
				__method_name__:'dataList'
			}
		}
		dataInterface(queryData).then(res => {
			if(res.data.code === 200){
        if(flag === 'check'){
          this.roleList = res.data.data
        } else {
          this.sendRoleList = res.data.data
        }
			}
		})
	},
	getObjectFormList(){
		const objectUuid = this.obuuid || this.$route.query.obuuid
		if(objectUuid){
			const url = `api/metadata/getFormDesigns?object_uuid=${objectUuid}`
			dataInterface({},url,'GET').then(res => {
				if(res.data.code === 200){
					this.designList = res.data.data.filter(item=>item.uuid !== '')
				}
			})
		}
	},
	getObjectFields(){
		const objectUuid = this.obuuid || this.$route.query.obuuid
		const url = `api/metadata/objectFields?object_uuid=${objectUuid}&size=500`
		if(objectUuid){
			dataInterface({},url,'GET').then(res => {
				if(res.data.code === 200){
					this.objectFieldsList = res.data.data.data
				}
			})
		}
	},
	changeObjectFormField(formUuid){
		this.flowChange()
		if(formUuid){
			const url = `api/metadata/transform/${formUuid}`
			dataInterface({},url,'GET').then(res => {
				if(res.data.code === 200){
					const formDesignFields = res.data.data?.fields;
					if(formDesignFields instanceof Array){
						const fields = flowGetFormItem(formDesignFields);
						this.fieldData = fields;
					}
				}
			})
		}
	},
	onSelectDepart(depart) {
		let departId = Array.from(depart, (item) => item.id)
		this.flowStep.userSection = departId
		this.onChange()
	},
	onSelectChange(userData, picker) {
		let users = []
		for (let item of userData) {
			let user = {
				id: item.user_id,
				name: item.name,
				user_table: item.user_table,
				section_id: item.section_id
			}
			users.push(user)
		}
		let {pickerType} = picker
		if (pickerType === 'check') {
			this.targetUser = users
		} else if(pickerType === 'publisher'){
			this.publisher = users
		} else {
			this.sendUser = users
		}
		this.onChange()
	},
	userScopeChange(val, flag = 'check') {
		if(this.flowStep.userType === 6 || this.flowStep.sendType === 4){
      this.flowStep.sendRole = ''
			this.getArchRole(flag) //请求岗位接口
		}
		let targetArch = JSON.parse(localStorage.getItem('targetArchi'))
		const archId = targetArch.company_id ? targetArch.company_id : targetArch.parent_id
		if(this.flowStep.userFrom === 3){
			this.flowStep.userArch = archId
		} else {
			this.flowStep.userArch = 0
		}
		if(this.flowStep.sendFrom === 3){
			this.flowStep.sendArch = archId
		} else {
			this.flowStep.sendArch = 0
		}
		if(this.flowBranch.userType === 3){
			this.flowBranch.userArch = archId
		} else {
			this.flowBranch.userArch = 0
		}
		this.onChange()
	},
	flowChange() {
		let flowConfig = {
			name: this.flowConfig.flow_name,
			description: this.flowConfig.flow_desc,
			is_outer: this.flowData.is_outer,
			formUuid: this.flowConfig.formUuid,
			module: this.flowData.module,
			field_data: this.fieldData,
			carry_listen: this.flowSelectedEvent,
		}
		this.$emit('flow-change', flowConfig)
	},
	updated(propsData) {
		this.props = propsData
		this.active = 'flowSetting'
		if (!this.props.node) return
		let node = this.props.node
		this.nodeName = node.text
		this.recoverDisplay(node)
		this.onChange()
		switch (node.name) {
			case 'circle':
			case 'activityFinal':
				this.active = 'startEndSet'
				break
			case 'rectangle':
				this.active = 'nodeSetting'
				break
			case 'polyline':
				this.active = 'branchSetting'
				break
			default:
				this.active = 'flowSetting'
		}
	},
	addCondition(){
		let item = {field:'',judge:'gt',value:''};
		if(this.flowBranch.conditionCollect == undefined){
			this.flowBranch.conditionCollect = [];
		}
		this.flowBranch.conditionCollect.push(item);
		this.onChange();
	},
	delCondition(index){
		this.flowBranch.conditionCollect.splice(index,1);
		this.onChange();
	},
	openCheckEvent(eventType = 'trigger') {
		this.eventType = eventType
		this.flowEventModal = !this.flowEventModal
		if (eventType === 'trigger') {
			this.triggerTitle = '请选择流程触发器'
			this.selectedEventRows = this.stepSelectedEvent
		} else {
			this.triggerTitle = '请选择流程接收器'
			this.selectedEventRows = this.flowSelectedEvent
		}
	},
	resetCheckUser() {
		if (this.flowStep.userType === 1) {
			this.flowStep.userWho = 1
		} else if (
			this.flowStep.userType === 2 ||
			this.flowStep.userType === 7
		) {
			this.targetUser = []
			this.flowStep.userWho = 1
		} else if (
			this.flowStep.userType === 3 ||
			this.flowStep.userType === 4
		) {
			this.targetUser = []
		} else if (this.flowStep.userType === 5) {
			this.targetUser = []
			this.flowStep.userFrom = 1
		} else if (this.flowStep.userType === 6) {
			this.targetUser = []
			// this.flowStep.userFrom = 1
			// if (this.roleList.length > 0)
			// 	this.flowStep.userFrom = this.roleList[0].id
		}
	},
	resetSendUser() {
		if (this.flowStep.sendType === 1) {
			this.flowStep.sendWho = 1
		} else if (
			this.flowStep.sendType === 2 ||
			this.flowStep.sendType === 6
		) {
			this.sendUser = []
			this.flowStep.sendWho = 1
		} else if (this.flowStep.sendType === 3) {
			this.sendUser = []
			this.flowStep.sendFrom = 1
			if (this.sendRoleList.length > 0)
				this.flowStep.sendFrom = this.sendRoleList[0].id
		} else if (this.sendType === 4) {
			this.flowStep.sendWho = 1
			this.sendUser = []
		}
	},
	resetInitDisplay() {
		this.flowStep.userScope = false
		this.flowStep.userType = 7
		this.flowStep.userFrom = 1
		this.flowStep.userWho = 1
		this.flowStep.userArch = 0
		this.flowStep.userSection = []
		this.flowStep.userRole = []

		this.editFields = []
		this.targetUser = []
		this.roleList = []
		this.flowStep.turnTo = false
		this.flowStep.signType = 1
		this.flowStep.signRate = 1

		this.flowStep.sendType = 1
		this.flowStep.sendFrom = 1
		this.flowStep.sendWho = 1
		this.flowStep.sendArch = 0
		this.flowStep.sendRole = []

		this.flowStep.selectStep = false
		this.flowStep.attachRequired = false
		this.flowStep.suggestRequired = false
		this.flowStep.extendFields = {}
		this.sendUser = []
		this.sendRoleList = []

		this.flowBranch.conditionType = 1
		this.flowBranch.condition = []
		this.publisher = []
		this.btnList = [
			{btn:'退回',status: -1,show: true, system:1},
			{btn:'通过',status: 1,show: true, system:1},
			{btn:'转交',status: 2,show: true, system:1},
			{btn:'重新发起',status: 3,show: true, system:1},
			{btn:'提交至退回人',status: 4,show: true, system:1},
		]
		this.flowBranch.conditionCollect = [
			{
				field: '',
				judge: 'gt',
				value: '',
			},
		]
	},
	recoverDisplay(node) {
		this.resetInitDisplay()
		if (node.data != '') {
			switch (node.name) {
				case 'circle':
				case 'activityFinal':
					this.flowStep.selectStep = node.data.select_step || false
					this.flowStep.sendType = node.data.send_type
					this.flowStep.sendFrom = node.data.send_from
					this.flowStep.sendWho = node.data.send_who
					this.flowStep.sendArch = node.data.send_arch || 0
					this.flowStep.sendRole = node.data.send_role || []
					this.sendUser = node.data.send_user || []
					this.sendRoleList = node.data.sendRoleList || []
					break
				case 'rectangle':
					this.flowStep.selectStep = node.data.select_step || false
					this.flowStep.userScope = node.data.user_scope || false
					this.flowStep.userType = node.data.user_type
					this.flowStep.userFrom = node.data.user_from
					this.flowStep.userWho = node.data.user_who
					this.flowStep.userArch = node.data.user_arch || 0
					this.flowStep.userRole = node.data.user_role || []
					this.flowStep.userSection = node.data.user_section || []

					this.editFields = node.data.edit_fields || []
					this.targetUser = node.data.target_user || []
					this.roleList = node.data.roleList || []
					this.flowStep.turnTo = node.data.turn_to
					this.flowStep.signType = node.data.sign_type
					this.flowStep.signRate = node.data.sign_rate

					this.flowStep.attachRequired = node.data.attachRequired || false
					this.flowStep.suggestRequired = node.data.suggestRequired || false
					this.flowStep.extendFields = node.data.extendFields || {}

					this.flowStep.sendType = node.data.send_type
					this.flowStep.sendFrom = node.data.send_from
					this.flowStep.sendWho = node.data.send_who
					this.flowStep.sendArch = node.data.send_arch
					this.flowStep.sendRole = node.data.send_role || []
					this.sendUser = node.data.send_user || []
					this.sendRoleList = node.data.sendRoleList || []
					this.stepSelectedEvent = node.data.task_listen || []
					// eslint-disable-next-line no-case-declarations
					const btnList = node.data?.btn_group || []
					if(btnList.length>0){
						this.btnList = btnList
					}
					break
				case 'polyline':
					this.flowBranch.conditionType = node.data.condition_type || 1
					this.flowBranch.userType = node.data.user_type || 1
					this.flowBranch.userArch = node.data.user_arch || 0
					this.flowBranch.condition = node.data.condition || []
					this.publisher = node.data.publisher || []
					this.flowBranch.conditionCollect =
						node.data.condition_collect || []
					break
			}
			if(this.flowStep.userType === 6 || this.flowStep.sendType === 4){
				this.getArchRole() //请求岗位接口
			}
		}
	},
	stepText(data) {
		let prefix = '审批人：'
		let textConf = {
			1: '指定人员',
			2: '发起人自选',
			3: '连续多级主管',
			4: '主管',
			5: '部门',
			6: '岗位',
			7: '流程中指定',
			8: '空步骤',
			9: '发起人审核',
		}
		if (data.user_type === 1) {
			let nameArr = Array.from(data.target_user, (item) => item.name)

			if (nameArr.length > 0) {
				let nameStr = nameArr.join('，')
				prefix += nameStr
			} else {
				prefix += textConf[data.user_type]
			}
		} else {
			prefix += textConf[data.user_type]
		}
		return prefix
	},
	onChange() {
		this.props.node.text = this.nodeName
		let node = this.props.node,
			data = {},
			nodeType,
			prefix,
			defaultName
		switch (node.name) {
			case 'circle':
			case 'activityFinal':
				nodeType = node.name === 'circle' ? 'start' : 'end'
				this.resetSendUser()
				data = {
					nodeType: nodeType,
					nodeId: node.id,
					name: node.text,
					select_step: this.flowStep.selectStep,
					send_type: this.flowStep.sendType,
					send_from: this.flowStep.sendFrom,
					send_who: this.flowStep.sendWho,
					send_arch: this.flowStep.sendArch,
					send_user: this.sendUser,
					send_role: this.flowStep.sendRole,
					sendRoleList: this.sendRoleList,
				}
				break
			case 'rectangle':
				this.resetCheckUser()
				this.resetSendUser()
				data = {
					nodeType: 'step',
					nodeId: node.id,
					name: node.text,
					select_step: this.flowStep.selectStep,
					user_scope: this.flowStep.userScope,
					user_type: this.flowStep.userType,
					user_from: this.flowStep.userFrom,
					user_who: this.flowStep.userWho,
					user_arch: this.flowStep.userArch,
					user_empty: this.flowStep.userEmpty,
					target_user: this.targetUser,
					send_type: this.flowStep.sendType,
					send_from: this.flowStep.sendFrom,
					send_who: this.flowStep.sendWho,
					send_arch: this.flowStep.sendArch,
					send_user: this.sendUser,
					turn_to: this.flowStep.turnTo,
					sign_type: this.flowStep.signType,
					sign_rate: this.flowStep.signRate,
					edit_fields: this.editFields,
					btn_group: this.btnList,
					carry_listen: [],
					task_listen: this.stepSelectedEvent,
					color_status: 0,

					user_section: this.flowStep.userSection,
					user_role: this.flowStep.userRole,
					send_role: this.flowStep.sendRole,

					attachRequired: this.flowStep.attachRequired,
					suggestRequired: this.flowStep.suggestRequired,
					extendFields: this.flowStep.extendFields,
					roleList: this.roleList,
					sendRoleList: this.sendRoleList,
				}
				//审批人员，步骤文案输出
				prefix = this.stepText(data)
				defaultName = [
					'审批人：指定人员',
					'审批人：发起人自选',
					'审批人：连续多级主管',
					'审批人：主管',
					'审批人：部门',
					'审批人：岗位',
					'审批人：流程中指定',
					'审批人：空步骤',
					'审批人：发起人审核',
				]
				if (
					defaultName.indexOf(this.nodeName) === -1 &&
					data.user_type !== 1
				) {
					prefix = this.nodeName
				}
				this.props.node.text = prefix
				this.nodeName = prefix
				data.name = prefix
				break
			case 'polyline':
				if (this.flowBranch.conditionType == 1) {
					this.flowBranch.condition = this.publisher
				} else if (this.flowBranch.conditionType == 2) {
					this.flowBranch.condition = this.flowBranch.conditionCollect
				}
				data = {
					nodeType: 'line',
					nodeId: node.id,
					name: node.text,
					from: node.from.id,
					to: node.to.id,
					condition_type: this.flowBranch.conditionType,
					user_type: this.flowBranch.userType,
					user_arch: this.flowBranch.userArch,
					condition: this.flowBranch.condition,
					color_status: 0,
					publisher: this.publisher,
					condition_collect: this.flowBranch.conditionCollect,
				}
				break
		}

    console.log(data, 9999)

		this.props.node.data = data
		this.$emit('change', this.props.node)
	},
}
