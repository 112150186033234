<!--
 * @Description: 基础日期
 * @Author: luocheng
 * @Date: 2022-05-09 15:01:13
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-07-27 17:21:07
-->
<template>
  <div class="common-month-filter" :class="[statusConfig.transparent ? 'transparent-bg' : '', element.id]">
    <!-- 单日、月 -->
    <el-date-picker
      v-if="statusConfig.type === 'date' || statusConfig.type === 'month'"
      v-model="dateResult"
      :type="statusConfig.type"
      :value-format="statusConfig.valueFormat"
      @change="onSign"
      placeholder="请选择">
    </el-date-picker>
    <!-- 日期、月份 段 -->
    <el-date-picker
      v-else
      v-model="dateResult"
      :type="statusConfig.type"
      :value-format="statusConfig.valueFormat"
      range-separator="至"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      :picker-options="pickerOptions"
      @change="onBetween">
    </el-date-picker>
  </div>
</template>

<script>
import { DatePicker } from 'element-ui';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonBaseDate',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    'el-date-picker': DatePicker
  },
  data() {
    return {
      dateResult: '',
      pickerOptions: {
      }
    }
  },
  computed: {
    statusConfig() {
      return this.element?.statusConfig || {};
    },
    shortcuts() {
      return this.statusConfig?.shortcuts || [];
    },
    // 影藏边框
    hideBorder() {
      return this.statusConfig?.hideBorder;
    },
    // 高度
    selectHeight() {
      return this.statusConfig?.selectHeight
    },
    // 隐藏应用
    hideIcon() {
      return this.statusConfig?.hideIcon;
    },
    dateType() {
      return this.statusConfig?.type
    }
  },
  watch: {
    shortcuts: {
      handler() {
        this.pickerOptions.shortcuts = [];
        if (Array.isArray(this.shortcuts)) {
          this.initShortcuts(this.shortcuts);
        }
      },
      deep: true,
      immediate: true
    },
    selectHeight: {
      handler(newVal) {
        this.$nextTick(() => {
          if (this.dateType === 'date' || this.dateType === 'month') {
            const select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`)
            const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`)
            const selectIconI = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix i`)
            const statusHeight = isNaN(+newVal) ? '36px' : `${newVal}px`;
            select.style.height = statusHeight
            selectIcon.style.height = statusHeight;
            selectIcon.style.lineHeight = statusHeight;
            selectIconI.style.lineHeight = statusHeight;
          } else {
            const dateEditor = document.querySelector(`.${this.element.id} .el-date-editor`);
            const select = document.querySelector(`.${this.element.id} .el-date-editor .el-range-input`);
            const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__icon `)
            const statusHeight = isNaN(+newVal) ? '36px' : `${newVal}px`;
            const selectSeparator = document.querySelector(`.${this.element.id} .el-range-separator`);
            select.style.height = statusHeight
            dateEditor.style.height = statusHeight;
            selectIcon.style.height = statusHeight;
            selectIcon.style.lineHeight = statusHeight;
            selectIcon.style.overflow = 'hidden';
            selectSeparator.style.height = statusHeight;
            selectSeparator.style.lineHeight = statusHeight;
            // selectIconI.style.lineHeight = statusHeight;
          }
        });
      },
      deep: true,
      immediate: true
    },
    hideBorder: {
      handler(newVal) {
        let select = null;
        this.$nextTick(() => {
          if (this.statusConfig.type === 'date' || this.statusConfig.type === 'month') {
            select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`)
          } else {
            select = document.querySelector(`.${this.element.id} .el-date-editor`)
          }
          select.style.border = newVal ? 'none' : '1px solid #DCDFE6';
        });
      },
      deep: true,
      immediate: true
    },
    hideIcon: {
      handler(newVal) {
      this.$nextTick(() => {
        let selectIcon = null;
        if (this.statusConfig.type === 'date' || this.statusConfig.type === 'month') {
          selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`)
        } else {
          selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__icon`)
        }
				selectIcon.style.display = newVal ? 'none' : 'block';
			});
    },
    deep: true,
    immediate: true
    }
  },
  mounted() {
      this.$nextTick(() => {
      const select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`)
      const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`)
      const selectIconI = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix i`)
      if (select && selectIconI && selectIcon) {
        let { hideBorder, selectHeight = 36, hideIcon, type } = this.statusConfig;
        const statusHeight = isNaN(+selectHeight) ? '36px' : `${selectHeight}px`;
        select.style.height = statusHeight
        selectIcon.style.height = statusHeight;
        selectIcon.style.lineHeight = statusHeight;
        selectIconI.style.lineHeight = statusHeight;
        select.style.border = hideBorder ? 'none' : '1px solid #DCDFE6';
        selectIcon.style.display = hideIcon ? 'none' : 'block';
        if (type !== 'date' && type !== 'month') {
          // 时间段
          const selectDuring = document.querySelector((`.${this.element.id} .el-date-editor`))
          selectDuring.style.border = hideBorder ? 'none' : '1px solid #DCDFE6';
        }
      }
    });
  },
  methods: {
    /**
     * @desc: 单日月
     * @param {String} val
     */
    onSign(val) {
      this.element.resolveData = {
        date: val || []
      };
      this.onSearch();
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData: {
          date: val || []
        }
			});
    },
    /**
     * @desc: 时间段 格式化暴露出去的结果数据
     */
    onBetween(val) {
       let result = val;
      // 暴露数据
      const { valueFormat = 'yyyy-MM-dd' } = this.statusConfig;
      try {
        let endTime = result[1];
        const times = endTime.toString().split(' ');
        let timeEnd = times[1];
        if (valueFormat === 'yyyy-MM-dd HH:mm' || valueFormat === 'yyyy-M-d H:m') {
          timeEnd = '23:59'
        } else if (valueFormat === 'yyyy-MM-dd HH:mm:ss' || valueFormat === 'yyyy-M-d H:m:s') {
          timeEnd = '23:59:59'
        }
        if (timeEnd) {
          endTime =  `${times[0]} ${timeEnd}`;
        }
        // 月份段，则将结束时间由结束月一日改为最后一天
        if (this.statusConfig.type === 'monthrange') {
          const arrList = endTime.split(' ');
          if (arrList.length > 0) {
            const dateMap = arrList[0]?.split('-');
            if (dateMap.length) {
              let monthDayCount = 30;
              if ([1, 3, 5, 7, 8, 10, 12].includes(+dateMap[1])) {
                monthDayCount = 31;
              } else if (+dateMap[1] === 2) {
                // 平闰年
                if (((+dateMap[0]) % 4 === 0 && (+dateMap[0]) & 100 !== 0) || (+dateMap[0]) % 400 === 0) {
                  monthDayCount = 29;
                }
                monthDayCount = 28;
              }
              arrList.splice(0, 1, `${dateMap[0]}-${dateMap[1]}-${monthDayCount}`);
              endTime = arrList.join(' ');
            }
          }
        }
        result.splice(1, 1, endTime);
      } catch (e) {
        console.log(e);
      }
			this.element.resolveData = {
        date: result || []
      };
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData: {
          date: val || []
        }
			});
      this.onSearch();
    },
    /**
     * @desc: 搜索
     */
    onSearch() {
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'any',
        output: this.dateResult,
        isInit: false,
        noUpdate: false
      });
    },
    /**
     * @desc: 操作快捷操作项
     * @param {Array} arr
     */
    initShortcuts(arr) {
      const shortcuts = [];
      const that = this;
      arr.forEach(ele => {
        // 仅在时间段使用今天 近三天 和近一周
        if(ele === 'targetDay' && this.statusConfig.type === 'daterange') {
          shortcuts.push({
            text: '今天',
            onClick(picker) {
              const start = new Date();
              picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
            }
          })
        } else if(ele === 'threeDays' && this.statusConfig.type === 'daterange') {
          shortcuts.push({
            text: '近三天',
            onClick(picker) {
              const start = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000);
              picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
            }
          })
        } else if(ele === 'targetWeek' && this.statusConfig.type === 'daterange') {
          shortcuts.push({
            text: '近一周',
            onClick(picker) {
              const start = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);
              picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
            }
          })
        } else if (ele === 'targetMonth') {
          shortcuts.push({
            text: '本月',
            onClick(picker) {
              const start = new Date();
              picker.$emit('pick', [that.setStartDate(start), that.setEndDate(new Date())]);
            }
          })
        } else if (ele === 'targetYear') {
          shortcuts.push({
            text: '今年至今',
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
            }
          })
        } else if (ele === 'threeMonth') {
          shortcuts.push({
            text: '近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
            }
          })
        } else if (ele === 'sixMonth') {
          shortcuts.push({
            text: '近六个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
            }
          })
        } else if (ele === 'oneYear') {
          shortcuts.push({
            text: '近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
            }
          })
        }
      });
      this.pickerOptions = {
        ...this.pickerOptions,
        shortcuts
      }
    },
    /**
     * @desc: 设置开始日期为 1日 00:00:00
     * @param {Object<Date>} date
     * @param {Boolean} ignorDate 是否忽略设置天
     */
    setStartDate(date, ignorDate = false) {
      if(!ignorDate) {
        date.setDate(1);
      }
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },
    /**
     * @desc: 设置结束日期为  X日 23:59:59
     * @param {Object<Date>} date
     */
    setEndDate(date) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      return date;
    }
  }
}
</script>

<style lang="less" scoped>
.common-month-filter{
  width: 100%;
  display: flex;
  &.transparent-bg{
    :deep(.el-date-editor){
      input{
        background: transparent;
        color: #fff;
        &::-webkit-input-placeholder{
            color:#fff;
        }
        &::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color:#fff;
        }
        &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
            color:#fff;
        }
        &:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
            color:#fff;
				}
      }
      .el-input__prefix,.el-input__suffix{
        color: #fff;
      }
    }
    :deep(.el-date-editor,.el-range-editor, .el-input__inner, .el-date-editor--monthrange, .el-range-editor--small) {
      background: transparent;
      color: #fff;
      &::-webkit-input-placeholder{
          color:#fff;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
          color:#fff;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
          color:#fff;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
          color:#fff;
      }
      .el-range-separator{
        color: #fff;
        font-weight: bold;
      }
      .el-input__prefix,.el-input__suffix, i{
        color: #fff;
      }
    }
  }
  :deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
    flex: 1;
  }
}
</style>