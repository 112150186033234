import { axios } from '../http/request';

export const cptureImgApi = (id, serial, no, params) => axios.request({
  url: `api/monitorPlatform/apps/${id}/devices/${serial}/channels/${no}/capture`,
  params,
  method: 'get'
})
// 监控收藏
export const monitorCollectionListApi = (params) => axios.request({
  url: 'api/monitorCollection/resource',
  params,
  method: 'get'
})
export const monitorCollectionAddApi = (data) => axios.request({
  url: 'api/monitorCollection/resource',
  data,
  method: 'post'
})
export const monitorCollectionEditApi = (data, id) => axios.request({
  url: 'api/monitorCollection/resource/' + id,
  data,
  method: 'put'
})
export const monitorCollectionDelApi = (id) => axios.request({
  url: 'api/monitorCollection/resource/' + id,
  method: 'delete'
})
export const jumpPositionApi = (data) => axios.request({
  url: 'api/monitorCollection/jumpPosition',
  data,
  method: 'post'
})