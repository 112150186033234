<template>
  <div class="h-full">
    <template v-if="pageUUID">
      <PageParser :pageUUID="pageUUID" :defaultTableSelectData="defaultSelect"></PageParser>
    </template>
    <template v-else>
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        border
        class="w-full"
        :height="tableHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="40">
        </el-table-column>
        <!-- <el-table-column label="ID" width="80" prop="id"></el-table-column> -->
        <template v-if="Object.keys(dicts).length">
          <el-table-column
            v-for="(item, name, index) in dicts"
            :key="index"
            :label="item"
            :prop="name"
          ></el-table-column>
        </template>

        <template v-else>
          <el-table-column label="暂无数据项"></el-table-column>
        </template>

      </el-table>
      <el-pagination
        style="float: right; margin-top: 8px"
        v-if="total !== null"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import { formRequest } from "@/apis/data/form";
import { Pagination } from "element-ui";

export default {
  name: "list",
  components:{
    PageParser: () => import('@/components/parser/Index'),
    'el-pagination':Pagination
  },
  props: {
    pageUUID: {
      type: String,
      default: '',
    },
    needPage: {
      type: Boolean,
      default: true,
    },
    filterRule: {
      type: Array,
      default: () => {
        return [];
      }
    },
    relateData: {
      type: Array,
      default: () => []
    },
    relateConfig: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    tableHeight() {
      if (this.total === null) {
        return "100%";
      } else {
        return "calc(100% - 44px)";
      }
    },
  },
  data() {
    return {
      defaultSelect: this.relateData,

      tableData: [],
      currentPage: 1,
      tableLoading: false,
      total: null,
      size: 14,
      timer: null,
      dicts: {},
      selectMultiple:{},
      multiSelected:[],
    };
  },
  watch: {
    filterRule:{
      handler(){
        if(!this.pageUUID) this.getDatas();
      },
      deep:true
    },
  },
  methods: {
    getSelection(){
      let object = this.dicts; //查找主键索引key
      let idKey = Object.keys(object).find(key => object[key] === 'id');
      return {idKey:idKey,rows: this.multiSelected}
    },
    handleSelectionChange(val){
      this.selectMultiple[this.currentPage] = val;
      let multiSelected = Object.values(this.selectMultiple).flat();
      this.multiSelected = multiSelected;
      // this.$emit('select-relate',idKey,multiSelected)
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getDatas();

    },
    handleSizeChange(size) {
      this.size = size;
      this.getDatas();
    },
    getDatas() {
      let dataType = this.relateConfig.__config__.dataType
      let searchData = []; //过滤条件
      if(this.filterRule instanceof Array){
        for (let item of this.filterRule){
          searchData.push(item)
        }
      }
      this.tableLoading = true;

      let obuuid = this.relateConfig.__config__.objectUuid
      let view_uuid = this.relateConfig.__config__.viewUuid
      let url,param;
      if(obuuid && view_uuid){
        url = '/api/mapi'
        param = {
          object_uuid: obuuid,
          view_uuid: view_uuid,
          search: searchData,
          page: this.currentPage,
          size: this.size,
          __method_name__: "dataList"
        }
      } else if (dataType === 'interface') {
        url = '/api/form4/funcDataList'
        let params = {};
        let paramData = this.relateConfig.__config__.params;
        for (let item of paramData){
          params[item.name] = item.value
        }
        param = {
          function:this.relateConfig.__config__.function,
          params:params,
          search: searchData, //通用搜索
        }
      }
      if(url && param){
        formRequest('post',url,param).then(res=>{
          if (res.data.data.data) {
            this.tableData = res.data.data.data;
          } else {
            this.tableData = res.data.data || [];
          }
          this.dealWithTableData(res)
        })
      }
    },
    dealWithTableData(res){
      let dicts = {};
      this.tableData.forEach((item, index) => {
        let keys = Object.keys(item);
        keys.forEach((key) => {
          if (index == 0) {
            // 过滤metadata	没在接口数据中出现的字段不予显示
            if(!['创建日期','更新日期','删除日期'].includes(res.data.metadata[key])){
              dicts[key] = res.data.metadata[key];
            }
          }
          if (item[key] instanceof Array) {
            item[key] = "共" + item[key].length + "条";
          }
        });
      });
      this.dicts = dicts;
      this.tableLoading = false;
      if (res.data.data.total) {
        this.total = res.data.data.total;
      }
      this.$nextTick(res=>{
        let idKey = Object.keys(dicts).find(key => dicts[key] === 'id');
        this.tableData.forEach(item=>{
          if(this.relateData.includes(item[idKey])){
            this.$refs.multipleTable.toggleRowSelection(item);
          }
        })
      })
    },
  },
  mounted() {
    if(!this.pageUUID) this.getDatas();
  },
};
</script>
<style scoped>
.h-full {
  height: 100%;
}
</style>
