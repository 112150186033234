<!--
 * @Description: 文件夹模式
 * @Author: luocheng
 * @Date: 2022-03-04 13:57:05
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-18 16:06:16
-->
<template>
  <div class="folder-list-wrap">
    <div class="check" v-if="statusConfig.batchAction">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"></el-checkbox>
      <div class="header-actions-wrap">
        <template v-if="!selectList || !selectList.length">全选</template>
        <template v-else>已选{{ selectList.length }}项</template>
        <ul class="header-actions" v-if="selectList && selectList.length && !samll">
          <li class="action-item" @click="onBatchAction('download')">下载</li>
          <li class="action-item" @click="onBatchAction('move')">移动</li>
          <li class="action-item" @click="onBatchAction('copy')">复制</li>
          <li class="action-item delete" @click="onBatchAction('delete')">删除</li>
        </ul>
      </div>
    </div>
    <template v-if="fileList && Array.isArray(fileList) && fileList.length">
      <el-checkbox-group v-if="statusConfig.batchAction" class="folder-list" v-model="selectList">
        <template v-for="file in fileList" >
          <section :key="file.id" class="folder-item" @click="onFile(file)">
            <el-checkbox class="check-item" @click.native.stop="" :label="file">&nbsp;</el-checkbox>
            <span v-if="statusConfig.showActions && !samll" class="actions">
              <ActionMenu  :rowData="file" @action="onAction"></ActionMenu>
            </span>
            <i v-if="file.type_id === 4 && !file.path" class="iconfont upload-area" @drop.prevent="handleDrop(file, $event)"  @dragover.prevent="dragover = true"  @dragleave.prevent="dragover = false" :class="[getClass(file)]"></i>
            <i v-else-if="file.type_id === 4 && file.path" class="iconfont upload-area iconzhanweifu" @drop.prevent="handleDrop(file, $event)"  @dragover.prevent="dragover = true"  @dragleave.prevent="dragover = false"></i>
            <i v-else class="iconfont" :class="[getClass(file)]"></i>
            <span class="name">{{ file.name }}</span>
          </section>
        </template>
      </el-checkbox-group>
      <div v-else class="folder-list">
        <template v-for="file in fileList" >
          <section :key="file.id" class="folder-item" @click="onFile(file)">
            <span v-if="statusConfig.showActions && !samll" class="actions">
              <ActionMenu  :rowData="file" @action="onAction"></ActionMenu>
            </span>
            <i v-if="file.type_id === 4 && !file.path" class="iconfont upload-area" @drop.prevent="handleDrop(file, $event)"  @dragover.prevent="dragover = true"  @dragleave.prevent="dragover = false" :class="[getClass(file)]"></i>
            <i v-else-if="file.type_id === 4 && file.path" class="iconfont upload-area iconzhanweifu" @drop.prevent="handleDrop(file, $event)"  @dragover.prevent="dragover = true"  @dragleave.prevent="dragover = false"></i>
            <i v-else class="iconfont" :class="[getClass(file)]"></i>
            <span class="name">{{ file.name }}</span>
          </section>
        </template>
      </div>
    </template>
    <el-empty description="暂无文件" v-else></el-empty>
    <div class="action-wrap" v-if="selectList && selectList.length && samll">
      <ul class="action-list">
        <li class="action-item" @click="onBatchAction('move')">
          <i class="iconfont iconyidong1"></i>
          <span>移动</span>
        </li>
        <li class="action-item" @click="onBatchAction('download')">
          <i class="iconfont iconxiazai1"></i>
          <span>下载</span>
        </li>
        <li class="action-item" @click="onBatchAction('copy')">
          <i class="iconfont iconfuzhi"></i>
          <span>复制</span>
        </li>
        <li class="action-item" v-if="selectList.length === 1" @click.stop="onAction({
          type: 'modify',
          rowData: selectList[0]
        })">
          <i class="iconfont iconzhongmingming1"></i>
          <span>重命名</span>
        </li>
        <li class="action-item" v-if="selectList.length === 1 && selectList?.[0].type_id === 4 && selectList?.[0].path" @click.stop="onAction({
          type: 'replace',
          rowData: selectList[0]
        })">
          <i class="iconfont icontihuan"></i>
          <span>替换</span>
        </li>
        <li class="action-item" @click="onBatchAction('delete')">
          <i class="iconfont iconshanchu4"></i>
          <span>删除</span>
        </li>
      </ul>
    </div>
    <!-- 重命名 -->
    <Rename
      v-if="currenData && showRename"
      v-model="showRename"
      :fileName="currenData && currenData.name"
      @nameResult="onRename"
    >
    </Rename>
    <LinkModel
      v-if="currenData && showLinkModify"
      v-model="showLinkModify"
      :inputData="currenData"
      @submit="onModifyRow"
    >
    </LinkModel>
    <CreatePlaceholder
      v-if="currenData && showPlaceholderModify"
      v-model="showPlaceholderModify"
      :fileName="currenData && currenData.name"
      @nameResult="onRename"
    >
    </CreatePlaceholder>
    <!-- 移动文件 -->
    <!-- 操作文件(批量) -->
    <OperationFile
      v-if="showBatchOperation"
      v-model="showBatchOperation"
      :type="batchAction"
      :movedData="selectList"
      @updateList="onBatchUpdate"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
    >
    </OperationFile>
    <div class="upload-com">
      <input type="file" ref="fileChose">
      <FileUpload ref="uploadCom" @extra-files="onUploadSuccess"/>
    </div>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
import FileUpload from './FileUpload';
import mixin from '../mixin';
import { dataInterface } from '@/apis/data';
import { CheckboxGroup } from 'element-ui'

import ActionMenu from './ActionMenu';
import Rename from './Rename';
import OperationFile from './OperationFile';
import LinkModel from './LinkModel'
import CreatePlaceholder from './CreatePlaceholder'
import { downloadFileBlob } from '@/utils/tools';

export default {
  name: 'FolderView',
  mixins: [mixin],
  data() {
    return {
      dragover: false,
      selectList: [],
      currenData: {},
      // 弹窗
      showRename: false,
      showLinkModify: false,
      showPlaceholderModify: false,
      showBatchOperation: false,
      // 批量操作
      batchAction: 'move'
    }
  },
  components: {
    'el-empty': Empty,
    FileUpload,
    ActionMenu,
    Rename,
    OperationFile,
    LinkModel,
    CreatePlaceholder,
    'el-checkbox-group': CheckboxGroup
  },
  inject: ['element'],
  computed: {
    actionConfig() {
      return this.element?.actionConfig;
    },
    statusConfig() {
      return this.element?.statusConfig;
    },
    database() {
      return this.element?.database;
    },
    samll() {
      return this.mode === 'samll'
    },
    isIndeterminate() {
      return this.selectList.length > 0 && this.selectList.length < this.fileList.length;
    },
    checkAll: {
      get() {
        return this.fileList.length === this.selectList.length
      },
      set(v) {
        this.selectList = v ? this.fileList : []
      }
    }
  },
  props: {
    fileList: {
      type: Array,
      required: true,
      default: () => []
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
    mode: {
      type: String,
      default: 'normal'
    }
  },
  watch: {
    fileList: {
      handler() {
        // 每次更新清空选择列表
        this.selectList = [];
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @description: 选择文件上传
     * @param {*} row
     * @return {*}
     */    
    handleChoseFile(row) {
      if(!this.$refs.fileChose) return;
      this.$refs.fileChose.click();
      this.$refs.fileChose.onchange = (e) => {
        const files = e.target.files;
        if(files.length) {
          const file = files[0];
          const reg = new RegExp(`^${row.name}` + '\\.[a-z1-9A-z]+$');
          if(reg.test(file.name) || row.name === file.name) {
            file.sourceID = row.id;
            this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
          } else {
            this.$message('请确保名称一致');
          }
        }
        e.target.files = null;
      }
    },
    /**
     * @description: 拖拽文件上传
     * @param {*} row
     * @param {*} e
     * @return {*}
     */    
    handleDrop(row, e) {
      e.stopPropagation();
      e.preventDefault();
      this.dragover = false;
      if(e.dataTransfer.files.length) {
        const file = e.dataTransfer.files[0];
        const reg = new RegExp(`^${row.name}` + '\\.[a-z1-9A-z]+$');
        if(reg.test(file.name) || row.name === file.name) {
          if(row.path) {
            this.$confirm('此操作将替换文件， 不可撤销, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              file.sourceID = row.id;
              this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
            }).catch(() => {
              // nothing todo
            });
          } else {
            file.sourceID = row.id;
            this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
          }
        } else {
          this.$message('请确保名称一致');
        }
      }
    },
    /**
     * @desc: 上传成功
     * @param {Object} data 上传成功的返回数据
     */
    onUploadSuccess(data) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.upload?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'updateData',
        data_id: data.sourceID, // 更新数据
        name: data.name,
        path: data.filepath,
        hash: data.file_md5,
        file_size: data.filesize,
        ext: data.fileext,
        full_data: data,
        ...param
      });
    },
    /**
     * @desc: 创建数据或编辑数据
     * @param {Object} params 参数
     */
    modifyData(params) {
      // TODO NET
      const { objectData = {} } = this.database;
      if (!objectData) {
        this.$message.error('操作失败！');
        return;
      }
      const loading = this.$loading();
      dataInterface({
        ...params,
        object_uuid: objectData.uuid
      }).then(res => {
        loading.close();
        if (res.status === 200 && res.data.code === 200) {
          this.$message.success('操作成功！');
          this.showRename = false;
          this.showLinkModify = false;
          this.showPlaceholderModify = false;
          this.$emit('updateList');
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('操作失败！');
        loading.close();
      })
    },
    /**
     * @desc: 点击
     * @param {Object} item 数据
     */
    onFile(item) {
      this.$emit('selectFile', item);
      if (+item.type_id === 4 && !item.path) {
        this.handleChoseFile(item)
      }
    },
    /**
     * @description: 上传更新文件
     * @param {*} row
     * @return {*}
     */    
    updateFile(row) {
      if (+row.type_id === 4 && row.path) {
        this.$confirm('此操作将替换文件， 不可撤销, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleChoseFile(row)
        }).catch(() => {
          // nothing todo
        });
      }
    },
    // 文件操作
    async onAction(data) {
      const { type, rowData } = data;
      this.currenData = rowData;
      if (!type || !rowData) {
        this.$message.error("出错了，请重试!");
        return false;
      }
      if (type === "delete") {
        // 删除
        this.doDelete();
      } else if (type === "modify") {
        if (rowData.type_id === 3) {
          // 编辑重命名链接
          this.showLinkModify = true
        } else if (rowData.type_id === 4) {
          // 编辑重命名链接
          this.showPlaceholderModify = true
        } else {
          // 编辑文件重命名
          this.showRename = true;
        }
      } else if (type === "move") {
        // 移动
        this.selectList = [rowData];
        this.batchAction = "move";
        this.showBatchOperation = true;
      } else if (type === "download") {
        // 下载
        let url = rowData.path || rowData.url;
        if (!url) {
          this.$message.error("文件地址错误!");
          return;
        }
        if (url.indexOf("http") === -1) {
          url = process.env.VUE_APP_BASE_URL + url;
        }
        const loading = this.$loading();
        await downloadFileBlob(url, rowData.name);
        loading.close();
      } else if(type === "replace") {
        this.updateFile(this.currenData)
      } else {
        this.$message.warning("功能开发中...");
      }
    },
    /**
     * @desc: 重命名
     * @param {String} name 新名成
     */
    onRename(name) {
      this.modifyData({
        __method_name__: "updateData",
        name,
        data_id: this.currenData.id,
      });
    },
    /**
     * @description: 修改链接数据
     * @param {Object} data 操作数据对象
     */
    onModifyRow(data) {
      this.modifyData({
        __method_name__: 'updateData',
        ...data,
        data_id: this.currenData.id
      });
    },
    /**
     * @desc: 删除
     */
    doDelete(deleteIds = []) {
      const confirmStr =
        Array.isArray(deleteIds) && deleteIds.length
          ? "是否确认删除选中文件、文件夹及所有下级?"
          : "";
      this.$confirm(confirmStr || `是否确认删除【${this.currenData.name}】?`)
        .then(() => {
          this.modifyData({
            __method_name__: "deleteData",
            data_id:
              Array.isArray(deleteIds) && deleteIds.length
                ? deleteIds
                : this.currenData.id,
          });
        })
        .catch(() => {});
    },
    /**
     * @desc: 更新列表
     */
    updateList() {
      this.$emit("updateList");
      this.currenData = null;
    },
    /**
     * @desc: 批量操作
     * @param {String} type 操作类型
     */
    onBatchAction(type) {
      this.batchAction = type;
      if (type === "download") {
        // 下载
        this.doBatchDownload();
      } else if (type === "move") {
        // 移动
        this.showBatchOperation = true;
      } else if (type === "copy") {
        // 复制
        this.showBatchOperation = true;
      } else if (type === "delete") {
        // 删除
        this.doDelete(this.selectList.map((ele) => ele.id));
      }
    },
    /**
     * @description: 批量下载
     * 后端@廖伟
     */
    doBatchDownload() {
      if (!this.selectList?.length) {
        this.$message.warning("请选择至少一个文件或文件夹!");
        return;
      }
      if (!this.element?.database?.objectData) {
        this.$message.error('配置错误！');
      }
      this.$loading({
        text: '文件下载中...'
      });
      const data_id = this.selectList.map(ele => {
        return ele.id;
      })
      dataInterface({
        __method_name__: "globalFunctionCall",
        typeName: "PublicFunc",
        type: "behavior",
        funcName: "NetdiskBatchFileDownload",
        payload: {
          object_uuid: this.element?.database?.objectData?.uuid, // 下载对象uuid
          data_id, // 下载数据id，可传数组
          parent_id_field: "", // 父id字段 默认: parent_id，若填写则为字段UUID
          name_field: "", // name字段 默认: name，若填写则为字段UUID
          file_path_field: "", // 文件path字段 默认: path，若填写则为字段UUID
          is_children: true, // 是否递归子文件夹 默认 true
          keep_structure: true // 保持文件夹结构 默认 true
        }
      }).then(async res => {
        this.$loading().close();
        if (res.status === 200 && res.data.code === 200) {
          const path = res.data.data;
          if (!path) {
            this.$message.error('下载错误，请联系管理员！');
            return;
          }
          await downloadFileBlob(path, '')
          this.$message.success('下载成功！');
          return;
        }
        // this.$message.error('下载错误，请联系管理员！');
      }).catch(err => {
        console.log(err, '---111---');
        this.$loading().close();
        this.$message.success('下载失败！');
      });
    },
    /**
     * @desc: 批量操作操作回调
     */
    onBatchUpdate() {
      this.selectList = [];
      this.updateList();
    },
    /**
     * @description: 获取class
     * @param {*} row
     * @return {*}
     */    
    getClass(row) {
      const { type_id, ext } = row;
      if (+type_id === 3) return 'iconlianjie';
      if (+type_id === 4) return 'iconxinzeng5'
      if (+type_id === 1) return 'iconwenjianjia';
      if (ext === 'xlsx' || ext === 'xls') {
        return 'iconexcle1x'
      } else if (ext === 'doc' || ext === 'docx') {
        return 'iconword1x'
      } else if (ext === 'txt') {
        return 'icontxt1x'
      } else if (ext === 'pptx' || ext === 'ppt') {
        return 'iconppt1x'
      } else if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(ext)) {
        return 'iconzhaopian1x'
      } else if (ext === 'pdf') {
        return 'iconpdf1x'
      }
      return 'iconother1x'
    }
  }
}
</script>

<style lang="less" scoped>
.folder-list-wrap{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .check{
    padding: 0 12px;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    display: flex;
    .header-actions-wrap{
      margin-left: 12px;
      display: flex;
      .header-actions {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding-left: 12px;
        .action-item {
          padding: 0 12px;
          color: @theme;
          &.delete {
            color: @dangerColor;
          }
        }
      }
    }
  }
  .folder-list{
    flex-grow: 1;
    height: 10px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .folder-item{
      width: 98px;
      height: 106px;
      padding: 0 32px 30px 32px;
      position: relative;
      text-align: center;
      .check-item{
        position: absolute;
        left: 12px;
        top: 6px;
      }
      .actions{
        position: absolute;
        right: 12px;
        top: 6px;
        line-height: 1;
      }
      .iconfont{
        display: inline-block;
        margin-right: 5px;
        font-size: 48px;
      }
      .upload-area{
        border-radius: var(--radius-3, 6px);
        border: 1px dashed var(--border-on-surface-strong, #C5C7D0);
        background: var(--overall-background-default, #F4F6F9);
        line-height: 48px;
        width: 48px;
        font-size: 24px;
      }
      .name{
        margin-top: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #25282e;
        text-align: center;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .action-wrap{
    width: 100%;
    .action-list{
      margin-top: 4px;
      display: flex;
      padding: 0 16px;
      border-radius: 6px;
      background: #F2F7FF;
      align-items: center;
      justify-content: space-between;
      .action-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4px 12px;
        box-sizing: border-box;
        border-radius: 6px;
        color: #387FFC;
        &:hover{
          background: #D1E3FF;
        }
        .iconfont{
          font-size: 18px;
        }
        span{
          margin-top: 4px;
          line-height: 18px;
          font-size: 12px;
        }
      }
    }
  }
  .el-empty{
    margin: 0 auto;
  }
  .iconwenjianjia {
    color: rgb(249, 194, 10)
  }
  .iconexcle1x {
    color: rgb(48, 165, 92);
  }
  .iconword1x {
    color: rgb(45, 133, 254);
  }
  .icontxt1x {
    color: rgb(45, 133, 254);
  }
  .iconzhaopian1x{
    color: rgb(44, 162, 92);
  }
  .iconpdf1x{
    color: rgb(226, 52, 45);
  }
  .iconppt1x{
    color: rgb(246, 123, 30);
  }
  .iconother1x{
    color: rgb(171, 189, 204);
  }
  .upload-com{
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
</style>