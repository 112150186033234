import { render, staticRenderFns } from "./InputItem.vue?vue&type=template&id=0fc9988e&scoped=true&"
import script from "./InputItem.vue?vue&type=script&lang=js&"
export * from "./InputItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc9988e",
  null
  
)

export default component.exports