/*
 * @LastEditTime: 2023-07-04 18:15:23
 * @Description: 请求接口或者方法结果缓存
 * @FilePath: /dataview-viewer-test/src/apis/http/ApiStore.js
 * @Date: 2022-03-30 23:56:16
 * @Author: lisushuang
 * @LastEditors: 冉桂精 156189868@qq.com
 */
export default class ApiStore {

  static store = [];

  /**
   * @description: 
   * @param {Object} options 配置项，或者token
   * @param {Function} instance 实例方法
   * @param {Boolean} persist 是否永久缓存，仅在time=0时生效，刷新后清除
   * @param {Integer} time  缓存时间，为0则永久变量缓存
   * @return {AxiosPromise}
   */  
  static handler(options,instance,persist=false,time=50) {
    if (options?.params?.EDITOR_closeConfigCache) {
      return instance();
    }
    // todo 先判断持久化缓存 并返回
    // 页面数据请求永久缓存
    if(options?.data?.view_uuid == "view61b951c6a8186" && options?.data?.page_uuid){
      time = 0
    }

    // 表单配置请求永久缓存
    if(options?.url.indexOf("api/metadata/transformuuid/") > -1){
      time = 0
    }

    // todo 其他页面请求永久缓存，还有哪些？

    let key = JSON.stringify(options)
    if(ApiStore.store[key] !== undefined){
      return ApiStore.store[key]
    }

    
    let request = instance()
    // console.log(JSON.parse(JSON.stringify(options)), 'options');

    ApiStore.store[key] = request;

    // 处理请求失败的情况处理，清空该key的永久缓存
    if(time === 0){
      request.then((res)=>{
        if(res.data.code !== 200){
          delete ApiStore.store[key]
        }
      }).catch(()=>{
        delete ApiStore.store[key]
      })
    }

    if(time === 0 && persist){
      // 执行持久化缓存
      return request
    }

    // 如果时间为0，则永久缓存
    if(time){
      setTimeout(()=>{
        delete ApiStore.store[key]
      },time)
    }

    return request
  }

  /**
   * @description: 清除所有缓存
   * @return {void}
   */  
  static clear(){
    ApiStore.store = []
  }
}