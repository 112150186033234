import { render, staticRenderFns } from "./commonPano.vue?vue&type=template&id=485b1271&scoped=true&"
import script from "./commonPano.vue?vue&type=script&lang=js&"
export * from "./commonPano.vue?vue&type=script&lang=js&"
import style0 from "./commonPano.vue?vue&type=style&index=0&id=485b1271&prod&lang=less&"
import style1 from "./commonPano.vue?vue&type=style&index=1&id=485b1271&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485b1271",
  null
  
)

export default component.exports