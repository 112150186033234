<!--
 * @Description: 葛洲坝登录页
 * @Author: luocheng
 * @Date: 2022-03-21 14:31:09
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-23 14:39:42
-->
<template>
  <div class="common-login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="rules"
    >
      <el-form-item
        prop="name"
        :style="itemStyle"
      >
        <el-input
          v-model="loginForm.name"
          placeholder="请输入账号"
          clearable
        >
          <i
            class="el-icon-s-home icon"
            slot="prefix"
            :style="iconStyle"
          ></i>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="password"
        :style="itemStyle"
      >
        <el-input
          v-model="loginForm.password"
          type="password"
          placeholder="请输入密码"
          clearable
        >
          <i
            class="el-icon-lock icon"
            slot="prefix"
            :style="iconStyle"
          ></i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onLogin"
          :style="btnStyle"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import cardMixin from '@/custom-component/business/cardMixin.js';
import mixin from './mixin';

export default {
  name: 'GZBLogin',
  mixins: [cardMixin, mixin],
  data() {
    return {
      year: new Date().getFullYear(),
      loginForm: {
        name: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
    };
  },
  computed: {
    commonLoginStyle() {
      return {
        transform: `scaleX(${this.scaleWidth}) scaleY(${this.scaleHeight})`
      };
    },
    itemStyle() {
      return {
        width: '100%',
        height: `${50 * this.scaleHeight}px`,
        marginBottom: `${30 * this.scaleHeight}px`,
        overFlow: 'hidden'
      };
    },
    btnStyle() {
      return {
        height: `${44 * this.scaleHeight}px`,
        padding: `${9 * this.scaleHeight}px ${15 * this.scaleHeight}px`
      };
    },
    iconStyle() {
      return {
        fontSize: `${25 * this.scaleHeight}px`
      };
    }
  },
  methods: {
    /**
     * @desc: 登录
     */
    onLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$loading();
          try {
            this.doLogin({
              ...this.loginForm
            });
          } catch (err) {
            this.$loading().close();
            this.$message.error('登录失败，请联系管理员！');
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.common-login {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  margin-right: 100px;
  :deep(.el-form) {
    .el-form-item {
      margin-bottom: 30px;
      .el-form-item__content {
        height: 100%;
        line-height: 100%;
      }
      .el-button {
        display: block;
        width: 100%;
        background: #007fe0;
        border: none;
        height: 44px;
        font-size: 14px;
        letter-spacing: 2px;

        &:hover {
          background: rgba(31, 104, 216, 0.8);
        }
      }
      .el-input {
        height: 100%;
        width: 100%;
        &__inner {
          height: 100%;
          min-height: 20px;
          line-height: 100%;
          width: 100%;
          font-size: 15px;
          font-weight: 600;
          padding-left: 40px;
        }

        &__prefix {
          display: flex;
          align-items: center;
          padding: 0 5px;
        }

        &__clear {
          font-size: 17px;
          display: flex;
          align-items: center;
        }

        .icon {
          font-size: 25px;
          line-height: 100%;
        }
      }
    }
  }
}
</style>
