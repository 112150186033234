<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-04-29 17:21:04
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-05-11 14:49:01
 * @FilePath: \dataview-next\src\custom-component\functionCom\IframeCom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="conpound-container">
    <div v-if="!preview && !isPreview" class="text-container">
      <span>内嵌网页：{{ finalSRC }}</span>
    </div>
    <iframe v-else :src="finalSRC" @load="loaded()" id="inner-iframe" class="inner-iframe"></iframe>
  </div>
</template>
<script>
import Minin from './mixin'
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import eventBus from '@/plugins/eventBus';
/**
 * @description 内嵌网页
 */
export default {
  name: 'IframeCom',
  mixins: [Minin,CustomComponentMixin],
  data() {
    return {
      preview: true,
			paramsResult: {
        param: {},
        search: [],
        canPost: true
      },
    }
  },
	computed: {
    // 组件属性
		statusConfig() {
			return this.element?.statusConfig;
		},
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    },
    // 最终src
    finalSRC() {
      if(this.statusConfig?.addParamToSrc) {
        return this.serializeSrc(this.src, this.paramsResult.param);
      } else {
        return this.src;
      }
    }
	},
  mounted() {
    if(!this.statusConfig.hrefValue && window.location.href.indexOf('modify-page')> -1){
      this.preview = false;
    }
		this.paramsResult = this.initParams(this.paramsConfig || []);
    const databaseTrigger = {
      [this.element.id]: config => {
        if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
          return;
        }
        this.paramsResult = this.initParams(this.paramsConfig || []);
        // 更换项目
        this.changeProject();
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
		//切换项目
		changeProject(){
			if(this.paramsResult?.param?.archi_type){
				document.getElementById('inner-iframe').contentWindow.postMessage({type:'changeProject',archiId:this.paramsResult.param.archi_type}, '*');
			}
		},
    // 检查url
    checkUrl() {
      let sRegex = '^((https|http)?://)' + '?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?' //ftp的user@ 
        + '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184 
        + '|' // 允许IP和DOMAIN（域名） 
        + '([0-9a-z_!~*\'()-]+.)*' // 域名- www. 
        + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名 
        + '[a-z]{2,6})' // first level domain- .com or .museum 
        + '(:[0-9]{1,4})?' // 端口- :80 
        + '((/?)|' // a slash isn't required if there is no file name 
        + '(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$';
      let re = new RegExp(sRegex);
      if (re.test(this.src)) {
        return true;
      }
      return false;
    },
		loaded(){
			let info = 	{
        type:'defaultConfig',
        token: this.$GetToken(),
        targetArchi:this.$GetTargetArchi(),
        userInfo:this.$GetUserInfo()
			}
			document.getElementById('inner-iframe').contentWindow.postMessage(info,'*');
			this.changeProject();
		},
    serializeSrc(url, parmas) {
      const urlObj = new URL(url);
      for (const key in parmas) {
        if (Object.hasOwnProperty.call(parmas, key)) {
          if(urlObj.searchParams.has(key)) {
            urlObj.searchParams.set(key, parmas[key]);
          } else {
            urlObj.searchParams.append(key, parmas[key]);
          }
        }
      }
      return urlObj.toString();
    }
  }
}
</script>
<style lang="less" scoped>
  .conpound-container{
    width: 100%;
    height: 100%;
    .text-container{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .inner-iframe{
      width: 100%;
      height: 100%;
      border: none;
    }
  }
</style>