<template>
  <div class="pano-container" v-loading="loading">
    <div class="pano-header">
      <div :class="['pano-header-btn', isCompare ? 'isCompare' : 'noCompare']" @click="handleComparePano">
        {{ isCompare ? "全景查看" : "全景对比" }}	
        <i class="iconxiangyou-copy iconfont"></i>
      </div>
      <div class="dataview-header-select">
        <el-select class="custom-select" v-model="leftPanoVal" @change="handleLeftPanoChange" placeholder="请选择">
          <el-option-group
            v-for="group in panoList"
            :key="group.id"
            :label="group.name">
            <el-option
              v-for="item in group.panos"
              :key="item.id"
              :label="item.pano_name + ' ' +  item.pano_time"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div v-if="isCompare" class="dataview-header-select pano-header-right-select">
        <el-select class="custom-select" v-model="rightPanoVal" @change="handleRightPanoChange" placeholder="请选择">
          <el-option
            v-for="item in rightPanoData"
            :key="item.id"
            :label="item.pano_name + ' ' + item.pano_time"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="pano-content">
      <iframe :src="isCompare ? comparePath : panoPath_left" frameborder="0" style="width: 100%; height: 100%"></iframe>
    </div>
  </div>
</template>

<script>
import { OptionGroup } from 'element-ui'
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
/**
 * @description 全景对比组件
 */
export default {
  name: "PanoCom",
  mixins: [CustomComponentMixin],
  components: { 
    'el-option-group': OptionGroup 
  },
  data() {
    return {
      isCompare: false, // 是否对比
      panoList: [],
      rightPanoData: [],
      panoPath_left: "",
      path_left: "",
      path_right: "",
      leftPanoVal: "",
      rightPanoVal: "",
      panoMap: {},
      loading: false
    };
  },
  computed: {
    comparePath() {
      return `https://pano.bimcc.net/splitscreen?pano_1=${this.path_left}&pano_2=${this.path_right}`;
    },
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    }
  },
  methods: {
    // 全景数据
    async getPanoDataList() {
      const { search = [], param = {}, canPost } = this.initParams(this.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
      if (!canPost) return false;
      this.panoList = [];
      this.rightPanoData = [];
      const params = {
        __method_name__: 'dataList',
        transcode: 0,
        ...param,
        search,
        object_uuid: 'object61e1275be950e',
        view_uuid: 'view61e127c50f8c2'
      }
      this.loading = true
      dataInterface(params).then(res => {
        if (res.data.code === 200 && res.data.data && res.data.data.data) {
          this.isCompare = false;
          this.path_left = '';
          this.path_right = '';
          this.panoPath_left = '';
          this.leftPanoVal = '';
          this.rightPanoVal = '';
          res.data.data.data.map((el, index) => {
            el.panos.map(ele => {
              this.panoMap[ele.id] = {
                ...ele,
                parentIndex: index
              }
            })
          })
          this.handlePanoData(res.data.data.data);
        }
      }).finally(() => {
        this.loading = false;
      })
    },

    handlePanoData(resultData = []) {
      if(Array.isArray(resultData) && resultData.length > 0 && Array.isArray(resultData[0].panos) && resultData[0].panos[0]) {
        const { pano_path, pano_name, pano_time, id } = resultData[0].panos[0];
        if (pano_path.indexOf("https") > -1) {
          this.panoPath_left = pano_path;
        } else {
          this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${pano_path}`;
          this.path_left = `${pano_path}`;
        }
        this.leftPanoVal = id;
        this.compareLeft_placeholder = pano_time ? pano_name + " " + pano_time : pano_name;
        this.rightPanoData = resultData[0].panos;
      }
      this.panoList = resultData;
    },

    handleLeftPanoChange(v) {
      const selectData = this.panoMap[v];
      if (selectData.pano_path.indexOf("https") > -1) {
        this.panoPath_left = selectData.pano_path;
      } else {
        this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${selectData.pano_path}`;
        this.path_left = `${selectData.pano_path}`;
      }
      this.rightPanoData = this.panoList[selectData.parentIndex].panos;
      if (this.path_right) {
        let temp = this.rightPanoData.filter(item => item.pano_path !== this.path_left);
        if (Array.isArray(temp) && temp.length > 0) {
          const { pano_path, pano_name, pano_time } = temp[0];
          this.path_right = pano_path;
          this.compareRight_placeholder = pano_time ? pano_name + " " + pano_time : pano_name;
        } else {
          this.path_right = this.path_left;
          this.compareRight_placeholder = this.compareLeft_placeholder;
        }
      }
    },

    handleRightPanoChange(v) {
      const selectData = this.panoMap[v];
      this.path_right = `${selectData.pano_path}`;
    },

    handleComparePano() {
      this.isCompare = !this.isCompare;
      if (!this.path_right) {
        let temp = this.rightPanoData.filter(
          (item) => item.pano_path !== this.path_left
        );
        if (Array.isArray(temp) && temp.length > 0) {
          const { pano_path, id } = temp[0];
          this.path_right = pano_path;
          this.rightPanoVal = id;
        } else {
          this.path_right = this.path_left;
          this.rightPanoVal = this.leftPanoVal;
        }
      }
    },
  },
  mounted() {
    this.getPanoDataList();
    const databaseTrigger = {
      [this.element.id]: config => {
        if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
          return;
        }
        this.getPanoDataList();
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  }
};
</script>

<style lang="less" scoped>
.pano-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  text-align: left;
  .pano-header {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    .dataview-header-select {
      display: inline-block;
      width: 200px;
      height: 40px;
      line-height: 40px;
      color: #FFFFFF;
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_kuang.png) no-repeat center;
      background-size: 100% 100%;
      background-color: rgba(2, 122, 180, 0.2);
      :deep(.custom-select) {
        .el-input__inner{
          border: none;
          color: #fff;
          background: none;
        }
      }
    }
    .pano-header-right-select {
      margin-left: calc(~"50% - 290px");
    }
    .pano-header-btn {
      display: inline-block;
      width: 90px;
      height: 40px;
      line-height: 40px;
      margin-right: 10px;
      vertical-align: bottom;
      text-align: center;
      color: #ffffff;
      background-color: rgba(2, 122, 180, 0.2) !important;
      cursor: pointer;
			font-size:13px;
			i{ 
				font-size:15px;
			}

    }
    .isCompare {
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn_pre.png) no-repeat center;
      background-size: 100% 100%;
    }
    .noCompare {
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .pano-content {
    display: flex;
    width: 100%;
    height: 100%;
  }
}
</style>