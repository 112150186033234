/*
 * @Description: 应用中心卡片mixin
 * @Author: luocheng
 * @Date: 2022-07-04 09:57:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-19 15:13:59
 */
import { judgingArchiAPermission } from '@/utils/tools';

export default {
  methods: {
    /**
     * @desc: 跳转页面
     */
    jumpTo() {
			const { route } = this.statusConfig;
			if (!route) {
				this.$message.warning('暂未配置有效路由！');
				return;
			}
      // 编辑器不做跳转
      const href = window.location.href;
      if (href.indexOf('/modify-page?') !== -1 || href.indexOf('/page-view?') !== -1) {
        this.$message.success(`跳转成功【${route.menuPath}】`);
        return false;
      }
			try {
				let routeObj = null, targetApp = null;
				const appList = JSON.parse(sessionStorage.getItem('appList'));
				for (let i = 0; i < appList.length; i++) {
					const app = appList[i];
					routeObj = this.getRouteObj(route, app?.app_orign_data);
					if (routeObj) {
						targetApp = app;
						break;
					}
				}
        if (!routeObj || !targetApp) {
          this.$message.warning('无效路径');
          return;
        }
        // 不同层级的路由需要获取不同的跳转路径
        // 0 编辑器配置页面，1 外链， 2 功能组， 3 (弃用)， 4 跳转路由(一般为微应用或基座内置页面@曾伟)
        const { menu_type } = routeObj;
          if (menu_type === 0 || menu_type === 4) {
          // 配置页面和跳转路由
          this.goRoute(routeObj)
        } else if (menu_type === 1) {
          // 外链
          this.goRoute(routeObj)
        } else if (menu_type === 2) {
          // 功能组
          // 跳转第一个有权限的路由
          const { children_list = [] } = routeObj;
          const indexObj = this.getGroupIndex(children_list);
          if (!indexObj) {
            this.$message.warning('无效路径');
            return;
          }
          this.goRoute(indexObj);
        }
        // 设置当前应用
				this.$store.commit('setCurrentApp', targetApp);
			} catch(err) {
				console.log(err);
        this.$message.error('跳转应用失败！');
			}
		},
    /**
     * @desc: 跳转路由
     * @param {Object} routeObj
     */
    goRoute(routeObj) {
      const { menu_type, menu_path, page_uuid } = routeObj;
      if (menu_type === 0 || menu_type === 4) {
        // 配置页面和跳转路由
        this.$router.push({
          path: menu_path,
          query: {
            menu_type: menu_type || 0,
            pageUUID: page_uuid
          }
        });
      } else if (menu_type === 1) {
        // 外链
        const { link_type, link } = routeObj
        if (link_type === '_blank') {
          window.open(link);
        } else if (link_type === '_self') {
          window.location.href = link;
        } else {
          window.open(link);
        }
      } else {
        this.$message.warning('无效路径');
      }
    },
    /**
     * @desc: 根据配置的路由地址获取完整路由对象
     * @param {String} path 路径
     * @param {Array} menuList 菜单列表
     */
    getRouteObj(path, menuList) {
      if (!path || !Array.isArray(menuList)) return null; 
      // let result = menuList.find(ele => ele.menu_path === path);
      let result = menuList.find(ele => ele.menu_path === path);
      if (result) return result;
      for (let i = 0; i < menuList.length; i++) {
        const children = menuList[i]?.children_list || [];
        if (children.length) {
          result = this.getRouteObj(path, children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    /**
     * @desc: 获取分组中的首页
     * @param {Array} children 下级列表
     */
    getGroupIndex(children) {
      let result = null;
      for (let i = 0; i < children.length; i++) {
        const { menu_type, archi_limit = [], permissions = [], children_list = [], is_hide } = children[i];
        if (!judgingArchiAPermission(archi_limit, permissions) || is_hide) {
          continue;
        }
        // 分组
        if (menu_type === 2 && children_list.length) {
          result = this.getGroupIndex(children_list);
          if (result) {
            return result;
          }
        } else {
          return children[i];
        }
      }
      return result;
    }
  }
}