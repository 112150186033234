<template>
    <div v-show="show" class="wrapPanel">
        <viewerBIMScene
            ref="scenes"
            :element.sync="element"
            :bindViewerId="bindViewerId"
            :bindDataMainId="bindDataMainId"
            @registerBehaviors="registerBehaviors"
        ></viewerBIMScene>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    import viewerBIMScene from "./components/viewerBIMScene";
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';

    export default {
        name: "viewerBIMModuleViewState",
        components: {
            viewerBIMScene,
        },
        data(){
            return{
                show : true,
                bindViewerId : null, //绑定viewer的ID
                bindDataMainId : '',
            }
        },
        computed: {
            ...mapState([
                'componentData',
                'originComponentData'
            ]),
        },
        props :{
                element: {
                    type: Object,
                    required: true,
                    default: () => {
                        return {
                        }
                    }
                },
                // 是否在组合内
                isGroup: {
                    type: Boolean,
                },
                // 组合内组件列表
                groupComponents: {
                    type: Array,
                    default: () => []
                }
            },
        mixins : [CustomComponentMixin,BehaviorHandlerMixin],
        methods: {
            initEventListener(){
                eventBus.$on("Viewer.onViewerInited" , (  )=>{
                    this.refreshDataMainId();
                });

                eventBus.$on("BIMModule.GetSource" , ( eid ,  cb )=>{
                    if( eid !== this.element.id)return;

                    const loop = (array = [], resList = []) => {
                        for(let item of array){
                            if(item.component !== "ViewerBIM") {
                                if(item.children && item.children.length > 0) loop(item.children, resList);
                                continue;
                            }

                            if(resList.findIndex(x => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.originComponentData, comps);
                    loop(this.componentData, comps);

                    cb({
                        list : comps,
                        bindId : this.bindViewerId,
                    });
                });


                eventBus.$on("BIMModule.BindViewer" ,  ( eid , bindId )=>{
                    if( eid !== this.element.id) return;
                    if( !this.checkBind(bindId) ) return;

                    this.element.modelBindId = bindId;
                    this.bindViewerId = bindId;
                    this.refreshDataMainId();
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                });
            },
            checkBind( bindId = this.bindViewerId ){
                    const checkFunc = ( bid , list )=>{
                        let hasComp = false;
                        if(!list) return hasComp;

                        for(let comp of list){

                            if( comp.children ){
                                const flag = checkFunc( bid , comp.children);

                                if(flag){
                                    hasComp = true;
                                    break
                                }
                            }
                            if( comp.id !== bid ) continue;

                            hasComp = true;
                            break;
                        }

                        return hasComp
                    };

                const hasComp = checkFunc(bindId, this.componentData);
                const hasOriginComp = checkFunc(bindId, this.originComponentData);

                if (!hasComp && !hasOriginComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            },
            initBehaviors(){
                this.element.behaviors = [];
                this.registerBehaviors([
                    {
                        label : "[基础]切换显示",
                        desc : `
                            调用后就会切换显示和隐藏
                        `,
                        value : "BIMModule-switchDisplay",
                        func : ()=>{
                            this.show = !this.show;
                        },
                    },
                ]);

                for(let eid in this.$refs){
                    const ele = this.$refs[eid];
                    if(ele.initBehaviors){
                        ele.initBehaviors();
                    }
                }
            },
            refreshDataMainId(){
                if (!this.bindViewerId) return false;
                eventBus.$emit('ViewerBIM.getDataMainId', this.bindViewerId , ( id ) =>{
                    this.bindDataMainId = id;

                    this.$nextTick(()=>{
                        for(let cid in this.$refs){
                            const comp = this.$refs[cid];
                            if(!comp.onChangeDataMainId) continue;

                            comp.onChangeDataMainId( id );
                        }
                    });
                });
            },
        },
        mounted(){
            this.initEventListener();
            this.initBehaviors();

            if(this.element.modelBindId){
                if( this.checkBind( this.element.modelBindId) ){
                    this.bindViewerId = this.element.modelBindId;
                }
            }

        }
};
</script>

<style lang="less" scoped>
.wrapPanel{
    float: left;
        padding: 0 !important;
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        background-color: inherit;
        color: inherit;
}
</style>
