<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-06-16 14:16:39
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
    <div class="model-choose-cont">
        <div class="list relative">
            <el-page-header
                @back="back"
                style="
                    line-height: 45px;
                    padding-left: 15px;
                    border-bottom: 1px solid #dcdfe6;
                "
                content="模型绑定"
            >
            </el-page-header>
            <list
                class="list-cont scroller"
                ref="listRef"
                :model-data="modelData"
                :search="search"
                @show-model="showModel"
                @clear-obk="clearObk"
            />
            <el-button
                size="mini"
                type="primary"
                class="absolute"
                style="top: 8px; right: 10px"
                @click="commit()"
                >确定</el-button
            >
        </div>
        <preview
            ref="preview"
            v-if="previewShow"
            @setViewData="setViewData"
            class="preview-cont"
						:appId="appId" :appSecret="appSecret" 
            :choose-type="chooseType"
            :model-data="modelData"
        />
        <div v-if="previewShow" class="other-btn">
            <el-tooltip
                content="获取视点"
                v-if="chooseType == 'view'"
                effect="dark"
                placement="bottom"
            >
                <i class="iconfont iconxiangji1" @click="setViews" />
            </el-tooltip>
            <el-select
                style="margin-left: 10px"
                v-if="viewList.length != 0"
                v-model="viewData"
								value-key="id"
                multiple
                placeholder="请选择"
            >
                <el-option-group v-for="(group, index) in viewList" :key="index" :label="group.content" >
                    <el-option
                        v-for="(item,j) in group.children"
                        :key="j"
                        :value="item"
												:label="item.name"
                    >
                    </el-option>
                </el-option-group>
            </el-select>
        </div>
    </div>
</template>

<script>
import List from "@/custom-component/bindModel/modelList.vue";
import Preview from "@/custom-component/bindModel/preview.vue";
import { PageHeader, Button, Tooltip, OptionGroup } from "element-ui";
export default {
    name: "Bind",
    components: {
        List,
        Preview,
        "el-button": Button,
        "el-page-header": PageHeader,
        "el-tooltip": Tooltip,
				'el-option-group':OptionGroup,
    },
    props: {
        modelData: {
            type: [Array,String],
            default: () => [],
        },
        //模型和视点model/view
        chooseType: {
            type: String,
            default: "model",
        },
        appId: {
            type: String,
            default: ""
        },
        appSecret: {
            type: String,
            default: ""
        },
        search: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            previewShow: true,
            viewData: [],
            viewList: [], //视图列表
            choosedViewIds: [],
        };
    },
    mounted(){
    },
    methods: {
        showModel(obk, bol) {
            if (bol) {
                if (!this.previewShow) {
                    this.previewShow = true;
                    setTimeout(() => {
                        this.$refs.preview.addModel(obk);
                    }, 500);
                } else {
                    this.$refs.preview.addModel(obk);
                }
            } else {
                this.$refs.preview.minusModel(obk);
            }
        },
        clearObk() {
            this.checkedObks = [];
            //黄旭凯不支持清空全部的模型，直接隐藏完模型的cont显示
            this.previewShow = false;
        },
        back() {
            this.$emit("back");
        },
        setViews() {
						if(this.viewList.length == 0){
							this.$confirm("请选择获取视点方式", "获取视点", {
									confirmButtonText: "列表选择",
									cancelButtonText: "新增视图",
									type: "success",
									callback: (action) => {
											if (action === "confirm") {
													this.viewList = this.$refs.preview.viewList;
											} else {
													this.$refs.preview.setViews();
											}
									},
							});
						}else{
							this.viewList = this.$refs.preview.viewList;
							this.$refs.preview.setViews();
						}
            
        },
        setViewData(data) {
            data.id = data.model_id;
            data.data = JSON.parse(data.data);
            this.viewData.push(data);
            this.$message.success("视点获取成功，可继续添加或点击确定！");
        },
        commit() {
            if (this.chooseType == "model") {
                let selectData = this.$refs.preview.selectData;
                let listChecked = this.$refs.listRef.loadedData;
                if (selectData && selectData.length != 0) {
                    this.$emit("set-data", selectData);
                } else {
                    if (listChecked && listChecked.length != 0) {
                        let arr = [];
                        let n = 0;
                        listChecked.forEach((item) => {
                            let version = item.allversions.filter(
                                (v) => v.id == item.used_version
                            )[0];
                            if (version.model_id != "") {
                                let obj = {
                                    model_id: version.model_id,
                                    select: [],
                                };
                                arr.push(obj);
                            }
                            n++;
                        });
                        if (n == listChecked.length) {
                            this.$emit("set-data", arr);
                        }
                    } else {
                        this.$message.warning("请选择模型");
                    }
                }
            } else {
                if (this.viewData.length != 0) {
                  this.$emit("set-data", this.viewData);
                } else {
                    this.$message.warning("请点击相机添加视点！");
                }
            }
        },
    },
};
</script>
<style lang="less">
.model-choose-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    .list {
        width: 300px;
        height: 100%;
        z-index: 120;
        border-right: 1px solid #dcdfe6;
        .list-cont {
            height: ~"calc(100% - 45px)";
        }
    }
    .preview-cont {
        height: 100%;
        flex: 1;
    }
    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
    .el-page-header__content {
        font-size: 14px !important;
    }
    .el-page-header__left {
        color: #409eff;
        animation: fade 1500ms infinite;
        -webkit-animation: fade 1500ms infinite;
    }
    @keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
    .other-btn {
        position: absolute;
        top: 10px;
        left: 310px;
        height: 20px;
        z-index: 100;
        i {
            font-size: 18px;
        }
    }
		
}
</style>
