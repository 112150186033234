<!--
 * @Description: 流程图
 * @Author: Zengwei
 * @Date: 2023-03-03 11:24:58
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-05-04 15:38:57
-->
<template>
  <div class="common-flow">
    <FlowDesigner
      ref="flowDesigner"
      v-if="configResult && isInit"
      :objectUuid="configResult.object_uuid"
      :formUuid="configResult.form_uuid || configResult.object_uuid"
      :editable="true"
      :flow-data="flowData"
      :user-data="userData"
      :form-item="formInputItem"
      :sub-flow-list="subFlowData"
      @on-save="submitFlow"
      @on-form="openFormDesign"
      :obuuid="obuuid"
    ></FlowDesigner>
    <el-empty
      v-else
      description="流程配置加载中"
    ></el-empty>

    <el-dialog
      :visible.sync="dialogVisible"
      title="流程节点自定义审批内容"
      fullscreen
      append-to-body
      custom-class="flow-form-design"
    >
      <section class="domain-body">
        <FormDesigner @form-data="saveFormDesign"></FormDesigner>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
import FlowDesigner from "@/custom-component/flow/flow-designer";
import {flowEngine} from "@/apis/data/workflow";
import {transFieldsUUid} from "@/apis/data";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import FormDesigner from "@/custom-component/form/FormDesigner";
import { getComponentById, removeEventBus } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';

export default {
  name: 'CommonFlow',
  components: {
    'el-empty': Empty,
    FormDesigner,
    FlowDesigner
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		componentList: {
      default: null
		},
		// 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // 同步组件时候组件此处勿动！！！
      // !!! 实际渲染阶段不要随意显示流程配置错误
      // !!! 假定放到业务端渲染的时候，流程配置错误不会出现
      isInit: false,
      flowData: {},
      userData: [],
      formInputItem: [],
      subFlowData: [],
      dialogVisible: false,
      configResult: null,
      reqNums: 0, // 流程数据准备
      obuuid: ''
    }
  },
  computed: {
		...mapState(['componentData']),
    // 流程图配置
    flowConfig() {
      return this.element && this.element.flowConfig;
    }
  },
  watch: {
    reqNums(newVal){
      if(newVal > 2){
        this.isInit = false;
        this.$nextTick(()=>{
          this.isInit = true;
        })
      }
    },
    flowConfig: {
			handler() {
				const {
					configType = '',
					object_uuid = '',
					form_uuid = '',
					customConfig
				} = this.flowConfig;
				// let obuuid = '';
				this.configResult = null;
				this.isInit = false;
				if (configType === 'component') {
					this.updateComponentSource(customConfig);
					return;
				} else if (this.flowConfig?.object_uuid) {
					this.configResult = {
						form_uuid,
						object_uuid
					};
					this.obuuid = this.flowConfig.object_uuid;
				}
        this.initData()
			},
			immediate: true,
			deep: true
		}
  },
	mounted() {
		this.$nextTick(() => {
			const databaseTrigger = {
				[this.element.id]: (data = {}) => {
					if (this.flowConfig.configType !== 'component') return;
					const { customConfig = {} } = this.flowConfig;
					if (customConfig.componentId !== data.componentId) return;
					this.configResult = null;
					this.isInit = false;
					// 刷新
					this.updateComponentSource(customConfig);
				}
			};
			eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
		})
	},
  methods:{
    /**
		 * @desc: 更新组件来源配置的视图刷新
		 */
		updateComponentSource(customConfig) {
			// let obuuid = '';
			if (!customConfig || !customConfig?.object_uuid_field || !customConfig.componentId) {
				return;
			}
			// 普通从组件获取
			let sourceComponent = getComponentById(this.componentList || this.componentData, customConfig?.componentId);
			if (!sourceComponent && this.isGroup && this.groupComponents.length) {
				sourceComponent = getComponentById(this.groupComponents, customConfig?.componentId);
			}
			const resolveData = sourceComponent?.resolveData;
			if (!resolveData) return;
			this.configResult = {
				form_uuid: resolveData?.[customConfig?.form_uuid_field],
				object_uuid: resolveData?.[customConfig?.object_uuid_field],
        is_template: customConfig?.is_template,
        formUUID: customConfig?.formUUID, // 模板目标表单，用于字段条件
			};
			this.obuuid = resolveData?.[this.flowConfig.object_uuid_field];
			// this.$router.replace({
			// 	query: {
			// 		...this.$route.query,
			// 		obuuid
			// 	}
			// });
      this.initData()
		},
    initData(){
      this.reqNums = 0
      const formUuid = this.configResult.form_uuid || this.configResult.object_uuid
      let otherFormUuid = this.configResult.form_uuid
      if(this.configResult.is_template){
        otherFormUuid = this.configResult.formUUID
      }
      if(formUuid){
        if(this.configResult.is_template){
          this.initTemplateFlow(formUuid)
        } else {
          this.initFlowDetail(formUuid)
        }
        this.initFormItem(otherFormUuid)
        this.initLowerFlow(formUuid)
      }
    },
    initTemplateFlow(formUuid){
      flowEngine.designTempShow({module: formUuid}).then(res=>{
        this.flowData = res.data.data
        this.reqNums ++
      })
    },
    initFlowDetail(formUuid){
      flowEngine.designShow({module: formUuid}).then(res=>{
        this.flowData = res.data.data
        this.reqNums ++
      })
    },
    initFormItem(formUuid){
      transFieldsUUid(formUuid).then(res=>{
        const jsonFields = res.data.data?.fields || []
        this.formInputItem = viewGetFormItem(jsonFields)
        this.reqNums ++
      })
    },
    initLowerFlow(formUuid){
      flowEngine.designList({parent: true, module:formUuid}).then(res=>{
        this.subFlowData = res.data.data.data
        this.reqNums ++
      })
    },
    submitFlow(data){
      flowEngine.designSave(this.flowData.id,data).then(res=>{
        if(res.data.code === 200) {
          this.$message.success('保存流程成功');
        }
      })
    },
    openFormDesign(){
      this.dialogVisible = true;
    },
    saveFormDesign(formDesignData){
      const design = JSON.parse(formDesignData.json)
      this.$refs.flowDesigner.setExtendForm(design)
      this.dialogVisible = false
    },
  },
	beforeDestroy() {
		removeEventBus(['databaseTrigger'], this.element.id);
	}
};
</script>

<style lang="less" scoped>
.common-flow {
  height: 100%;
  width: 100%;
  position: relative;
  border: 1px solid #f7f9ff;
  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
  }
}
:deep(.flow-form-design){
  .domain-body{
    width: 100%;
    height: calc(100vh - 60px);
  }
  .el-dialog__header{
    border-bottom: 1px solid #f1e8e8;
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>
