<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2022-05-13 09:19
-->
<template>
  <div class="task-flow-container">
    <div class="contextmenu" :style="contextmenuXY">
      <div class="menu-item" @click="toTop">置顶</div>
      <div class="menu-item" @click="toBom">置底</div>
    </div>

    <div class="indictor" v-if="taskMode === 'recover'">
      <div class="indictor-todo">
        <b></b> 待处理
      </div>
      <div class="indictor-complete">
        <b></b> 已完成
      </div>
      <div class="indictor-danger">
        <b></b> 已延期
      </div>
    </div>

    <div class="action-bar">
      <el-button-group >
        <el-button plain size="small" @click="fitViewer">
          居中
        </el-button>
        <el-button v-if="taskMode === 'designer'" plain size="small" @click="saveTaskFlow">
          保存
        </el-button>
      </el-button-group>
    </div>
    <div class="graph-container" v-if="taskMode === 'designer'">
      <div
        v-for="item in nodeList"
        :key="item.text"
        class="node-item"
        @mousedown="drag($event,item)"
      >
        <div class="node-item-icon" :class="item.class"></div>
        <span class="node-label">{{ item.text }}</span>
      </div>
    </div>

    <task-props
      v-if="propOpen && nodeType === 'task'"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :node-type="nodeType"
      :form-node="formNode"
      @on-close="close"
      @on-change="updateProps"
    ></task-props>

    <edge-props
      v-if="propOpen && (nodeType === 'edge' || nodeType === 'gateway')"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :node-type="nodeType"
      :form-node="formNode"
      @on-close="close"
      @on-change="updateProps">
    </edge-props>

    <swim-props
      v-if="propOpen && nodeType === 'swim'"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :disabled="false"
      @on-close="close"
      @on-change="updateProps">
    </swim-props>

    <div class="task-config-wrapper" v-if="configOpen">
      <p class="conf-title">
        任务引擎
        <i class="iconfont iconc-close" @click="closeConfig"></i>
      </p>
      <div class="content">
        <task-config
          ref="taskConfig"
          :config-prop="configData"
          @on-change="configChange"
        ></task-config>
      </div>
      <div class="footer">
        <el-button>取消</el-button>
        <el-button type="primary" @click="submitTaskFlow">保存</el-button>
      </div>
    </div>

    <div ref="x6Container" class="task-block"></div>

    <el-dialog
      :title="modalTitle"
      :visible.sync="dialogVisible"
      width="60%"
      custom-class="common-dialog"
      append-to-body
      top="5vh"
    >
      <div v-if="skeleton" class="skeleton">
        <skeleton></skeleton>
      </div>
      <div class="dialog-main" @click="closePicker">
        <template v-if="parserMode === 'form'">
          <PcParserNew
            v-if="dialogVisible && formDesignData"
            ref="dialogForm"
            :form-data="formDesignData"
            :displayData="displayData"
            :editFields="editFields"
            :disabled="formDisabled"
            :needFlow="needFlow"
            :moduleUuid="moduleUuid"
            :formMode="formMode"
            :fillData="fillData"
            :otherParam="otherParam"
            @submit="onFormSubmit"
            @on-close="onEndFlow"
            @changeSkeleton="changeSkeleton"
          ></PcParserNew>
        </template>
        <template v-else>
          <PageParser
            style="padding: 15px"
            v-if="pageUUID"
            :pageUUID="pageUUID">
          </PageParser>
        </template>
      </div>
      <div slot="footer" v-if="['add','edit'].includes(formMode)" >
        <div class="task-publish" v-if="formMode === 'add'">
          <TaskPicker
            :key="key"
            ref="taskPicker"
            v-if="dialogVisible && (formDesignData || pageUUID)"
            type="next"
            :stepNode="curStepNode"
            :taskEngineId="taskEngineId"
            :taskRecoverId="taskRecoverId"
            @checked-users="dealWithPicker"
            @selected-step="getSelectStep"
            @on-cancel="dialogVisible = false"
            @on-submit="triggerSubmit"
          ></TaskPicker>
        </div>
        <div class="flow-operate" v-if="formMode === 'detail'">
          <FlowOperate
            v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"
            :edit-data="displayData"
            :flow-edit="editFieldsValue"
            @change-end="onEndFlow"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TaskGraph from "@/custom-component/task-flow/components/TaskInitializer";
import {ButtonGroup, Button } from "element-ui";
import TaskProps from "@/custom-component/task-flow/property/TaskProps.vue";
import TaskConfig from "@/custom-component/task-flow/property/TaskConfig.vue";
import {dataInterface, transFieldsUUid} from "@/apis/data";
import {preTaskNode} from "@/plugins/util";
import SwimProps from "@/custom-component/task-flow/property/SwimProps.vue";
import EdgeProps from "@/custom-component/task-flow/property/EdgeProps.vue";
import {formDesign} from "@/apis/data/workflow";
import eventBus from "@/plugins/eventBus";
import TaskPicker from "@/custom-component/task-flow/operation/TaskPicker.vue";
import FlowOperate from "@/custom-component/flow/flow-designer/src/operation/FlowOperate.vue";
import Skeleton from "@/custom-component/form/newParser/skeleton.vue";
import {initParams} from "@/utils/tools";

export default {
  name: "index",
  components: {
    Skeleton,
    'el-button-group':ButtonGroup,
    'el-button':Button,
    FlowOperate,
    TaskPicker,
    EdgeProps,
    SwimProps,
    TaskProps,
    TaskConfig,
    PcParserNew: () => import("@/custom-component/form/newParser/PcParserNew.vue"),
    PageParser: () => import('@/components/parser/Index')
  },
  props: {
    element: {
      type: Object,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    componentList: {
      default: null
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
    taskId:{
      type: [Number,String],
      default: 0
    },
  },
  data() {
    return {
      key: '',
      loading: true,
      skeleton: true,
      graph: null,
      dnd: null,
      taskEngineId: this.taskId,
      propOpen: false,
      configOpen: false,
      configData: {
        name: '',
        desc: '',
        interval_open: 0,
        interval:{}
      },
      nodeList:[
        {text: '开始', type: 'start', class: 'node-start',},
        {text: '任务', type: 'task', class: 'node-task',},
        {text: '网关', type: 'gateway', class: 'node-gateway',},
        {text: '结束', type: 'end', class: 'node-end',},
        {text: '泳道', type: 'swim', class: 'node-swim',},
      ],
      selectedNode: null,
      currentItem: null,
      currentNodeId: '',
      nodeType: null,
      formNode: [],
      editFieldsValue:{},

      objectDataId: 0,
      objectDataTable: '',
      taskRecoverId: 0,
      taskNodeData: {},
      taskTodoId: 0,
      completeTodo:[],
      taskTodo:[],
      stepNode:[],
      stepNodeComp:[],
      taskMode: 'designer',
      curStepNode: '',
      curCell: null,

      modalTitle: '',
      dialogVisible: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      fillData: {},
      otherParam: {},
      parserMode: 'form',
      // 编辑器页面渲染
      pageUUID: null,
      contextmenuXY: {
        top: '0px',
        left: '0px',
        display: ''
      },
      outerParam: null,
    }
  },
  created() {
    this.taskMode = this.element?.taskConfig?.customConfig?.taskMode || 'designer'
    eventBus.$on('open-task-form',(data)=>{
      this.openFormParser(data)
    })
    eventBus.$on('editor-prop-change',(taskConfig)=>{
      this.taskMode = taskConfig?.customConfig?.taskMode || 'designer'
      this.initCanvas()
    })
  },
  mounted() {
    this.initCanvas()
    if(this.element?.id){
      const databaseTrigger = {
        [this.element.id]: () => {
          this.initCanvas(); // 外部参数驱动 - 树形点击事件处理
        },
      };
      eventBus.$on("databaseTrigger", databaseTrigger[this.element.id]);
    }
  },
  methods: {
    initCanvas(){
      const {param = {}} = initParams(
        this.element?.operationConfig?.params || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      this.outerParam = param; // 外部参数
      if (this.outerParam?.data_id) {
        this.taskEngineId = this.outerParam?.data_id
      }
      if (this.outerParam?.object_data_id) {
        this.objectDataId = this.outerParam?.object_data_id
      }
      if (this.outerParam?.data_table) {
        this.objectDataTable = this.outerParam?.data_table
      }
      if (this.outerParam?.task_recover_id) {
        this.taskRecoverId = this.outerParam?.task_recover_id
      }
      this.$nextTick(()=>{
        if(this.graph){
          // 属性改变，多个画布问题，初始化销毁画布
          this.graph.dispose()
        }
        if(this.$refs.x6Container){
          const graphInitializer = new TaskGraph(this)
          this.graph = graphInitializer.graph
          if(this.taskEngineId){
            this.getTaskEngine()
          }
        }
      })
    },
    changeSkeleton(value){
      this.skeleton = value;
    },
    open(){
      this.configOpen = true
    },
    close(){
      this.propOpen = false
    },
    closeConfig(){
      this.configOpen = false
    },
    configChange(data){
      const {name,desc,interval_open,interval} = data
      this.configData = {name,desc,interval_open,interval}
    },
    getTaskEngine(){
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object628619c716579',
        data_id: this.taskEngineId,
        transcode: 0,
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          const {name,desc,interval_open,interval,json} = res.data.data
          this.canvasData = json;
          this.configData = {name,desc,interval_open,interval}
          if(this.taskMode === 'designer' && json?.cells){
            this.graph.fromJSON(json)
            this.graph.centerContent()
          } else if(this.objectDataId && this.objectDataTable){
            this.getTaskDetail()
          }
        }
      })
    },
    getTaskDetail(){
      const param = {
        task_engine_id: this.taskEngineId,
        object_data_id: this.objectDataId,
        data_table: this.objectDataTable
      }
      dataInterface(param,'api/taskengine/tasktodo','GET').then(res=>{
        if(res.data.code === 200){
          if(res.data.metadata?.task_recover_id){
            this.taskRecoverId = res.data.metadata.task_recover_id
          }
          if(res.data.metadata?.task_recover_json) {
            this.canvasData = res.data.metadata.task_recover_json
          }
          const data = res.data.data;
          const userInfo = this.$GetUserInfo()
          this.taskTodo = data.filter(item => {
            const userId = Array.from(item.user_task,ele => ele.user_id)
            return item.status === 0 && userId.includes(userInfo.id)
          })
          this.completeTodo = data.filter(item => item.status === 1)
          const nodeId = Array.from(this.taskTodo,item => item.step_node)
          const completeNodeId = Array.from(this.completeTodo,item => item.step_node)
          this.stepNode = nodeId
          this.stepNodeComp = completeNodeId
          this.recoverTaskTodo()
        }
      })
    },
    recoverTaskTodo(){
      const start = this.canvasData.cells.find(item => item.shape === 'start')
      this.stepNodeComp.unshift(start.id)
      for (let item of this.canvasData.cells){
        if(item.shape === 'task' && this.stepNode.includes(item.id)){
          item.data.color = '#539FE6'
        } else if (item.shape === 'task' && this.stepNodeComp.includes(item.id)){
          item.data.color = '#40B67C'
        } else if (
          item.shape === 'edge' &&
          (this.stepNodeComp.includes(item.source.cell) || this.stepNodeComp.includes(item.target.cell) || this.stepNode.includes(item.target.cell))
        ){
          item.attrs.line.stroke = '#40B67C'
        } else if (item.shape === 'start'){
          const attrs =  {
            body: {strokeWidth: 2, stroke: '#40B67C', fill: '#FFF'},
            label: {text: '开始', fontSize: 16, fill: '#7E8595'},
          }
          item.attrs = attrs
        }
        let currentDate = new Date()
        let deadline = new Date(item.data.deadline)
        if(
          item.data.deadline && !this.stepNodeComp.includes(item.id) &&
          currentDate>deadline && item.shape === 'task'
        ){
          item.data.color = '#F94520'
        }
      }
      this.graph.fromJSON(this.canvasData.cells)
      this.graph.zoomToFit({padding: 20})
      this.graph.centerContent()
    },
    validTaskEngine(graphData){
      let startNode = false,endNode = false
      let source = [],target = []

      for (let cell of graphData){
        if(cell.shape === 'start') startNode = true
        if(cell.shape === 'end') endNode = true
        if(cell.shape === 'edge'){
          source = source.concat(cell.source.cell)
          target = source.concat(cell.target.cell)
        }
      }
      return {startNode,endNode,source,target}
    },
    fitViewer(){
      this.graph.zoomToFit({padding: 20})
      this.graph.centerContent()
    },
    toTop(){
      this.curCell.toFront()
      this.contextmenuXY.display = 'none'
    },
    toBom(){
      this.curCell.toBack()
      this.contextmenuXY.display = 'none'
    },
    saveTaskFlow(){
      this.configOpen = true
    },
    submitTaskFlow() {
      if(this.graph){
        const graphData = this.graph.toJSON()
        const nodeData = this.formatData(graphData.cells)
        for (let node of nodeData.node_data.nodeList){
          if(node.user_type === 1 && node.nodeType === 'task' && node.target_users.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 责任人不能为空，请选择责任人')
            return
          } else if(node.user_type === 5 && node.nodeType === 'task' && node.user_depart.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 部门不能为空，请选择部门')
            return
          } else if(node.user_type === 6 && node.nodeType === 'task' && node.user_role.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 岗位不能为空，请选择岗位')
            return
          }
        }
        const valid = this.validTaskEngine(graphData.cells)
        if(typeof this.$parent.triggerValidate === 'function'){
          try {
            this.$parent.triggerValidate()
          } catch (e) {
            this.$message.error('数据校验失败，请核对必填字段！')
            return
          }
        }
        if(!this.configData.name){
          this.$refs.taskConfig.validForm()
          this.$message.error('任务引擎名称缺失，请检查后保存！')
          return
        }
        if(!valid.startNode || !valid.endNode){
          this.$message.error('开始或结束节点缺失，请检查后保存！')
          return
        }
        for (let item of graphData.cells){
          if(
            item.shape === 'task' &&
            (!valid.source.includes(item.id) || !valid.target.includes(item.id))
          ){
            this.$message.error('任务连线未连接完整，请检查后保存！')
            return
          }
        }
        const param = {
          __method_name__: 'createData',
          object_uuid: 'object628619c716579',
          transcode: 0
        }
        if(this.taskEngineId){
          param.__method_name__ = 'updateData'
          param.data_id = this.taskEngineId
        }
        const saveData = {
          ...this.configData,
          json: graphData,
          ...nodeData,
          ...param
        }
        dataInterface(saveData).then(res=>{
          if(res.data.code === 200){
            this.$message.success(res.data.msg)
          }
        })
        // this.$emit('on-save',saveData)
      }
    },
    formatData(graphData){
      const nodeList = [], lineList = []
      let start_node='', end_node=''
      for (let item of graphData){
        if(['edge','gateway'].includes(item.shape)){
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            run_logic: item.data?.run_logic || [],
          }
          if(item.shape === 'edge'){
            newData.from = item.source.cell
            newData.to = item.target.cell
            lineList.push(newData)
          } else {
            nodeList.push(newData)
          }
        } else {
          if(item.shape === 'start') start_node = item.id
          if(item.shape === 'end') end_node = item.id
          if(item.shape === 'task') delete item.component
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            user_type: item.data?.user_type || 7,
            task_type: item.data?.task_type || 1,
            target_users: item.data?.target_users || [],
            user_from: item.data?.user_from || 1,
            user_depart: item.data?.user_depart || [],
            user_role: item.data?.user_role || [],
            assign: item.data?.assign || 1,
            form_type: item.data?.form_type || 1,
            object_uuid: item.data?.object_uuid || '',
            func_form: item.data?.func_form || '',
            run_flow: item.data?.run_flow || 1,
            run_logic: item.data?.run_logic || [],
          }
          nodeList.push(newData)
        }
      }
      const node_data = {nodeList,lineList}
      return {node_data,start_node,end_node}
    },
    updateProps(data){
      if(this.selectedNode){
        if(this.nodeType === 'edge'){
          if(data.name){
            const label = {
              markup: [
                {tagName: 'rect', selector: 'body',},
                {tagName: 'text', selector: 'label',},
              ],
              attrs: {
                text: {
                  text: data.name,
                },
                label: {
                  fill: '#000',
                  fontSize: 14,
                  textAnchor: 'middle',
                  textVerticalAnchor: 'middle',
                  pointerEvents: 'none',
                },
                body: {
                  ref: 'label',
                  fill: '#ffd591',
                  stroke: '#ffa940',
                  strokeWidth: 2,
                  rx: 4,
                  ry: 4,
                  refWidth: '140%',
                  refHeight: '140%',
                  refX: '-20%',
                  refY: '-20%',
                },
              },
              zIndex:10
            }
            this.selectedNode.setLabels([label])
          } else {
            this.selectedNode.setLabels([])
          }
        }
        this.selectedNode.updateData(data)
      }
    },
    drag(e,nodeItem){
      let nodeData;
      switch (nodeItem.type){
        case 'start':
          nodeData = {
            shape: "start", width: 70, height: 70,
            data:{}
          }
          break
        case 'task':
          nodeData = {
            shape: "task", width: 220, height: 150,
            data: {
              name: '任务标题',
              content: []
            }
          }
          break
        case 'gateway':
          nodeData = {
            shape: "gateway", width: 86, height: 86,
            data:{}
          }
          break
        case 'end':
          nodeData = {
            shape: "end", width: 70, height: 70,
            data:{}
          }
          break
        case 'swim':
          nodeData = {
            shape: "swim", width: 450, height: 200,
            data:{
              task: {
                content: [
                  {name: '泳道',ratio: 1}
                ]
              }
            }
          }
          break
      }
      const node = this.graph.createNode(nodeData)
      this.dnd.start(node, e)
    },
    // 获取前置任务配置Data
    getPreTask(nodeId,type){
      const graphData = this.graph.toJSON()
      const preNodeId = preTaskNode(graphData.cells,nodeId,type,[nodeId])
      const preNode = graphData.cells.filter(item => preNodeId.includes(item.id) && item.shape === 'task')
      const nodeData = Array.from(preNode,item=>item.data)
      return nodeData
    },
    openFormParser(node){
      this.skeleton = true
      this.otherParam.f_task_engine_id = this.taskEngineId
      this.otherParam.f_task_node_id = node.id
      this.otherParam.f_task_recover_id = this.taskRecoverId
      if (this.outerParam) {
        this.otherParam = Object.assign(this.otherParam, this.outerParam)
        this.fillData = Object.assign(this.fillData, this.outerParam)
      }
      if(this.taskMode !== 'recover'){
        return false
      }
      if([1,2].includes(node.form_type)) {
        this.parserMode = 'form'; // 表单
      } else {
        this.parserMode = 'page'; // page页面
      }
      let data = this.taskTodo.find(item=> item.step_node === node.id)
      if(!data) {
        data = this.completeTodo.find(item=> item.step_node === node.id)
      }
      if(data === undefined) {
        this.$message.error('当前任务尚未开始，无法填报')
        return false
      }
      this.modalTitle = node.name;
      this.dialogVisible = true;
      this.displayData = {};
      this.formDesignData = null;
      let formDataId = 0  // 表单数据详情回显 -- 有多条只展示第一条数据
      for (let item of data?.user_task) {
        if(item.task_form_id > 0) {
          formDataId = item.task_form_id
          break
        }
      }
      if(formDataId) {
        this.formMode = 'detail'
      } else {
        this.formMode = 'add'
      }
      this.curStepNode = data.step_node;
      this.taskNodeData = data.nodeData;
      this.taskTodoId = data.task_log_id;
      if(node.form_type === 1){
        formDesign.formShow(node.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = !!formData.need_flow
            this.moduleUuid = formData.module_uuid
          }
        });
      } else if(node.form_type === 2) {
        const objectUUID = node.object_uuid
        const formUUID = node.func_form
        transFieldsUUid(objectUUID, formUUID, {}).then((res) => {
          if (res.data.code == 200) {
            const formData = res.data.data
            let ruuids = []; // 详情子表需传入关联UUID
            for (let i = 0; i < formData.fields.length; i++) {
              let item = formData.fields[i];
              if (item.__config__.tagIcon == 'form') {
                ruuids.push({ relationship_uuid: item.__vModel__ });
              }
            }
            if(formDataId) {
              dataInterface({
                object_uuid: objectUUID,
                __method_name__: 'dataInfo',
                data_id: formDataId,
                ruuids
              }).then((res1) => {
                this.displayData = res1.data.data;
                this.formDesignData = formData
                this.needFlow = !!formData.need_flow
                this.moduleUuid = formData.uuid
              })
            } else {
              this.formDesignData = formData
              this.needFlow = !!formData.need_flow
              this.moduleUuid = formData.uuid
            }
          }
        })
      } else if(node.form_type === 3) {
        this.pageUUID = node.func_form
        this.skeleton = false
        let key = `dialogRelationParams_${this.pageUUID}` || `${this.pageUUID}`
        sessionStorage.setItem(key, JSON.stringify(this.fillData))
      }
    },
    getSelectStep(){},
    // 任务责任人
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    triggerSubmit(){
      if(this.taskNodeData.form_type === 3){
        let formData = {field649c0d25e8097: this.pageUUID}
        this.onFormSubmit(formData)
      } else {
        this.$refs.dialogForm.handleSubmit()
      }
    },
    onFormSubmit(formData){
      formData.task_log_id = this.taskTodoId
      if (
        this.checkPickerNum !== 0 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('下一任务节点责任人未选择！')
        return false
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }
      if(this.taskNodeData.form_type === 1){
        formData.module_uuid = this.moduleUuid
        formDesign.formDataStore(formData).then((res) => {
          if (res.data.code === 200) {
            this.dialogVisible = false
            this.$message.success(res.data.msg);
            this.initCanvas()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if(this.taskNodeData.form_type === 2){
        const objectUuid = this.taskNodeData.object_uuid
        const params = {
          __method_name__: 'createData',
          object_uuid: objectUuid,
          ...formData
        }
        dataInterface(params).then((res) => {
          if (res.data.code=== 200) {
            this.dialogVisible = false
            this.$message.success(res.data.msg);
            this.initCanvas()
          }
        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
          this.$loading().close();
        });
      } else if(this.taskNodeData.form_type === 3){
        // 模拟formModel,便于页面类型任务流转
        const objectUuid = 'object649c0d00271b8'
        const params = {
          __method_name__: 'createData',
          object_uuid: objectUuid,
          ...formData
        }
        dataInterface(params).then((res) => {
          if (res.data.code=== 200) {
            this.dialogVisible = false
            this.$message.success(res.data.msg);
            this.initCanvas()
          }
        }).catch((err) => {
          console.log(err);
        }).finally(()=>{
          this.$loading().close();
        });
      }
    },
    closePicker(){
      this.$refs.taskPicker.isExpand = false
    },
    onEndFlow(){
      this.dialogVisible = false
    },
  },
  destroyed() {
    this.graph.dispose()
    this.graph = null
    eventBus.$off('open-task-form')
  }
}
</script>
<style lang="less">
  .snapline{
    .x6-widget-snapline-horizontal{
      border-bottom:1px dashed #F57F17 !important;
    }
    .x6-widget-snapline-vertical{
      border-right:1px dashed #F57F17 !important;
    }
  }
</style>
<style lang="less" scoped>

.indictor{
  position: absolute;
  top: 25px;
  left: 85px;
  line-height: 20px;
  z-index: 10;
  display: flex;
}

.indictor-todo{
  padding-left: 15px;

  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #539FE6;
    border-radius: 3px;
  }
}

.indictor-complete{
  padding-left: 15px;
  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #40B67C;
    border-radius: 3px;
  }
}

.indictor-danger {
  padding-left: 15px;
  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #F94520;
    border-radius: 3px;
  }
}

:deep(.el-tabs) {
  height: 100%;
  box-shadow: none;
  .el-tabs__content{
    padding: 0;
    height: calc(100% - 40px);
    .el-tab-pane{
      height: 100%;
    }
  }
}

:deep(.common-dialog)  {
  .el-dialog__header{
    border-bottom: 1px #f2f3f5 solid;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding: 10px 0;
    border-top: 1px #f2f3f5 solid;
  }
}

.dialog-main{
  padding: 0;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
}

.scrollbar-bar{
  overflow-y: auto;
  padding: 0 15px;
  height: calc(100% - 60px);
}

.iconc-close{
  position: absolute;
  font-size: 22px;
  top: 5px;
  right: 8px;
  z-index: 10;
  cursor: pointer;
}

.task-config-wrapper{
  text-align: initial;
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  .conf-title{
    padding: 10px;
    border-bottom: 1px solid #eaebed;
    .iconc-close{
      float: right;
    }
  }
  .content {
    height: calc(100% - 105px);
  }
}

.task-flow-container{
  text-align: initial;
  position: relative;
  width: 100%;
  height: calc(100% - 5px);
  border: 1px solid #eaebed;

  .contextmenu{
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 10;
    border-radius: 5px;
    box-shadow: 3px 3px 5px #e0e0e0;
    width: 100px;
    background: #FFFFFF;
    .menu-item {
      line-height: 25px;
      text-align: center;

      &:hover {
        background: #E3E4E3;
      }
    }
  }

  .action-bar{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  .graph-container{
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 101;
    width: 50px;
    padding: 20px 10px;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgb(228, 224, 219);

    .node-item {
      margin-bottom: 20px;
    }

    .node-item-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background-size: cover;
    }

    .node-label {
      font-size: 12px;
      line-height: 30px;
      user-select: none;
    }

    .node-start {
      background: url('./background/start.png') no-repeat;
      background-size: cover;
    }

    .node-task {
      background: url('./background/task.png') no-repeat;
      background-size: cover;
    }

    .node-gateway {
      background: url('./background/gateway.png') no-repeat;
      background-size: cover;
    }

    .node-end {
      background: url('./background/end.png') no-repeat;
      background-size: cover;
    }
    .node-swim {
      background: url('./background/swim.png') no-repeat;
      background-size: cover;
    }
  }

  .task-block{
    width: 100%;
    height: 100%;
    background-color: #F7F9FF;
  }

  .footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

}

.task-publish {
  display: flex;
  align-content: space-around;
}
.skeleton {
  padding: 15px;
}
</style>
