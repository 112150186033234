/*
 * @Description: 应用相关
 * @Author: luocheng
 * @Date: 2022-03-18 15:08:18
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-05-08 11:10:09
 */
import Vue from 'vue';

export default {
  state: {
    // 应用列表
    appList: null,
    // 当前应用
    currentApp: null,
    // 当前应用首页
    appIndexMenu: null,
    // 当前访问菜单
    activeMenu: null,
    // 一二级菜单
    currentMenu: null,
    // 三级菜单
    currentMenuThree: null,
    // 是否显示四五级菜单
    showMenuList: false,
    // 历史菜单
    appNavHistoryList: [],
    // work-order页面下是否自动打开应用中心
    showAppCenterVisible: false
  },
  mutations: {
    /**
     * @desc: 保存应用列表
     */
    setAppList(state, appList) {
      state.appList = appList;
    },
    /**
     * @desc: 设置当前应用
     * @param {Object} currentApp
     */
    setCurrentApp(state, currentApp) {
      state.currentApp = currentApp;
      if (currentApp) {
        sessionStorage.setItem('currentApp', JSON.stringify(currentApp));
      } else {
        sessionStorage.removeItem('currentApp')
      }
    },
    /**
     * @desc: 当前操作的菜单
     */
    setCurrentMenu(state, config) {
      if (!config) {
        state.currentMenu = null;
        state.currentMenuThree = null;
        state.activeMenu = null;
        return;
      }
      const { level, menuData } = config;
      if (level < 3) {
        state.currentMenu = menuData;
      } else if (level === 3) {
        state.currentMenuThree = menuData;
      }
      state.activeMenu = menuData;
    },
    /**
     * @desc: 切换四五级菜单显示
     */
    toggleMenuListShow(state, status) {
      state.showMenuList = status !== undefined ? status : !state.showMenuList;
    },
    /**
     * @description: 设置当前显示的3级菜单，即MenuPart值
     */
    setCurrentMenuPart(state, partData) {
      state.currentMenuPart = partData;
    },
    /**
     * @desc: 设置菜单历史列表
     */
    /* eslint-disable */ 
    setAppNavHistory(state, { history, origin }) {
      // console.log(state.appNavHistoryList, '-----111-----', history, '-----2222--', origin);
      if (Array.isArray(history)) {
        Vue.set(state, 'appNavHistoryList', history);
      } else {
        if (!state.appNavHistoryList.find(ele => ele.id === history.id)
          && !state.appNavHistoryList.find(ele => ele.menu_path === history.menu_path)
        ) {
          state.appNavHistoryList.push(history);
        }
      }
      sessionStorage.setItem('appNavHistoryList', JSON.stringify(state.appNavHistoryList))
    },
    /**
     * @desc: 移除
     */
    removeAppNavHistory(state, index) {
      state.appNavHistoryList.splice(index, 1)
      sessionStorage.setItem('appNavHistoryList', JSON.stringify(state.appNavHistoryList))
    },
    /**
     * @desc: 保存首页路由
     * @param {Object} indexMenu
     */
    setAppIndexMenu(state, indexMenu) {
      state.appIndexMenu = indexMenu;
      if (indexMenu) {
        sessionStorage.setItem('appIndexMenu', JSON.stringify(indexMenu))
      } else {
        sessionStorage.removeItem('appIndexMenu')
      }
    },
    setShowAppCenter(state, showAppCenterVisible) {
      state.showAppCenterVisible = showAppCenterVisible;
    }
  }
}