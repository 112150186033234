<!--
 * @Description: 3.0消息弹窗
 * @Author: luocheng
 * @Date: 2021-12-20 19:09:02
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-07-13 10:20:25
 * @FilePath: /dataview-viewer-test/src/components/global/Dailog.vue
-->
<template>
  <div class="global-dialog global-dialog-one" v-loading="loading">
    <el-dialog
      :title="title"
      custom-class="global-dialog-overscroll"
      :visible.sync="showDialog"
      :before-close="onFormSubmit"
      width="60%"
      :fullscreen="isFullScreen"
    >
      <header class="global-dialog-title" slot="title">
        <h3>{{ title || '--' }}</h3>
        <i class="iconfont icondaping-quanping fullscreen" @click="onToggleScreen"></i>
      </header>
      <div v-if="!prepared" v-loading="true" style="height:500px"></div>
      <PcParserNew
        v-else-if="newFormFlow"
        ref="dialogForm"
        :form-data="formDesignData"
        :displayData="displayData"
        :editFields="editFields"
        :disabled="disabled"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        @submit="onFormSubmit"
        @on-close="onEndFlow"
        @edit-change="formEditChange"
      >
      </PcParserNew>
      <PcParser
        v-else
        ref="dialogForm"
        :class="{'p20': !newFormFlow}"
        :form-data="formDesignData"
        :displayData="displayData"
        :disabled="disabled"
        :editFields="editFields"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
				@on-close="onEndFlow"
        @submit="onFormSubmit"
      >
      </PcParser>
      <div slot="footer" v-if="newFormFlow">
        <FlowOperate
          v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"
          :edit-data="displayData"
          :flow-edit="editFieldsValue"
          @change-end="onEndFlow"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser";
import PcParserNew from '@/custom-component/form/newParser/PcParserNew.vue';
import { transFieldsUUid, dataInterface } from "@/apis/data/index";
import eventBus from '@/plugins/eventBus';
import FlowOperate from "@/custom-component/flow/flow-designer/src/operation/FlowOperate";

export default {
  name: "GlobalDialog",
  components: {
    PcParser,
    PcParserNew,
    FlowOperate
  },
  props: {
    form_uuid: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    data_id:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:'提示'
    },
    tag:{
      type:String,
      default:''
    },
    archi_type:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      showDialog: false,
      displayData: null,
      editFields: {},
      formDisabled: "dataInfo",
      formDesignData: {},
      // 流程
      needFlow: true,
      needParentFlow: false,
      formUUID: "",
      requestType: "detail",
      loading: true,
      prepared:false,
      // 全屏
      isFullScreen: false,
      newFormFlow: false, // 新流程表单
      editFieldsValue: {}
    };
  },
  mounted() {
    this.formUUID = this.form_uuid;
    this.showDialog = true;
    this.loading = true;
    // TIPS 请求表单携带原始数据id @凌志华
    transFieldsUUid("", this.formUUID,{ tag: this.tag, org_data_id: this.data_id })
      .then((res) => {
        if (res.data.code == 200) {
          this.needFlow = res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == "form") {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          dataInterface({
            data_id: this.data_id,
            object_uuid: this.formUUID,
            view_uuid: "",
            ruuids,
            __method_name__: "dataInfo",
            __now_archi_type:this.archi_type,
            tag:this.tag
          }).then((res1) => {
            let data = res1.data.data;
            this.formDesignData = res.data.data;
            if(data?.recover?.cells){
              this.newFormFlow = true; // 新版流程表单
            }
            // console.log(this.formDesignData)
            this.displayData = data;
            this.editFields = data?.editFields || {};
            this.loading = false;
            this.prepared = true
            // this.showDialog = true;
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  },
  methods: {
    formEditChange(val){
      this.editFieldsValue = val
    },
    /**
     * @desc: 表单提交
     */
    onFormSubmit() {
      this.showDialog = false;
      this.loading = false;
      this.$emit('close')
    },
    /**
     * @desc: 操作完毕关闭弹窗
     */
    onEndFlow() {
      this.showDialog = false;
      // 更新状态列表
      eventBus.$emit('updateTodoList');
    },
    /**
     * @description: 切换全屏/非全屏
     */
    onToggleScreen() {
      this.isFullScreen = !this.isFullScreen;
      // document.querySelector('.global-dialog-overscroll').style.height = '100vh';
      document.querySelector('.global-dialog-overscroll').style.maxHeight = this.isFullScreen ? '100vh' : '80%';
    }
  },
};
</script>

<style lang="less">
.global-dialog-overscroll{
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 6px!important;
  box-sizing: border-box;
  padding: 5px;
  transition: all .2s;
  .el-dialog__header{
    border-bottom: 1px solid #f2f3f5;
    padding-top: 10px;
    .el-dialog__headerbtn {
      top: 15px;
    }
    .el-icon-close {
      position: relative;
      line-height: 24px;
      font-size: 20px;
    }
  }
  .global-dialog-title {
    display: flex;
    h3 {
      flex: 1;
      line-height: 24px;
      font-size: 18px;
      color: #303133;
      box-sizing: border-box;
      padding-right: 20px;
    }
    .fullscreen {
      margin-right: 30px;
      color: #909399;
    }
  }
  .el-dialog__body{
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 0;
  }
  .el-dialog__footer{
    padding: 10px 0;
  }
  .p20 {
    padding: 20px;
  }
}
</style>
