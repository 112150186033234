import { render, staticRenderFns } from "./ViewerBIM.vue?vue&type=template&id=ff43a370&scoped=true&"
import script from "./ViewerBIM.vue?vue&type=script&lang=js&"
export * from "./ViewerBIM.vue?vue&type=script&lang=js&"
import style0 from "./ViewerBIM.vue?vue&type=style&index=0&id=ff43a370&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff43a370",
  null
  
)

export default component.exports