<!--
    @name: TaskRules
    @description：TaskRules
    @author: ZengWei
    @date: 2022-05-17 17:43
-->
<template>
  <div class="task-rule">
    <el-form class="form-table" label-position="left" label-width="80px">
      <template v-for="(item,index) in taskRules">
        <div :key="index">
          <div class="self-define">
            <span>{{index>9 ? index : '0'+(index+1)}}</span>
            <span class="float-r" @click="delRule(index)">
              <i class="el-icon-delete"></i>
            </span>
            <span class="float-r" @click="upGo(index)">
              <i class="el-icon-top"></i>
            </span>
            <span class="float-r" @click="downGo(index)">
              <i class="el-icon-bottom"></i>
            </span>
          </div>
          <el-form-item label="逻辑">
            <el-radio-group v-model="item.logic_type">
              <el-radio label="and">与 (and)</el-radio>
              <el-radio label="or">或 (or)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="规则">
            <el-radio-group class="rule-radio" v-model="item.rule_type" style="margin-bottom: 15px;">
              <el-radio-button :label="1">表单字段取值</el-radio-button>
              <el-radio-button :label="2">公式聚合规则</el-radio-button>
            </el-radio-group>
            <el-select
              v-if="item.rule_type === 1"
              v-model="item.rule"
              placeholder="请选择"
              @change="onChange">
              <el-option
                v-for="(dd, ii) in allFormFields"
                :key="ii"
                :label="dd.name"
                :value="dd.flag"
              />
            </el-select>
            <el-button v-else style="width: 100%;" @click="openLogic">配置聚合条件公式规则</el-button>
          </el-form-item>
          <el-form-item label="比较">
            <el-select
              v-model="item.judge"
              placeholder="请选择"
              @change="onChange"
            >
              <el-option
                v-for="(dd, ii) in conditionList"
                :key="ii"
                :label="dd.name"
                :value="dd.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="取值">
            <el-input v-model="item.value" @change="onChange"></el-input>
          </el-form-item>
        </div>
      </template>
      <el-button @click="addRule" class="add-rule-btn" icon="el-icon-plus">添加新条件</el-button>
    </el-form>

    <el-dialog
      title="聚合条件 - 公式计算规则配置"
      :visible.sync="ruleVisible"
      width="60%"
      custom-class="common-dialog"
      :append-to-body="true"
    >
      <div style="height: 62vh;margin:0 20px">
        <logic-rules ref="logicRules" :fields-list="allFormFields" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="ruleVisible = false">
          取 消
        </el-button>
        <el-button size="small" type="primary" @click="getCalcRules">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {RadioGroup,RadioButton,Radio} from "element-ui";
// import LogicRules from "@/custom-component/task-flow/components/LogicRules";
import LogicRules from "@/custom-component/task-flow/property/LogicRules.vue";

export default {
  name: "TaskRules",
  components: {
    LogicRules,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-radio': Radio,
  },
  props: ['allFormFields'],
  data() {
    return {
      fieldList:[],
      ruleVisible: false,
      taskRules:[],
      conditionList:[
        {name: '等于', value: 'eq'},
        {name: '不等于', value: 'neq'},
        {name: '大于', value: 'gt'},
        {name: '小于', value: 'lt'},
        {name: '大于等于', value: 'egt'},
        {name: '小于等于', value: 'elt'},
        {name: '包含任意', value: 'or'},
        {name: '完全等于', value: 'and'},
        {name: '介于之间', value: 'between'},
      ]
    }
  },
  methods: {
    delRule(index){
      this.taskRules.splice(index,1)
    },
    addRule(){
      const rule = {
        logic_type: 'and',
        rule_type: 1,
        rule: '',
        judge: '',
        value: '',
      }
      this.taskRules.push(rule)
    },
    onChange(){
      this.$emit('on-logic',this.taskRules)
    },
    openLogic(){
      this.ruleVisible = true
    },
    getCalcRules(){},
    upGo(index){
      let fieldData = this.taskRules;
      if(index!=0){
        fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
      }else{
        fieldData.push(fieldData.shift());
      }
      this.taskRules = fieldData;
      this.onChange();
    },
    downGo(index){
      let fieldData = this.taskRules;
      if(index != fieldData.length-1){
        fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
      }else{
        fieldData.unshift( fieldData.splice(index,1)[0]);
      }
      this.taskRules = fieldData;
      this.onChange();
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.task-rule{
  .add-rule-btn{
    width: 100%;
    margin: 15px 0;
  }

  :deep(.form-table)  {
    .el-form-item {
      border: 1px solid #eaebed;
      display: flex;
      margin-bottom: -1px !important;
      /*margin-bottom: -1px !important;*/

      .el-form-item__label {
        border-right: 1px solid #eaebed;
        background: #f8f8f9;
        padding: 6px 10px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        flex: 1;
        padding: 6px 10px;
        overflow: hidden;
        clear: both;
        /*margin-bottom: 1px;*/

        .el-select,
        .el-cascader {
          width: 100%;
        }

        .rule-radio {
          .el-radio-button__inner{
            padding: 9px 16px;
          }
        }
      }
    }
  }
}



.float-r{
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
.clearfix:after{
  content: '';
  display: block;
  clear: both;
}
.self-define{
  margin: 8px 0;
  text-indent: 2px;
}
.select-item{
  width: 100%;
}
</style>
