<template>
    <div class="moduleWrap horizontal-flex">
        <div class="horizontal-flex item-wrap">
            <span class="item-content-short" style="margin-left: 20px">Z：{{height}}</span>
            <span class="item-content-long">A：{{lng}}</span>
            <span class="item-content-long">B：{{lat}}</span>
        </div>

        <a class="item-icon iconfont icondingwei"></a>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';

    export default {
        name: "ViewerGISMeasurePoint",
        data() {
            return {
                bindViewerId: null,
                lng: 0,
                lat: 0,
                height: 0
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }
        },
        methods: {
            initEventListener() {
                eventBus.$on('ViewerGISMeasurePoint.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.originComponentData, comps);
                    loop(this.componentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId
                    });
                });

                eventBus.$on('ViewerGISMeasurePoint.BindViewer', (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);

                });

                eventBus.$on('ViewerGIS.onViewerInited', (eid) => {
                    if (eid !== this.bindViewerId) return;
                });

                eventBus.$on('ViewerGIS.onMeasurePoint', (eid, point) => {
                    if (eid !== this.bindViewerId) return;

                    if(point){
                        this.lng = Number(point.lng.toFixed(6));
                        this.lat = Number(point.lat.toFixed(6));
                        this.height = Number(point.height.toFixed(2));
                    }
                });
            },
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.componentData);
                const hasOriginComp = checkFunc(bindId, this.originComponentData);

                if (!hasComp && !hasOriginComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
        color: inherit;
    }

    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .item-wrap{
        width: 360px;
        height: 40px;
        background: linear-gradient(to right, rgba(0, 66, 71, 0), #002D76);
        border-radius: 4px;
        color: inherit;
    }

    .item-content-long{
        width: 130px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: inherit;
        font-size: 16px;
    }

    .item-content-short{
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: inherit;
        font-size: 16px;
    }

    .item-icon{
        width: 50px;
        height: 40px;
        line-height: 40px;
        font-size: 36px;
        color: inherit;
    }
</style>
