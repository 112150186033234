/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-06-01 17:32:45
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-06-02 15:47:22
 * @FilePath: \dataview-next\src\libs\dataRequest.js
 * @Description: 用于方案图组件加载表格
 */
import { dataInterface } from '@/apis/data';

/**
 * @description: 请求数据
 * @param {*} uuid
 * @return {*}
 */
function requestData(uuid) {
  return new Promise((resolve, reject) => {
    getChartInfo(uuid).then(data => {
      const option = getOptions(data.chartData, data.data);
      resolve(option);
    }).catch(err => {
      console.error(err);
      reject();
    })
  })
}

/**
 * @description: 获取图表信息
 * @param {*} page_uuid
 * @return {*}
 */
function getChartInfo(page_uuid) {
  return new Promise((resolve, reject) => {
    getChartConfig(page_uuid).then((chartConfig) => {
      if(chartConfig) {
        const chartData = chartConfig.page_data?.chartData;
        const sourceType = chartData?.sourceType;
        switch (sourceType) {
          case 'static': {
            getStaticData(chartData).then(data => {
              resolve({
                chartData,
                data
              });
            }).catch(err => {
              reject(err);
            })
            break;
          }
          case 'custom': {
            getCustomData(chartData).then(data => {
              resolve({
                chartData,
                data
              });
            }).catch(err => {
              reject(err);
            })
            break;
          }
          case 'fillData': {
            getFillData(chartData).then(data => {
              resolve({
                chartData,
                data
              });
            }).catch(err => {
              reject(err);
            })
            break;
          }
          default: {
            getStatistical(page_uuid, chartData).then(data => {
              resolve({
                chartData,
                data
              });
            }).catch(err => {
              reject(err);
            })
            break;
          }
        }
      } else {
        reject();
      }
    }).catch(() => {
      reject();
    })
  })
}

/**
 * @description: 获取图表配置
 * @param {*} page_uuid
 * @return {*}
 */
function getChartConfig(page_uuid) {
  return new Promise((resolve, reject) => {
    dataInterface({
      __method_name__: 'dataList',
      object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
      page_uuid,
      transcode: 0,
      view_uuid: 'view61b951c6a8186'
    })
      .then(res => {
        resolve(res?.data?.data?.[0]);
      })
      .catch(err => {
        console.log(err);
        reject([]);
      });
  });
}

/**
 * @description: 获取静态统计
 * @param {*} config
 * @return {*}
 */
function getStaticData(config) {
  return new Promise((resolve, reject) => {
    const { staticConfig = [] } = config;
    const chartLineData = [];
    for (let i = 0; i < staticConfig.length; i++) {
      const ele = staticConfig[i];
      const { objectUUID, viewUUID, x_fields = [], y_fields = [] } = ele || {};
      if ((!objectUUID || !viewUUID || !Array.isArray(x_fields), !Array.isArray(y_fields))) {
        reject()
      }
      const params = { object_uuid: objectUUID, view_uuid: viewUUID }
      getDataList(params).then((dataList) => {
        if (!dataList || !Array.isArray(dataList)) {
          reject();
        }
        switch(config.type) {
          case 'ring': {
            const names = x_fields.map(ele => ele.name);
            let process = 0;
            let totalData = 0;
            if (dataList.length) {
              process = dataList[0]?.[x_fields[0]?.uuid];
              totalData = dataList[0]?.[y_fields[0]?.uuid];
            }
            resolve({ names, process, totalData });
            break
          }
          case 'completionRatio': {
            console.error('暂不支持')
            reject();
            break;
          }
          case 'barLine': {
            // 折柱混合
            const xData = [];
            const series = [];
            dataList.forEach(dataItem => { xData.push(dataItem?.[x_fields[0]?.uuid]) });
            y_fields.forEach(ele => {
              const yData = [];
              dataList.forEach(dataItem => {
                yData.push(+dataItem?.[ele?.uuid]);
              });
              series.push({
                data: yData,
                name: ele.name
              })
            });
            chartLineData.push({
              chartType: ele.chartType,
              names: [''],
              xData,
              series
            });
            break;
          }
          case 'pointer': {
            const { totalField, totalType, valueField, valueType } = config?.staticConfig?.[0]?.pointer;
            let total = 100,
              value = 0;
            if (totalType === 'fixed') {
              total = totalField;
            } else {
              total = dataList?.[0]?.[totalField] || 0;
            }
            if (valueType === 'fixed') {
              value = valueField;
            } else {
              value = dataList?.[0]?.[valueField] || 0;
            }
            resolve({
              total,
              value
            });
            break;
          }
          default: {
            let series = [];
            let xData = [];
            const names = y_fields.map(ele => {
              series.push({
                name: ele.name,
                uuid: ele.uuid,
                data: []
              });
              return ele.name;
            });
            dataList.forEach(ele => {
              series = series.map(item => {
                const data = item.data;
                data.push(isNaN(+ele[item.uuid]) ? 0 : +ele[item.uuid]);
                return {
                  ...item,
                  data
                };
              });
              xData.push(ele[x_fields[0]?.uuid]);
              const chartData = [
                {
                  names,
                  xData,
                  series
                }
              ];
              const useSort = config?.useSort;
              const des = config?.isASC;
              // 如果启用排序
              if (useSort && ['bar', 'line', 'barLine'].includes(config.type)) {
                let sortIndex = [];
                const root = chartData[0];
                if(root.series.length && root.series[0]?.data.length) {
                  const sortData = root.series[0]?.data;
                  sortIndex = sortData.map((el, index) => ({ index: index, value: el})).sort((a, b) =>  des ? a.value - b.value : b.value - a.value);
                }
                chartData.forEach(el => {
                  el.xData = sortIndex.map(ele => el.xData[ele.index]);
                  el.series.forEach(ele => {
                    ele.data = sortIndex.map(elee => ele.data[elee.index]);
                  })
                })
              }
              resolve(chartData);
            })
          }
        }
        if (config.type === 'barLine') {
          const useSort = config?.useSort;
          const des = config?.isASC;
          // 如果启用排序
          if (useSort && ['bar', 'line', 'barLine'].includes(config.type)) {
            let sortIndex = [];
            const root = chartLineData[0];
            if(root.series.length && root.series[0]?.data.length) {
              const sortData = root.series[0]?.data;
              sortIndex = sortData.map((el, index) => ({ index: index, value: el})).sort((a, b) =>  des ? a.value - b.value : b.value - a.value);
            }
            chartLineData.forEach(el => {
              el.xData = sortIndex.map(ele => el.xData[ele.index]);
              el.series.forEach(ele => {
                ele.data = sortIndex.map(elee => ele.data[elee.index]);
              })
            })
          }
          resolve(chartLineData)
        }
      })
    }
  })
}

/**
 * @description: 获取自定义统计
 * @param {*} config
 * @return {*}
 */
function getCustomData(config) {
  return new Promise((resolve, reject) => {
    const chartLineData = [];
    const { customConfig = {} } = config;
    const { xDataConfig = [], legendConfig = [], bindConfig = [] } = customConfig;
    if (!bindConfig.length) {
      reject()
    }
    for (let i = 0; i < bindConfig.length; i++) {
      const ele = bindConfig[i];
      const { objectUUID, viewUUID, methodName = 'dataList', bindLegend = '', valueConfig = [] } = ele || {};
      if (!objectUUID || !viewUUID) {
        return;
      }
      const param = { object_uuid: objectUUID, view_uuid: viewUUID }
      getDataList(param, methodName).then((dataList) => {
        if (!dataList || !Array.isArray(dataList)) {
          reject()
        }
        if (config.type === 'ring') {
          // 百分比环图
          // TO DO
          reject()
        } else if (config.type === 'completionRatio') {
          // 柱状进度图
          // TO DO
          reject()
        } else if (config.type === 'barLine') {
          // 折柱混合
          const xData = xDataConfig,
            yData = [];
          // 取列表第一个
          let valueObject = null;
          if (Array.isArray(dataList) && dataList.length) {
            valueObject = dataList[0];
          }
          // TIPS 如果存在值就赋值，不存在就直接赋0
          if (valueObject) {
            valueConfig.forEach(dataItem => {
              yData.push(isNaN(+valueObject[dataItem.fieldUUID]) ? 0 : +valueObject[dataItem.fieldUUID]);
            });
          } else {
            valueConfig.forEach(() => {
              yData.push(0);
            });
          }
          chartLineData.push({
            chartType: ele.chartType,
            names: legendConfig,
            xData,
            series: [
              {
                data: yData,
                name: bindLegend
              }
            ]
          });
        } else {
          // TO DO
          reject()
        }
        resolve(chartLineData)
      }).catch(() => {
        reject()
      })
    }
  })
}

/**
 * @description: 获取填充数据
 * @param {*} config
 * @return {*}
 */
function getFillData(config) {
  return new Promise((resolve, reject) => {
    const chartLineData = [];
    const { fillConfig = [] } = config;
    if (config.type === 'barLine' || config.type === 'bar' || config.type === 'line') {
      fillConfig.forEach(ele => {
        const obj = {
          chartType: ele.chartType || 'bar',
          names: ele.data.data.map(elee => elee.name),
          xData: ele.data.col.map(elee => elee.name),
          series: ele.data.data.map(elee => {
            return {
              name: elee.name,
              data: ele.data.col.map(eleee => elee[eleee.fild])
            };
          })
        };
        chartLineData.push(obj);
      });
      resolve(chartLineData)
    } else if (config.type === 'pie') {
      const data = [];
      fillConfig.forEach(ele => {
        const obj = {};
        ele.data.col.forEach(elee => {
          if (Array.isArray(ele.data.data) && ele.data.data.length) {
            obj[elee.name] = ele.data.data[0][elee.fild];
          }
        });
        data.push(obj);
      });
      if (data.length) {
        resolve(data)
      } else {
        reject()
      }
    } else if (config.type === 'ring') {
      if (fillConfig.length) {
        const obj = {
          names: [fillConfig[0].data.data[0].name],
          process: fillConfig[0].data.data[0][fillConfig[0].data.col[0].fild],
          totalData: fillConfig[0].data.data[0][fillConfig[0].data.col[1].fild]
        };
        resolve(obj)
      }
    } else if (config.type === 'completionRatio') {
      reject()
    }
  })
}

/**
 * @description: 动态统计
 * @param {*} page_uuid
 * @param {*} config
 * @return {*}
 */
function getStatistical(page_uuid, config) {
  return new Promise((resolve, reject) => {
    getStatisticalData(page_uuid).then((statisticalData) => {
      if (!statisticalData?.length) {
        reject()
      }
      const useSort = config?.useSort;
      const des = config?.isASC;
      // 如果启用排序
      if (useSort && ['bar', 'line', 'barLine'].includes(config.type)) {
        let sortIndex = [];
        const root = statisticalData[0];
        if(root.series.length && root.series[0]?.data.length) {
          const sortData = root.series[0]?.data;
          sortIndex = sortData.map((el, index) => ({ index: index, value: el})).sort((a, b) =>  des ? a.value - b.value : b.value - a.value)
        }
        statisticalData.forEach(el => {
          el.xData = sortIndex.map(ele => el.xData[ele.index]);
          el.series.forEach(ele => {
            ele.data = sortIndex.map(elee => ele.data[elee.index]);
          })
        })
      }
      resolve(statisticalData);
    }).catch((error) => {
      console.log(error)
      reject()
    })
  })
}

function getStatisticalData(page_uuid, param = {}, search = []) {
  return new Promise((resolve, reject) => {
    dataInterface({
      __method_name__: 'globalFunctionCall',
      typeName: 'PublicFunc',
      type: 'value',
      funcName: 'CommonAnalyse',
      payload: {
        page_uuid: page_uuid
      },
      search,
      ...param
    })
      .then(res => {
        if (res && res.status === 200 && res.data && res.data.code === 200) {
          resolve(res.data.data);
        } else {
          reject(null);
        }
      })
      .catch(err => {
        console.log(err);
        reject(null);
      });
  });
}

function getDataList(config, __method_name__ = 'dataList') {
  return new Promise((resolve, reject) => {
    dataInterface({
      __method_name__,
      ...config
    })
      .then(res => {
        resolve(res?.data?.data || [])
      })
      .catch(() => {
        reject([])
      })
  })
}

function getOptions(config, data) {
  switch(config.type) {
    case 'bar': {
      return getBarOptions(config, data)
    }
    case 'line': {
      return getLineOptions(config, data)
    }
    case 'barLine': {
      return getBarLineOptions(config, data)
    }
    case 'pie': {
      return getPieOptions(config, data)
    }
  }
}

// 获取柱状图 options
function getBarOptions(config, data) {
  const getLegendData = (dataFrom, color = '#000', showLine = false) => {
    let s = [];
    if (!dataFrom || !dataFrom.names) {
      return [];
    }
    for (let i = 0; i < dataFrom.names.length; i++) {
      s.push({
        name: dataFrom.names[i],
        textStyle: {
          color,
        }
      });
    }
    // 折线
    if (showLine && dataFrom.series.length > 1) {
      s.push({
        name: '累计',
        textStyle: {
          color,
          fontSize: 12
        }
      })
    }
    return s;
  }
  const getSeries = (series, barWidth) => {
    let s = [];
    if (!series) {
      return [];
    }
    const { attributes = {} } = config;
    const { XColorRange = [], distinguishColor = false, barChart = false, useGradient = false, gradientRange = [], usePolar = false } = attributes;
    for (let i = 0, len = series.length; i < len; i++) {
      const el = series[i];
      let data = el.data || [];
      if (distinguishColor || useGradient) {
        data = data.map((value, index) => {
          return {
            value,
            itemStyle: getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange)
          }
        })
      }
      s.push({
        name: el.name,
        type: 'bar',
        barWidth: barWidth,
        coordinateSystem: usePolar ? 'polar' : 'cartesian2d',
        data,
        label: {
          show: attributes.showBarLabel,
          position: usePolar ? 'middle' : attributes.barChart ? 'right' : 'top',
          fontSize: 12,
          color: useGradient ? attributes.textColor : 'inherit', // 设置跟随为视觉映射得到的颜色，如系列色
        }
      });
    }
    return s;
  }
  const getItemStyle = (distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange) => {
    if (distinguishColor && useGradient) {
      return {
        color: {
          type: 'linear',
          x: barChart ? 0 : 0.5,
          y: barChart ? 0.5 : 1,
          x2: barChart ? 1 : 0.5,
          y2: barChart ? 0.5 : 0,
          colorStops: Array.isArray(gradientRange[index % gradientRange.length]) ?
          gradientRange[index % gradientRange.length].map((el, sindex) => {
            if(sindex === 0) {
              return ({
                offset: 0,
                color: el
              })
            }
            return ({
              offset: ( 1 / gradientRange[index % gradientRange.length].length) * (sindex + 1),
              color: el
            })
          }) : [],
          global: false // 缺省为 false
        }
      }
    } else if(useGradient) {
      return {
        color: {
          type: 'linear',
          x: barChart ? 0 : 0.5,
          y: barChart ? 0.5 : 1,
          x2: barChart ? 1 : 0.5,
          y2: barChart ? 0.5 : 0,
          colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
          gradientRange[i % gradientRange.length].map((el, index) => {
            if(index === 0) {
              return ({
                offset: 0,
                color: el
              })
            }
            return ({
              offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
              color: el
            })
          }) : [],
          global: false // 缺省为 false
        }
      }
    } else {
      return ({
        color: XColorRange[index % XColorRange.length]
      })
    }
  }
  if (!data || !data.length) return false;
  const { xData = [], series = [] } = data[0];
  if (!xData?.length) {
    return false;
  }
  const { attributes = {} } = config;
  const {
    showTitle = true,
    title = '',
    textColor = '',
    titleFontSize = 12,
    titleFontWeight = 400,
    titleTop = 'auto',
    titleBottom = 'auto',
    titleRight = 'auto',
    titleLeft = 'auto',
    showTooltip = true,
    showLegend = true,
    legendTextColor = '#000000',
    legenFontSize = 12,
    colorRange = [],
    barWidth = 20,
    showLine,
    barChart = false,
    axisFontSize = 12,
    axisTextColor = '#ffffff',
    xAxisText = '',
    xAxisTextFontSize = 12,
    xAxisTextColor = '#ffffff',
    yAxisText = '',
    yAxisTextFontSize = 12,
    yAxisTextColor = '#ffffff',
    left = '10%',
    right = '10%',
    top = 60,
    bottom = 60,
    legendIcon = 'circle',
    showDataZoom = false,
    dataZoomBottom = 'auto',
    dataZoomStart = 0,
    dataZoomEnd = 100,
    legendOrient = 'vertical',
    legendType = 'plain',
    usePolar = false,
    minInterval = 0,
    labelRotate = 0,
    dataZoomBackGround ='rgba(47,69,84,0)',
    dataZoomFill = 'rgba(167,183,204,0.4)',
    areaStyleColor = '#d2dbee',
    areaStyleOpacity=  0.2,
    areaStyleShadowColor = '',
    selectedDataAreaColor ='#8fb0f7',
    selectedDataAreaOpacity = 0.2,
    selectedDataAreaShadowColor = '',
    dateZoomHeight = 30,
    moveHandleColor = '#D2DBEE'
  } = attributes;
  const xAxis = {
    type: 'category',
    data: xData,
    name: xAxisText,
    nameTextStyle: {
      color: xAxisTextColor,
      fontSize: xAxisTextFontSize,
      align: 'right'
    },
    axisTick: {
      alignWithLabel: true,
      show: false
    },
    splitLine: {
      show: false //去掉网格线
    },
    axisLabel: {
      textStyle: {
        color: axisTextColor,
        fontSize: axisFontSize
      },
      rotate: labelRotate,
      margin: 16 // 文案与坐标轴间距
    },
    axisLine: {
      show: true // 隐藏坐标轴
    }
  };
  const yAxis = [
    {
      type: 'value',
      name: yAxisText,
      nameTextStyle: {
        color: yAxisTextColor,
        fontSize: yAxisTextFontSize,
        align: 'center'
      },
      axisTick: {
        show: false // 隐藏刻度线
      },
      axisLine: {
        show: true // 隐藏坐标轴
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(194, 197, 204, 0.1)'
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        }
      }
    },
    {
      type: 'value',
      axisTick: {
        show: false // 隐藏刻度线
      },
      axisLine: {
        show: false // 隐藏坐标轴
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(194, 197, 204, 0.1)'
        }
      },
      axisLabel: {
        show: false,
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        }
      }
    }
  ];
  if(!isNaN(Number(minInterval))) {
    const number = Number(minInterval)
    yAxis.forEach(el => {
      el.minInterval = number
    })
  }
  let option = {
    title: {
      show: showTitle,
      text: title,
      left: 'left',
      y: 'top',
      textStyle: {
        color: textColor,
        fontFamily: '微软雅黑',
        fontSize: titleFontSize || 12,
        fontWeight: titleFontWeight || 400,
        left: titleLeft,
        top: titleTop,
        bottom: titleBottom,
        right: titleRight
      }
    },
    tooltip: {
      show: showTooltip,
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      },
      appendToBody: true
    },
    legend: {
      show: showLegend,
      itemWidth: 10,
      itemHeight: 10,
      data: getLegendData(data[0], legendTextColor, showLine),
      icon: legendIcon,
      ...getLegendPosition(attributes),
      type: legendType,
      orient: legendOrient,
      textStyle: {
        fontSize: legenFontSize
      }
    },
    color: colorRange || [],
    grid: {
      left,
      right,
      bottom,
      top,
      containLabel: true
    },
    dataZoom: [
      {
        type: 'slider',
        show: showDataZoom,
        backgroundColor: dataZoomBackGround,
        fillerColor: dataZoomFill,
        dataBackground: {
          areaStyle: {
            color: areaStyleColor,
            opacity: areaStyleOpacity,
            shadowColor: areaStyleShadowColor
          }
        },
        selectedDataBackground: {
            areaStyle: {
              color: selectedDataAreaColor,
              opacity:selectedDataAreaOpacity
            },
            shadowColor: selectedDataAreaShadowColor
        },
        start: dataZoomStart || 0,
        end: dataZoomEnd || 100,
        bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
        height: dateZoomHeight,
        moveHandleStyle: {
          color: moveHandleColor
        }
      }
    ],
    series: getSeries(series, barWidth)
  };
  // 使用极坐标
  if(usePolar) {
    option.polar = {
      radius: [30, '80%']
    }
    option.angleAxis = {
      max: Math.max(...option.series.map(el => Math.max(...el.data.map(ele => ele.value)))) * (4 / 3),
      startAngle: 90
    }
    option.radiusAxis = {
      type: 'category',
      data: yAxisText
    }
    option.tooltip.show = false
  } else {
    option.xAxis = barChart ? yAxis : xAxis
    option.yAxis = barChart ? xAxis : yAxis
  }
  return option
}

function getLineOptions(config, data) {
  function getLegendData(names, color) {
    let s = [];
    if (!names) {
      return [];
    }
    for (let i = 0; i < names.length; i++) {
      s.push({
        name: names[i],
        textStyle: {
          color,
          // fontSize: this.scaleSize(12)
        }
      });
    }
    return s;
  }
  if (!data || !data.length) return false;
  const { xData = [], series = [], names = [] } = data[0];
  if (!xData?.length) {
    return false;
  }
  const { attributes = {} } = config;
  const {
    showTitle = true,
    title = '',
    textColor = '',
    titleFontSize = 12,
    titleFontWeight = 400,
    showTooltip = true,
    showLegend = true,
    legendTextColor = '#000000',
    legenFontSize = 12,
    smooth = false,
    xAxisText = '',
    yAxisText = '',
    colorRange = [],
    left = '10%',
    right = '10%',
    top = 60,
    bottom = 60,
    legendIcon = 'circle',
    showDataZoom = false,
    dataZoomBottom = 'auto',
    dataZoomStart = 0,
    dataZoomEnd = 100,
    legendOrient = 'vertical',
    legendType = 'plain',
    titleTop = 'auto',
    titleBottom = 'auto',
    titleRight = 'auto',
    titleLeft = 'auto',
    axisTextColor = '#ffffff',
    axisFontSize = '14px',
    useArea = false,
    userStack = false,
    useGradient = false,
    gradientRange = [],
    minInterval = 0,
    labelRotate = 0,
    dataZoomBackGround ='rgba(47,69,84,0)',
    dataZoomFill = 'rgba(167,183,204,0.4)',
    areaStyleColor = '#d2dbee',
    areaStyleOpacity=  0.2,
    areaStyleShadowColor = '',
    selectedDataAreaColor ='#8fb0f7',
    selectedDataAreaOpacity = 0.2,
    selectedDataAreaShadowColor = '',
    dateZoomHeight = 30,
    moveHandleColor = '#D2DBEE'
  } = attributes;
  let option = {
    title: {
      show: showTitle,
      text: title,
      left: 'left',
      y: 'top',
      textStyle: {
        color: textColor,
        fontFamily: '微软雅黑',
        fontSize: titleFontSize || 12,
        fontWeight: titleFontWeight || 400,
        left: titleLeft,
        top: titleTop,
        bottom: titleBottom,
        right: titleRight
      }
    },
    tooltip: {
      show: showTooltip,
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      },
      appendToBody: true
    },
    legend: {
      show: showLegend,
      itemWidth: 10,
      itemHeight: 10,
      data: getLegendData(names, legendTextColor),
      icon: legendIcon,
      ...getLegendPosition(attributes),
      type: legendType,
      orient: legendOrient,
      textStyle: {
        fontSize: legenFontSize
      }
    },
    color: colorRange || [],
    grid: {
      left,
      right,
      bottom,
      top,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      name: xAxisText,
      data: xData || [],
      axisTick: {
        show: false // 隐藏刻度线
      },
      axisLine: {
        show: true // 隐藏坐标轴
      },
      axisLabel: {
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        },
        rotate: labelRotate,
        margin: 16 // 文案与坐标轴间距
      }
    },
    dataZoom: [
      {
        type: 'slider',
        show: showDataZoom,
        backgroundColor: dataZoomBackGround,
        fillerColor: dataZoomFill,
        dataBackground: {
          areaStyle: {
            color: areaStyleColor,
            opacity: areaStyleOpacity,
            shadowColor: areaStyleShadowColor
          }
        },
        selectedDataBackground: {
            areaStyle: {
              color: selectedDataAreaColor,
              opacity:selectedDataAreaOpacity
            },
            shadowColor: selectedDataAreaShadowColor
        },
        start: dataZoomStart || 0,
        end: dataZoomEnd || 100,
        bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
        height: dateZoomHeight,
        moveHandleStyle: {
          color: moveHandleColor
        }
      }
    ],
    yAxis: {
      type: 'value',
      name: yAxisText,
      axisTick: {
        show: false // 隐藏刻度线
      },
      axisLine: {
        show: true // 隐藏坐标轴
      },
      axisLabel: {
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        },
        margin: 16 // 文案与坐标轴间距
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(194, 197, 204, 0.1)'
        }
      }
    },
    series: series.map((ele, i) => {
      const data = {
        name: ele.name,
        type: 'line',
        symbol: smooth ? 'none' : '', //去掉折线图中的节点
        smooth, //true 为平滑曲线，false为直线
        data: ele.data
      }
      if(userStack || useArea) {
        // 使用堆叠
        if(userStack) {
          data.stack = 'Total'
        }

        const areaStyle = {
          opacity: 0.8
        }
        if(useGradient) {
          areaStyle.color = {
            type: 'linear',
            x: 0.5,
            y: 1,
            x2: 0.5,
            y2: 0,
            colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
            gradientRange[i % gradientRange.length].map((el, index) => {
              if(index === 0) {
                return ({
                  offset: 0,
                  color: el
                })
              }
              return ({
                offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
                color: el
              })
            }) : [],
            global: false // 缺省为 false
          }
        }
        data.areaStyle = areaStyle
      }
      return data
    })
  };
  if(!isNaN(Number(minInterval))) {
    const number = Number(minInterval)
    if(number > 0) {
      option.yAxis.minInterval = number
    }
  }
  return option
}

function getBarLineOptions(config, data) {
  function getLegendData(dataList, color = '#000', showLine = false) {
    let s = [];
    for (let i = 0; i < dataList.length; i++) {
      const { series = [] } = dataList[i];
      for (let j = 0; j < series.length; j++) {
        s.push({
          name: series[j].name,
          textStyle: {
            color,
            // fontSize: this.scaleSize(12)
          }
        });
      }
      if (showLine && series.length > 1) {
        s.push({
          name: '累计',
          textStyle: {
            color,
            fontSize: 12
          }
        });
      }
    }
    return s;
  }
  function getSeries(series = [], barWidth) {
    let s = [];
    if (!series) {
      return [];
    }
    const { attributes = {}, specialAttributes = {} } = config;
    const { XColorRange = [], showBarLabel = true, useGradient = false, gradientRange = [], differentY= false, userStack = false, useArea = false, useOverlap = false } = attributes;
    for (let i = 0, len = series.length; i < len; i++) {
      const { data = [], chartType = 'bar', } = series[i];
      let result = [];
      for (let j = 0, l = data.length; j < l; j++) {
        // const name = data[0]?.name
        const name = data[j].name;
        const dataList = data[j].data;
        result = dataList?.map((value) => {
          const dataResult = {
            value
          }
          if(chartType === 'bar') {
            // change start 当接口为合并动态统计时，会将数据合并，这时候j的值始终为0，故无法正常计算，当data长度为1时，取消j参与运算
            if (data.length === 1) {
              dataResult.itemStyle = {
                color: XColorRange[i % XColorRange.length]
              }
            // change end
            } else {
              dataResult.itemStyle = {
                color: XColorRange[(i + 1) * j % XColorRange.length]
              }
            }
          }
          if(chartType === 'bar' && useGradient) {
            dataResult.itemStyle.color = {
              type: 'linear',
              x: 0.5,
              y: 1,
              x2: 0.5,
              y2: 0,
              colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
              gradientRange[i % gradientRange.length].map((el, index) => {
                if(index === 0) {
                  return ({
                    offset: 0,
                    color: el
                  })
                }
                return ({
                  offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
                  color: el
                })
              }) : [],
              global: false // 缺省为 false
            }
          }
          return dataResult
        })
        let lineStackStyle = null
        if(chartType !== 'bar' && (userStack || useArea)) {
          lineStackStyle = {}
          if(userStack) {
            lineStackStyle.stack = 'Total'
          }
          const areaStyle = {
            opacity: 0.3
          }
          if(useGradient) {
            areaStyle.color = {
              type: 'linear',
              x: 0.5,
              y: 1,
              x2: 0.5,
              y2: 0,
              colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
              gradientRange[i % gradientRange.length].map((el, index) => {
                if(index === 0) {
                  return ({
                    offset: 0,
                    color: el
                  })
                }
                return ({
                  offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
                  color: el
                })
              }) : [],
              global: false // 缺省为 false
            }
          }
          lineStackStyle.areaStyle = areaStyle
        }
        let res = {
          name,
          type: chartType,
          barWidth,
          data: result,
          label: {
            show: showBarLabel,
            position: attributes.barChart ? 'right' : 'top',
            color: useGradient ? attributes.textColor : 'inherit', // 设置跟随为视觉映射得到的颜色，如系列色
            fontSize: 12,
            ...getSeriesLabelConfig(specialAttributes?.seriesLabel?.[i])
          },
          // stack: 'Total', // 堆叠
          stack: userStack ? 'total' : ''
        }
        // 仅使用双边下为第二个指定第二个y轴
        if(i === 1 && differentY) {
          res.yAxisIndex = 1
        }
        if(lineStackStyle) {
          res = Object.assign({}, res, lineStackStyle)
        }
        if (useOverlap) {
          s.push({
            ...res,
            // 重叠柱状图
            z: j,
            barGap: '-100%'
          });
        } else {
          s.push(res);
        }
      } 
    }
    return s;
  }
  function getSeriesLabelConfig(config = null) {
    if (!config) return {};
    const { position = 'top', distance = 5, padding = 0 } = config;
    let paddingResult = 0;
    try {
      if (padding) {
        if (padding.length) {
          paddingResult = padding.split(',').map(ele => {
            return +ele;
          });
        } else {
          paddingResult = isNaN(+padding) ? 0 : +padding
        }
      }
    } catch (err) {
      console.log(err);
      paddingResult = 0;
    }
    return {
      position,
      distance: isNaN(+distance) ? 5 : +distance,
      padding: paddingResult
    }
  }
  if (!data || !data.length) return false;
  const { attributes = {} } = config;
  const {
    showTitle = true,
    title = '',
    textColor = '',
    titleFontSize = 12,
    titleFontWeight = 400,
    titleTop = 'auto',
    titleBottom = 'auto',
    titleRight = 'auto',
    titleLeft = 'auto',
    showTooltip = true,
    showLegend = true,
    legendTextColor = '#000000',
    legenFontSize = 12,
    colorRange = [],
    barWidth = 20,
    showLine,
    barChart = false,
    axisFontSize = 12,
    axisTextColor = '#ffffff',
    xAxisText = '',
    xAxisTextFontSize = 12,
    xAxisTextColor = '#ffffff',
    yAxisText = '',
    yAxisTextFontSize = 12,
    yAxisTextColor = '#ffffff',
    left = '10%',
    right = '10%',
    top = 60,
    bottom = 60,
    legendIcon = 'circle',
    showDataZoom = false,
    dataZoomBottom = 'auto',
    dataZoomStart = 0,
    dataZoomEnd = 100,
    legendOrient = 'vertical',
    legendType = 'plain',
    yUnitLeft = '',
    yUnitRight = '',
    differentY= false,
    minInterval = 0,
    labelRotate = 0,
    dataZoomBackGround ='rgba(47,69,84,0)',
    dataZoomFill = 'rgba(167,183,204,0.4)',
    areaStyleColor = '#d2dbee',
    areaStyleOpacity=  0.2,
    areaStyleShadowColor = '',
    selectedDataAreaColor ='#8fb0f7',
    selectedDataAreaOpacity = 0.2,
    selectedDataAreaShadowColor = '',
    dateZoomHeight = 30,
    moveHandleColor = '#D2DBEE'
  } = attributes;
  const xData = data[0]?.xData || [];
  if (!xData?.length) {
    return false;
  }
  const series = [];
  data.forEach((ele, index) => {
    series.push({
      data: ele.series || [],
      chartType: data[index]?.chartType || 'bar'
    });
  });
  const xAxis = {
    type: 'category',
    data: xData,
    name: xAxisText,
    nameTextStyle: {
      color: xAxisTextColor,
      fontSize: xAxisTextFontSize,
      align: 'right'
    },
    axisTick: {
      alignWithLabel: true,
      show: false
    },
    splitLine: {
      show: false //去掉网格线
    },
    axisLabel: {
      textStyle: {
        color: axisTextColor,
        fontSize: axisFontSize
      },
      rotate: labelRotate,
      margin: 16 // 文案与坐标轴间距
    },
    axisLine: {
      show: true // 隐藏坐标轴
    }
  };
  const yAxis = [
    {
      type: 'value',
      name: yAxisText,
      nameTextStyle: {
        color: yAxisTextColor,
        fontSize: yAxisTextFontSize,
        align: 'center'
      },
      axisTick: {
        show: false // 隐藏刻度线
      },
      axisLine: {
        show: true // 隐藏坐标轴
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(194, 197, 204, 0.1)'
        }
      },
      axisLabel: {
        show: true,
        formatter: `{value} ${yUnitLeft}`,
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        }
      }
    },
    {
      type: 'value',
      name: yAxisText,
      nameTextStyle: {
        color: yAxisTextColor,
        fontSize: yAxisTextFontSize,
        align: 'center'
      },
      // 隐藏刻度线
      axisTick: {
        show: differentY ? true : false
      },
      // 隐藏轴线
      axisLine: {
        show: differentY ? true : false
      },
      splitLine: {
        show: differentY ? true : false,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(194, 197, 204, 0.1)'
        }
      },
      axisLabel: {
        show: differentY ? true : false,
        formatter: `{value} ${yUnitRight}`,
        textStyle: {
          color: axisTextColor,
          fontSize: axisFontSize
        }
      }
    }
  ];
  if(!isNaN(Number(minInterval))) {
    const number = Number(minInterval)
    yAxis.forEach(el => {
      el.minInterval = number
    })
  }
  let option = {
    title: {
      show: showTitle,
      text: title,
      left: 'left',
      y: 'top',
      textStyle: {
        color: textColor,
        fontFamily: '微软雅黑',
        fontSize: titleFontSize || 12,
        fontWeight: titleFontWeight || 400,
        left: titleLeft,
        top: titleTop,
        bottom: titleBottom,
        right: titleRight
      }
    },
    tooltip: {
      show: showTooltip,
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      },
      appendToBody: true
    },
    legend: {
      show: showLegend,
      itemWidth: 10,
      itemHeight: 10,
      data: getLegendData(data, legendTextColor, showLine),
      icon: legendIcon,
      ...getLegendPosition(attributes),
      type: legendType,
      orient: legendOrient,
      color: colorRange,
      textStyle: {
        fontSize: legenFontSize
      }
    },
    color: colorRange || [],
    grid: {
      left,
      right,
      bottom,
      top,
      containLabel: true
    },
    dataZoom: [
      {
        type: 'slider',
        show: showDataZoom,
        backgroundColor: dataZoomBackGround,
        fillerColor: dataZoomFill,
        dataBackground: {
          areaStyle: {
            color: areaStyleColor,
            opacity: areaStyleOpacity,
            shadowColor: areaStyleShadowColor
          }
        },
        selectedDataBackground: {
            areaStyle: {
              color: selectedDataAreaColor,
              opacity:selectedDataAreaOpacity
            },
            shadowColor: selectedDataAreaShadowColor
        },
        start: dataZoomStart || 0,
        end: dataZoomEnd || 100,
        bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
        height: dateZoomHeight,
        moveHandleStyle: {
          color: moveHandleColor
        }
      }
    ],
    xAxis: barChart ? yAxis : xAxis,
    yAxis: barChart ? xAxis : yAxis,
    series: getSeries(series, barWidth)
  };
  return option
}

function getPieOptions(config, data) {
  if (!data || !data.length) return false;
  const { attributes = {} } = config;
  const {
    showTitle = true,
    title = '',
    textColor = '',
    titleFontSize = 12,
    titleFontWeight = 400,
    showTooltip = true,
    showLegend = true,
    legendTextColor = '#000000',
    legenFontSize = 12,
    colorRange = [],
    showLabel = true,
    labelSize = 12,
    legendIcon = 'circle',
    changeToRing = false,
    isRose = false,
    legendOrient = 'vertical',
    legendType = 'plain',
    legendLimit = 100,
    titleTop = 'auto',
    titleBottom = 'auto',
    titleRight = 'auto',
    titleLeft = 'auto',
    left = '10%',
    right = '10%',
    top = 60,
    bottom = 60,
    pieLeft = '50%',
    pieTop = '50%',
    startRadius = 0,
    endRadius = 0,
    borderWidth = 0,
    borderRadius = 0, // 间距圆角
    borderColor = '#FFF', // 间距颜色
    centerFontColor = '#2EBF76',
    centerFontSize = 24,
    centerFontTop = 50,
    centerFontLeft = 50,
    descType = 'none' // 描述类型
  } = attributes;
  const dataItem = data[0];
  const seriesData = [];
  for (let key in dataItem) {
    seriesData.push({
      name: key,
      value: dataItem[key]
    });
  }
  if (!seriesData?.length) {
    this.canShow = false;
    return false;
  }
  let option = {
    title: {
      show: showTitle,
      text: title,
      left: 'left',
      y: 'top',
      textStyle: {
        color: textColor,
        fontFamily: '微软雅黑',
        fontSize: titleFontSize || 12,
        fontWeight: titleFontWeight || 400,
        left: titleLeft,
        top: titleTop,
        bottom: titleBottom,
        right: titleRight
      }
    },
    tooltip: {
      show: showTooltip,
      trigger: 'item',
      appendToBody: true,
      confine: true // 避免浮窗显示超出容器框
    },
    legend: {
      show: showLegend,
      // right: 'left',
      // top: legendTop || '20%', // 改为通过计算上下居中
      // right: '5%',
      ...getLegendPosition(attributes),
      type: legendType,
      orient: legendOrient,
      itemWidth: 10,
      itemHeight: 10,
      icon: legendIcon,
      textStyle: {
        color: legendTextColor,
        fontSize: legenFontSize
      },
      formatter: (name) => {
        const data = seriesData;
        let tarValue;
        for (var i = 0; i < data.length; i++) {
          if (data[i].name === name) {
            tarValue = data[i].value;
            break;
          }
        }
        // TIPS 计算需要截取的字体
        let formatTest = name
        if(this.$refs && this.$refs.chartInstance) {
          const limit = Math.floor(this.$refs.chartInstance?.$el.offsetWidth * legendLimit / 100 / 12)
          formatTest = name.length > limit - 4 ? name.substring(0, limit) + '...' : name
        }
        return `${formatTest} ${tarValue ?? 0}`;
      }
    },
    color: colorRange || [],
    grid: {
      left,
      right,
      bottom,
      top,
      containLabel: true
    },
    series: [
      {
        name: title || '',
        radius: changeToRing || isRose ? 
        startRadius && endRadius ? [`${startRadius}%`, `${endRadius}%`] : ['30%', '70%'] : '50%',
        // 玫瑰图
        roseType: isRose ? 'radius' : false,
        center: [pieLeft, pieTop],
        // 结束
        type: 'pie',
        data: seriesData || [],
        label: {
          show: showLabel,
          // formatter: '{b} {c}',
          textStyle: {
            fontSize: labelSize //文字的字体大小
          }
        },
        itemStyle:{
          borderWidth:borderWidth,
          borderRadius:borderRadius,
          borderColor:borderColor,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  if(descType !== 'none') {
    let textValue = seriesData.reduce((pre, currentv) => {
      if(isNaN(currentv.value)) {
        return pre + 0;
      } else {
        return pre + Number(currentv.value);
      }
    }, 0)
    if(descType === 'total') {
      textValue = '总数：' + textValue;
    }
    option.graphic = [
      {
        type: 'group',
        rotation: 0,
        bounding: 'raw',
        top: `${centerFontTop}%`,
        left: `${centerFontLeft}%`,
        z: 100,
        children: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            z: 100,
            style: {
              fill: `${centerFontColor}`,
              text: textValue,
              font: `bold ${centerFontSize}px sans-serif`
            }
          }
        ]
      }
    ]
  }
  return option
}

function getLegendPosition(attributes) {
  return {
    left: attributes.legendLeft ?? 'auto',
    right: attributes.legendRight ?? 'auto',
    top: attributes.legendTop ?? 'auto',
    bottom: attributes.legendBottom ?? 'auto'
  };
}

export default requestData