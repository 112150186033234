<!--
  * @Author: your name
  * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-08-11 10:36:01
 * @LastEditors: Shiltin 18580045074@163.com
  * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
  * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
 -->
 <template>
  <div class="lat-choose-cont">
    <div class="header absolute">
      <div class="relative">
        <el-page-header class="page-header" :content="title" @back="back" />
        <el-radio-group class="absolute" style="top: 8px; right: 80px" v-model="chooseType" size="mini">
          <el-radio-button label="point" @click.native="switchPoint = true">选点</el-radio-button>
          <el-radio-button label="cover" @click.native="switchPoint = false">画区域</el-radio-button>
        </el-radio-group>
				<el-button
          class="absolute"
          size="mini"
          style="top: 8px; right: 218px"
          type="primary"
          @click="clearChooseData()"
        >
          清除已选
        </el-button>
        <el-button
          class="absolute"
          size="mini"
          style="top: 8px; right: 10px"
          type="primary"
          @click="commit()"
        >
          确定
        </el-button>
      </div>
    </div>
		<div class="w-full h-full" style="flex-direction: columns;display:flex;">
			<List 
				v-if="componentType === 'gis'" 
				class="list-cont scroller"
				ref="listRef"
				:model-data="modelData"
				@show-model="showModel"
				@clear-obk="clearObk" 
			/>
			<div  id="map2dCont" class="relative flex1">
				<div  class="absolute choose-detail">
						<p v-if="latLng && latLng !== null">经度：{{ latLng.lng }} 纬度：{{ latLng.lat }}</p>
						<p v-if="info.area">已选区域：{{ info.area }}</p>
						<p v-if="info.fullName">详细地址：{{ info.fullName }}</p>
				</div>
			</div>
		</div>	
		<!-- 搜索 -->
		<div v-show="componentType=== 'latlng'" class="bind-search-cont">
			<el-select
				popper-class = "bind-select-list"
				v-model="value"
				filterable
				remote
				style="width: 100%;"
				reserve-keyword
				placeholder="请输入关键词"
				loading-text="加载中..."
				:remote-method="remoteMethod"
				@change="checkList"
				:loading="loading">
				<el-option
					v-for="(item, index) in options"
					:key="item.id"
					:label="item.address"
					:value="item.id">
					{{index+1}}. {{ item.address }}
				</el-option>
			</el-select>
		</div>
    
  </div>
</template>

<script>
import axios from 'axios'
import List from "@/custom-component/bindLatLng/modelList.vue"
import { PageHeader, RadioGroup, RadioButton  } from "element-ui";
let viewerGis;
export default {
  name: "Bind",
  components: {
    "el-page-header": PageHeader,
    "el-radio-group": RadioGroup,
    "el-radio-button": RadioButton,
		List
  },
  props: {
    modelData: {
			type: [Object,Array],
			default: null
    },
    componentType: {
      type: String,
      default: "",
    },
    appId: {
			type: String,
			default: ''
		},
		appSecret: {
			type: String,
			default: ''
		}
  },
  data() {
    return {
      latLng: null,
      info: {
        area: "",
        fullName: "",
      },
      switchPoint: false,
			loading: false,
      // 模型绑定数据
      modelsInfo:  [{
				"model_id": "",
				"select": [],
				"polygon":[],
				"location_gis": null,			
				"location_name":'',
			}],
      chooseType: '',
			options:[],
			value:''
    };
  },
  computed: {
    title() {
      if (this.componentType === "latlng") {
        return "绑定经纬度";
      } else if (this.componentType === "gis") {
        return "绑定GIS";
      } else {
        return "";
      }
    },
  },
  watch: {
    componentType: {
      handler: function () {
        switch (this.componentType) {
          case "gis":
            viewerGis.Scene.setImageryLayer("TianDiTu");
            this.drawPoint();
            break;
          case "latlng":
            viewerGis.Scene.setImageryLayer("AMapStreets");
            this.drawPolygon();
            break;
          default:
        }
      },
    },
    // 监听switchPoint的变化，若为true则切换为经纬度，若为false则切换为画区域
    chooseType: {
      handler: function (newVal) {
				this.modelsInfo =  [{
					"model_id": this.modelsInfo[0].model_id || '',
					"select": this.modelsInfo[0].select || [],
					"polygon": this.modelsInfo[0].polygon || [],
					"location_gis": this.modelsInfo[0].location_gis ||  null,			
					"location_name": this.modelsInfo[0].location_name || ''
				}]
				if(newVal === 'point'){
					this.drawPoint() 
				} else if(newVal === 'cover'){
					this.drawPolygon()
				}
      },
    },
  },
  methods: {
  /**
   * @description: 切换搜索地址
   * @return {*}
   */		
		checkList(id){
			if(viewerGis!==null){
				const findData = this.options.filter(v=>v.id === id)
				if(findData.length){
					viewerGis.Poi.locationPOI(id)
					this.latLng = findData[0].position
					this.info = {
						area: `${findData[0].pname}/${findData[0].cityname}/${findData[0].adname}`,
						fullName: `${findData[0].pname}${findData[0].cityname}${findData[0].adname}${findData[0].address}`,
					}
					this.modelsInfo =  [{
						location_gis: findData[0].position,
            location_name: this.info.area +'|'+ this.info.fullName
					}]
				}
			}
		},
  /**
   * @description: 远程搜索，调用gis接口
   * @param {*} query
   * @return {*}
   */		
		async remoteMethod(query) {
			this.loading = true
			if (query !== '') {
				if(viewerGis!==null){
					this.options = await viewerGis.Poi.createPOI(query)
					this.loading = false
				}
			} else {
				this.latLng = null
				this.options = [];
				this.info = {
					area: "",
					fullName: "",
				}
			}
		},
    /**
		 * @description: 加载模型
		 * @param {*} id
		 * @return {*}
		 */	
		showModel(id,bol){
			if(viewerGis!==null){
				if(bol){
					viewerGis.Model.createModel(id, true)
				} else {
					viewerGis.Model.removeModel(id)
				}
			}
		},
		clearObk(){
			this.modelsInfo =  [{
				"model_id": "",
				"select": [],
				"polygon":[],
				"location_gis": null,			
				"location_name":''
			}]
			if(viewerGis!==null){
				const loadedModesId = viewerGis.Model.getModelKeys()
				if(loadedModesId?.length){
					loadedModesId.forEach((item)=>{
						viewerGis.Model.removeModel(item)
					})
				}
			}
		},
  /**
   * @description: 清除绘制的面
   * @return {*}
   */		
		clearChooseData(){
			this.modelsInfo =  [{
				"model_id": "",
				"select": [],
				"polygon":[],
				"location_gis": null,			
				"location_name":''
			}]
			this.latLng = null
      this.info = {
        area: "",
        fullName: "",
      }
			if(viewerGis){
				viewerGis.Plot.removeAllPlot()
				viewerGis.Poi.removePOI();
				viewerGis.MouseCustomEvent.removeDrawing(true);  
				this.chooseType = ''
			}
		},
    /**
     * @description: 返回
     * @return {*}
     */		
    back() {
      this.$emit("back");
    },
    /**
     * @description: 提交数据
     * @return {*}
     */		
    commit() {
			//删除空对象以及空数字空字符串
			if(this.modelsInfo.length){
				for(let i = 0; i < this.modelsInfo.length;i++){
					for(let k in this.modelsInfo[i]){
						if(Array.isArray(this.modelsInfo[i][k]) && !this.modelsInfo[i][k].length){
							delete this.modelsInfo[i][k]
						} else if(!this.modelsInfo[i][k]){
							delete this.modelsInfo[i][k]
						}
					}
				}
			}
			this.$emit("set-data", this.modelsInfo);
    },
    /**
     * @description: 经纬度转换省市区
     * @param {*} val
     * @return {*}
     */		
    getInfo(val) {
      const url = `https://api.tianditu.gov.cn/geocoder?postStr={'lon':${val.lng},'lat':${val.lat},'ver':1}&type=geocode&tk=c5aaa7bebd44c40e6e03f73df75582d2`;
      axios.get(url).then(res => {
        if ( res.data?.result?.addressComponent ) {        
					this.info.area = ''
					this.info.fullName = ''
					this.modelsInfo[0].location_name = ''
          const data = res.data.result.addressComponent;
					let arr = ['nation','province','city','county','address','poi']
					let n = 4
					if(data.nation && data.nation === '中国' ){
						arr = arr.splice(1, arr.length - 1)
						n = 3
					}
					arr.forEach((item,i)=>{
						if(data[item]){
							if(i === 0){
								if(i < n){
									this.info.area += data[item] + '/'
								}
								this.info.fullName += data[item]
							} else if (data[arr[i - 1]] !== data[item]) {
								if(i < n){
									this.info.area += data[item] + '/'
								}
								this.info.fullName += data[item]
							}
						}
					})
					if(this.info.area && this.info.area.includes('/')){
						this.info.area = this.info.area.substring(0,this.info.area.length - 1)
					}
					this.modelsInfo[0].location_name = this.info.area && this.info.fullName? this.info.area +'|'+ this.info.fullName : ''
        }
      });
    },
    // 监听绘制面结束，返回面的经纬度数组
    drawPolygon() {
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      viewerGis.MouseCustomEvent.drawPolygon();
    },
    // 监听绘制点结束，返回点的经纬度
    drawPoint() {
			if(viewerGis){
				viewerGis.Plot.removeAllPlot()
			}
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      viewerGis.MouseCustomEvent.drawPoint();
    },
  },
  async mounted() {
    viewerGis = new global.BIMCC_GIS_Base.Viewer("map2dCont", {
      host: "models.bimcc.net",
      port: "",
      isHttps: true,
      appId: this.appId || process.env.VUE_APP_MODEL_APPID,
      appSecret: this.appSecret || process.env.VUE_APP_MODEL_APPSECRET,
    });
    await viewerGis.init();
		window.viewerGis = viewerGis;
		//切换成2d地图
		if (this.componentType === "latlng") {
      viewerGis.Scene.setImageryLayer("AMapStreets");
			viewerGis.MouseController.mouseStyleByColumbusView();
		}
    
		//监听模型加载完毕
		viewerGis.on(viewerGis.EventType.modelLoaded, (modelId) => {
			if(modelId){
				if(Object.prototype.toString.call(this.modelData) === '[object Object]' && this.modelData?.models.length){
					//回显构件选中，兼容老格式
					this.modelData?.models.forEach(val=>{
						if(val.model_id === modelId){
							if(val.dbids?.length){
								viewerGis.Model.selectFeature(val.model_id,val.dbids)
							} else if(val.select?.length){
								viewerGis.Model.selectFeature(val.model_id,val.select)
							}
						}
					})
				} else 	if(Object.prototype.toString.call(this.modelData) === '[object Array]'){
					//回显构件选中,新格式
					this.modelData.forEach(val=>{
						if(val.model_id === modelId){
							if(val.select?.length){
								viewerGis.Model.selectFeature(val.model_id,val.select)
							} else if(val.select?.length){
								viewerGis.Model.selectFeature(val.model_id,val.select)
							}
						}
					})
				}
			}
    });
		//监听绘面返回数据
		viewerGis.on(viewerGis.EventType.drawPolygonByMouseCustom, (res) => {
			const arr = res.map((item) => ({
				lng: item[0],
				lat: item[1],
				height: 0,
			}));
			viewerGis.Plot.createPlot(Date.now(), {
				plotType: "polygon",
				positions: arr,
				options: {
					plotType: "polygon",
					perPositionHeight: true,
					height: 5,
					fill: true,
					fillType: "color",
					fillColor: "#eabebf",
					alpha: 50,
					outline: true,
					outlineColor: "#dc2e41",
					outlineWidth: 1,
					isClampToGround: false,
					stRotation: 0,
				},
			});
			
			this.modelsInfo[0].polygon = [{position: arr}]
		});
		//监听绘制点的经纬度数据
		viewerGis.on(viewerGis.EventType.drawPointByMouseCustom, async (res) => {
			if (res) {
				this.modelsInfo[0].location_gis = res
				this.getInfo(res);
				this.latLng = { lat: res.lat, lng: res.lng };
				this.value = ''
				this.options = []
			}
		});
		if((Array.isArray(this.modelData) && !this.modelData?.length) || this.modelData === null){
			//默认绑定经纬度
			this.drawPoint();
			this.chooseType = 'point'
		} else {
			// 回显框选区域
			if (this.modelData?.coverData && Array.isArray(this.modelData.coverData) && this.modelData.coverData.length) {
				this.modelsInfo[0].polygon = [{
					position:this.modelData.coverData
				}]
				this.modelData.coverData.forEach((item,index)=>{
					//定位到第一个点
					if(index === 0){
						viewerGis.Scene.flyTo(item[0],4000,90,0)
					}
					viewerGis.Plot.createPlot(index, {
						plotType: "polygon",
						positions: item,
						options: {
							plotType: "polygon",
							perPositionHeight: true,
							height: 5,
							fill: true,
							fillType: "color",
							fillColor: "#eabebf",
							alpha: 50,
							outline: true,
							outlineColor: "#dc2e41",
							outlineWidth: 1,
							isClampToGround: false,
							stRotation: 0,
						},
					});
				})
			} 
			//  标点回显，对象老格式
			if (this.modelData !== null && ((this.modelData.lat && this.modelData.lng) || this.modelData.position)) {
				let position = this.modelData
				if(this.modelData.position){
					position = this.modelData.position
				}
				this.modelsInfo[0].location_gis = position
				this.getInfo(position);
				this.latLng = { lat: position.lat, lng: position.lng };
				viewerGis.Marker.createMarker('point', {
					type: "Billboard",
					position: {
						lng: position.lng,
						lat: position.lat,
					},
					options: {
						image: viewerGis.Image.getIconImage(),
						width: 36,
						height: 36,
						color: '#3300ff'
					},
				});
				//定位到这个点
				viewerGis.Scene.flyTo(position,4000,90,0)
			}
			// 回显，数组新格式
			if (this.modelData !== null && Array.isArray(this.modelData) && this.modelData.length) {
				for(let k in this.modelData[0]){
					this.modelsInfo[0][k] = this.modelData[0][k]
				}
				for(let i = 0; i < this.modelData.length; i++) {
					if(this.modelData[i].location_gis){
						this.latLng = { lat: this.modelData[i].location_gis.lat, lng: this.modelData[i].location_gis.lng };
						viewerGis.Marker.createMarker('point', {
							type: "Billboard",
							position:this.modelData[i].location_gis || null,
							options: {
								image: viewerGis.Image.getIconImage(),
								width: 36,
								height: 36,
								color: '#3300ff'
							},
						});
						viewerGis.Scene.flyTo(this.modelData[i].location_gis,4000,90,0)
					}
					if(this.modelData[i].location_name){
						const addressType =  this.modelData[i].location_name.split('|')
						if(addressType.length === 2 ){
							this.info.area = addressType[0];
              this.info.fullName = addressType[1];
						}
					}
					if(this.modelData[i].polygon?.length){
						for(let j=0; j<this.modelData[i].polygon.length;j++){
							viewerGis.Plot.createPlot(i.toString()+j.toString(), {
								plotType: "polygon",
								positions: this.modelData[i].polygon[j].position,
								options: {
									plotType: "polygon",
									perPositionHeight: true,
									height: 5,
									fill: true,
									fillType: "color",
									fillColor: "#eabebf",
									alpha: 50,
									outline: true,
									outlineColor: "#dc2e41",
									outlineWidth: 1,
									isClampToGround: false,
									stRotation: 0,
								}
							})
						}
						viewerGis.Scene.flyTo(this.modelData[i].polygon[0].position[0],4000,90,0)
					}
				}
			}
		}
		//监听构件选中
		viewerGis.on(viewerGis.EventType.featureSelect, (res) => {
			if(Array.isArray(res) && res.length){
				let models = []
				for (let i=0; i<res.length; i++) {
					if(i === 0){
						models.push({ model_id: res[i].modelId, dbids:[res[i].dbid], select:[res[i].dbid]});
					} else {
						const index = models.findIndex(v=>v.model_id === res[i].modelId)
						if(index > -1){
							if(!models[index].dbids.includes(res[i].dbid)){
								models[index].dbids.push(res[i].dbid)
								models[index].select.push(res[i].dbid)
							}
						} else {
							models.push({ model_id: res[i].modelId, dbids:[res[i].dbid], select:[res[i].dbid]});
							
						}
					}
				}
				if(models.length){
					this.modelsInfo[0].model_id = models[0].model_id
					this.modelsInfo[0].dbids = models[0].dbids
					this.modelsInfo[0].select = models[0].select
				}
			}
		})
  },
	beforeDestroy() {
		viewerGis.MouseCustomEvent.removeDrawing();
		viewerGis.off(viewerGis.EventType.drawPolygonByMouseCustom)
		viewerGis.off(viewerGis.EventType.drawPointByMouseCustom)
	}
}
</script>
<style lang="less">
.lat-choose-cont {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .header {
    height: 45px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    .page-header {
      line-height: 45px;
      padding-left: 15px;
      border-bottom: 1px solid #dcdfe6;
    }
  }
  .el-page-header__content {
    font-size: 14px !important;
  }
  .el-page-header__left {
    color: #409eff;
    animation: fade 1500ms infinite;
    -webkit-animation: fade 1500ms infinite;
  }
  @keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  .flex1 {
    flex: 1;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .zIndexUp {
    z-index: 100;
    position: absolute;
  }
  .zIndexDown {
    z-index: 0;
    position: absolute;
  }
  .choose-detail {
    left: 0px;
    top: 45px;
    background: #f5f5f5;
    z-index: 99;
    opacity: 0.8;
		p{
			padding: 5px 2px;
		}
  }
	.list-cont{
		width: 300px;
		padding-top:50px;
	}
	
}
</style>
<style>
	.bind-search-cont{
		width: 286px;
    position: absolute;
    top: 60px;
    right: 10px;
	}
	.bind-select-list{
		width: 286px!important;
		right: 10%!important;
	}
	.bind-select-list .el-select-dropdown__item{
		word-wrap: break-word!important;
    word-break: normal!important;
		height: auto;
		white-space: inherit!important;
		overflow:inherit!important;
		text-overflow: inherit!important;
		position: relative;
	}
	.bind-select-list .el-select-dropdown__item:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 20px;
		height: 1px;
		width: 244px;
		background-color: #d9d9d9;
	}
	.cesium-viewer-message{
		z-index:100
	}
</style>
