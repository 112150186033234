import formValidate from "@/custom-component/form/quesParser/scripts/quesParserFormat"
import {formRequest} from "@/apis/home";
import {formatValue} from "@/custom-component/form/newParser/scripts/tools";

export default {
	format(){
		return this.activeId + ' / ' + this.indicator.length
	},
	turnToQues(index,blockInd){
		this.activeId = index
		const domId = document.querySelectorAll('.ques-wrapper-item');
		domId[blockInd].scrollIntoView(true)
	},
	initQuesFormatData(jsonData){
		const data = formValidate(jsonData)

		const recoverData = this.paperLog?.value || {}
		if(Object.keys(recoverData).length){
			const existRecover = Object.keys(recoverData);
			for (let item of data) {
				const tagIcon = item.config.__config__.tagIcon;
				let multiple = item.config.multiple || ''
				if(item.type === 'treeSelect') multiple =  item.config.props.props.multiple
				if(existRecover.includes(item.field)){
					const valueWait = recoverData[item.field];
					item.value = formatValue(tagIcon,valueWait,multiple);
				}
			}
		}

		this.formValidate.items = this.formValidate.items.concat(data);
		const indicator = []; // 去除文本控件与前置后置表单控件 试题指示器
		let number = 1;
		this.formValidate.items.forEach((item,index)=>{
			const position = item.config.__config__?.position || 1
			if(item.type === 'outerForm' && position !== 2){
				if(position === 1){
					this.position = position;
					this.outerFormElement = item // 考前 填写表单
				}else{
					this.afterPosition = position;
					this.outerAfterElement = item //考后 填写表单
				}
				this.formValidate.items.splice(index,1)
			}
		})
		this.formValidate.items.forEach((item,index)=>{
			if(item.type !== 'text'){
				item.indicator = number // 重新生成序号
				indicator.push({indicator:number, blockInd: index})
				number++;
			}
		})
		this.indicator = indicator;
		this.skeleton = false;
	},
	getSubmitData(){
		// 采集表单输入数据
		const formSubmitData = {};
		const scoreSubmitData = {};
		for (const ele of this.formValidate.items) {
			if (ele.type === "form") {
				const fieldData = [];
				const scoreData = [];
				const equalField = ele.config.__config__.addShow;
				if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
					for (let eleData of ele.value) {
						let itemField = {};
						let itemScore = {};
						for (let eleItem of eleData) {
							itemField[eleItem.field] = eleItem.value;
							itemScore[eleItem.field] = eleItem.score || 0;
						}
						fieldData.push(itemField);
						scoreData.push(itemField);
					}
					formSubmitData[ele.field] = fieldData;
					scoreSubmitData[ele.field] = scoreData;
				}
			} else if (ele.type === "row") {
				if (ele.value) {
					for (let eleItem of ele.value) {
						formSubmitData[eleItem.field] = eleItem.value;
						scoreSubmitData[eleItem.field] = eleItem.score || 0;
					}
				}
			} else {
				if (ele.field) {
					formSubmitData[ele.field] = ele.value;
					scoreSubmitData[ele.field] = ele.score || 0;
				}
			}
		}
		if(this.outerFormElement){
			// 考试前外部表单数据
			formSubmitData[this.outerFormElement.field] = this.outerFormElement.value;
			scoreSubmitData[this.outerFormElement.field] = this.outerFormElement.score || 0;
		}

		if(this.outerAfterElement){
			// 考试后外部表单数据
			formSubmitData[this.outerAfterElement.field] = this.outerAfterElement.value;
			scoreSubmitData[this.outerAfterElement.field] = this.outerAfterElement.score || 0;
		}

		return {value:formSubmitData,score:scoreSubmitData}
	},
	// 完成考试并提交
	submitPaperResult(){
		const submitData = this.getSubmitData()
		console.log(submitData,'答题记录')
		const score = Object.values(submitData.score)
		const total = eval(score.join("+"))
		this.$refs.QuesParser.validate(async valid=>{
			// 下级的表单Ref
			const subFormRef = []
			const handleSubValidate = (data) => {
				data.map(el => {
					if(el.$refs && el.$refs.rowFormRef) {
						subFormRef.push(el.$refs.rowFormRef)
					}
					if (Array.isArray(el.$children) && el.$children.length) {
						handleSubValidate(el.$children)
					}
				})
			}
			// 递归获取所有下级需要验证的表单
			handleSubValidate(this.$refs.QuesParser.$children)
			let subflag = true
			try{
				// Promise try catch
				const res = await Promise.all(subFormRef.map(el => el.validate()))
				subflag = res.every(el => el)
			} catch(err) {
				subflag = false
			}
			// 如果所有的验证都通过
			if(valid && subflag) {
				this.$confirm('您的得分为{'+ total +'} 分, 您确定要交卷?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.outerAfterElement){
						this.openOuterForm(this.outerAfterElement)
					} else {
						this.submitData()
					}
				})
			} else {
				this.$message.error('您还有必答试题未完成答题');
			}
		})
	},
	// 完成考试交卷，提交考试记录
	submitData(){
		const submitData = this.getSubmitData()
		console.log(submitData,'答题记录')
		const score = Object.values(submitData.score)
		const total = eval(score.join("+"))
		const paperLog ={
			paper_id: this.$route.query.paperId,
			score: total,
			answer: submitData,
		};
		const data = {
			__method_name__: 'createData',
			object_uuid: 'object624800db1cbc3',
			transcode: 0,
			...paperLog
		}
    formRequest('POST', '/api/mapi', data).then(res => {
      if (res.data.code === 200) {
        this.$message.success(res.data.msg)
      }
    })
		/*formRequest('POST', '/api/form4/ques/submitAnswer', paperLog).then(res => {
			if (res.data.code === 200) {
				this.$message.success(res.data.msg)
			}
		})*/
	},
	// 打开外部表单
	async openOuterForm(element) {
		// const element = this.outerFormElement

		const formDesignId = element.config.__config__.formDesignId
		const url = '/api/metadata/transformuuid/'+ formDesignId
		const response = await formRequest('get', url, {});
		const resData = response.data.data;
		element.config.__config__.designData = resData

		this.outerFormData = resData
		this.outerNeedFlow = !!element.config.__config__.designData?.need_flow
		this.outerFormMode = element.value ? 'detail': 'add'
		this.outerFormTitle = element.config.__config__.label
		this.outerFormShowType = element.config.__config__.showType
		this.outerFormType = element.config.__config__.formType
		this.outerFormUuid = element.config.__config__.formDesignId
		this.outerObjectUuid = element.config.__config__.objectUuid

		if(parseInt(element.value)){
			//数据详情回显
			const url='api/mapi',method='POST';
			let ruuids = [];
			for (let i = 0; i < this.outerFormData.fields.length; i++) {
				let item = this.outerFormData.fields[i];
				if (item.__config__.tagIcon == 'form') {
					ruuids.push({ relationship_uuid: item.__vModel__ });
				}
			}
			const param = {
				object_uuid: this.outerObjectUuid,
				view_uuid: '',
				data_id: element.value,
				ruuids,
				__method_name__: 'dataInfo'
			}
			formRequest(method,url,param).then(res => {
				let data = res.data.data;
				this.outerFormDisplay = data;
				this.outerFormVisible = true
			});
		} else {
			this.outerFormVisible = true
		}
	},
	confirmSubmit(){
		this.$refs.outerFormParser.handleSubmit()
	},
	async outerFormSubmit(formSubmitData){
		if(!formSubmitData){
			formSubmitData = this.$refs.outerFormParser.getFormData()
		}
		if(this.outerObjectUuid){
			const url = '/api/mapi'
			const data = {
				__method_name__: 'createData',
				object_uuid: this.outerObjectUuid,
			}
			Object.assign(data,formSubmitData);
			let response = await formRequest('post', url, data);
			if(response.data.code === 200){
				const id = response.data.data.id

				if(this.outerFormElement){
					this.outerFormElement.value = id
					this.outerFormVisible = false;
					this.$message.success(response.data.msg)
				}
				if(this.outerAfterElement){
					this.outerAfterElement.value = id
					this.outerFormVisible = false;
					this.$message.success(response.data.msg)
					this.submitData()
				}
			} else {
				this.$message.error(response.data.msg)
			}
		}
	},
}
