/*
 * @Description: 配置数据
 * @Author: luocheng
 * @Date: 2021-09-13 15:42:36
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-16 11:23:54
 */
import Vue from 'vue';
import store from './index';
import { appChildById, editorComponent } from '@/utils/editor';
import { deepClone } from '@topology/core';

export default {
	state: {
		// 页面类型
		editorType: 'page',
		// 组件数据
		componentData: [],
		// 画布样式
		editorConfig: {
			title: '测试配置',
			height: 840,
			width: 700, // 1200
			scale: 100,
			backgroundColor: '#fff',
			// 附属页面数据配置
			subsidiaryComponentData: {},
			// 全局定时刷新
			globalInterval: null,
			signComponentData: [],
		},
	},
	mutations: {
		/**
		 * @description: 设置标记的数据
		 */
		setSignComponentData (state,data) {
			state.signComponentData = data
		},
		/**
		 * @description: 设置全局定时器
		 */
		setGlobalInterval(state, globalInterval) {
			state.globalInterval = globalInterval;
		},
		/**
		 * @desc: 保存配置数据
		 */
		setConfigData(state, { componentData, editorConfig, editorType }) {
			Vue.set(state, 'componentData', deepClone(componentData));
			Vue.set(state, 'editorConfig', deepClone(editorConfig));
			Vue.set(state, 'editorType', editorType);
			state.componentData = componentData;
			state.editorConfig = editorConfig;
			state.editorType = editorType;
		},
		/**
		 * @desc: 清楚配置数据 
		 */
		clearConfigData(state) {
			state.componentData = null;
			state.editorConfig = {};
			state.editorType = '';
		},
		/**
		 * @desc: 编辑图层信息，对图层增改
		 * @param {Object} component 组件对象
		 * @param {String} containerId 放置的容器ID  根容器为 null
		 * @param {Number} index 下标(应为嵌套存在因此弃用)
		 */
		modifyComponent(state, { component, containerId, isModify = false, pageUUID = null }) {
			if (!component) return;
			if (!pageUUID) {
				// 页面配置状态下直接编辑主要页面数据
				if (isModify) {
					// 编辑 根据ID查找整体替换数据(存在优化点！！！)
					store.commit('setComponentData', editorComponent(state.componentData, component));
				} else {
					// 新增
					if (containerId) {
						store.commit('setComponentData', appChildById(state.componentData, containerId, component));
					} else {
						state.componentData.push(component);
					}
				}
				if (state.curComponent && state.curComponent.id === component.id) {
					store.commit('setCurComponent', {
						component: component
					});
				}
				// 测试
				// console.log(state.componentData)
				// sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
			} else {
				// 附加页面数据配置只会在渲染时候生效(因此无需管curComponent等)
				// 页面配置状态下直接编辑主要页面数据
				try {
					if (state.subsidiaryComponentData?.[pageUUID]) {
						state.subsidiaryComponentData = {
							...state.subsidiaryComponentData,
							[pageUUID]: {
								...state.subsidiaryComponentData[pageUUID],
								componentData: editorComponent(state.subsidiaryComponentData[pageUUID].componentData, component)
							}
						}
					}
				} catch (err) {
					console.log(err, '--页面配置状态下直接编辑主要页面数据--');
				}
			}
		},
		/**
		 * @desc: 直接设置图层数据 (历史记录，直接获取编辑等)
		 */
		setComponentData(state, componentData = []) {
			Vue.set(state, 'componentData', componentData);
		},
		/**
		 * @desc: 设置附属页面配置信息(表单内嵌页)
		 */
		setSubsidiaryComponentData(state, { pageUUID, pageConfig }) {
			state.subsidiaryComponentData = {
				...state.subsidiaryComponentData,
				[pageUUID]: pageConfig
			};
		}
	}
}