import { Addon, Graph, Shape } from '@antv/x6';
import X6GraphEvent from './X6GraphEvent';

const stepImg = 'https://static.bimcc.com/openim/16639286429162E5B90EB87ADA9658C37F4EB11DA5900.png';
const sendImg = 'https://static.bimcc.com/openim/166392868839080209E812AAAEBDEB8D4DD11337696B0.png';
const eventImg = 'https://static.bimcc.com/openim/16639287590573AEF784ABF87A556CB54131F3D027371.png';
const subFlowImg = 'https://static.bimcc.com/openim/1663928742870F1B69AAD6F8029B2E928D991C7137E7C.png';

const ports = {
  groups: {
    top: {
      position: 'top',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    right: {
      position: 'right',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    bottom: {
      position: 'bottom',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    left: {
      position: 'left',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
  },
  items: [
    { group: 'top' },
    { group: 'right' },
    { group: 'bottom' },
    { group: 'left' },
  ],
};
const CustomCells = [
  {
    name: 'Start',
    options: {
      inherit: 'circle',
      attrs: {
        body: {
          strokeWidth: 2,
          stroke: '#6BCA25',
          fill: '#FFF',
        },
        label: {
          text: '开始',
          fontSize: 16,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'Step',
    options: {
      inherit: 'rect',
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'image',
          selector: 'img',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
      attrs: {
        body: {
          rx: 6,
          ry: 6,
          stroke: '#5F95FF',
          fill: '#EFF4FF',
          strokeWidth: 1,
        },
        img: {
          x: 6,
          y: 6,
          width: 20,
          height: 20,
          'xlink:href': stepImg,
        },
        label: {
          text: '审批节点',
          fontSize: 12,
          fill: '#262626',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'Send',
    options: {
      inherit: 'rect',
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'image',
          selector: 'img',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
      attrs: {
        body: {
          rx: 6,
          ry: 6,
          stroke: '#5F95FF',
          fill: '#EFF4FF',
          strokeWidth: 1,
        },
        img: {
          x: 6,
          y: 6,
          width: 20,
          height: 20,
          'xlink:href': sendImg,
        },
        label: {
          text: '抄送节点',
          fontSize: 12,
          fill: '#262626',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'Gateway',
    options: {
      inherit: 'polygon',
      attrs: {
        body: {
          refPoints: '0,10 10,0 20,10 10,20',
          strokeWidth: 2,
          stroke: '#7E8595',
          fill: '#EFF4FF',
        },
        label: {
          text: '+',
          fontSize: 50,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'End',
    options: {
      inherit: 'circle',
      attrs: {
        body: {
          strokeWidth: 4,
          stroke: '#F16463',
          fill: '#FFF',
        },
        label: {
          text: '结束',
          fontSize: 16,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'Event',
    options: {
      inherit: 'rect',
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'image',
          selector: 'img',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
      attrs: {
        body: {
          rx: 6,
          ry: 6,
          stroke: '#5F95FF',
          fill: '#EFF4FF',
          strokeWidth: 1,
        },
        img: {
          x: 6,
          y: 6,
          width: 20,
          height: 20,
          'xlink:href': eventImg,
        },
        label: {
          text: '系统任务',
          fontSize: 12,
          fill: '#262626',
        },
      },
      ports: { ...ports },
    },
  },
  {
    name: 'SubFlow',
    options: {
      inherit: 'rect',
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'image',
          selector: 'img',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
      attrs: {
        body: {
          rx: 6,
          ry: 6,
          stroke: '#5F95FF',
          fill: '#EFF4FF',
          strokeWidth: 1,
        },
        img: {
          x: 6,
          y: 6,
          width: 20,
          height: 20,
          'xlink:href': subFlowImg,
        },
        label: {
          text: '子流程',
          fontSize: 12,
          fill: '#262626',
        },
      },
      ports: { ...ports },
    },
  },
];

export default class X6Graph {
  constructor(vm, cells = []) {
    // 主要变量
    this.vm = vm; // vue 实例
    this.$refs = vm.$refs; // vue实例 refs
    this.graph = null; // x6画布实例
    this.curCell = null; // 当前选中cell
    this.isPasting = false; // 当前是否在粘贴状态
    this.dnd = null; // 拖拽实例
    this.initX6(cells);
  }
  initX6(cells) {
    this.registerCustomNode();
    const options = this.initOptions();
    this.graph = new Graph(options);
    this.initEventManager();
    if (cells.length > 1) {
      this.graph.fromJSON(cells);
      if(!this.vm.editable) {
        this.graph.zoomToFit();
      }
      this.graph.centerContent();
    }
    this.initDnd();
  }
  // X6事件注册
  initEventManager() {
    new X6GraphEvent(this);
  }
  initOptions() {
    return {
      container: this.$refs.x6Main,
      autoResize: true,
      mousewheel: {
        enabled: true,
        zoomAtMousePosition: true,
        minScale: 0.5,
        maxScale: 3,
      },
      panning: {
        enabled: true,
        modifiers: 'ctrl',
      },
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 20,
          },
        },
        connector: {
          name: 'rounded',
          args: {
            radius: 8,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        snap: {
          radius: 20,
        },
        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
            data: {
              name: '',
              priority: 1,
              condition: [
                {
                  logicType: 1,
                  judgeType: 1,
                  userType: 1,
                  userArch: 0,
                  publisher: [],
                  field: '',
                  judge: 'gt',
                  value: '',
                },
              ],
            },
            zIndex: 0,
          });
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          name: 'stroke',
          args: {
            attrs: {
              fill: '#5F95FF',
              stroke: '#5F95FF',
            },
          },
        },
      },
      resizing: true,
      rotating: false,
      selecting: {
        enabled: true,
        rubberband: true,
        showNodeSelectionBox: true,
        showEdgeSelectionBox: true,
      },
      snapline: {
        enabled: true,
        className: 'snapline',
        clean: false,
      },
      keyboard: {
        enabled: true,
        global: true,
      },
      history: true,
      clipboard: true,
      translating: true,
    };
  }
  registerCustomNode() {
    CustomCells.forEach((cell) => {
      Graph.registerNode(cell.name, cell.options, true);
    });
  }
  createStartNode() {
    const options = {
      shape: 'Start',
      width: 75,
      height: 75,
      data: {},
    };
    return this.graph.createNode(options);
  }
  createStepNode() {
    const options = {
      shape: 'Step',
      width: 165,
      height: 60,
      data: {
        name: '审批节点',
        userType: 7,
        userFrom: 1,
        userArch: 0,
        userScope: false, // 二次选择范围
        userSection: [],
        userJob: [], // 岗位
        targetUser: [],
        selectStep: false,
        backType: 1,
        turnTo: false,
        revoke: false,
        signType: 1,
        signRate: 1,
        attachRequired: false,
        suggestRequired: false,
        extendFields: {},
        btnList: [
          {
            btn: '退回', status: -1, show: true, system: 1,
          },
          {
            btn: '通过', status: 1, show: true, system: 1,
          },
          {
            btn: '转交', status: 2, show: true, system: 1,
          },
          {
            btn: '重新发起', status: 3, show: true, system: 1,
          },
          {
            btn: '提交至退回人', status: 4, show: true, system: 1,
          },
        ]
      },
    };
    return this.graph.createNode(options);
  }
  createSendNode() {
    const options = {
      shape: 'Send',
      width: 165,
      height: 60,
      data: {
        name: '抄送节点',
        userType: 1,
        userFrom: 1,
        userArch: 0,
        userScope: 1,
        userSection: [],
        userJob: [],
        sendUser: [],
      },
    };
    return this.graph.createNode(options);
  }
  createGatewayNode() {
    const options = {
      shape: 'Gateway',
      width: 85,
      height: 85,
      data: {
        name: '',
        condition: [],
      },
    };
    return this.graph.createNode(options);
  }
  createEndNode() {
    const options = {
      shape: 'End',
      width: 75,
      height: 75,
      data: {},
    };
    return this.graph.createNode(options);
  }
  createEventNode() {
    const options = {
      shape: 'Event',
      width: 165,
      height: 60,
      data: {},
    };
    return this.graph.createNode(options);
  }
  createSubFlowNode() {
    const options = {
      shape: 'SubFlow',
      width: 165,
      height: 60,
      data: {
        name: '子流程',
        canArch: [],
        skipSubFlow: false,
        subFlowId: '',
      },
    };
    return this.graph.createNode(options);
  }
  // 删除选中单元
  deleteCellsWithSelected() {
    const cells = this.graph.getSelectedCells();
    if (cells.length) {
      this.graph.removeCells(cells);
    }
  }
  initDnd() {
    this.dnd = new Addon.Dnd({
      target: this.graph,
      scaled: false,
      animation: true,
    });
  }
  handleDrag(e, target) {
    const node = this[`create${target.type}Node`]();
    this.dnd.start(node, e);
  }
}
