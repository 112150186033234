import { render, staticRenderFns } from "./CurveChart.vue?vue&type=template&id=2df6d438&scoped=true&"
import script from "./CurveChart.vue?vue&type=script&lang=js&"
export * from "./CurveChart.vue?vue&type=script&lang=js&"
import style0 from "./CurveChart.vue?vue&type=style&index=0&id=2df6d438&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df6d438",
  null
  
)

export default component.exports