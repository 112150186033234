<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-05-24 09:11:31
 * @LastEditors: hw 315249041@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /dataview-next/src/custom-component/bindComponent/BindComponent.vue
-->
<template>
  <div class="bind-component">
		<div class="w-full h-full" v-if="!isBtn">
			<BindBim  @set-data="onAction($event,'setData')" @back="onAction($event,'back')" :modelData="modelData" :appId="appId" :appSecret="appSecret" v-if="componentType === 'bim'"></BindBim>
			<BindLatLng  @set-data="onAction($event,'setData')" @back="onAction($event,'back')" :modelData="modelData" :appId="appId" :appSecret="appSecret" :componentType="componentType" v-if="['gis','latlng'].includes(componentType)"></BindLatLng>
			<BindDrawing  @set-data="onAction($event,'setData')" @back="onAction($event,'back')" :drawingData="modelData" v-if="componentType === 'drawing'"></BindDrawing>
			<BindPano  @set-data="onAction($event,'setData')" @back="onAction($event,'back')" :modelData="modelData"  v-if="componentType === 'pano'"></BindPano>
		</div>
		<div class="w-full h-full" v-else>
			<BindBimBtn  ref="bindBim" :appId="appId" :appSecret="appSecret" :modelData="modelData" v-if="componentType === 'bim'" @set-data="onAction($event,'setData')">
				<template #showType>
          <el-button
            size="medium"
            style="width: 100%"
            type="primary"
            @click="openDrawer('bindBim')"
            v-html="bindValue ? '已选择': '请选择'"
          />
        </template>
			</BindBimBtn>
			<BindLatLngBtn  ref="bindLatLng" :appId="appId" :appSecret="appSecret" :modelData="modelData" :componentType="componentType" v-if="['gis','latlng'].includes(componentType)" @set-data="onAction($event,'setData')">
				<template #showType>
          <el-button
            size="medium"
            style="width: 100%"
            type="primary"
            @click="openDrawer('bindLatLng')"
            v-html="bindValue ? '已选择': '请选择'"
          />
        </template>
			</BindLatLngBtn>
			<BindDrawingBtn  ref="bindDawing" v-if="componentType === 'drawing'" :drawingData="modelData" @set-data="onAction($event,'setData')">
				<template #showType>
          <el-button
            size="medium"
            style="width: 100%"
            type="primary"
            @click="openDrawer('bindDawing')"
            v-html="bindValue ? '已选择': '请选择'"
          />
        </template>
			</BindDrawingBtn>
			<BindPanoBtn  ref="bindPano" :modelData="modelData" v-if="componentType === 'pano'" @set-data="onAction($event,'setData')">
				<template #showType>
          <el-button
            size="medium"
            style="width: 100%"
            type="primary"
            @click="openDrawer('bindPano')"
            v-html="bindValue ? '已选择': '请选择'"
          />
        </template>
			</BindPanoBtn>
		</div>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import BindBim from '@/custom-component/bindModel/index'
// import BindGis from '@/custom-component/bindGis/index'
import BindLatLng from '@/custom-component/bindLatLng/index'
import BindDrawing from '@/custom-component/bindDrawing/index'
import BindPano from '@/custom-component/bindPano/index'
import BindBimBtn from '@/custom-component/bindModel/ButtonType'
// import BindGisBtn from '@/custom-component/bindGis/ButtonType'
import BindLatLngBtn from '@/custom-component/bindLatLng/ButtonType'
import BindDrawingBtn from '@/custom-component/bindDrawing/ButtonType'
import BindPanoBtn from '@/custom-component/bindPano/ButtonType'
import { getComponentById, getQueryValue } from '@/utils/tools';
export default {
  name: 'BindComponent',
	props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: true
		}
  },
  components: {
		BindBim,
		// BindGis,
		BindLatLng,
		BindDrawing,
		BindPano,
		BindBimBtn,
		// BindGisBtn,
		BindLatLngBtn,
		BindDrawingBtn,
		BindPanoBtn
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus',
			'subsidiaryComponentData'
		]),
		componentType(){
			return this.element.bindConfig.type;
		},
		appId(){
			return this.element.bindConfig.appId? this.element.bindConfig?.appId : process.env.VUE_APP_MODEL_APPID;
		},
		appSecret(){
			return this.element.bindConfig.appSecret? this.element.bindConfig?.appSecret : process.env.VUE_APP_MODEL_APPSECRET;
		},
		isBtn(){
			return this.element.bindConfig.isBtn;
		},
		objectUuid(){
			return this.element.database?.objectData?.uuid || '';
		},
		viewUuid(){
			return this.element.database?.viewData?.uuid || '';
		},
		// 请求类型(待追加)
		requestType() {
			return this.element?.sourceConfig?.[0].type || 'updateData';
		},
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData || [];
			}
			return this.componentData || [];
		}
	},	
	data() {
		return {
			bindValue:null,
			modelData:null
		};
	},
	methods: {
		setData(data){
			console.log(data,'选中绑定数据')
			this.bindValue = data
			const params = this.getParams();
			if(this.objectUuid && this.viewUuid && data && params?.data_id!==undefined && params.bind_field){
				let obj = {
					object_uuid:this.objectUuid,
					view_uuid:this.viewUuid,
					data_id:params?.data_id,
					[params.bind_field]: data,
					__method_name__:this.requestType
				}
				dataInterface(obj).then((res) => {
					if(res.data.code === 200){
						this.$message.success('绑定成功！')
					}
				})
			} else {
				this.$message.warning('请选择绑定数据或值绑定！')
			}
		},
		openDrawer(el){
			this.$refs[el].show = true
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams() {
			const sourceConfig = this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'pageStatus' && statusCode) {
					const result = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode]
					this.$set(sourceParams, key, result);
				} else if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if(originType === 'fixed'){
					this.$set(sourceParams, key, sourceConfig[i].fixedValue);
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.subComponentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
					this.$set(sourceParams, key, result);
				}
			}
			return sourceParams;
		},
		onAction(data,funName){
			this[funName](data)
			const eventList = this.element.actionConfig[funName]?.eventList || [];
			if (eventList && eventList.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							ele.effects.forEach((effect) => {
								this.$store.commit('triggerEvents', {
									config: {
										...ele,
									...effect
									},
									element: this.element,
									EDITOR_pageUUID: this.EDITOR_pageUUID
								});
							});
							// 触发组件行为
							const { behaviors } = ele;
								behaviors.forEach(behavior => {
									this.$store.commit('triggerEvents', {
										config: {
											behavior,
											isBehavior: true
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
								});
						}
					})
				});
			}		
		},
		back(){
		}
	},
	created() {
		const params = this.getParams();
		if(params?.echo_data){
			this.modelData = params?.echo_data
		}
	}
}
</script>
<style lang="less" scoped>
.bind-component {
	width: 100%;
	height: 100%;
}   
</style>
