<!--
    @name: FlowOperate
    @description：FlowOperate
    @author: ZengWei
    @date: 2021-10-27 12:06
-->
<template>
  <div class="flow-operate" v-if="editData.btn && editData.btn.length !== 0">
    <div class="type-label">反馈
      <span>
        <i class="iconfont iconc-close" style="float:right;cursor: pointer;" @click.stop="hidOperate()"/>
      </span>
    </div>
    <div style="text-align: left">
      <el-radio-group v-model="btnRadio" @change="btnChange">
        <template v-for="(item, index) in editData.btn">
          <el-radio
            v-if="item.show"
            :label="item.status"
            :key="index">
            {{ item.btn }}
          </el-radio>
        </template>
      </el-radio-group>
      <!-- <el-button
        v-for="(item, index) in editData.btn"
        :key="index"
        size="small"
        :type="item.status === -1 ? 'danger' : 'primary'"
        @click="actionEvent(item.status)"
      >
        {{ item.btn }}
      </el-button> -->
    </div>
    <div class="operate-content">
      <div v-if="buttonStatus !== 0">
        <el-form ref="baseForm" label-width="80px" :model="applyMsg">
          <el-form-item
            label="审批意见" prop="remarks"
            :rules="suggestRequired ? suggestRule :[]">
            <el-input
              v-model="applyMsg.remarks"
              placeholder="请输入审批意见"
              :rows="3"
              type="textarea"
            />
          </el-form-item>
          <el-form-item
            label="审批附件" prop="files"
            :rules="attachRequired ? attachRule :[]">
            <fileUpload :files="applyMsg.files" @extra-files="extraFiles" />
          </el-form-item>
        </el-form>
        <PcParser
          ref="otherForm"
          v-if="showOtherForm"
          :form-data="curStepNode.extendFields"
        >
        </PcParser>
      </div>
      <div v-if="buttonStatus === -1">
        <div class="type-label" style="margin-top: 20px">选择退回类型</div>
        <el-form label-width="120px">
          <el-form-item label="退回类型">
            <el-radio-group v-model="back_type" size="small">
              <el-radio-button :label="1">退回发起人</el-radio-button>
              <el-radio-button :label="2">退回上一步</el-radio-button>
              <el-radio-button :label="3">指定步骤退回</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="back_type === 3" label="步骤选择">
            <el-select
              v-model="back_step"
              placeholder="请选择退回的步骤"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in editData.doneStep"
                :key="index"
                :value="item.nodeId"
              >
                {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="buttonStatus > 0 && buttonStatus !== 4">
        <FlowPicker
          :data-id="dataId"
          :module="module"
          :turn="buttonStatus === 2 ? true : false"
          type="next"
          :flow-edit="flowEdit"
          @checked-users="dealWithPicker"
          @selected-step="getSelectStep"
        />
      </div>
    </div>
    <div v-if="buttonStatus !== 0" class="button-click">
      <el-button style="width:180px" size="small" type="default" @click="buttonStatus = 0;hidOperate()">
        取消
      </el-button>
      <el-button :loading="submitLoading" style="width:180px" size="small" type="primary" @click="flowOperate">
        提交
      </el-button>
    </div>
    <div v-if="!btnRadio">
      <el-empty style="height: 100%" description="请选择反馈类型"></el-empty>
    </div>
  </div>
</template>

<script>
  import fileUpload from '@/components/fileUpload/newFiles/index.vue'
  import FlowPicker from './FlowPicker'
  import { flowEngine } from '@/apis/data/workflow'
  import {Form, FormItem, Input, Select, Option, RadioGroup, Radio, Empty, RadioButton} from 'element-ui'

  export default {
    name: 'FlowOperate',
    components: { fileUpload,
			FlowPicker,
			'el-form': Form,
			'el-form-item': FormItem,
			'el-input': Input,
			'el-select': Select,
			'el-option': Option,
			'el-radio-group': RadioGroup,
			'el-radio-button': RadioButton,
			'el-radio': Radio,
			'el-empty': Empty,
		},
    inject:{
      parser: {
        default: null
      }
    },
    props: {
      editData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      //流程中修改的数据
      flowEdit:{
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return{
        curStepNode: {},
        buttonStatus: 0,
        back_type: 1,
        back_step: '',
        module:'',
        dataId:'',
        applyMsg:{
          remarks: '',
          files: [],
        },
        selectedUsers: [],
        checkPickerNum: 0,
        selectStep: '',

        btnRadio:'',
        submitLoading: false,
        suggestRequired: false,
        attachRequired: false,
        suggestRule: {
          required: true, message: '审批意见不能为空',type: 'string', trigger: 'blur'
        },
        attachRule:{
          required: true, message: '审批意见不能为空', type: 'array', trigger: 'change'
        },
        showOtherForm: false
      }
    },
    methods:{
      formItemShow(list){
        this.$emit('input-show',list)
      },
      getSelectStep(nodeId) {
        this.selectStep = nodeId
      },
      dealWithPicker(selected, total) {
        this.selectedUsers = selected
        this.checkPickerNum = total
      },
      extraFiles(files){
        this.applyMsg.files = files
      },
      actionEvent(status){
        this.buttonStatus = status
      },
      flowOperate() {
        let invalid = false
        try {
          this.$refs.baseForm.validate((valid)=>{
            if(!valid){
              throw new Error('表单验证失败')
            }
          })
          if(this.$refs.otherForm) {
            this.$refs.otherForm.validateFormInput()
          }
        } catch (e){
          invalid = true
          this.$message.error('审批意见必填项未填报完整')
        }
        if(invalid) return

        // 兼容手写功能流程审批
        if(this.parser){
          this.parser.formParserValid().then(valid=>{
            if(valid){
              if (
                this.checkPickerNum > 0 &&
                this.buttonStatus !== 4 &&
                this.checkPickerNum !== this.selectedUsers.target_users.length
              ) {
                this.$message.error('审批人未选择完整！')
                return false
              }
              let formData = {
                status: this.buttonStatus,
                module: this.module,
                remarks: this.applyMsg.remarks,
                appendix: this.applyMsg.files,
              }
              if(this.$refs.otherForm){
                const otherData = this.$refs.otherForm.getFormData()
                formData.extend_fields = otherData
              }
              if (this.buttonStatus === -1) {
                formData.back_type = this.back_type
                if (this.back_type === 3) formData.back_step = this.back_step
              }
              if (
                this.selectedUsers.target_users &&
                this.selectedUsers.target_users.length > 0
              ) {
                formData.target_users = this.selectedUsers.target_users
              }
              if (
                this.selectedUsers.send_users &&
                this.selectedUsers.send_users.length > 0
              ) {
                formData.send_users = this.selectedUsers.send_users
              }
              if (this.selectStep) formData.select_step = this.selectStep
              if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit
              if (this.submitLoading) {
                return
              }
              this.submitLoading = true
              flowEngine.flowOperate(this.dataId, formData).then((res) => {
                if (res.data.code === 200) {
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
                this.$emit('change-end')
                setTimeout(() => {
                  this.submitLoading = false
                }, 500)
              })
            } else {
              this.$message.error('数据验证失败，请核对必填字段数据');
            }
          })
        } else {
          if (
            this.checkPickerNum > 0 &&
            this.buttonStatus !== 4 &&
            this.checkPickerNum !== this.selectedUsers.target_users.length
          ) {
            this.$message.error('审批人未选择完整！')
            return false
          }
          let formData = {
            status: this.buttonStatus,
            module: this.module,
            remarks: this.applyMsg.remarks,
            appendix: this.applyMsg.files,
          }
          if(this.$refs.otherForm){
            const otherData = this.$refs.otherForm.getFormData()
            formData.extend_fields = otherData
          }
          if (this.buttonStatus === -1) {
            formData.back_type = this.back_type
            if (this.back_type === 3) formData.back_step = this.back_step
          }
          if (
            this.selectedUsers.target_users &&
            this.selectedUsers.target_users.length > 0
          ) {
            formData.target_users = this.selectedUsers.target_users
          }
          if (
            this.selectedUsers.send_users &&
            this.selectedUsers.send_users.length > 0
          ) {
            formData.send_users = this.selectedUsers.send_users
          }
          if (this.selectStep) formData.select_step = this.selectStep
          if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit
          if (this.submitLoading) {
            return
          }
          this.submitLoading = true
          flowEngine.flowOperate(this.dataId, formData).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
            this.$emit('change-end')
            setTimeout(() => {
              this.submitLoading = false
            }, 500)
          })
        }


      },
      /* 操作按钮选择 */
      btnChange(val) {
        if(val === -1){
          this.checkPickerNum = 0
        }
        this.actionEvent(val)
      },
      /* 隐藏操作区域 */
      hidOperate() {
        this.$emit('hid-operate')
      }
    },
    created () {
      this.module = this.editData.module
      this.dataId = this.editData.id
      this.curStepNode = this.editData?.curStepNode
      const extendFields = this.curStepNode?.extendFields || {}
      if(extendFields instanceof Object && extendFields?.fields?.length){
        this.showOtherForm = true
      }
      this.attachRequired = this.curStepNode?.attachRequired || false
      this.suggestRequired = this.curStepNode?.suggestRequired || false
    }
  }
</script>
<style scoped>

</style>
<style lang="less" scoped>
  .flow-operate {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 10px;
    box-sizing: border-box;
    .hid-button {
      width: 100%;
    }
    .operate-content {
      flex:1;
      overflow-y: auto;
      padding:15px 5px 15px 0;
      overflow-x: hidden;
    }
    .button-click {
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }
   :deep(.el-form-item) {
      /*display: block !important;
      border: none !important;*/
    }
    :deep(.el-form-item__label) {
      /*width: 100% !important;
      display: block !important;
      text-align: left !important;
      margin: 10px 0 !important;
      background-color: transparent !important;
      border: none !important;*/
    }
    :deep(.el-form-item__content) {
      /*margin-left: 10px !important;
      display: block !important;
      width: 100% !important;*/
    }
  }
  .type-label {
    border-left: 3px solid #007fe0 !important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
  }
</style>
