import eventBus from '@/plugins/eventBus';

export default class ModelEvents {
  constructor() {}

  static getEvents(viewer, comp) {
    viewer.on(viewer.EventType.modelLoaded, (modelId) => {
      eventBus.$emit('ViewerGIS.onModelLoaded', comp.element.id, modelId);
      comp.onAction('onModelLoaded');
    });

    return {
      'ViewerGIS.createModel': (eid, modelId, isLocation, cb) => {
        if (eid !== comp.element.id) return;

        viewer.Model.createModel(modelId, isLocation).then((res) => {
            if(!res) return console.error("已添加相同modelId模型");

            const tree = viewer.Model.getTreeByKey(modelId);

            cb && cb(res, tree);
        });
      },
      'ViewerGIS.removeModel': (eid, modelId) => {
        if (eid !== comp.element.id) return;

        viewer.Model.removeModel(modelId);
      },
      'ViewerGIS.locationModel': (eid, modelId) => {
        if (eid !== comp.element.id) return;

        viewer.Model.locationModel(modelId);
      },
      'ViewerGIS.showModel': (eid, modelId, isShow) => {
        if (eid !== comp.element.id) return;

        viewer.Model.showModel(modelId, isShow);
      },
      'ViewerGIS.getModelKeys': (eid, cb) => {
        if (eid !== comp.element.id) return;

        const keys = viewer.Model.getModelKeys();
        cb && cb(keys);
      },
      'ViewerGIS.getModelInfo': (eid, cb) => {
        if (eid !== comp.element.id) return;

        const info = viewer.Model.getModelInfo();

        for (let item of info){
          const tree = viewer.Model.getTreeByKey(item.modelId);
          item.tree = tree;
        }

        cb && cb(info);
      },
      'ViewerGIS.getSelectFeature': (eid, cb) => {
        if (eid !== comp.element.id) return;

        const dbids = viewer.Model.getSelectFeature();
        cb && cb(dbids);
      },
      'ViewerGIS.selectFeature': (eid, modelId, dbids, isLocation = false) => {
        if (eid !== comp.element.id) return;

        viewer.Model.selectFeature(modelId, dbids, isLocation);
      },
      'ViewerGIS.insulateFeature': (eid, modelId, dbids, isLocation = false) => {
        if (eid !== comp.element.id) return;

        viewer.Model.insulateFeature(modelId, dbids, isLocation);
      },
      'ViewerGIS.showFeature': (eid, modelId, dbids, isShow, isLocation = false) => {
        if (eid !== comp.element.id) return;

        viewer.Model.showFeature(modelId, dbids, isShow, isLocation);
      },
      'ViewerGIS.showAllFeature': (eid, modelId, isShow) => {
        if (eid !== comp.element.id) return;

        viewer.Model.showAllFeature(modelId, isShow);
      },
      'ViewerGIS.locationFeature': (eid, modelId, dbids, duration) => {
        if (eid !== comp.element.id) return;

        viewer.Model.locationFeature(modelId, dbids, duration);
      },
      'ViewerGIS.getModelTree': (eid, modelId, cb) => {
        if (eid !== comp.element.id) return;

        const tree = viewer.Model.getTreeByKey(modelId);
        cb && cb(tree);
      },
      'ViewerGIS.setFeatureColor': (eid, modelId, dbids, color) => {
        if (eid !== comp.element.id) return;

        viewer.Model.setFeatureColor(modelId, dbids, color);
      },
      'ViewerGIS.clearFeatureColor': (eid, modelId, dbids) => {
        if (eid !== comp.element.id) return;

        viewer.Model.clearFeatureColor(modelId, dbids);
      },
    };
  }
}
