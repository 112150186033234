import { render, staticRenderFns } from "./FormDesigner.vue?vue&type=template&id=231e27b9&scoped=true&"
import script from "./FormDesigner.vue?vue&type=script&lang=js&"
export * from "./FormDesigner.vue?vue&type=script&lang=js&"
import style0 from "./FormDesigner.vue?vue&type=style&index=0&id=231e27b9&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231e27b9",
  null
  
)

export default component.exports