<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: zx
 * @LastEditTime: 2022-10-25 17:52:26
 * @Description:
-->
<template>
  <div style="height:100%;width:100%;">
    <div style="height:100%;width:100%;" v-if="!inpage">
      <el-select class="new-select-style" :class="isConsole?'console-user-style':''" style="display: block;" :value="seatValue" :disabled="disabled" multiple>
        <template #prefix>
            <div
              v-if="showValue && !showChooseButton"
              class="show-value"
              :style="disabled && !isConsole ? 'background:#F5F7FA;color:#c0c4cc' : ''"
            >
              {{ showValue }}
            </div>
            <div
              v-if="!showValue && !showChooseButton"
              class="show-value"
              style="cursor: pointer"
              :style="disabled && !isConsole ? 'background:#F5F7FA;color:#c0c4cc' : ''"
            >
              请选择人员
            </div>
            <div v-if="showChooseButton" class="choose-button">
              <span>添加人员</span>
              <i class="iconfont iconkuaisuxinzeng" style="font-size:18px"></i>
            </div>
        </template>
        <template #empty>
          <div class="pover">
            <div
            v-loading="showTree"
            class="pover-content"
            :style="'height:' + maxHeight + 'px'"
          >
            <div class="content-left">
              <div class="content-left-search">
                <el-input v-model="searchValue" placeholder="请输入人员名字" />
              </div>
              <div class="content-left-tree scroller">
                <el-tree
                  ref="treeRef"
                  :data="dataList"
                  :render-after-expand="isJgsc"
                  :default-checked-keys="showTreeKeys"
                  :default-expanded-keys="isJgsc ? expandKeys : []"
                  :default-expand-all="!isJgsc ? defaultExpand : false"
                  :filter-node-method="filterNode"
                  node-key="idKey"
                  :lazy="isJgsc"
                  :load="loadNode"
                  :props="{
                    label: 'name',
                    children: 'children',
                    isLeaf: 'leaf'
                  }"
                  :show-checkbox="isCheckType"
                  @check="handleCheckChange"
                  @node-click="handleNodeClick"
                >
                  <template #default="{ node }">
                    <div class="custom-tree-node">
                      <el-tooltip
                        class="item"
                        :content="node.label"
                        effect="dark"
                        placement="top-start"
                        :open-delay="1000"
                      >
                        <div style="display: flex;">
                          <div :style="{color: getTypeInfo(node) ? getTypeInfo(node).color : '',fontWeight: 600,display: 'flex', alignItems: 'center', marginRight: '4px' }">
                            <span v-if="getTypeInfo(node) && getTypeInfo(node).name !== '文' && getTypeInfo(node).name !== '人'">
                              {{ getTypeInfo(node) ? getTypeInfo(node).name : '' }}
                            </span>
                            <i v-if="getTypeInfo(node) && getTypeInfo(node).name === '文'" class="iconfont iconwenjianjia" />
                            <img v-if="getTypeInfo(node) && getTypeInfo(node).name === '人'" src="../img/user.png" style="width: 16px;height: 16px;">
                          </div>
                          {{ node.label }}
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-tree>
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-top">
                <span>
                  已选人员（{{ selectUserList.length }}）
                </span>
                <span
                  style="float: right; color: #0076ff; cursor: pointer"
                  @click="clearChoose(1)"
                >
                  清空
                </span>
              </div>
              <div class="content-right-choose scroller">
                <div
                  v-for="(item, index) in selectUserList"
                  :key="index"
                  class="content-show"
                >
                  <div class="content-show-img">
                    <img v-if="item.wechat_avatar || item.avatar" :src="item.avatar ? item.avatar : item.wechat_avatar">
                    <div v-else class="content-show-seat" />
                  </div>
                  <div class="content-show-name">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top-start"
                      :open-delay="1000"
                    >
                      <template #content>
                        {{ item.name + '(' +
                          (item.company_name ? item.company_name : item.project_name) +
                          (item.external_name ? '/' + item.external_name : '') + '/' +
                          item.section_name+ ')'
                        }}
                      </template>
                      <p>{{ item.name }}({{ item.section_name }})</p>
                    </el-tooltip>
                    <p>
                      {{
                        item.company_name ? item.company_name : item.project_name
                      }}
                      {{
                        item.external_name ? '/' + item.external_name:''
                      }}
                    </p>
                  </div>
                  <div class="content-show-delete">
                    <i
                      class="iconfont iconwangpan-qingchu1x"
                      style="color: #A9ADB8"
                      @click.stop="deleteClick(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </template>
      </el-select>
      <div v-if="showChooseButton">
        <div
          v-for="(item, index) in selectUserList"
          :key="index"
          class="content-show content-show-new"
          style="justify-content: flex-start"
        >
          <div style="width: 20px;height:20px;margin: 4px 8px 0 0">
            <img v-if="item.wechat_avatar || item.avatar" :src="item.avatar ? item.avatar : item.wechat_avatar" style="width:100%;height:100%">
            <div v-else
              style="
              width:100%;
              height:100%;
              background: #eee;
              border-radius: 4px;
              overflow: hidden;" 
            />
          </div>
          <div class="content-show-name" style="flex:1">
            <el-tooltip
              class="item"
              :content="item.name + '(' + getTitle(item)+ ')'"
              effect="dark"
              placement="top-start"
              :open-delay="1000"
            >
              <p>{{ item.name }}</p>
            </el-tooltip>
          </div>
          <div class="content-show-delete-new">
            <i
              style="font-size:12px; cursor: pointer;"
              class="iconfont iconbimgis_guanbi"
              @click.stop="deleteClick(item)" 
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 回显 -->
    <div v-if="inpage" class="pover" style="width: 100%; height: 100%">
      <!-- <div class="show-value" style="height: 30px; line-height: 30px">
        <span>{{ showValue }}</span>
      </div> -->
      <div v-loading="showTree" class="pover-content">
        <div class="content-left">
          <div class="content-left-search">
            <el-input v-model="searchValue" placeholder="请输入人员名字" />
          </div>
          <div class="content-left-tree scroller">
            <el-tree
              ref="treeRef"
              :data="dataList"
              :default-checked-keys="showTreeKeys"
              :default-expand-all="defaultExpand"
              :filter-node-method="filterNode"
              node-key="idKey"
              :props="{
                label: 'name',
                children: 'children'
              }"
              :show-checkbox="isCheckType"
              @check="handleCheckChange"
              @node-click="handleNodeClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <el-tooltip
                    class="item"
                    :content="node.label"
                    effect="dark"
                    placement="top-start"
                    :open-delay="1000"
                  >
                    <span>
                      <span :style="{color: getTypeInfo(node) ? getTypeInfo(node).color : '',fontWeight: 600 }">
                        <span v-if="getTypeInfo(node) && getTypeInfo(node).name !== '文'">
                          {{ getTypeInfo(node) ? getTypeInfo(node).name : '' }}
                        </span>
                        <i v-if="getTypeInfo(node) && getTypeInfo(node).name === '文'" class="iconfont iconwenjianjia" />
                      </span>
                      {{ node.label }}
                    </span>
                  </el-tooltip>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-top">
            <span>
              已选中
              <span class="content-right-top-number">
                {{ selectUserList.length }}
              </span>
              人
            </span>
            <span
              style="float: right; color: #0076ff; cursor: pointer"
              @click="clearChoose(1)"
            >
              清空
            </span>
          </div>
          <div class="content-right-choose scroller">
            <div
              v-for="(item, index) in selectUserList"
              :key="index"
              class="content-show"
            >
              <div class="content-show-img">
                <img v-if="item.wechat_avatar || item.avatar" :src="item.avatar ? item.avatar : item.wechat_avatar">
                <div v-else class="content-show-seat" />
              </div>
              <div class="content-show-name">
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top-start"
                  :open-delay="1000"
                >
                  <template #content>
                    {{ item.name + '(' +
                      (item.company_name ? item.company_name : item.project_name) +
                      (item.external_name ? '/' + item.external_name : '') + '/' +
                      item.section_name+ ')'
                    }}
                  </template>
                  <p>{{ item.name }}({{ item.section_name }})</p>
                </el-tooltip>
                <p>
                  {{
                    item.company_name ? item.company_name : item.project_name
                  }}
                  {{
                    item.external_name ? '/' + item.external_name:''
                  }}
                </p>
              </div>
              <div class="content-show-delete">
                <i
                  class="iconfont iconwangpan-qingchu1x"
                  style="color: #A9ADB8"
                  @click.stop="deleteClick(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Tooltip,Tree,Input} from 'element-ui'

export default{
  name: '',
  components: {
		'el-tooltip':Tooltip,
		'el-tree':Tree,
		'el-input':Input,
	},
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Array,
      default: () => []
    },
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: Boolean,
      default: true
    },
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    /* 展示内容 */
    showTree: {
      type: Boolean,
      default: true
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    },
    /* 展示按钮的选择人员 */
    showChooseButton: {
      type: Boolean,
      default: false
    },
		// 中控台的样式
		isConsole :{
			type: Boolean,
      default: false
		}
  },
  data(){
    return {
      seatValue:'',
      treeRef: null,
      showTreeKeys: [],
      selectUserList: [],
      searchValue: '',
      firsetIn: false,
      dataList: [],
      expandKeys: [], // 懒加载状态的回显人员展开的key,
      isJgsc: false
    }
  },
  computed:{
     /* 计算当前选择的人员回显 */
    showValue(){
      let str = ''
      this.selectUserList.forEach((item) => {
        if (!str) {
          str += item.name
        } else {
          str += ',' + item.name
        }
      })
      return str
    },
  },
  watch:{
    searchValue:{
      handler(val){
        if (!this.isJgsc) {
          this.$refs.treeRef.filter(val)
        } else {
          /* 搜索数据结构方法 */
          const mapTree = (value, arr) => {
              let newarr = [];
              arr.forEach(element => {
                  if (element.name.indexOf(value) > -1) { // 判断条件
                      element.open=true
                      newarr.push(element);
                  } else {
                  if (element.children && element.children.length > 0) {
                      let redata = mapTree(value, element.children);
                      if (redata && redata.length > 0) {
                          let obj = {
                              ...element,
                              children: redata
                          };
                              newarr.push(obj);
                          }
                      }
                  }
              });
              return newarr;
          }
          if (val) {
            const treeData = mapTree(val, this.dataInfo)
            const arr = []
            if (treeData?.length) {
              treeData.forEach((element) => {
                if (element?.company || element?.company?.length === 0) {
                  delete element.company
                }
                if (element?.project || element?.project?.length === 0) {
                  delete element.project
                }
                if (element?.sections || element?.sections?.length === 0) {
                  delete element.sections
                }
                arr.push(element)
              })
            }
            this.dataList = arr
          } else {
            const arr = []
            if (this.dataInfo?.length) {
              this.dataInfo.forEach((element) => {
                if (element?.company || element?.company?.length === 0) {
                  delete element.company
                }
                if (element?.project || element?.project?.length === 0) {
                  delete element.project
                }
                if (element?.sections || element?.sections?.length === 0) {
                  delete element.sections
                }
                arr.push(element)
              })
            }
            this.expandKeys = [1, 48, 47, 46]
            this.dataList = arr
            const showKeys = []
            if (this.selectUserList?.length) {
              this.selectUserList.forEach(element => {
                showKeys.push(element.idKey)
              });
            }
            this.showTreeKeys = showKeys
            this.$refs.treeRef.setCheckedKeys(showKeys)
            this.returnShowTreeInfo(this.showTreeKeys)
          }
        }
      }
    },
    dataInfo:{
      handler(val){
        if (this.isJgsc) {
          this.dataList = []
          if (this.dataInfo?.length) {
            this.dataInfo.forEach(element => {
              const obj = JSON.parse(JSON.stringify(element))
              obj.children = []
              this.dataList.push(obj)
            })
          }
        } else {
          this.dataList = val
        }
        this.showTreeInfo(this.showList)
      },
      deep:true,
    },
    showList:{
      handler(val){
        this.showTreeInfo(val)
      },
      deep:true,
    },
  },
  methods: {
    /* 当前选择的nodekey数组 */
    handleCheckChange(){
      /* 级联单选 */
      if (!this.isCheckType) {
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.treeRef.getCheckedKeys()
      let arr = []
      if( keys && keys.length) {
        const loopTree = (array) => {
          array.forEach(element => {
            if (keys.indexOf(element.idKey) !== -1 && element.user_id && element.section_id) {
              arr.push(+element.idKey)
            }
            if (element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        loopTree(this.dataInfo)
        this.showTreeKeys = arr
      }else{
        this.showTreeKeys = []
      }
      this.returnShowTreeInfo(arr)
    },
    /* 级联单选 */
    handleNodeClick(data){
      if (this.isCheckType) {
        return
      }
      if (data.nodeKey) {
        this.showTreeKeys = []
        this.showTreeKeys = [data.idKey]
      }
      this.returnShowTreeInfo(this.showTreeKeys)
    },
     /* 当前选择的人员数组数据（item包含所有信息） */
    returnShowTreeInfo(list){
      const selectArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.children && itemy.children.length && itemy.children[0].section_id && itemy.children[0].nodeKey) {
              itemy.children.forEach((item) => {
                if (item.idKey === element) {
                  selectArr.push(item)
                }
              })
            } else if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.dataInfo)
      })
      if (this.isJgsc && this.searchValue) {
        const unique = (arr, attrName) => {
          const res = new Map()
          return arr.filter(
            (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
          )
        }
        let newArr = this.selectUserList.concat(selectArr)
        newArr = unique(newArr, 'idKey')
        this.selectUserList = newArr
      } else {
        this.selectUserList = selectArr
      }
      this.$emit('on-select-change', this.selectUserList)
    },
    /* 传入回显的showList 获取勾选数组  进行默认回显 */
    showTreeInfo(list){
      if (!list || list.length === 0 || !this.dataInfo || this.dataInfo.length === 0) {
        return
      }
      /* 判断回显数组与当前选择的数据对比 */
      if (list && list.length && this.selectUserList && this.selectUserList.length && this.selectUserList.length === list.length) {
        let num = 0
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          for (let ind = 0; ind < this.selectUserList.length; ind++) {
            const item = this.selectUserList[ind]
            if (element.user_id === item.user_id && element.section_id === item.section_id) {
              num += 1
            }
          }
        }
        if (num === list.length) {
          return
        }
      }
      if (this.$refs.treeRef) {
        this.$refs.treeRef.setCheckedKeys([])
      }
      let showArr = []
      let expandKeys = []
      list.forEach((element) => {
        let singleKeys = []
        const showTree = (arr) => {
          for(var i = 0;i<arr.length;i++){
            if (arr[i].user_id === element.user_id && arr[i].section_id === element.section_id) {
              showArr.push(arr[i])
              singleKeys = arr[i].fatherKeys
              break
            } else if (arr[i].children && arr[i].children.length) {
              arr[i].children.forEach(element => {
                if (!arr[i].fatherKeys || arr[i].fatherKeys.length === 0) {
                  element.fatherKeys = [arr[i].idKey]
                } else {
                  let array = JSON.parse(JSON.stringify(arr[i].fatherKeys))
                  array.push(arr[i].idKey)
                  element.fatherKeys = array
                }
              });
              showTree(arr[i].children)
            }
          }
        }
        showTree(this.dataInfo)
        expandKeys = [...new Set([...expandKeys,...new Set(singleKeys)])]
      })
      this.expandKeys = [...new Set(expandKeys)]
      const unique = (arr, attrName) => {
        const res = new Map()
        return arr.filter(
          (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
        )
      }
      showArr = unique(showArr, 'user_id')
      const arr = []
      showArr.forEach((element) => {
        arr.push(element.idKey)
      })
      this.showTreeKeys = arr
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 删除单个勾选 */
    deleteClick(data){
      for (let index = 0; index < this.showTreeKeys.length; index++) {
        if (this.showTreeKeys[index] === data.idKey) {
          this.showTreeKeys.splice(index, 1)
        }
      }
      const ind = this.selectUserList.findIndex(item => item.idKey === data.idKey)
      this.selectUserList.splice(ind, 1)
      this.returnShowTreeInfo(this.showTreeKeys)
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
    },
    /* 清空所有勾选 */
    clearChoose(){
      if (!this.isCheckType) {
        // const treeRef = ref(this.treeRef)
        this.$refs.treeRef.setCurrentKey(null)
        this.showTreeKeys = []
        this.returnShowTreeInfo(this.showTreeKeys)
        this.selectUserList = []
        this.$emit('on-select-change', this.selectUserList)
        return
      }
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys([])
      this.selectUserList = []
      this.$emit('on-select-change', this.selectUserList)
    },
    /* tree组件的过滤规则 */
    filterNode(value, data){
      if (!value) return true
      return !!(data.name && data.name.indexOf(value) !== -1)
    },
    getTitle(item){
      return (item.external_name ? item.external_name + '/' : '') + item.section_name
    },
    /* 根据架构级别返回显示不同的颜色和类型文字 */
    getTypeInfo (data) {
      const info = data.data
      if ((info.table_name && info.table_name.indexOf('companys') > -1) || (info.parent_id && !info.company_id) || info.type) {
        if (info.type) {
          return {
            name: '文',
            color: '#FFCB00'
          }
        }
        return {
          name: '公',
          color: '#A56EFF'
        }
      } else if ((info.table_name && info.table_name.indexOf('companys') === -1) || info.type) {
        return {
          name: '项',
          color: '#2BA5F5'
        }
      } else if (info.section_type || (info.company_id && info.children && info.children.length && info.children[0].user_id)) {
        return {
          name: '部',
          color: '#26A049'
        }
      } else if (info.user_id) {
        return {
          name: '人',
          color: '#26A049'
        }
      }
    },
    /* 懒加载子节点 */
    loadNode(node, resolve) {
      let arr = []
      const loop = (array) => {
        array.forEach(element => {
          if (element.idKey === node?.data?.idKey) {
            const arrayList = []
            if (element.children?.length) {
              element.children.forEach(item => {
                const obj = JSON.parse(JSON.stringify(item))
                obj.children = []
                arrayList.push(obj)
              })
            }
            arr = arrayList
          }
          if (element.children?.length) {
            loop(element.children)
          }
        })
      }
      if (this.dataInfo?.length && node?.data?.idKey) {
        if (this.searchValue) {
          loop(this.dataList)
        } else {
          loop(this.dataInfo)
        }
        const unique = (arr, attrName) => {
          const res = new Map()
          return arr.filter(
            (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
          )
        }
        arr = node?.data?.children?.length ? node?.data?.children.concat(arr) : arr
        arr = unique(arr, 'idKey')
        arr.forEach(element => {
          if (element.user_id) {
            element.leaf = true
          }
        });
        resolve(arr)
        this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
      } else {
        resolve([])
      }
    }
  },
  created() {
    if (+process.env.VUE_APP_IS_JGSC === 1) {
      this.isJgsc = true
    }
  },
  mounted () {
    if (this.isJgsc) {
      this.dataList = []
      if (this.dataInfo?.length) {
        this.dataInfo.forEach(element => {
          const obj = JSON.parse(JSON.stringify(element))
          obj.children = []
          this.dataList.push(obj)
        })
      }
    } else {
      this.dataList = this.dataInfo
      this.showTreeInfo(this.showList)
    }
  }
}
</script>
<style lang="less" scoped>
/* 修改select组件样式 */
.new-select-style {
  width: 100%;
  height: 36px;
  :deep(.el-input__suffix)  {
    display: none !important;
  }
  :deep(.el-input__inner)  {
    display: none !important;
  }
  :deep(.el-input__prefix)  {
    width: 100% !important;
    display: inherit !important;
    height: 100% !important;
    position: inherit !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }
  :deep(.el-input--suffix)  {
    width: 100% !important;
    display: inherit !important;
    height: 100% !important;
    position: inherit !important;
    box-sizing: border-box !important;
  }
}
.show-value {
  height: 100%;
  line-height: 36px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.choose-button {
  width: 100%;
  height: 32px;
  background-color: #E8F0FE;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 4px;
  color: #121619;
  line-height: 32px;
  font-weight: 550;
  cursor: pointer;
}
.content-show-new {
  width:100%; 
  height: 28px;
  line-height: 28px;
  display: flex; 
  justify-content: space-between;
  padding: 0 11px 0 20px;;
  box-sizing: border-box;
  .content-show-delete-new {
    width: 14px;
    height: 14px;
    line-height: 14px;
    margin-top: 7px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    background-color: #C1C7CD;
    color: #fff;
  }
}
.pover {
  width: 428px;
  height: 100%;
  font-size: 14px;
  .pover-content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    .content-left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-left-search {
        height: 50px;
        padding: 5px 10px;
        box-sizing: border-box;
      }
      .content-left-tree {
        padding: 0 10px;
        flex: 1;
        overflow-y: auto;
				overflow-x: hidden;
      }
    }
    .content-right {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-right-top {
        height: 50px;
        line-height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        .content-right-top-number {
          color: #0076ff;
        }
      }
      .content-right-choose {
        flex: 1;
        padding: 0 10px;
        overflow-y: auto;
        .content-show {
          width: 100%;
          height: 50px;
          display: flex;
          .content-show-img {
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            padding: 6px;
            .content-show-seat {
              background: #eee;
              width: 100%;
              height:100%;
              border-radius: 4px;
              overflow: hidden;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
              overflow: hidden;
            }
          }
          .content-show-name {
            flex: 1;
            height: 50px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            p {
              height: 25px;
              line-height: 25px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:nth-child(2) {
              font-size: 12px;
              color: #999;
            }
          }
          .content-show-delete {
            width: 10%;
            height: 50px;
            line-height: 50px;
            text-align: center;
          }
        }
      }
    }
  }
}
//中控台样式
.console-user-style{
	background-color: transparent;
	:deep(.el-input__prefix)  {
		color:inherit!important;
		left:0px!important;
		padding:0px!important;
		.show-value{
			color:inherit!important;
			width: 100%!important;
			text-align: left!important;
			background-color: transparent;
		}
	}
}
</style>
