<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-06-01 17:32:45
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-06-20 14:05:38
 * @FilePath: \dataview-next\src\custom-component\drawing\ConceptualDrawing.vue
 * @Description: 方案图组件
-->
<template>
  <div class="conceptual-drawing-wrap" @contextmenu.stop="">
		<div v-if="needRenderFocusIcon" class="select-component-icon">
			<i class="iconfont iconpage-layer-20" />
		</div>
		<div class="inner" ref="wrap">

		</div>
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getComponentById, getLinkByTemplate, doEEActionHandle, doFrontOperation, initParams } from '@/utils/tools';
import { mapState } from 'vuex';
import '@/libs/BIMCCGraph.css'
import G from '@/libs/BIMCCGraph.umd.js'
import chartRequest from '@/libs/dataRequest'
export default {
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => { },
      required: true
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      graph: null,
			needRenderFocusIcon: true
    }
  },
  mounted() {
		//监听行为
		const doComponentBehavior = {
			[this.element.id]: config => {
				const { component, list = [] } = config;
				if (this.element.id !== component) return false;
				list.forEach(ele => {
					const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, false, this.componentData, []);
					if (canPost) {
						// 调用行为方法
						behaviors.forEach(funName => {
							try {
								eval(this[funName])(param)
							} catch (err) {
								console.log(err);
							}
						});
					}
				});
			}
		}
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id])
    this.init()
  },
	unmounted() {
		eventBus.$off('doComponentBehavior', this.element.id);
	},
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    statusConfig() {
      return this.element.statusConfig || {};
    },
    actionConfig() {
      return this.element.actionConfig || {}
    }
  },
  methods: {
    init() {
      this.graph = new G.Graph(this.$refs.wrap, {
        nodeFilter: ['子图'],
        nodePathFilter: ['math', 'logic', 'debug', 'basic'],
      });
			// 获取原始数据
      this.graph.setState(this.getGData());
      this.graph.addMenu('外部添加', (...arg) => {
        console.log(arg);
      });
      if(this.$route) {
        if(this.$route.name !== 'ModifyPage') {
					// 只读模式
          this.graph.setViewerMode();
					// 不再显示聚焦按钮
					this.needRenderFocusIcon = false;
        }
      }
			// 设置绘制背景
			this.graph.setDrawBackground(this.renderBackground)
			// 设置图标请求
      this.graph.setChartRequest(this.getChartOption);
			// 设置数据更改
      this.graph.setOnAfterChange(this.onAfterChange);
			// 设置节点点击回调
      this.graph.setOnNodeClick(this.onNodeClick);
    },
  /**
   * @description: 改变方案图store值
   * @param {*} obj
   * @return {*}
   */		
		changeStore(obj) {
			if(this.graph && obj && typeof obj === 'object') {
				for (const key in obj) {
					if (Object.hasOwnProperty.call(obj, key)) {
						const element = obj[key];
						this.graph.setStore(key, element)
					}
				}
			}
		},
  /**
   * @description: 通过event_name 改变节点颜色
   * @param {*} obj
   * @return {*}
   */		
		changeNodeStyle(obj) {
			if(this.graph && obj && typeof obj === 'object') {
				for (const key in obj) {
					if (Object.hasOwnProperty.call(obj, key)) {
						const element = obj[key];
						this.graph.setNodeStyle('event_name', key, element)
					}
				}
			}
		},
  /**
   * @description: 绘制背景
   * @param {*} ctx
   * @param {*} area
   * @return {*}
   */		
		renderBackground(ctx, area) {
			ctx.save();
			ctx.fillStyle = this.statusConfig?.background ?? 'rgba(0, 0, 0, .2)';
			ctx.fillRect(...area);
			ctx.restore();
			if(this.statusConfig?.backgroundImage) {
				if(this.imagePattern) {
					ctx.save();
					ctx.fillStyle = this.imagePattern;
					ctx.fillRect(...area);
					ctx.restore();
				} else if(this.isloadedImagePattern) {
					return;
				} else {
					this.isloadedImagePattern = true;
					// 不转可能会导致画布污染
					// const image = new Image();
					// image.src = this.statusConfig?.backgroundImage;
					// image.onload = () => {
					// 	try{
					// 		this.imagePattern = ctx.createPattern(image, "repeat");
					// 	} catch(err) {
					// 		console.error(err)
					// 	}
					// }
					// 转了部分地址无法正常绘制
					fetch(this.statusConfig?.backgroundImage).then(res => res.blob()).then(blob => {
						const reader = new FileReader();
						reader.onload = () => {
							if(reader.result && typeof reader.result === 'string') {
								const image = new Image()
								image.src = reader.result
								image.onload = () => {
									this.imagePattern = ctx.createPattern(image, "repeat");
								}
							}
						}
						reader.onerror = () => {};
						reader.readAsDataURL(blob);
					}).catch((err) => {
						console.log(err)
					})
				}
			}
		},
    /**
     * @description: 获取图标配置
     * @param {*} id
     * @return {*}
     */		
    getChartOption(id) {
      return chartRequest(id);
    },
    /**
     * @description: 数据变更回调
     * @param {*} info
     * @param {*} data
     * @return {*}
     */		
    onAfterChange(info, data) {
      const eventKeys = this.getEventsKey(data)
      const actionConfig = {}

      // 重新定义事件
      eventKeys.forEach(el => {
        if(Object.hasOwnProperty.call(this.actionConfig, el)) {
          Reflect.set(actionConfig, el, this.actionConfig[el])
        } else {
          Reflect.set(actionConfig, el, {
            dependLogic: [],
            type: el,
            name: el,
            eventList: [],
            permission: [], // 权限
            archiLimit: [], // 架构限制
            order: 3
          })
        }
      })
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          actionConfig,
          fullData: data,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
    },
    /**
     * @description: 获取事件key值
     * @param {*} data
     * @return {*}
     */		
    getEventsKey(data) {
      const s = new Set()
      if(data && data.nodes) {
        data.nodes.forEach(el => {
          if(el.properties && el.properties.event_name) {
            s.add(el.properties.event_name)
          }
          if(Array.isArray(el.widgets) && el.widgets.length) {
            el.widgets.forEach(ele => {
              if(ele.options && ele.options.event_name) {
                s.add(el.properties.event_name)
              }
            })
          }
        })
      }
      return Array.from(s)
    },
    /**
     * @description: 获取数据
     * @return {*}
     */		
    getGData() {
      return this.element?.fullData ?? {};
    },
    /**
     * @description: 节点点击
     * @param {*} node
     * @param {*} data
     * @return {*}
     */		
    onNodeClick(node, data) {
      if(data && data.options && data.options.event_name) {
        this.onAction(data.options.event_name, data.options, null);
      } else if (node && node.properties && node.properties.event_name) {
        this.onAction(node.properties.event_name, node.properties, null);
      }
    },
    /**
		 * @desc: 操作
		 * @param {String} action 操作类型
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output) {
			this.element.resolveData = output;
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: output
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData: output
			});
			// 关闭所有弹窗及抽屉
			this.removeModel();
			// 事件
			const actionKey = 'actionConfig'
			const comEvents = this.element?.[actionKey]?.[action]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.doActionItem(comEvents[i], output);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.doActionItem(ele, output);
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
			return false;
		},
		async doActionItem(ele, output) {
			console.log('事件触发， --------', ele, output);
			const { frontOperation = [] } = ele;
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
				canNext = await doFrontOperation(frontOperation, {
					isGroup: this.isGroup,
					componentList: this.subComponentData,
					componentData: this.subComponentData,
					groupComponents: this.groupComponents
				});
			}
			// console.log(canNext, '----canNext-----2222')
			if (!canNext) {
				console.log('操作失败!');
				this.$message.error('操作失败！');
				return false;
			}
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.subComponentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(
								query,
								queryItem.key,
								component.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						// 基座设置
						// if (ele.routePath) {
						// 	this.$router.replace({
						// 		path: ele.routePath,
						// 		query
						// 	});
						// }
						return;
					} else if (ele.linkType === 'outPage') {
						window.open(ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate, output)
						window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
				// 触发后端事件
					// console.log(ele, '0000001111111111', output);
					this.doEEAction(ele, output);
					// 搜集参数
				} else {
					// 页面事件
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
			// 组件行为
			const { behaviors = [] } = ele;
			behaviors.forEach(behavior => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element: this.element,
					EDITOR_pageUUID: this.EDITOR_pageUUID
				});
			});
			// 导出事件(只能导出当前行的数据)
			if (ele.actionType === 'export') {
				this.doExport(ele, output);
			}
		},
		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig, rowData, type = 'main') {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const idUUID = type === 'main' ? this.getIdUUID() : this.statusConfig.nestingRowKey;
				const data_id = rowData?.[idUUID];
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: objectUUID,
					view_uuid: viewUUID,
					data_id,
					event: eventName
				}).then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
						this.onPage(this.pager.current_page);
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err, '00000后端事件错误');
					this.loading = false;
				});
			}
		},
		/**
		 * @desc: 移除所有显示弹窗
		 */
		removeModel() {
			if (!this.actionConfig?.detail) return;
			const { detail } = this.actionConfig;
			if (!detail?.eventList?.length) return;
			const comEvents = detail?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === 'click') {
						comEvents[i].effects.forEach((effect = {}) => {
							const { targets = [] } = effect
							if (!targets.find(ele => ele.includes('CommonDialog'))) {
								// 非弹窗不执行
								return false;
							}
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect,
									actions: ['hidden']
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					if (ele.key === 'click') {
						ele.effects.forEach((effect) => {
							const { targets = [] } = effect;
							if (!targets.find(ele => ele.includes('CommonDialog'))) {
								// 非弹窗不执行
								return false;
							}
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
									...effect,
									actions: ['hidden']
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
				});
			}
		}
  }
}
</script>
<style lang="less" scoped>
  .conceptual-drawing-wrap {
    width: 100%;
    height: 100%;
		overflow: hidden;
		position: relative;
		.select-component-icon{
			position: absolute;
			top: 2px;
			left: 2px;
		}
		.inner{
			width: 100%;
			height: 100%;
			font-size: 16px;
			overflow: hidden;
		}
  }
</style>