<!--
 * @Description: 走马灯/swiper
 * @Author: luocheng
 * @Date: 2021-09-28 15:09:27
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-17 14:34:52
-->
<template>
  <el-carousel class="common-swiper"
    v-loading="loading"
    :height="isNaN(statusConfig.height) ? statusConfig.height : statusConfig.height +'px'"
    :interval="statusConfig.interval || 3000"
    :arrow="statusConfig.arrow"
    :initial-index="statusConfig.initialIndex || 0"
    :trigger="statusConfig.trigger"
    :autoplay="statusConfig.autoplay"
    :indicator-position="statusConfig.indicatorPosition"
    :type="statusConfig.type"
    :loop="statusConfig.loop"
    :direction="statusConfig.direction || 'horizontal'"
  >
    <el-carousel-item v-for="(item, index) in computedSwipeOptions" :key="index">
      <template v-if="Array.isArray(item)">
        <div class="swiper-wrap">
          <div class="swiper-item" v-for="(el, sIndex) in item" :key="sIndex">
            <img v-if="!statusConfig.preview" :src="el.img || defaultImg" :alt="el.text || ''" @click="onItem(el, index)">
            <el-image
              v-else
              style="height: 100%"
              :src="el.img || defaultImg"
              :preview-src-list="[el.img || defaultImg]"
            >
            </el-image>
            <p class="desc" v-if="statusConfig.showText && el.text" @click="onItem(el, index)">{{ el.text || ''}}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <img :src="item.img || defaultImg" :alt="item.text || ''" @click="onItem(item, index)">
        <p class="desc" v-if="statusConfig.showText && item.text" @click="onItem(item, index)">{{ item.text || ''}}</p>
      </template>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui';
import { dataInterface } from '@/apis/data';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { Image } from 'element-ui'
import eventBus from '@/plugins/eventBus';
import { removeEventBus } from '@/utils/tools';

export default {
  name: 'CommonSwiper',
  mixins: [CustomComponentMixin],
  components: {
    'el-carousel': Carousel,
    'el-carousel-item': CarouselItem,
    'el-image': Image
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    scaleWidth: {
      type: Number,
			default: 1
    },
		scaleHeight: {
			type: Number,
			default: 1
		}
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      loading: false,
      swiperOptions: [],
      defaultImg: require('@/assets/images/defaultImage.png')
    }
  },
  computed: {
    statusConfig() {
      return this.element && this.element.statusConfig;
    },
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    },
    swiperConfig() {
      return this.element?.swiperConfig;
    },
    carouselHeight() {
      const { height = '100%' } = this.statusConfig;
      return isNaN(height) ? height : `${this.scaleHeightSize(height)}px`
    },
    // 渲染所需的轮播图数据
    computedSwipeOptions() {
      const data = this.swiperOptions;
      let limit = Number(this.statusConfig.limit || 1) || 1;
      if(limit === 1) {
        return data;
      }
      // 进行拆分
      const RES = [];
      let temp = [];
      let current = 0;
      for (let i = 0; i < data.length; i++) {
        const index = Math.floor(i / limit);
        if(index !== current) {
          RES.push([...temp]);
          current = index;
          temp = [];
        }
        temp.push(data[i]);
        if(index === current && i === data.length - 1) {
          RES.push([...temp]);
        }
      }
      return RES;
    },
    useAllImages() {
      return !!this?.statusConfig?.useAllImages
    }
  },
  watch: {
    swiperConfig: {
      handler() {
        this.swiperOptions = [];
        this.resetSwiper();
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: data => {
        if (this.swiperConfig?.valueType === 'fixed') return;
        for (let i = 0; i < this.paramsConfig.length; i++) {
          const { originType, componentId } = this.paramsConfig[i];
          if (originType === 'component' && componentId === data.componentId) {
              this.getDynamic(this.swiperConfig.databaseConfig);
          }
        }
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
    scaleHeightSize(num) {
      if (isNaN(+num)) {
        return num;
      }
			// const ratio = window.devicePixelRatio;
      const ratio = 1;
      return ((+num) * this.scaleHeight * ratio).toFixed(2);
    },
    /**
     * @desc: 重置轮播图取值配置
     */
    resetSwiper() {
      if (!this.swiperConfig) {
        return false;
      }
      const { valueType, fixedConfig, databaseConfig } = this.swiperConfig;
      if (valueType === 'fixed') {
        // 固定
        this.formatFixed(fixedConfig);
      } else if (valueType === 'database') {
        // 动态取值
        if(databaseConfig.sourceType === 'database' || !databaseConfig.sourceType) {
          this.getDynamic(databaseConfig);
        } else if (databaseConfig.sourceType === 'interface') {
          this.getInterface(databaseConfig)
        }
      }
    },
    /**
     * @desc: 固定配置
     */
    formatFixed(fixedConfig) {
      if (!fixedConfig || !Array.isArray(fixedConfig)) {
         return false
      }
      this.swiperOptions = fixedConfig || [];
    },
    /**
     * @desc: 动态取值配置
     */
    getDynamic(config = {}) {
      const {
        objectUUID,
        viewUUID,
        imgUUID,
        textUUID,
        hrefUUID,
        hrefTypeUUID
      } = config;
      if (!objectUUID || !viewUUID) {
        this.$message.warning('请确认配置项完整！');
        return;
      }
      const { search = [], param = {}, canPost } = this.initParams(this.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
      if (!canPost) return false;
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: objectUUID,
        view_uuid: viewUUID,
        ...param,
        search,
      }).then(res => {
        let result = null;
        if (Array.isArray(res?.data?.data)) {
          result = res?.data?.data
        } else {
          result = res?.data?.data?.data || []
        }
        this.swiperOptions = []
        result.map(ele => {
          // 兼容多张图类型
          let img = ele?.[imgUUID];
          if (Array.isArray(img)) {
            if(this.useAllImages) {
              // 将上传内容中的所有图片放入轮播图 @何昌恩 甘肃建投
              img.forEach(elee => {
                const img = elee?.url || elee?.path || '';
                this.swiperOptions.push({
                  img,
                  text: ele?.[textUUID],
                  href: ele?.[hrefUUID],
                  hrefTypeUUID: ele?.[hrefTypeUUID]
                })
              })
            } else {
              img = img?.[0]?.url || img?.[0]?.path || '';
              this.swiperOptions.push({
                img,
                text: ele?.[textUUID],
                href: ele?.[hrefUUID],
                hrefTypeUUID: ele?.[hrefTypeUUID]
              })
            }
          } else {
            this.swiperOptions.push({
              img,
              text: ele?.[textUUID],
              href: ele?.[hrefUUID],
              hrefTypeUUID: ele?.[hrefTypeUUID]
            })
          }
        });
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    },
    /**
     * @description: 获取常规接口数据
     * @param {*} config
     * @return {*}
     */    
    getInterface(config = {}) {
      const {
        search = [],
        param = {},
        canPost
      } = this.initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents
      );
      if (!canPost) {
        console.log('无法请求')
        return false;
      }
      this.loading = true;
      const {
        url,
        imgUUID,
        textUUID,
        hrefUUID,
        hrefTypeUUID
      } = config;
      dataInterface({
        search,
        ...param
      }, `/api${url}`)
        .then(res => {
          let result = null;
        if (Array.isArray(res?.data?.data)) {
          result = res?.data?.data
        } else {
          result = res?.data?.data?.data || []
        }
        this.swiperOptions = result.map(ele => {
          // 兼容多张图类型
           let img = ele?.[imgUUID];
          if (Array.isArray(img)) {
            img = img?.[0]?.url || img?.[0]?.path || '';
          }
          return {
            img,
            text: ele?.[textUUID],
            href: ele?.[hrefUUID],
            hrefTypeUUID: ele?.[hrefTypeUUID]
          }
        });
        this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    /**
     * @desc: 点击
     * @param {Object} item
     * @param {Number} index
     */
    onItem(item, index) {
      if (!this.statusConfig.clickLink) return;
      console.log(index, '----index0000-----');
      const { href, hrefType } = item;
      const { path } = this.$route;
      if (path === '/modify-page' || path === '/page-view') {
        this.$message.info(`跳转${href}---方式${hrefType}`)
        return;
      }
      if (hrefType === 'link') {
        window.open(href);
      } else if (hrefType === 'route') {
        this.$router.push({
          path: href
        });
      }
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      // EDITOR_loopResolve 为循环容器特有
      removeEventBus(['databaseTrigger'], this.element.id);
    }
  },
}
</script>

<style lang="less" scoped>
.common-swiper{
  width: 100%;
  overflow: hidden;
  background: transparent;
  .el-carousel__item{
    background: transparent;
    // TIPS 单项时 z-index 不影响使用
    &.is-active{
      z-index: 0!important;
    }
    // 多项时将z-index 覆盖为2
    &.is-in-stage.is-active{
      z-index: 2 !important;
    }
    img{
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: cover;
    }
    .desc{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: 100%;
      background: rgba(0, 0, 0, .6);
      font-size: 14px;
      line-height: 20px;
      box-sizing: border-box;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .swiper-wrap{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      .swiper-item{
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
         img{
          display: block;
          margin: 0 auto;
          height: 100%;
          object-fit: cover;
        }
        .desc{
          position: absolute;
          bottom: 0;
          left: 0;
          height: 20px;
          width: 100%;
          background: rgba(0, 0, 0, .6);
          font-size: 14px;
          line-height: 20px;
          box-sizing: border-box;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }
    }
  }
}
</style>