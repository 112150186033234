/*
 * @Date: 2022-12-08 16:52:22
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-04-25 16:10:26
 * @FilePath: /dataview-viewer-test/src/custom-component/mixins/BehaviorHandlerMixin.js
 */
import eventBus from '@/plugins/eventBus';
import { initParams, removeEventBus } from '@/utils/tools';

export default {
	props: {
		element: {
			type: Object
		}
	},
	data() {
		return {
			behaviorHandler: {},
		}
	},
  methods : {
        //注册行为
        registerBehaviors( list ){
            for(let {label , func , value , desc } of list){
                if(!this.behaviorHandler[value]){
                    this.element.behaviors.push({
                        label,value,desc
                    });
                }

				this.behaviorHandler[value] = func;
			}
		},
	},
	mounted() {
		const componentBehavior = {
			[this.element.id]: ({ component, list }) => {
				if (component === this.element.id) {
				for (let { behaviors, params } of list) {
						for (let bid of behaviors) {
							if (this.behaviorHandler[bid]) {
								const { param = {} } = initParams(params, this.isGroup, this.subComponentData, this.groupComponents);
	
								this.behaviorHandler[bid](param);
							}
						}
					}
				}
			}
		}
		eventBus.$on("doComponentBehavior", componentBehavior[this.element.id]);
	},
	beforeDestroy() {
		removeEventBus(['doComponentBehavior'], this.element.id);
	}
}
