<!--
 * @Date: 2022-08-22 11:28:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-24 16:24:55
 * @FilePath: /dataview-viewer-test/src/custom-component/UEClient/UEClient.vue
-->
<template>
    <article >
    </article>
</template>
<script>
    import { UEClient }  from './UEClient';
    import { mapState } from 'vuex';
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    let client = null;
    export default {
        data () {
            return {
                
            }
        },
        mixins : [CustomComponentMixin , BehaviorHandlerMixin],
        computed: {
            ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
        },
        methods : {
            /**
             * 初始化UE事件
             */
            init(){
                client = new UEClient();
                this.addResolveList('EquipmentData','选中设备数据');
                this.registerActionConfig('Equipment' , '设备消息');

                this.addResolveList('ContrlStateData','状态控制数据');
                this.registerActionConfig('ContrlState' , '状态控制');

                this.emitUEEvent({
                    id : "ClientInined"
                });
                // 方便实施配置取值，后续在此添加
                this.element.metadata = {
                    System: 'System',
                    Build: 'Build',
                    Floor: 'Floor'
                };
            },  
            initEventListener(){
                this.element.behaviors = [];
                //注册行为接受外部传入
                this.registerBehaviors([{
                    label : "触发UE事件",
                    desc : `
                    `,
                    value : "emitUEEvent",
                    func : ( params )=>{
                        if( params.value && !params.data){
                            params.data = JSON.stringify({ value : params.value});
                        }

                        this.emitUEEvent(params);
                    },
                }]);

                client.on('onUEAction',( res )=>{

                    switch(res.type){
                        case "OnSceneInited":
                            this.onSceneInited(res.data );
                            break;
                        case "Equipment":
                            this.setResolveData('EquipmentData' , res.data);
                            this.onAction('Equipment');
                            break;
                        // case "ContrlState":
                        //     this.setResolveData('ContrlStateData', res.data);
                        //     this.onAction('ContrlState');
                        //     break;
                    }
                    
                });

                client.on('ContrlState', (res)=>{
                    this.setResolveData('ContrlStateData', res.data);
                    this.onAction('ContrlState'); 

                    // 此处不需要进行重新赋值，实际是进行命名操作，已移动至init方法
                    // if(!this.element) this.element.metadata = {}

                    if(res.data){
                        for(let id in res.data){
                            this.setResolveData(id, res.data[id]);
                        }
                    }else{
                        for(let id in res){
                            this.setResolveData(id, res[id]);
                        }
                    }

                });
            },
            /**
             * UE场景初始完成
             */
            onSceneInited( config = {}){
                if(config.ActionConfig){
                    for(let { id , label } of config.ActionConfig){
                        this.registerActionConfig(id , label);
                    }
                }
            },
            /**
             * 触发UE事件
             * @param {*} params 
             */
            emitUEEvent( params ){
                if( !params.data ) params.data = "{}";
                client.sendMsg(params.id  , params.data );
            },
            //注册组件内事件
            registerActionConfig( id , label ){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(!element.actionConfig[id]){
                    element.actionConfig[id] = {
                        use: true,
                        useDepend: false,
                        dependLogic: [],
                        type: id,
                        name: label,
                        color: '#409EFF',
                        btnType: 'text',
                        btnSize: '',
                        margin: 5,
                        // eventList: [],
                        // permission: [], // 权限
                        // archiLimit: [], // 架构限制
                    }
                }
            },
            /**
             * 删除组件内事件
             * @param {} id
             */
            removeActionConfig(id){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(element.actionConfig[id]){
                    delete element.actionConfig[id]
                }
            },
            //触发行为
            onAction( id ){
                const ac = this.element.actionConfig;
                if(!ac[id]) return;

                const eventList = ac[id].eventList || [];

                for(let el of eventList){
                    const { pattern, eventList = [], specialEventList = []  } = el;
                    const resultList = pattern === 'special' ? specialEventList : eventList;

                    for(let ele of resultList){
                        const { effects , behaviors } = ele;
                        effects.forEach((effect) => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    ...ele,
                                ...effect
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });

                        behaviors.forEach(behavior => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    behavior,
                                    isBehavior: true
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });
                    }
                }

            },
            //设置组件暴露数据
            setResolveData( id , data){
                this.element.resolveData[id] = data;
            },
            //添加到组件暴露数据的列表
            addResolveList( uuid , name){
                this.element.UEResolveList.push({
                    uuid,
                    name,
                });
            },
        },
        mounted (){
            this.element.resolveData = {};
            this.element.UEResolveList = []
            this.init();
            this.initEventListener();

            console.log('UE事件中心注册完毕---------------------------------------------')
        }
    }
</script>
<style scoped>
    .bimviewer_main{
        height: 100%;
        width: 100%;
    }
    .mask{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 999;
    }
</style>
