<!--
 * @Description: 年选择器
 * @Author: luocheng
 * @Date: 2022-08-01 10:25:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-09 14:43:01
-->
<template>
  <div class="common-date calendar-year">
    <div class="content">
      <ul class="date-list" v-if="showDateList && showDateList.length">
        <li class="date-item month-item" v-for="(item, index) in showDateList" :key="index"
          :class="{
            'is-today': item.isTarget,
            'is-active': currenYearObj && currenYearObj.year === item.year
          }"
          @click="onYear(item)"
        >
          <!-- 基础类型 -->
          <div class="base-item" v-if="calendarType === 'base' || item.type !== 'target'">
            {{ item.isTarget ? '今年' : item.year }}
          </div>
          <!-- 符合类型 -->
          <div class="with-data-item" v-else-if="calendarType === 'withDatabase' || calendarType === 'justTotal'">
            <p class="date-text">
              {{ item.isTarget ? '今年' : item.year }}
            </p>
            <p class="count-text">
              <span class="finish-count"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !(+item.finishCount)
                }"
              >{{ item.finishCount }}</span>
              <template v-if="calendarType === 'withDatabase'">
                <span class="separate" :class="{
                  'null-count': (!(+item.totalCount)) && (!(+item.finishCount))
                }">/</span>
                <span class="total-count" :class="{
                  'null-count': !(+item.totalCount)
                }">{{ item.totalCount }}</span>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'CalendarYear',
  mixins: [mixin],
  data() {
    return {
      yearList: [],
      currenYearObj: null
    }
  },
  computed: {
    startYear() {
      return this.statusConfig?.startYear || 2008;
    }
  },
  created() {
    this.initDate(true);
  },
  methods: {
    /**
     * @desc: 初始化
     * @param {Boolean} isInit
     */
    initDate(isInit) {
      this.yearList = [];
      const date = new Date();
      const year = date.getFullYear();
      if (isInit) {
        const month = this.addZero(date.getMonth() + 1);
        this.currenYearObj = {
          year,
          month,
          date: this.addZero(date.getDate()),
          isTarget: true,
          result: [`${year}-01-01 00:00:00`, `${year}-12-31 23:59:59`]
        };
      }
      for (let i = this.startYear; i < year + 20; i++) {
        this.yearList.push({
          year: i,
          month: null,
          date: null,
          isTarget: this.currenYearObj?.year === i,
          result: [`${i}-01-01 00:00:00`, `${i}-12-31 23:59:59`]
        });
      }
    },
    /**
     * @desc: 年份
     * @param {Object} year
     */
    onYear(yearObj) {
      this.currenYearObj = yearObj;
      this.$emit('setDate', yearObj.result);
    }
  }
}
</script>

<style lang="less" scoped>
@import "./common.less";
</style>