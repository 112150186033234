import eventBus from '@/plugins/eventBus';

export default class MarkerEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        viewer.on(viewer.EventType.templateMarkerClicked, (key) => {
            eventBus.$emit('ViewerGISMarker.onTemplateMarkerClick', comp.element.id, key, (data) => {
                comp.setResolveData('gis_templateMarker_id' , data.id.split('_')[1]);
                comp.setResolveData('gis_templateMarker_name' , data.name);
                comp.setResolveData('gis_templateMarker_groupId' , data.group_id.split('_')[1]);
                comp.setResolveData('gis_templateMarker_archiId' , data.archi_id);
                comp.setResolveData('gis_templateMarker_archiType' , data.archi_type);
                comp.setResolveData('gis_templateMarker_createdAt' , data.created_at);
                comp.onAction('onTemplateMarkerClick');
            });
        });

        return {
            'ViewerGIS.createTemplateMarker': (eid, key, data, isShow = true) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.createTemplateMarker(key, data, isShow);
            },
            'ViewerGIS.updateTemplateMarker': (eid, key, data) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.updateTemplateMarker(key, data);
            },
            'ViewerGIS.removeAllTemplateMarker': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.removeAllTemplateMarker();
            },
            'ViewerGIS.removeTemplateMarker': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.removeTemplateMarker(key);
            },
            'ViewerGIS.showTemplateMarker': (eid, key, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.showTemplateMarker(key, isShow);
            },
            'ViewerGIS.locationTemplateMarker': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.locationTemplateMarker(key);
            },
            'ViewerGIS.locationTemplateMarkerGroup': (eid, keys) => {
                if (eid !== comp.element.id) return;

                viewer.Marker.locationTemplateMarkerGroup(keys);
            },
        };
    }
}
