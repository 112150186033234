<!--
 * @Description: 常规页面配置
 * @Author: luocheng
 * @Date: 2021-08-17 16:59:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-05-29 15:46:21
-->
<template>
	<div class="normal normalpage-container" :style="{
		...canvasStyle,
		padding: pagePadding,
		scale: 1
	}">
		<ComponentBox
			v-for="element in customComponentList"
			:key="element.id"
			:element="element"
			:isPreview="true"
			@click.native.stop="onClickBox(element)"
			@dblclick.native.stop="onDblClickBox(element)"
			:defaultTableSelectData="defaultTableSelectData"
			:componentList="customComponentList"
		></ComponentBox>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { judgingEventLogic, getStatusVal, getComponentById } from '@/utils/tools';

export default {
	name: 'NormalPage',
	props: {
		// 内嵌渲染页配置数据
		insidePageData: {
			required: false,
			default: null
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
		canvasStyle: {
			type: Object,
			default: () => {}
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			componentList: null,
			// 单双击事件
			timer: null
		}
	},
	computed: {
		...mapState([
			'componentData', // 图层列表
			'sContainer',
			'originComponentData', // 大屏容器组件的元组件
			'_PageCustomStatus',
			'_APPCustomStatus',
			'subsidiaryComponentData'
		]),
		customComponentList() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || [];
			}
			return this.componentData || [];
		},
		// 页面内边距
		pagePadding() {
			if (!this.canvasStyle) return '10px';
			const { defaultPadding = undefined } = this.canvasStyle;
			if (defaultPadding || defaultPadding === undefined) {
				return '10px';
			}
			return '0';
		}
	},
	methods: {
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(item) {
			console.log('触发点击事件-----触发点击事件')
			if (
				!item.events ||
				!item.events.click ||
				!Array.isArray(item.events.click.effects) ||
				item.component === 'CommonDateButton'
			) {
				return false
			}
			const click = item.events.click || {};
			if (this.timer) {
				clearTimeout(this.timer);
			}
			if (item.events.dblclick) {
				this.timer = setTimeout(() => {
					this._triggerEvent(click, item);
				}, 300);
			} else {
				this._triggerEvent(click, item);
			}
		},
		/**
		 * @description: 双击事件
		 * @param {Object} item 点击的组件
		 */
		onDblClickBox(item) {
			if (
				!item.events ||
				!item.events.dblclick ||
				!Array.isArray(item.events.dblclick.effects) ||
				item.component === 'CommonDateButton'
			) {
				return false
			}
			const dblclick = item.events.dblclick || {};
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this._triggerEvent(dblclick, item);
		},
		/**
		 * @description: 触发事件
		 * @param {Object} eventConfig 事件配置
		 * @param {Object} element 点击元素
		 */
		_triggerEvent(eventConfig, element) {
			const { pattern, effects = [], specialEventList = []} = eventConfig;
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = eventConfig;
				if (actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(eventConfig.stateMachine || []);
				} else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element
						});
					});
				}
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType, linkType, queryList = [], linkUrl = '', pageId } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(eventConfig.stateMachine || []);
					} else if (actionType === 'jumpPage') {
						if (linkType === 'projectPage') {
							const query = {};
							queryList.forEach((queryItem) => {
								let component = getComponentById(this.componentData, queryItem.componentId);
								this.$set(
									query,
									queryItem.key,
									component?.resolveData[queryItem.feild]
								);
							});
							this.$router.push({
								path: pageId,
								query
							});
							return;
						} else if (linkType === 'outPage') {
							window.open(linkUrl);
						} else if (linkType === 'custom') {
							// 暂未处理
							// const customLink = getLinkByTemplate(linkTemplate, output)
							// window.open(customLink);
						}
					} else if (actionType === 'eeAction') {
						// 触发后端事件 暂未处理
						// console.log(ele, '0000001111111111', output);
						// this.doEEAction(element, output, type);
						// 搜集参数
					} else {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element
							});
						})
					}
				})
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentList;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		},
	}
};
</script>

<style lang="less" scoped>
.normal {
	height: 100%;
	width: 100%;
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	overflow: hidden;
	box-sizing: border-box;
	padding: 10px;
}
</style>