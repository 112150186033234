/* eslint-disable */
import eventBus from '@/plugins/eventBus';
import { formRequest } from "@/apis/data/form";
import { getSearchData,optionData } from "@/custom-component/form/newParser/scripts/tools";

export default {
	inject: ["parser"],
	created() {
		// this.recoverValue();
	},
	data () {
		return {
			loading: false
		}
	},
	methods: {
    triggerFlowBranch(){
      if(this.element.config.__config__?.flow_branch){
        // 值改变触发流程分支
        setTimeout(()=>{
          eventBus.$emit('TRIGGER_flow_branch')
        },100)
      }
    },
		recoverValue(){
			if (this.displayData) {
				const keys = Object.keys(this.displayData);
				if (keys.includes(this.element.field)) {
					this.element.value = this.displayData[this.element.field]
				}
			}
		},
		//触发交互事件
		async triggerInteractive(val,flag) {
      this.triggerFlowBranch() // 值改变触发流程分支
      this.$emit('change-calc')
      eventBus.$emit('TRIGGER_selectChange',this.element.config.__config__.formId)
			const tag = this.element.config.__config__.tagIcon;
			const interact = this.element.config.__config__?.interact;
			const effect = interact?.effect; // 交互效果 显示隐藏、级联
			const condition = interact?.condition;
			if (effect === 'showhide') {
				const trueData = condition.filter(item => {
					if(typeof item.condV === 'string' && item.condV.indexOf(',')){
						let arr = item.condV.split(',');
						return arr.includes(val) || arr.includes(val.toString())
					}
					return item.condV == val
				})
				const compList = Array.from(condition, item => item.comp).flat();
				let compShowHide = {};
				for (let item of compList){
					compShowHide[item] = false;
				}
				if (trueData.length) {
					for (let eleItem of trueData){
						let compShow = eleItem.comp;
						for (let item of compShow){
							compShowHide[item] = true;
						}
					}
				}
				this.$emit('trigger-active', compShowHide, flag);
			}
			if (effect === 'cascader') {
				let url, params, options = [];
				url = '/api/mapi';
				params = {
					__method_name__: 'dataList',
					object_uuid: interact.objectUuid,
					view_uuid: interact.viewUuid,
				};
				params[interact.param] = val;
				let response = await formRequest('post', url, params);
				let userData = response.data.data;
				let label = interact.label;
				let value = interact.value;
				if (userData instanceof Array) {
					for (let element of userData) {
						if (element[label] && element[value]) {
							let item = {label: element[label], value: element[value]};
							options.push(item);
						}
					}
				}
				let comp = Array.from(condition, item => item.comp).flat()
				this.$emit('trigger-active', comp, 'cascader', options)
			}
			if(effect === 'fill'){
				let comp = Array.from(condition, item => item.comp).flat()
				eventBus.$emit('auto-fill-relate',comp);
			}
			if (tag === 'select') {
				const dataType = this.element.config.__config__.dataType;
				const multi = this.element.config.multiple;
				const options = this.element.config.__slot__.options;
				const index = options.findIndex(item => item.value == val);
				const selectData = options[index]
				if (!selectData) return;
				const keys = Object.keys(selectData)
				if(keys.includes('allField')){
					this.outerViewerParam = selectData.allField
				}
				if (keys.includes('allField') && multi === false && dataType === 'dynamic') {
					this.$emit('trigger-active', [], 'selectFill', selectData.allField)
				}
			}
		},
		loadingDynamicData(){
			const filterRule = this.element.config.__config__.filterRule ?? []
			const objectUuid = this.element.config.__config__.objectUuid
			const viewUuid = this.element.config.__config__.viewUuid
			const dynamic = this.element.config.__config__.dataType

			if(this.element.type === 'select' && dynamic === 'static'){
				return false; //select 静态数据
			}

			let url= '/api/mapi', params;
			if(this.element.type === 'user'){
				params = {
					__method_name__: 'dataList', object_uuid: objectUuid,
					view_uuid: viewUuid, transcode: 0,
					__now_archi_type: this.parser.nowArchiType,
				}
			} else{
				let searchData = getSearchData(filterRule,this.parser.getFormIdValue);
				params = {
					__method_name__: 'dataList', object_uuid: objectUuid,
					view_uuid: viewUuid, search: searchData, size: 1000,
					__now_archi_type: this.parser.nowArchiType,
				}
				if(searchData.length) params.search = searchData
			}
			if(objectUuid && viewUuid){
				formRequest('post', url, params).then(res => {
					let respData;
					respData = res.data.data;
					if (res.data.data.data) {
						respData = res.data.data.data;
					}

					if(this.element.type === 'user'){
						const userData = {list:respData, type:this.element.config.__config__.dataType}
						this.element.config.__config__.userData = userData
						if(Object.keys(this.displayData).includes(this.element.field)){
							this.element.value = this.displayData[this.element.field];
						}
					} else if(this.element.type === 'select'){
						const tag = this.element.config.__config__.tagIcon;
						const propLabel = this.element.config.props.props.label;
						const propValue = this.element.config.props.props.value;
						const options = optionData(respData,tag,propLabel,propValue)
						if(options){
							this.element.config.__slot__.options = options
						}
					} else {
						const tag = this.element.config.__config__.tagIcon;
						const propLabel = this.element.config.props.props.label;
						const propValue = this.element.config.props.props.value;
						const propChild = this.element.config.props.props.children || [];
						const options = optionData(respData,tag,propLabel,propValue,propChild)
						if(options){
							this.element.config.options = options
						}
					}
					this.loading = false
				});
			}
		},
		async getDynamicData(item){
			const filterRule = item.config.__config__.filterRule ?? []
			const objectUuid = item.config.__config__.objectUuid
			const viewUuid = item.config.__config__.viewUuid

			let url= '/api/mapi', params;
			let searchData = getSearchData(filterRule,this.parser.getFormIdValue);
			params = {
				__method_name__: 'dataList', object_uuid: objectUuid,
				view_uuid: viewUuid, search: searchData, size: 1000,
				__now_archi_type: this.parser.nowArchiType,
			}
			if(searchData.length) params.search = searchData

			if(objectUuid && viewUuid){
				const res = await formRequest('post', url, params)
				let respData;
				respData = res.data.data;
				if (res.data.data.data) {
					respData = res.data.data.data;
				}

				if(item.type === 'user'){
					const userData = {list:respData, type:item.config.__config__.dataType}
					item.config.__config__.userData = userData
					if(Object.keys(this.displayData).includes(item.field)){
						item.value = this.displayData[item.field];
					}
				} else if(item.type === 'select'){
					const tag = item.config.__config__.tagIcon;
					const propLabel = item.config.props.props.label;
					const propValue = item.config.props.props.value;
					const options = optionData(respData,tag,propLabel,propValue)
					if(options){
						item.config.__slot__.options = options
					}
				} else {
					const tag = item.config.__config__.tagIcon;
					const propLabel = item.config.props.props.label;
					const propValue = item.config.props.props.value;
					const propChild = item.config.props.props.children || [];
					const options = optionData(respData,tag,propLabel,propValue,propChild)
					if(options){
						item.config.options = options
					}
				}
				return item
			}
		},
	}
}
