import { render, staticRenderFns } from "./QuesParser.vue?vue&type=template&id=4bd7a986&scoped=true&"
import script from "./QuesParser.vue?vue&type=script&lang=js&"
export * from "./QuesParser.vue?vue&type=script&lang=js&"
import style0 from "./QuesParser.vue?vue&type=style&index=0&id=4bd7a986&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd7a986",
  null
  
)

export default component.exports