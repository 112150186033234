<!--
 * @Description: 雷达图
 * @Author: luocheng
 * @Date: 2022-01-13 18:00:01
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-15 10:23:03
-->
<template>
  <div
    class="chart-radar"
    :style="{
      height: height + 'px'
    }"
    v-loading="getting"
    :element-loading-background="loadingBackColor"
  >
    <ChartContainer
      v-if="option && canShow"
      ref="chartInstance"
      class="chart-bar"
      :option="option"
    ></ChartContainer>
    <el-empty
      description="暂无数据"
      v-else
    ></el-empty>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'ChartRadar',
  mixins: [mixin],
  data() {
    return {
      option: null
    };
  },
  created() {
    this.getOptions();
  },
  methods: {
    /**
     * @desc: 配置图表
     */
    setOptions(data) {
      const { attributes = {} } = this.chartData;
      const { series = [], names = [] } = data[0];
      if (!series?.length) {
        this.canShow = false;
        return false;
      }
      const {
        showTitle = true,
        title = '',
        textColor = '',
        titleFontSize = 12,
        titleFontWeight = 400,
        showTooltip = true,
        showLegend = true,
        legendTextColor = '#000000',
        legenFontSize = 12,
        colorRange = [],
        legendIcon = 'circle',
        indicator = [],
        legendOrient = 'vertical',
        legendType = 'plain',
        titleTop = 'auto',
        titleBottom = 'auto',
        titleRight = 'auto',
        titleLeft = 'auto',
        left = '10%',
        right = '10%',
        top = 60,
        bottom = 60
      } = attributes;
      // 渲染的数据
      this.option = {
        title: {
          show: showTitle,
          text: title,
          left: 'left',
          y: 'top',
          textStyle: {
            color: textColor,
            fontFamily: '微软雅黑',
            fontSize: this.scaleSize(titleFontSize || 12),
            fontWeight: titleFontWeight || 400,
            left: titleLeft,
            top: titleTop,
            bottom: titleBottom,
            right: titleRight
          }
        },
        grid: {
          left,
          right,
          bottom,
          top,
          containLabel: true
        },
        tooltip: {
          show: showTooltip,
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          },
          appendToBody: true
        },
        legend: {
          show: showLegend,
          itemWidth: 10,
          itemHeight: 10,
          data: this.getLegendData(names, legendTextColor),
          icon: legendIcon,
          ...this.getLegendPosition(attributes),
          type: legendType,
          orient: legendOrient,
          textStyle: {
            fontSize: this.scaleSize(legenFontSize)
          }
        },
        color: colorRange || [],
        radar: {
          // shape: 'circle',
          indicator: indicator.map(ele => {
            return {
              name: ele.label,
              max: ele.value
            };
          })
        },
        series: [
          {
            name: title,
            type: 'radar',
            data: series.map(ele => {
              return {
                ...ele,
                value: ele.data
              };
            })
          }
        ]
      };
    },
    /**
     * @desc: 设置图例
     * @param {Array} names 名称
     * @param {String} color 文本颜色
     * @return {*}
     */
    getLegendData(names, color) {
      let s = [];
      if (!names) {
        return [];
      }
      for (let i = 0; i < names.length; i++) {
        s.push({
          name: names[i],
          textStyle: {
            color,
            // fontSize: this.scaleSize(12)
          }
        });
      }
      return s;
    }
  }
};
</script>

<style lang="less" scoped>
.chart-radar {
  height: 100%;
  width: 100%;
  :deep(.el-empty) {
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    .el-empty__image {
      width: 35%;
      max-width: 120px;
      min-width: 50px;
    }
    .el-empty__description {
      margin-top: 15px;
    }
    .page-container p {
      color: #fff;
    }
  }
}
</style>
