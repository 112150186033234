/*
 * @Description: 校验token
 * @Author: luocheng
 * @Date: 2021-09-13 11:55:24
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-30 15:46:08
 */
import { getToken } from '@/utils/tools';
import { Message } from 'element-ui';
import { dataInterface } from '@/apis/data';

/**
* @description: 校验token
* @return {*}
*/
export const validToken = (token) => {
 return  new Promise((resolve) => dataInterface({
    token,
    refresh: 1
  }, `${process.env.VUE_APP_BASE_URL}api/v4/user/refreshToken`).then(res => {
    resolve(res?.data?.data);
  }).catch(err => {
    console.log(err);
    Message({
      message: '请重新登录!',
      type: 'error'
    })
    sessionStorage.setItem('needReLogin', 1);
    //  this.$router.replace({
    //    name: 'Login'
    //  });
    resolve(null);
  }));
}

/**
 * @description: 根据token获取系统信息、用户信息、token等
 * @return {*}
 */
export const getInfoByToken = async () => {
  return new Promise((resolve) => {
    dataInterface({
      "__method_name__": "globalFunctionCall",
      "typeName": "LoginFuncClass",
      "type": "value",
      "funcName": "UserInfoByToken",
      "token": getToken(),
      transcode: 0
    }).then(res => {
      const { userRoles = '', userInfo = {}, targetArchi = {} } = res?.data?.data || {};
      localStorage.setItem("userRoles", userRoles || '');
      const targetArchiInfo = {
        ...targetArchi,
        archiId: targetArchi.archi_id,
        archiType: targetArchi.data_id.indexOf('companys') > -1 ? 'company' : 'project',
        id: targetArchi.data_id,
        company_id: targetArchi.data.company_id || '',
        modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false
      };
      // this.$store.commit('setTargetArchi', {targetArchi: targetArchiInfo, a: 2});
      localStorage.setItem('targetArchi', targetArchiInfo ? JSON.stringify(targetArchiInfo) : '');
      if (targetArchiInfo?.permissions !== undefined) {
        localStorage.setItem("userRoles", targetArchiInfo.permissions || '无效权限');
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo || {}));
      resolve(true);
    }).catch(err => {
      console.log(err, '---操作错误')
      resolve(false);
    })
  })
};