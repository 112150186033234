var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-parser screen-container",style:({
    backgroundColor: +_vm.isTransparent === 1 ? 'transparent' : _vm.editorConfig.backgroundColor
  })},[_vm._l((_vm.componentList),function(item,index){return [_c('AnimateCom',{key:item.id,attrs:{"animations":item.animations}},[((item.statusConfig && item.statusConfig.isShow)
						&& _vm.judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list)
						&& _vm.getUILogicStatus(item)
					)?_c('ShapeBox',{directives:[{name:"show",rawName:"v-show",value:(item.statusConfig
						&& (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)
					),expression:"item.statusConfig\n\t\t\t\t\t\t&& (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)\n\t\t\t\t\t"}],key:item.id,style:(_vm.getBoxStyle(item.style, item.specialStyle.fixedSize, false, item)),attrs:{"index":index,"defaultStyle":item.style || {},"element":item},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onClickBox(item)},"dblclick":function($event){$event.stopPropagation();return _vm.onDblClickBox(item)}}},[_c(item.component,{tag:"component",style:(_vm.getComStyle(item.style, item.specialStyle.fixedSize, item)),attrs:{"index":index,"element":item,"id":'component' + item.id,"propValue":item.propValue,"scaleHeight":+_vm.scaleHeight,"scaleWidth":+_vm.scaleWidth,"ratio":+_vm.ratio,"isPreview":true}})],1):_vm._e()],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }