<script>
import '@/assets/css/animate.css'
export default {
  name: 'AnimateCom',
  props: {
    animations: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data() {
    return {
      needRemoveClass: []
    }
  },
  computed: {
    /**
     * @description: 入场动画
     * @return {*}
     */    
    appearAnimations() {
      return this.animations.filter(el => el.type === 'appear')
    },
    /**
     * @description: 进场动画
     * @return {*}
     */    
    enterAnimations() {
      return this.animations.filter(el => el.type === 'enter')
    },
    /**
     * @description: 离场动画
     * @return {*}
     */    
    leaveAnimations() {
      return this.animations.filter(el => el.type === 'leave')
    }
  },
  methods: {
    /**
     * @description: 入场动画钩子
     * @param {*} el 元素
     * @param {*} done 回调钩子
     * @return {*}
     */    
    animateAppear(el, done) {
      if (Array.isArray(this.appearAnimations) && this.appearAnimations.length) {
        const animations = this.appearAnimations[0].animations
        if(Array.isArray(animations) && animations.length) {
          this.promiseDealTool(el, animations, done)
        }
      } else {
        done()
      }
    },
    /**
     * @description: 入场动画钩子取消
     * @param {*} el 元素
     * @return {*}
     */    
    animateAppearCanclelled(el) {
      this.removeAnimate(el)
    },
    /**
     * @description: 进入动画
     * @param {*} el 元素
     * @param {*} done 回调钩子
     * @return {*}
     */    
    animateEnter(el, done) {
      if (Array.isArray(this.enterAnimations) && this.enterAnimations.length) {
        const animations = this.enterAnimations[0].animations
        if(Array.isArray(animations) && animations.length) {
          this.promiseDealTool(el, animations, done)
        }
      } else {
        done()
      }
    },
    /**
     * @description: 进入动画取消
     * @param {*} el 元素
     * @return {*}
     */    
    animateEnterCancelled(el) {
      this.removeAnimate(el)
    },
    /**
     * @description: 离场动画
     * @param {*} el 元素
     * @param {*} done 回调钩子
     * @return {*}
     */    
    animateLeave(el, done) {
      if (Array.isArray(this.leaveAnimations) && this.leaveAnimations.length) {
        const animations = this.leaveAnimations[0].animations
        if(Array.isArray(animations) && animations.length) {
          this.promiseDealTool(el, animations, done)
        }
      } else {
        done()
      }
    },
    /**
     * @description: 离场动画取消
     * @param {*} el 元素
     * @return {*}
     */    
    animateLeaveCancelled(el) {
      this.removeAnimate(el)
    },
    /**
     * @description: 清空钩子动画的影响
     * @param {*} el 元素
     * @param {*} data class 数组
     * @return {*}
     */    
    removeAnimate(el) {
      el.classList.remove(...this.needRemoveClass);
      // 清理动画设置的相关属性，不清除会导致动画异常
      ['animation-duration', 'animation-delay', 'animation-iteration-count', 'animation-direction'].forEach(item => {
        el.style.removeProperty(item)
      })
    },
    /**
     * @description: 递归promise处理class添加与卸载
     * @param {*} el 元素
     * @param {*} arr class 数组
     * @return {*}
     */    
    promiseDealTool(el, arr, done) {
      // 哨兵
      let i = 0
      this.needRemoveClass = []
      const handle = () => {
        return new Promise(resolve => {
          const item = arr[i]
          const needRemoveProperty = []
          if(item.duration && Number(item.duration) > 0) {
            el.style.setProperty('animation-duration', `${item.duration}s`)
            needRemoveProperty.push('animation-duration')
          }
          if(item.delay && Number(item.delay) > 0) {
            el.style.setProperty('animation-delay', `${item.delay}s`)
            needRemoveProperty.push('animation-delay')
          }
          if(item.iterationCount) {
            if (item.iterationCount === 'infinite') {
              el.style.setProperty('animation-iteration-count', item.iterationCount)
              needRemoveProperty.push('animation-iteration-count')
            } else if (Number(item.iterationCount) > 0) {
              el.style.setProperty('animation-iteration-count', item.iterationCount)
              needRemoveProperty.push('animation-iteration-count')
            } else if (Number(item.iterationCount) < 0) {
              el.style.setProperty('animation-iteration-count', 'infinite')
              needRemoveProperty.push('animation-iteration-count')
            } 
          }
          if(item.direction === 'alternate') {
            el.style.setProperty('animation-direction', 'alternate')
            needRemoveProperty.push('animation-direction')
          }
          el.classList.add('animated', item.animate)
          this.needRemoveClass = ['animated', item.animate]
          el.onanimationend = () => {
            el.classList.remove('animated', item.animate)
            needRemoveProperty.forEach(item => {
              el.style.removeProperty(item)
            })
            if(i < arr.length - 1) {
              i ++
              resolve(handle())
            } else {
              resolve(done())
            }
          }
        })
      }
      handle()
    }
  },
  render(h) {
    // 改用render 优化没有动画的节点渲染
    const needAnimateions = !!this.animations.length
    const target = this.$slots.default
    if(needAnimateions) {
      return h('transition', {
        attrs: {
          appear: 'appear',
          css: false
        },
        on: {
          'appear': this.animateAppear,
          'appear-cancelled': this.animateAppearCanclelled,
          'enter': this.animateEnter,
          'enter-cancelled': this.animateEnterCancelled,
          'leave': this.animateLeave,
          'leave-cancelled': this.animateLeaveCancelled
        }
      }, target)
    } else {
      return target
    }
  }
}
</script>