import IBehaviors from './IBehaviors';

export default class MarkerBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        const getDateString = (date) => {
            let year = date.getFullYear();
            let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate().toString().padStart(2, "0");
            let hour = date.getHours().toString().padStart(2, "0");
            let minute = date.getMinutes().toString().padStart(2, "0");
            // let second = date.getSeconds().toString().padStart(2, "0");

            return year + "/" + month + "/" + day + " " + hour + ":" + minute;
        };

        return [
            {
                label: '[人员测试]创建人员定位',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_createDivBillboardTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeDivBillboard('personTest1');
                    this.viewer.Vector.removeHeatGrowthLine('HeatGrowthLine1');
                    this.viewer.Marker.removeVehicleGlb('VehicleTest1');

                    let list = [
                        {id: 'person1', name: '肖存经', position: {lng: 113.87453020, lat: 22.82418631, height: 0}, type: '劳务人员', company: '六局', department: '洞挖一队'},
                        {id: 'person2', name: '宋文彬', position: {lng: 113.87545019, lat: 22.82554724, height: 0}, type: '管理人员', company: '总承包部', department: '领导班子'},
                        {id: 'person3', name: '贺鹏飞', position: {lng: 113.87573719, lat: 22.82569063, height: 0}, type: '管理人员', company: '六局', department: '工程技术部'},
                        {id: 'person4', name: '赖建霖', position: {lng: 113.87416273, lat: 22.82329013, height: 0}, type: '管理人员', company: '八局', department: '综合管理部'}
                    ];

                    let input = [];
                    for (let item of list){
                        let iconDiv = `<div style="z-index: 8889">
                                            <img src="https://models.bimcc.net/demo/personIcon.png" style="width: 225px;height: 150px">
                                            <span style="position:absolute;left: 100px;top: 30px;font-size: 18px;color: white">${item.name}</span>
                                        </div>`;
                        let expandDiv = `<div style="
                                                    width: 205px;
                                                    height: 130px;
                                                    z-index: 8888;
                                                    background:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 66, 71, 0)), to(#004247))">
                                            <div style="padding: 15px 15px 0 ;font-size: 16px;color: white;height: 35px;line-height: 35px;float: left">人员类型：${item.type}</div>
                                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 35px;line-height: 35px;float: left">所属公司：${item.company}</div>
                                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 35px;line-height: 35px;float: left">部门：${item.department}</div>
                                        </div>`;

                        let data = {
                            obj: {
                                key: item.id,
                                position: item.position
                            },
                            options: {
                                offset: [-33, -150],
                                div: iconDiv
                            },
                            expandOptions: {
                                offset: [-30, -268],
                                div: expandDiv
                            }
                        };

                        input.push(data);
                    }

                    let db = this.viewer.Marker.createDivBillboard(input, 'personTest1');

                    let opt = {
                        interval: 1,
                        speed: 500,
                        isLoop: true
                    };
                    db.startAnimation('person1', [
                        {lng: 113.87453020, lat: 22.82418631, height: 0},
                        {lng: 113.87459457, lat: 22.82422339, height: 0},
                        {lng: 113.87469113, lat: 22.82427901, height: 0},
                        {lng: 113.87483865, lat: 22.82438161, height: 0},
                        {lng: 113.87496606, lat: 22.82448297, height: 0},
                        {lng: 113.87507200, lat: 22.82437790, height: 0},
                        {lng: 113.87517393, lat: 22.82431610, height: 0},
                        {lng: 113.87517259, lat: 22.82426294, height: 0},
                        {lng: 113.87505054, lat: 22.82413192, height: 0},
                        {lng: 113.87488559, lat: 22.82399348, height: 0},
                        {lng: 113.87473807, lat: 22.82385751, height: 0},
                        {lng: 113.87461871, lat: 22.82392549, height: 0},
                        {lng: 113.87453020, lat: 22.82404539, height: 0},
                        {lng: 113.87449801, lat: 22.82412327, height: 0}
                        ], opt);
                    db.startAnimation('person3', [
                        {lng: 113.87573719, lat: 22.82569063, height: 0},
                        {lng: 113.87577474117278, lat: 22.825626350316274, height: 0},
                        {lng: 113.87588068842886, lat: 22.825445882186472, height: 0},
                        {lng: 113.87604162096976, lat: 22.8252159703869, height: 0},
                        {lng: 113.87606039643286, lat: 22.825152929986928, height: 0},
                        {lng: 113.87602418661116, lat: 22.82512573607989, height: 0},
                        {lng: 113.87598529458045, lat: 22.825147985640609, height: 0},
                        {lng: 113.87585118412971, lat: 22.825356884116566, height: 0},
                        {lng: 113.87562990188599, lat: 22.825693098742029, height: 0}
                    ], opt)
                }
            },
            {
                label: '[人员测试]注销人员定位',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_removeDivBillboardTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeDivBillboard('personTest1');
                }
            },
            {
                label: '[机械测试]创建机械位置',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_createHeatGrowthLineTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeDivBillboard('personTest1');
                    this.viewer.Vector.removeHeatGrowthLine('HeatGrowthLine1');
                    this.viewer.Marker.removeVehicleGlb('VehicleTest1');

                    let expandOptions = `<div style="
                                    display: inline-flex;
                                    flex-direction: column;
                                    line-height: initial;
                                    vertical-align: middle;
                                    width: 280px;
                                    height: 200px;
                                    border: 1px solid #01FFF0;
                                    background-color: rgba(0, 0, 0, 0.7)">
                            <div style="padding: 15px 15px 0 ;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">车辆名称：临建一队-压路机</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">司机姓名：王鹏</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">年龄：40</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">车辆实时高度：3.9米</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">区域限高高度：50米</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">最近在线时间：${getDateString(new Date())}</div>
                        </div>`;

                    this.viewer.Vector.createHeatGrowthLine({
                        modelUrl: 'https://models.bimcc.net/demo/YaLuJi.glb',
                        kmlUrl: 'https://models.bimcc.net/demo/heatgrowthline.kml',
                        modelScale: 2.5,
                        lineRadius: 2,
                        interval: 50,
                        speed: 200,
                        expandOptions: {
                            offset: [-130, -200],
                            relativeHeight: 8,
                            div: expandOptions
                        }
                    }, 'HeatGrowthLine1');
                }
            },
            {
                label: '[机械测试]注销机械位置',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_removeHeatGrowthLineTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Vector.removeHeatGrowthLine('HeatGrowthLine1');
                }
            },
            {
                label: '[车辆测试]创建车辆定位',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_createVehicleGlbTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeDivBillboard('personTest1');
                    this.viewer.Vector.removeHeatGrowthLine('HeatGrowthLine1');
                    this.viewer.Marker.removeVehicleGlb('VehicleTest1');

                    this.viewer.Vector.locationKml('lineKml');

                    let url = 'https://models.bimcc.net/demo/LiftingTruck.glb';
                    let vehicleInfo = [
                        {vehicleName: '临建一队-渝B87152', personName: '李新才', personNum: 40, onlineTime: '2023/3/10 9:56', height: 3.2},
                        {vehicleName: '临建一队-渝B87155', personName: '周洪川', personNum: 31, onlineTime: '2023/3/10 12:32', height: 3.8},
                        {vehicleName: '临建一队-渝B87266', personName: '向才富', personNum: 46, onlineTime: '2023/3/10 18:53', height: 3.7},
                        {vehicleName: '临建一队-渝B87260', personName: '胡明聪', personNum: 27, onlineTime: '2023/3/10 16:24', height: 3.5}
                    ];
                    let list = [
                        {key: 'vehicle1', name: '货车01', position: {lng: 113.87453020, lat: 22.82418631, height: 0, rotate: 45}, url: url, scale: 3},
                        {key: 'vehicle2', name: '货车02', position: {lng: 113.87595177, lat: 22.82501325, height: 0, rotate: -50}, url: url, scale: 3},
                        {key: 'vehicle3', name: '货车03', position: {lng: 113.87568086, lat: 22.82567579, height: 0, rotate: -55}, url: url, scale: 3},
                        {key: 'vehicle4', name: '货车04', position: {lng: 113.87502104, lat: 22.82659544, height: 0, rotate: -60}, url: url, scale: 3}
                    ];

                    for (let i = 0; i < list.length; i++){
                        let expandOptions = `<div style="
                                    display: inline-flex;
                                    flex-direction: column;
                                    line-height: initial;
                                    vertical-align: middle;
                                    width: 280px;
                                    height: 200px;
                                    border: 1px solid #01FFF0;
                                    background-color: rgba(0, 0, 0, 0.7)">
                            <div style="padding: 15px 15px 0 ;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">车辆名称：${vehicleInfo[i].vehicleName}</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">司机姓名：${vehicleInfo[i].personName}</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">年龄：${vehicleInfo[i].personNum}</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">车辆实时高度：${vehicleInfo[i].height}米</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">区域限高高度：50米</div>
                            <div style="padding: 0 15px 0;font-size: 16px;color: white;height: 30px;line-height: 30px;text-align: left">最近在线时间：${getDateString(new Date())}</div>
                        </div>`;
                        list[i].expandOptions = {
                            offset: [-130, -200],
                            relativeHeight: 8,
                            div: expandOptions
                        }
                    }

                    let vg = this.viewer.Marker.createVehicleGlb(list, 'VehicleTest1');

                    let opt = {
                        interval: 0.3,
                        speed: 10,
                        isLoop: true
                    };
                    vg.startAnimation('vehicle2', [
                        {lng: 113.87595177, lat: 22.82501325, height: 0},
                        {lng: 113.8757586479187, lat: 22.824808061399821, height: 0},
                        {lng: 113.87546360492706, lat: 22.824555898869214, height: 0},
                        {lng: 113.87517392635345, lat: 22.824274069605806, height: 0},
                        {lng: 113.87491106986998, lat: 22.82402190608612, height: 0},
                        {lng: 113.8746267557144, lat: 22.823730186921811, height: 0},
                        {lng: 113.87436926364897, lat: 22.823418689496958, height: 0},
                        {lng: 113.87416541576385, lat: 22.823196190900013, height: 0}
                    ], opt);
                    vg.startAnimation('vehicle4', [
                        {lng: 113.87502104, lat: 22.82659544, height: 0},
                        {lng: 113.87507468461989, lat: 22.826489133000642, height: 0},
                        {lng: 113.87512028217316, lat: 22.826343276140086, height: 0},
                        {lng: 113.87513905763625, lat: 22.826212252047352, height: 0},
                        {lng: 113.87519538402557, lat: 22.826073811359532, height: 0},
                        {lng: 113.8752356171608, lat: 22.825947731324902, height: 0},
                        {lng: 113.87528657913208, lat: 22.825816706851455, height: 0},
                        {lng: 113.8753294944763, lat: 22.825688154415317, height: 0},
                        {lng: 113.87540996074675, lat: 22.825567018354793, height: 0},
                        {lng: 113.87554675340652, lat: 22.82540632749593, height: 0},
                        {lng: 113.87566477060318, lat: 22.825265413817434, height: 0},
                        {lng: 113.87578547000885, lat: 22.825149221727209, height: 0},
                        {lng: 113.87590080499648, lat: 22.825035501713071, height: 0}
                    ], opt);
                }
            },
            {
                label: '[车辆测试]注销车辆定位',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'marker_removeVehicleGlbTest',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeVehicleGlb('VehicleTest1');
                }
            },
        ];
    }
}
