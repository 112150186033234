<!--
 * @Description: 弹窗
 * @Author: luocheng
 * @Date: 2021-09-28 09:59:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-08-15 11:15:12
-->
<template>
	<div>
		<!-- @廖总 默认点击遮罩层不关闭 -->
			<!-- :close-on-click-modal="statusConfig.closeOnClickModal" -->
			<!-- :wrapperClosable="statusConfig.closeOnClickModal" -->
		<component
			:is="'el-' + componentType"
			:title="statusConfig.title || '提示'"
			:visible.sync="statusConfig.isShow"
			:custom-class="customClass"
			:width="fullDialogWidth || statusConfig.width || '400px'"
			:size="drawerWidth || 400"
			:fullscreen="statusConfig.fullscreen || (statusConfig.showExcel && preferredType === 'excel')"
			:top="componentType === 'dialog' ? statusConfig.top : ''"
			:modal="statusConfig.modal"
			:close-on-click-modal="false"
			:close-on-press-escape="statusConfig.closeOnPressEscape"
			:show-close="statusConfig.showClose"
			:center="statusConfig.center"
			:destroy-on-close="true"
			:lock-scroll="true"
			:wrapperClosable="false"
			append-to-body
		>

			<header class="model-header" slot="title">
				<h3>{{ statusConfig.title || '提示' }}</h3>
				<i v-if="!fullScreen" class="iconfont" :class="componentType === 'dialog' ? 'iconqiehuanchouti' : 'iconqiehuandanchuang'" @click="onToggleComponentType"></i>
				<i class="iconfont" :class="fullScreen ? 'icontuichuquanping' : 'iconquanping5'" v-if="!statusConfig.showExcel || (statusConfig.showExcel && preferredType !== 'excel')" @click="onToggleFullScreen"></i>
				<i class="iconfont toggle-preferred" v-if="statusConfig.showExcel"
					:class="{
						'iconzidingyibiaodan': preferredType === 'excel',
						'iconsaidi-daohang-biaoge': preferredType === 'form'
					}"
					@click="onTogglePreferredType"
				></i>
			</header>
			<article
				class="dialog-main"
				:class="[componentType === 'drawer' ? 'drawer-main' : 'dialog-main']"
				v-loading="loading"
			>
				<!-- 外部表单 -->
				<template v-if="contentConfig && ['outForm', 'luckySheet'].includes(contentConfig.contentType) && formDesignData">
					<PcParser
						v-show="preferredType === 'form'"
						@submit="onFormSubmit"
						@on-close="onEndFlow"
						:form-data="formDesignData"
						ref="dialogForm"
						:displayData="displayData"
						:editFields="editFields"
						:disabled="formDisabled"
						:needFlow="!!needFlow"
						:moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
						:needParentFlow="!!needParentFlow"
						:formMode="requestType"
						:nowArchiType="nowArchiType"
						:excelMetaData="excelMetaData"
						:contentConfig="contentConfig"
					>
					</PcParser>
					<!-- luckSheet -->
					<Entry v-show="statusConfig.showExcel && preferredType === 'excel' && excelMetaData && excelFormData"
						:type="excelModel"
						ref="dialogExcel"
						:excelUuid="contentConfig.excelTemplate"
						:objectUuid="contentConfig.objectUUID"
						:viewUuid="contentConfig.viewUUID"
						:formUuid="contentConfig.formUUID || contentConfig.objectUUID"
						:dataIds="excelIds"
						:excelMetaData="excelMetaData"
						:excelFormData="excelFormData"
						:show="preferredType === 'excel'"
						:needFlow="!!needFlow"
						:displayData="displayData"
						@submit="onFormSubmit"
						@receive="doCacheFormData"
					></Entry>
				</template>
				<!-- 外部页面 -->
				<PageParser
					v-else-if="contentConfig && contentConfig.contentType === 'configPage'"
					:pageId="contentConfig.pageId"
					:pageData="pageData"
					:pageType="pageType"
					:pageConfig="pageConfig"
				></PageParser>
			</article>
			<!-- 操作 -->
			<!-- 抽屉底部 -->
			<div
				class="dialog-footer"
				v-if="componentType === 'drawer' && (statusConfig.showConfirm || statusConfig.showClose)"
			>
				<el-button
					v-if="statusConfig.showCancel"
					:type="statusConfig.cancelType"
					@click="statusConfig.isShow = false"
				>
					{{ statusConfig.cancelText }}
				</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					@click="onConfirm(false)"
				>
					{{ statusConfig.confirmText }}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
				<!-- 缓存表单数据 -->
				<el-button type="" @click="onConfirm(true)" v-if="statusConfig.useCache">保存</el-button>
			</div>
			<!-- 弹窗 操作 -->
			<div
				slot="footer"
				class="dialog-footer"
				v-if="componentType === 'dialog' && (statusConfig.showConfirm || statusConfig.showClose)"
			>
				<el-button
					v-if="statusConfig.showCancel"
					:type="statusConfig.cancelType"
					@click="statusConfig.isShow = false"
				>
					{{ statusConfig.cancelText }}
				</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					@click="onConfirm(false)"
				>
					{{ statusConfig.confirmText }}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
				<!-- 缓存表单数据 -->
				<el-button type="" @click="onConfirm(true)" v-if="statusConfig.useCache">保存</el-button>
			</div>
		</component>
	</div>
</template>

<script>
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import PcParser from '@/custom-component/form/newParser/PcParser';
import PageParser from './parser/Index';
import { getComponentById, getQueryValue, doEEActionHandle } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { isJSONStr } from '@/utils/tools';
import Entry from '@/custom-component/common/Entry';

export default {
	name: 'CommonDialog',
	components: {
		PcParser,
		PageParser,
		Entry
	},
	props: {
		element: {
			type: Object,
			default: () => {},
			required: true
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			// 自定义表单相关
			objectUUID: null,
			// 表单配置数据
			formDesignData: null,
			displayData: {},
      // 流程中展示字段
      editFields:{},
			// 表单是否disable
			formDisabled: false,
			// 页面数据
			pageData: [],
			// 页面类型
			pageType: '',
			// 页面样式配置
			pageConfig: {
				height: 600,
				width: 500
			},
			loading: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 组件类型
			componentType: 'dialog',
			// 显示类型 form 表单  excel 图表
			preferredType: 'form',
			// 切换全屏
			fullScreen: false,
			// 全屏弹窗宽度
			fullDialogWidth: 0,
			// excel数据
			excelMetaData: null,
			excelFormData: null,
			// 缓存
			isCacheFormData: false,
			// 当前数据携带的archi_type
			nowArchiType: ''
		};
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus'
		]),
		customClass() {
			if (this.componentType === 'dialog' && ((this.statusConfig.showExcel && this.preferredType === 'excel') || this.fullScreen)) {
				return 'common-excel-dialog common-dialog'
			}
			return this.componentType === 'dialog' ? 'common-dialog' : 'common-drawer';
		},
		// 特殊样式
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 请求类型
		requestType() {
			const types = {
				createData: 'add',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0].type || ''];
		},
		// 抽屉宽度
		drawerWidth() {
			if (this.fullScreen || (this.statusConfig.showExcel && this.preferredType === 'excel')) {
				return '100vw'
			}
			if (!this.statusConfig) return 400;
			if (isNaN(this.statusConfig.drawerWidth)) {
				return this.statusConfig.drawerWidth;
			}
			return +this.statusConfig.drawerWidth || 400;
		},
		// 表格模式
		excelModel() {
			if (!this.element.sourceConfig || !this.element.sourceConfig.length) return 'add';
			const sourceType = this.element.sourceConfig[0].type;
			if (sourceType === 'updateData') {
				return 'edit'
			}
			if (sourceType === 'dataInfo') {
				return 'info'
			}
			return 'add';
		},
		// 表格的id
		excelIds() {
			const params = this.getParams();
			return params.id || params.ids || params.data_id;
		},
		// 更新规则
		updateRulesConfig() {
			return this.element?.updateRulesConfig || {
				pattern: '',
				rules: []
			}
		}
	},
	created() {
		this.componentType = this.statusConfig?.firstType || 'dialog';
		this.preferredType = this.statusConfig?.preferredType || 'form';
		this.fullScreen = this.statusConfig.fullscreen || false;
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig) return;
				if (this.contentConfig.contentType === 'outForm') {
					// 来至自定义表单@曾伟
					this.objectUUID = null;
					this.initFormDesign(this.contentConfig);
				} else if (this.contentConfig.contentType === 'luckySheet') {
					// excel 表格
				} else if (this.contentConfig.contentType === 'configPage') {
					// 来自外部页面
					this.initPageDesign();
					// 获取部分参数配置
					const params = this.getParams();
					sessionStorage.setItem(
						`dialogRelationParams_${this.contentConfig.pageId}`,
						JSON.stringify(params)
					);
				}
			},
			deep: true,
			immediate: true
		},
		// 切换显示修复层级问题
		'statusConfig.isShow': {
			handler() {
				this.$nextTick(() => {
					try {
						const modalBox = document.querySelector('.v-modal');
						modalBox.style.zIndex = '3000'
					} catch (err) {
						console.log(err, '切换显示修复层级问题--');
					}
				});
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 切换全屏
		 */
		onToggleFullScreen() {
			this.fullScreen = !this.fullScreen;
			if (this.fullScreen) {
				this.fullDialogWidth = '100vw';
			} else {
				this.fullDialogWidth = '';
			}
		},
		/**
		 * @desc: 切换表格和表单模式
		 */
		onTogglePreferredType() {
			const toggleType = {
				form: 'excel',
				excel: 'form'
			}
			this.preferredType = toggleType[this.preferredType];
		},
		/**
		 * @desc: 切换组件显示类型
		 */
		onToggleComponentType() {
			if (this.componentType === 'dialog') {
				this.componentType = 'drawer';
			} else {
				this.componentType = 'dialog';
			}
			// 特殊处理
			this.$nextTick(() => {
				const { sourceConfig } = this.element;
				const isInfo = sourceConfig[0].type === 'dataInfo';
				if (isInfo) {
					const drawer = document.querySelector('.common-drawer');
					if (drawer) {
						const drawerBox = drawer.parentNode?.parentNode;
						if (drawerBox) {
							drawerBox.classList.add('Editor-drawer__wrapper');
						}
					}
				}
			});
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm(isCacheFormData = false) {
			this.isCacheFormData = isCacheFormData;
			if (this.contentConfig && this.contentConfig.contentType === 'outForm') {
				if (this.$refs && this.$refs.dialogForm && this.preferredType === 'form') {
					if (isCacheFormData) {
						this.doCacheFormData(this.$refs.dialogForm.getFormData());
					} else {
						this.$refs.dialogForm.handleSubmit();
					}
				} else if (this.$refs && this.$refs.dialogExcel && this.preferredType === 'excel') {
					if (isCacheFormData) {
						this.$refs.dialogExcel.getFormData();
					} else {
						this.$refs.dialogExcel.triggerSubmit();
					}
				}
			}
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			const params = this.getParams();
			const { objectUUID, formUUID } = contentConfig
			transFieldsUUid(objectUUID, formUUID, params)
				.then((res) => {
					if (res.data.code == 200) {
						const { sourceConfig } = this.element;
						this.formDisabled = sourceConfig[0].type === 'dataInfo';
						this.needFlow = !!res.data.data.need_flow;
						this.needParentFlow = !!res.data.data.need_parent_flow;
						this.nowArchiType = res?.data?.data?.archi_type || '';
						// 编辑或详情
						if (
							sourceConfig[0].type === 'updateData' ||
							sourceConfig[0].type === 'dataInfo'
						) {
							// 编辑
							// 获取已经有的数据进行绑定
							let config = res.data.data;
							let ruuids = [];
							for (let i = 0; i < config.fields.length; i++) {
								let item = config.fields[i];
								if (item.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: item.__vModel__ });
								}
							}
							dataInterface({
								...params,
								object_uuid: objectUUID,
								view_uuid:
									sourceConfig[0].type === 'dataInfo'
										? this.contentConfig.viewUUID
										: '',
								ruuids,
								__method_name__: 'dataInfo'
							}).then((res1) => {
								let data = res1.data.data;
								this.formDesignData = res.data.data;
								this.excelFormData = {
									[objectUUID]: JSON.parse(JSON.stringify(data))
								};
								this.excelMetaData = JSON.parse(JSON.stringify(res1.data.metadata));
								this.displayData = data;
                this.editFields = data.editFields || [];
								this.loading = false;
							});
						} else {
							if (this.statusConfig.useCache && sourceConfig[0].type === 'createData' && res?.data?.data?.cache_data) {
								// 新增
								this.$confirm('是否使用缓存数据填充？', '提示').then(() => {
									try {
										this.formDesignData = res.data.data;
										this.loading = false;
										this.displayData = JSON.parse(res?.data?.data?.cache_data) || {};
									} catch(cacheErr) {
										console.log(cacheErr)
									}
								}).catch(() => {
									this.formDesignData = res.data.data;
									this.loading = false;
								});
							} else {
								this.formDesignData = res.data.data;
								this.loading = false;
							}
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			if (this.isCacheFormData) {
				this.doCacheFormData(formData);
				return false;
			}
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.statusConfig.isShow = false;
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				// let data_id = '';
				let params = {};
				// 发送请求 整理参数
				const sourceParams = this.getParams();
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					...sourceParams,
					// 表单的字段
					...formData
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then((res) => {
						this.$loading().close();
						if (res.code || (res.status && res.status === 200)) {
							this.beforeClose();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 缓存表单数据
		 * @param {Object} formData
		 * @return {*}
		 */
		doCacheFormData(value) {
			if (!value) {
				this.$message.warning('暂无可保存数据!');
				return false;
			}
			const loading = this.$loading();
			dataInterface({
				__method_name__: 'createData',
				object_uuid: 'object61bbffbdabbbe',
				user_id: this.$GetUserInfo('id'),
				key: `formcache-${this.contentConfig.formUUID || this.contentConfig.objectUUID}`,
				value
			}).then(res => {
				if (res.status === 200) {
					this.$message.success('操作成功！');
				}
				loading && loading.close();
			}).catch(err => {
				console.log(err);
				loading && loading.close();
			})
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			this.statusConfig.isShow = false;
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.sourceConfig || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					if (
						sourceConfig[i] &&
						!sourceConfig[i].originType &&
						sourceConfig[i].componentId
					) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: sourceConfig[i].componentId,
							isInit: sourceConfig[i].componentId.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								sourceConfig[i].componentId.indexOf('CommonTabs-') > -1
									? this.getOutput(sourceConfig[i].componentId)
									: ''
						});
					}
				}
			}
			if (pattern === 'rules' || pattern === 'compatible') {
				rules.forEach(ele => {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: ele.componentId,
						isInit: ele.componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ele.componentId.indexOf('CommonTabs-') > -1 ? this.getOutput(ele.componentId) : ''
					});
				})
			}
		},
		/**
		 * @desc: 获取数据数据
		 */
		getOutput(comId) {
			let componentItem = getComponentById(this.componentData, comId);
			if (!componentItem && this.isGroup && this.groupComponents.length) {
				componentItem = getComponentById(this.groupComponents, comId);
			}
			return componentItem?.resolveData || '';
		},
		/**
		 * @desc: 点击按钮
		 */
		onButton(btnConfig) {
			if (btnConfig && btnConfig.effectType === 'eeAction') {
				const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = btnConfig;
				if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				this.loading = true;
        const sourceParams = this.getParams();
				// 蓝图
        if (eeType === 'blueprint') {
          dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
          }).catch(() => {
            this.loading = false;
          })
          return true;
        }
				const data_id = sourceParams?.data_id;
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				// 触发后端事件
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					...sourceParams,
					data_id,
					event: btnConfig.eventName
				})
					.then((res) => {
						if (res.status === 200) {
							this.$message.success('操作成功！');
							doEEActionHandle(res.data?.__adds__);
						}
						this.loading = false;
					})
					.catch((err) => {
						console.log(err, '00000后端事件错误');
						this.loading = false;
					});
			}
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams() {
			const sourceConfig = this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'pageStatus' && statusCode) {
					const result = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode]
					this.$set(sourceParams, key, result);
				} else if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.componentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
					this.$set(sourceParams, key, result);
				}
			}
			return sourceParams;
		},
		/**
		 * @desc: 页面
		 */
		initPageDesign() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataInfo',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				data_id: this.contentConfig.pageId
			})
				.then((res) => {
					this.loading = false;
					if (
						res.status === 200 &&
						res.data &&
						res.data.code === 200 &&
						res.data.data
					) {
						const pageData =
							res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f'];
						let { componentData, editorConfig, editorType } = isJSONStr(pageData) ? JSON.parse(pageData) : pageData;
						this.pageData = componentData;
						this.pageType = editorType;
						this.pageConfig = editorConfig;
						this.inited = true;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		}
	},
	beforeDestroy() {
		sessionStorage.removeItem(
			`dialogRelationParams_${this.contentConfig.pageId}`
		);
	}
};
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
	height: auto;
	max-height: calc(100vh - 50px);
	overflow-y: hidden;
	// overflow-y: auto;
	.type-label {
		border-left: 3px solid #007fe0 !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
	}
	// 弹窗
	.dialog-main {
		overflow-x: auto;
	}
	.el-dialog__body {
		max-height: calc(100vh - 155px);
		min-height: 300px;
		padding: 0;
		overflow: hidden;
		display: flex;
		box-sizing: border-box;
		padding: 15px 16px;
		flex-direction: column;
		.dialog-footer {
			box-sizing: border-box;
			padding-top: 20px;
			text-align: right;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #f2f3f5;
	}
	.el-dialog__footer {
		padding: 15px 10px;
		border-top: 1px solid #f2f3f5;
	}
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 30px;
		cursor: pointer;
		h3 {
			flex: 1;
		}
		.iconfont {
			font-size: 20px;
			box-sizing: border-box;
			padding: 0 5px;
			&+.iconfont{
				margin-left: 5px;
			}
		}
	}
}
.el-dialog__wrapper{
	overflow: hidden;
}
:deep(.common-excel-dialog) {
	height: 100vh!important;
	max-height: 100vh!important;
	.el-dialog__body {
		min-height: calc(100vh - 115px)!important;
		max-height: calc(100vh - 115px)!important;
		height: calc(100vh - 115px)!important;
		.dialog-main{
			height: 100%;
			width: 100%;
		}
	}
}
:deep(.common-drawer)  {
	height: 100vh !important;
	overflow: hidden;
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 10px;
		cursor: pointer;
		h3 {
			flex: 1;
		}
		.iconfont {
			font-size: 20px;
			box-sizing: border-box;
			padding: 0 5px;
			&+.iconfont{
				margin-left: 5px;
			}
		}
	}
	// 抽屉
	.el-drawer__header {
		margin-bottom: 0;
		border-bottom: 1px solid #f2f3f5;
		padding-bottom: 20px;
	}
	.el-drawer__body {
		box-sizing: border-box;
		padding: 15px 16px;
		display: flex;
		flex-direction: column;
		.drawer-main {
			flex: 1;
			width: 100%;
			overflow-y: auto;
		}
		.dialog-footer {
			padding-top: 15px;
			text-align: right;
			border-top: 1px solid #f2f3f5;
		}
	}
	.type-label {
		border-left: 3px solid #007fe0 !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
		text-align: left;
	}
}

	.Editor-drawer__wrapper{
		width: 100vw;
	}
</style>
