<!--
 * @Description: 进度曲线
 * @Author: luocheng
 * @Date: 2022-05-31 15:41:30
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-05 10:16:56
-->
<template>
  <div class="curve-chart" v-if="paramsResult">
    <FilterSelect :filterKey="filterKey" @selectKey="onSelectKey" @updateList="getFilterList"
      :paramsResult="paramsResult"
    ></FilterSelect>
    <ChartContainer
      v-loading="loading"
      v-if="option"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
    ></ChartContainer>
  </div>
</template>

<script>
import FilterSelect from './FilterSelect';
import ChartContainer from '@/chart-components/components/common/ChartContainer';
import { dataInterface } from '@/apis/data';

export default {
  name: 'CurveChart',
  components: {
    FilterSelect,
    ChartContainer
  },
  data() {
    return {
      filterKey: '',
      option: null,
      loading: false,
      filterList: [],
      colorArr: ['#1A73E8', '#FF8C00', '#8B8682', '#ff3333', '#FFD700', '#836FFF'], // 颜色数组
    }
  },
  props: {
    paramsResult: {
      required: true,
    }
  },
  watch: {
    paramsResult: {
      handler() {
        this.getChartOption(this.paramsResult);
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    onSelectKey(val) {
      this.filterKey = val;
      this.$emit('selectKey', val);
      this.getChartOption(this.paramsResult);
    },
    /**
     * @desc: 获取图表option配置
     */
    async getChartOption(params = {}) {
      this.loading = true;
      let result = {};
      if (!this.filterKey) return false;
      const { param = {}, search = [], canPost = true } = params;
      if (!canPost) return false;
      // 测试删除后分部分项问题
      const questionRes = await this.getData(1, param, search);
      if (questionRes.code !== 200) {
        this.$message.error('获取数据失败!');
        this.loading = false;
        return false;
      }
      const questionData = questionRes?.data || [];
      if (questionData.length) {
        questionData.forEach((ele, index) => {
          const list = ele.schedule_list || [];
          if (index) {
            result = Object.assign(result, { [ele.schedule_name + index]: list })
          } else {
            result = Object.assign(result, { [ele.schedule_name]: list })
          }
        });
      }
      // 实际进度
      const progressRes = await this.getData(2, param, search);
      if (progressRes.code !== 200) {
        this.$message.error('获取数据失败!');
        this.loading = false;
        return false;
      }
      const progressData = progressRes?.data || [];
      if (!progressData.length) {
        this.buildData(result);
        return;
      }
      result = Object.assign(result, { '实际进度': progressData });
      // 获取完成率
      const percentRes = await this.getPercent(param, search);
      let percentData = percentRes?.data || [];
      if (percentData.length) {
        percentData = percentData.map(ele => {
          return {
            ...ele,
            rate: parseFloat(ele.rate)
          }
        });
      }
      result = Object.assign(result, { '完成率': percentData });
      this.buildData(result)
      // console.log(percent, '00000----获取完成率-----');
    },
    /**
     * @desc: 构建数据
     */
    buildData(data) {
      // console.log('构建数据=======', data);
      const rangeX = []
      const seriesList = []
      let n = 0
      for (var i in data) {
        if (i !== '实际进度') {
          n++
        }
        const item = data[i]
        const innerData = []
        if (item.length !== 0) {
          item.forEach((item) => {
            innerData.push([item.date, item.rate * 100])
            rangeX.push(item.date)
            // 日期升序排序
            rangeX.sort(function (a, b) {
              return Date.parse(a) - Date.parse(b)
            })
          })
        }
        const obj = {
          name: i,
          type: 'line',
          data: innerData,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              lineStyle: {
                color: i === '实际进度' ? 'green' : this.colorArr[n - 1],
                lineStyle: {
                  width: i === '实际进度' ? 3 : 1.5,
                  type: i === '实际进度' ? 'solid' : 'dotted', // 'dotted'虚线 'solid'实线
                  color: i === '实际进度' ? 'green' : this.colorArr[n - 1]
                }
              }
            }
          }
        }
        seriesList.push(obj)
      }
      this.option = {
        animationDuration: 3000,
        title: {
          // text: `${this.filterList.find(ele => ele.id === this.filterKey)?.rule_name}进度控制曲线`,
          align: 'center',
          color: '#ffffff'
        },
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            let str = params[0].axisValueLabel + '<br/>'
            for (let i = 0; i < params.length; i++) {
              str += '<div style="margin: 0px 0 0;line-height:20px;height:20px;background">' +
              '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' + params[i].color + '"></span>' +
              '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + params[i].seriesName + '</span>' +
              '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + (params[i].value[1]).toFixed(2) + '%</span>' +
              '<div style="clear:both"></div>' +
              '</div>'
            }
            return str
          }

        },
        grid: {
          top: 45,
          right: 20,
          bottom: 30
        },
        legend: {
          // data: ['计划进度', '实际进度', '完成率'],
          width: 400,
          top: 0,
          right: 20,
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Array.from(new Set(rangeX)),
          nameTextStyle: {
            color: '#ffffff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}' + '%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#939191'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        },
        series: seriesList
      };
    },
    /**
     * @desc: 获取统计数据
     * @param {String,Number} type 类型
     * type 1 测试删除后分部分项问题
     *      2 实际进度
     *      3 完成率
     */
    getData(type, param = {}, search = []) {
      return new Promise((resolve, reject) => {
        dataInterface({
          rule_id: this.filterKey,
          type,
          token: this.$GetUserInfo()?.token,
          search,
          ...param,
          transcode: 0
        }, '/api/fourSchedule/countGroupChart', 'GET').then(res => {
          if (res.status === 200) {
            resolve(res.data);
            return;
          }
          reject(false);
        }).catch((err) => {
          console.log(err);
          reject(false);
        })
      })
    },
    /**
     * @desc: 完成率
     */
    getPercent(param = {}, search = []) {
      return new Promise((resolve, reject) => {
        dataInterface({
          rule_id: this.filterKey,
          token: this.$GetUserInfo()?.token,
          search,
          ...param,
          transcode: 0
        }, '/api/fourSchedule/mileageCount', 'GET').then(res => {
          if (res.status === 200) {
            resolve(res.data);
            return;
          }
          reject(false);
        }).catch(err => {
          console.log(err);
          reject(false);
        });
      });
    },
    /**
     * @desc: 获取筛选列表
     */
    getFilterList(list) {
      this.filterList = list;
    }
  }
}
</script>

<style lang="less" scoped>
.curve-chart{
  position: relative;
  height: 100%;
  width: 100%;
  .filter-select{
    position: absolute;
    left: 0;
  }
}
</style>