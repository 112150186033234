/*
 * @Date: 2022-08-22 11:31:13
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-07-21 10:54:55
 * @FilePath: \dataview-next\src\custom-component\UEClient\UEClient.js
 */
//UE旧版插件 此版本勿删
class UEClient {
	constructor(){
			this.synccallue = false;

			const global = this.getGlobal();

			if(!global.ue){
					global.ue = {};
			}

			if("object" != typeof global.ue.interface || "function" != typeof global.ue.interface.synccallue ){
					global.ue.interface = {};
			}else{
					this.synccallue = true;
			}
	}

	getGlobal(){
			return window;
	}

	on(id  , func){
			const global = this.getGlobal();

			global.ue.interface[id] = func.bind(this);
	}

	sendMsg( type, data, cb, timeout){
			const global = this.getGlobal();

			const setCallBackFunc = function(func, time) {
					if(!func)return "";

					const uuid = global.uuidv4();
					global.ue.interface[uuid] = func;

					setTimeout(function() {
							delete global.ue.interface[uuid];
					}, 1e3 * Math.max(1, parseInt(time) || 0));
					return uuid;
			}

			if( "object" != typeof global.ue["$receive"] || "function" != typeof global.ue["$receive"]["asyn"] ){
					// return
			}else{
					global.ue["$receive"].asyn( type , JSON.stringify(data));
			}

			if(!this.synccallue){
					let hash;
					let msgList ;

					if("string" == typeof type ){
							if("function" == typeof data){
									timeout= cb;
									cb = data;
									data = null;
							}
							msgList = [type, "", setCallBackFunc(cb, timeout)];

							if(data){
									msgList[1] = data
							}
							hash = encodeURIComponent(JSON.stringify(msgList));
					}

					if("object" == typeof history && "function" == typeof history.pushState ){
							history.pushState({}, "", "#" + hash);
							history.pushState({}, "", "#" + encodeURIComponent("[]"));
					}else{
							document.location.hash = hash;
							document.location.hash = encodeURIComponent("[]");
					}
			}else{
					let uuid = null;

					if("string" == typeof type){
							if("function" == typeof data){
									timeout= cb;
									cb = data;
									data = null
							}
							uuid = setCallBackFunc(cb, timeout);

							if(data){
									global.ue.interface.synccallue(type, JSON.stringify(data), uuid);
							}else{
									global.ue.interface.synccallue(type, "", uuid);
							}
					}
			}
	}
}


export {
	UEClient,
}
