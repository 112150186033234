<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-06-08 13:45:32
 * @FilePath: \dataview-next\src\custom-component\video\player\ImouPlayer.vue
 * @Description: 乐橙云播放器
-->
<template>
  <div ref="player" class="imou-full-player-container">
    <div ref="vidoeWarp" class="video-container" v-imouDirectives="options"></div>
    <div v-show="config.showControl" class="action">
      <Control 
        :data="data"
        :playing="playing"
        :isRecording="saveing"
        :isfull="isfull"
        :intelligibility="intelligibility"
        @play="play"
        @stop="stop"
        @capturePicture="capturePicture"
        @PTZInstruct="handlePTZevent"
        @record="record"
        @fullScreen="fullScreen"
        @ptzvisibleChange="handlePtzvisibleChange"
        @changeIntelligibility="handleChangeIntelligibility"
        @playbackRange="handlePlaybackRangeChange"
        @playbackType="handlePlaybackType"
      />
    </div>
    <Rocker :voideData="data" v-if="showPTZ" @handleAction="handlePTZevent" />
  </div>
</template>

<script>
/**
 * @desc 乐橙云播放器完整版本, 采用指令来进行播放，这样可以简化dom的操作，进行分别设置是为了上层适配，提高可阅读性
 */
import './imouplayer.js'
import { axios as REQUEST } from '@/apis/http/request';
import Control from './Control.vue'
import Rocker from './Rocker.vue'
import { format } from "date-fns";
const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if(entry.target.player && entry.contentRect.width && entry.contentRect.height) {
      try{
        // imou 很奇怪，内部自带的_setPlayerSize() 无法使用，那就只能去改盒子的宽高了，宽度是自适应的，这里只处理了高度
        entry.target.firstElementChild.style.height = entry.contentRect.height + 'px';
      } catch (err) {
        console.log(err);
      }
    }
  }
});

export default {
  name: "ImouFullPlayer",
  components: { Control, Rocker },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
    config: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      player: null,
      playing: false,
      saveing: false,
      isfull: false,
      showPTZ: false,
      intelligibility: 0, // 0 标清 1 高清
      dataRange: null,
      playBackType: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.player.onfullscreenchange = () => {
        this.isfull = !this.isfull;
      }
    })
  },
  computed: {
    options() {
      return {
        appId: this.data.appId,
        channel_id: this.data.channel_id,
        device_serial: this.data.device_serial,
        intelligibility: this.intelligibility,
        playBackType: this.playBackType,
        dataRange: this.dataRange, // 回放时间段
        setPlayer: this.setPlayer
      }
    }
  },
  directives: {
    imouDirectives: {
      inserted: async (el, binding) => {
        // ezuikit-js只支持id
        const id = 'imouplayer' + Math.floor(Math.random() * 1000).toString(16);
        // 这里是因为极简模式下会改变dom结构，导致盒子监听存在异常
        const warp = document.createElement('div');
        warp.id = id;
        el.appendChild(warp);
        resizeObserver.observe(el);

        let kit;
        try {
          kit = await REQUEST.request({
            url: `api/monitorPlatform/apps/${binding.value.appId}/devices/${binding.value.device_serial}/channels/${binding.value.channel_id}/kitToken`,
            methods: 'get'
          });
        } catch(err) {
          // 
        }
        if (kit.data.code !== 200 || !kit.data.data.kitToken) {
          this.$message.error(kit.data.msg);
          return;
        }
        if(!window.ImouPlayer) return;
        const player = new window.ImouPlayer(`#${id}`);
        player.token =  kit.data.data.kitToken;
        player.id = id;
        // 播放器初始化
        player.setup({
          src: [
            {
              url: `imou://open.lechange.com/${binding.value.device_serial}/${binding.value.channel_id}/1?streamId=1`,
              kitToken: kit.data.data.kitToken,
            }
          ],
          width: '100%',
          height: '100%',
          // 是否自动播放
          autoplay: true,
          // 是否显示控制台
          controls: false,
          // 是否开启静音
          automute: false
        });
        el.player = player;
        binding.value.setPlayer(player);
      },
      update: (el, binding) => {
        const oldValue = binding.oldValue;
        const value = binding.value;
        const key = ['accessToken', 'channel_id', 'device_serial', 'intelligibility', 'dataRange', 'playBackType'];
        const diff = key.some(el => {
          return !Object.is(oldValue[el], value[el]);
        })
        if(!diff) return;
        if(el.player) {
          console.log(el.player, binding);
          const token = el.player.token;
          const id = el.player.id;
          // 先销毁
          el.player.stop();
          el.player.destroy();
          const intelligibility = binding.value.intelligibility ? '0' : '1';
          const dateRange = binding.value?.dataRange;
          if(Array.isArray(dateRange) && dateRange.length) {
            const s = format(dateRange[0], 'yyyy-MM-dd HH:mm:ss');
            const e = format(dateRange[1],'yyyy-MM-dd HH:mm:ss');
            const source = binding.value?.playBackType === 'cloud' ? '' : 'recordType=localRecord&';
            const player = new window.ImouPlayer(`#${id}`);
            player.token =  token;
            player.id = id;
            // 播放器初始化
            player.setup({
              src: [
                {
                  url: `imou://open.lechange.com/${binding.value.device_serial}/${binding.value.channel_id}/2?${source}streamId=${intelligibility}&beginTime=${s}&endTime=${e}`,
                  kitToken: token,
                }
              ],
              width: '100%',
              height: '100%',
              // 是否自动播放
              autoplay: true,
              // 是否显示控制台
              controls: false,
              // 是否开启静音
              automute: false
            });
            el.player = player;
            binding.value.setPlayer(player);
          } else {
            const player = new window.ImouPlayer(`#${id}`);
            player.token =  token;
            player.id = id;
            // 播放器初始化
            player.setup({
              src: [
                {
                  url: `imou://open.lechange.com/${binding.value.device_serial}/${binding.value.channel_id}/1?streamId=${intelligibility}`,
                  kitToken: token,
                }
              ],
              width: '100%',
              height: '100%',
              // 是否自动播放
              autoplay: true,
              // 是否显示控制台
              controls: false,
              // 是否开启静音
              automute: false
            });
            el.player = player;
            binding.value.setPlayer(player);
          }
        }
      },
      unbind: (el, binding) => {
        resizeObserver.unobserve(el);
        if(el.player) {
          console.log(el.player, binding)
          el.player.stop();
          el.player.destroy();
        }
      }
    }
  },
  methods: {
    handlePlaybackRangeChange(dateRange) {
      this.dataRange = dateRange;
    },
    handleChangeIntelligibility(v) {
      this.intelligibility = Number(v);
    },
    handlePlaybackType(type) {
      this.playBackType = type;
    },
    handlePtzvisibleChange(v) {
      this.showPTZ = v;
    },
    setPlayer(install) {
      this.player = install;
      this.playing = true;
    },
    play() {
      this.player.play();
      this.playing = true;
    },
    stop() {
      this.player.pause();
      this.playing = false;
    },
    capturePicture() {
      const data = {
        token: this.player.token,
        deviceId: this.options.device_serial,
        channelId: this.options.channel_id
      }
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      REQUEST.request({
        url: `api/monitorPlatform/apps/${this.options.appId}/devices/${this.options.device_serial}/channels/${this.options.channel_id}/capture`
      }).then(res => {
        // console.log(res);
        if (+res.data.code === 200) {
          this.$emit('docapturePicture', {
            device_serial: this.options.device_serial,
            screenshot_picture: [
              {
                filename: `${this.data.monitor_name}.jpg`,
                fileext: 'jpg',
                filepath: res.data.data.url,
                filesize: 0,
                filetype: 'image/jpeg',
                path: res.data.data.url,
                url: res.data.data.url
              }
            ],
            monitor_name: this.data.monitor_name,
            monitor_id: this.data.monitor_id
          })
        }
      })
    },
    record() {
      if(this.saveing) {
        this.stopSave();
      } else {
        this.startSave();
      }
    },
    // 全屏
    fullScreen() {
      if (this.isfull) {
        document.exitFullscreen();
      } else {
        this.$refs.player.requestFullscreen();
      }
    },
    startSave() {
      
    },
    stopSave() {
      
    },
    destroy() {
      this.player.destroy();
    },
    // 摇杆事件
    handlePTZevent(instruct) {
      // 一秒触发一次
      if(this.PTZTimer) return;
      this.PTZMove(instruct);
      this.PTZTimer = setTimeout(() => {
        this.PTZTimer = null;
      }, 1000)
    },
    // 乐橙云直接访问会报跨域
    PTZMove(type) {
      const data = {
        slug: 'lechange',
        lechange_operation: -1,
        lechange_duration: 400
      }
      // 操作命令：0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-停止
      switch(type) {
        case 'up': {
          data.lechange_operation = 0;
          break
        }
        case 'down': {
          data.lechange_operation = 1;
          break
        }
        case 'left': {
          data.lechange_operation = 2;
          break
        }
        case 'right': {
          data.lechange_operation = 3;
          break
        }
        case 'right-top': {
          data.lechange_operation = 6;
          break
        }
        case 'right-bottom': {
          data.lechange_operation = 7;
          break
        }
        case 'left-top': {
          data.lechange_operation = 4;
          break
        }
        case 'left-bottom': {
          data.lechange_operation = 5;
          break
        }
        case 'scaleup': {
          data.lechange_operation = 8;
          break
        }
        case 'scaledown': {
          data.lechange_operation = 9;
          break
        }
      }
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      REQUEST.request(
        {
          url: `api/monitorPlatform/apps/${this.options.appId}/devices/${this.options.device_serial}/channels/${this.options.channel_id}/ptz`,
          data: REQDATA,
          method: 'post'
        }).then(res => {
        console.log(res);
        this.oprateFlag = true;
      })
    }
  }
};
</script>

<style lang="less" scoped>
  .imou-full-player-container{
    position: relative;
    width: 100%;
    height: 100%;
    .video-container{
      width: 100%;
      height: 100%;
    }
    .video-model{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .action{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    :deep(.ptz-container) {
      position: absolute;
      right: 10px;
      bottom: 60px;
      width: 160px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
