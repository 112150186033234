<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-03-29 14:42:19
 * @FilePath: \dataview-next\src\custom-component\video\CommonVideo.vue
 * @Description: 视频组件
-->
<template>
  <div class="common-video-container">
    <!-- 封面 用于不初始化播放器 -->
    <!-- 封面情况非链接格式情况下，如何显示？暂时显示为空 2023.2.4 -->
    <el-image v-if="!isPlay" class="cover-img" :src="coverImage">
      <div slot="error" class="image-slot">
        <el-empty description="暂无封面"></el-empty>
      </div>
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </el-image>
    <div v-if="!isPlay" class="play-btn" @click.stop="isPlay = !isPlay">
      <svg t="1671084519442" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="38125" width="100%" height="100%"><path d="M512 42.670186C252.80132 42.670186 42.670186 252.790763 42.670186 512S252.80132 981.329814 512 981.329814c259.209237 0 469.340371-210.120577 469.340371-469.329814S771.209237 42.670186 512 42.670186z m196.54466 500.493195l-266.662268 176.00132a37.339052 37.339052 0 0 1-57.882392-31.163381v-351.960413a37.339052 37.339052 0 0 1 57.892948-31.163381l266.662268 176.001319a37.339052 37.339052 0 0 1 0 62.316207v-0.031671z" fill="#FFFFFF" p-id="38126"></path></svg>
    </div>
    <!-- 播放器 -->
    <Player v-else-if="isPlay && data.onLine !== 0" :data="data" :config="config"/>
    <div v-else class="outline-icon">
      <svg width="131" height="121" viewBox="0 0 131 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60.447 98.7908C85.127 98.7908 105.134 77.8397 105.134 51.9951C105.134 26.1505 85.127 5.19934 60.447 5.19934C35.7669 5.19934 15.7598 26.1505 15.7598 51.9951C15.7598 77.8397 35.7669 98.7908 60.447 98.7908Z" fill="url(#paint0_linear_1493_15108)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06721 29.2579C8.89862 24.3333 17.7704 23.3958 26.2645 23.6905C27.4783 23.7326 28.4296 24.7971 28.3894 26.0681C28.3491 27.3391 27.3326 28.3353 26.1189 28.2932C17.9668 28.0103 10.0994 28.9499 3.33115 33.2059C2.29004 33.8606 0.939258 33.5075 0.314088 32.4173C-0.311083 31.327 0.0260997 29.9125 1.06721 29.2579Z" fill="url(#paint1_linear_1493_15108)"/>
      <g filter="url(#filter0_f_1493_15108)">
      <ellipse cx="50.1376" cy="45.1807" rx="26.9442" ry="4.82165" fill="#C7D6FC"/>
      </g>
      <g filter="url(#filter1_f_1493_15108)">
      <ellipse cx="95.8399" cy="44.8236" rx="12.2784" ry="4.82165" fill="#519BA3"/>
      </g>
      <g filter="url(#filter2_f_1493_15108)">
      <ellipse cx="66.5077" cy="93.397" rx="26.9442" ry="4.82165" fill="#C7D6FC"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M117.686 66.6727C118.311 67.763 117.974 69.1775 116.933 69.8322C109.102 74.7562 100.226 75.694 91.7326 75.3995C90.5188 75.3574 89.5675 74.293 89.6077 73.022C89.6479 71.751 90.6643 70.7548 91.8781 70.7968C100.03 71.0794 107.902 70.1395 114.669 65.8841C115.71 65.2294 117.061 65.5825 117.686 66.6727Z" fill="url(#paint2_linear_1493_15108)"/>
      <path d="M57.3057 93.0979H79.6397C87.1283 93.0979 93.2002 84.6046 93.2002 74.1245C93.2002 63.6444 87.1283 55.1478 79.6397 55.1478H57.3057C61.9389 55.1478 66.0288 58.3978 68.4727 63.3714C70.0452 66.7203 70.863 70.4025 70.863 74.1344C70.863 77.8663 70.0452 81.5484 68.4727 84.8974C66.0288 89.8578 61.9389 93.0979 57.3057 93.0979Z" fill="url(#paint3_linear_1493_15108)"/>
      <path d="M73.3628 62.1151C70.6363 56.579 66.0721 52.9541 60.9048 52.9541H55.9134C47.5578 52.9541 40.7822 62.4342 40.7822 74.1248C40.7822 85.8154 47.5578 95.2923 55.9134 95.2956H60.9205C66.0878 95.2956 70.6583 91.6772 73.3785 86.1345C75.132 82.3985 76.0438 78.291 76.0438 74.1281C76.0438 69.9652 75.132 65.8578 73.3785 62.1217L73.3628 62.1151Z" fill="url(#paint4_linear_1493_15108)"/>
      <path d="M68.315 62.1148C65.5947 56.5885 61.04 52.9669 55.8821 52.9537C47.5328 52.9537 40.7666 62.4404 40.7666 74.1245C40.7666 85.8085 47.5328 95.282 55.8821 95.2952C61.04 95.2952 65.5947 91.6768 68.315 86.1341C70.0705 82.3987 70.9835 78.291 70.9835 74.1277C70.9835 69.9645 70.0705 65.8568 68.315 62.1214V62.1148Z" fill="url(#paint5_linear_1493_15108)"/>
      <path d="M57.8754 70.2208C57.1222 71.6077 55.8615 72.5159 54.4342 72.5159H53.0555C50.7475 72.5159 48.876 70.1409 48.876 67.2121C48.876 64.2834 50.7475 61.9092 53.0555 61.9084H54.4386C55.8659 61.9084 57.1283 62.8149 57.8797 64.2034C58.3641 65.1394 58.6159 66.1684 58.6159 67.2113C58.6159 68.2542 58.3641 69.2832 57.8797 70.2192L57.8754 70.2208Z" fill="url(#paint6_linear_1493_15108)"/>
      <path d="M57.8754 84.5C57.1222 85.8869 55.8615 86.795 54.4342 86.795H53.0555C50.7475 86.795 48.876 84.4201 48.876 81.4913C48.876 78.5625 50.7475 76.1884 53.0555 76.1876H54.4386C55.8659 76.1876 57.1283 77.094 57.8797 78.4826C58.3641 79.4186 58.6159 80.4476 58.6159 81.4905C58.6159 82.5334 58.3641 83.5624 57.8797 84.4983L57.8754 84.5Z" fill="url(#paint7_linear_1493_15108)"/>
      <path d="M56.335 70.2208C55.5984 71.6053 54.3652 72.5126 52.9687 72.5159C50.708 72.5159 48.876 70.1392 48.876 67.2121C48.876 64.285 50.708 61.9117 52.9687 61.9084C54.3652 61.9084 55.5984 62.8149 56.335 64.2034C56.8103 65.1392 57.0575 66.1683 57.0575 67.2113C57.0575 68.2543 56.8103 69.2834 56.335 70.2192V70.2208Z" fill="#A9C1FF"/>
      <path d="M56.335 84.5C55.5984 85.8844 54.3652 86.7917 52.9687 86.795C50.708 86.795 48.876 84.4184 48.876 81.4913C48.876 78.5642 50.708 76.1909 52.9687 76.1876C54.3652 76.1876 55.5984 77.094 56.335 78.4826C56.8103 79.4184 57.0575 80.4475 57.0575 81.4905C57.0575 82.5335 56.8103 83.5625 56.335 84.4983V84.5Z" fill="#A9C1FF"/>
      <path d="M60.6946 46.5544H38.3606C30.872 46.5544 24.7969 38.0611 24.7969 27.581C24.7969 17.1009 30.872 8.60437 38.3606 8.60437H60.6946C56.0613 8.60437 51.9715 11.8543 49.5276 16.8279C47.9248 20.1668 47.1043 23.8573 47.134 27.5942C47.1042 31.33 47.9247 35.0195 49.5276 38.3572C51.9715 43.3045 56.0613 46.5544 60.6946 46.5544Z" fill="url(#paint8_linear_1493_15108)"/>
      <path d="M44.6335 15.5844C47.3632 10.0483 51.9274 6.42334 57.0947 6.42334H62.1018C70.4574 6.42334 77.233 15.9035 77.233 27.5941C77.233 39.2847 70.4574 48.7615 62.1018 48.7648H57.0947C51.9274 48.7648 47.3569 45.1464 44.6335 39.6037C42.8488 35.8782 41.9348 31.762 41.9666 27.5941C41.9348 23.4262 42.8488 19.31 44.6335 15.5844Z" fill="url(#paint9_linear_1493_15108)"/>
      <path d="M49.6814 15.5844C52.4048 10.0582 56.9564 6.4365 62.1174 6.42334C70.4667 6.42334 77.2329 15.91 77.2329 27.5941C77.2329 39.2781 70.4667 48.7516 62.1174 48.7615C56.9564 48.7615 52.4048 45.1431 49.6814 39.6037C47.8967 35.8782 46.9827 31.762 47.0145 27.5941C46.9827 23.4262 47.8967 19.31 49.6814 15.5844Z" fill="url(#paint10_linear_1493_15108)"/>
      <path d="M63.4178 23.9692C65.0503 23.9692 66.3737 22.0458 66.3737 19.6732C66.3737 17.3006 65.0503 15.3772 63.4178 15.3772C61.7853 15.3772 60.4619 17.3006 60.4619 19.6732C60.4619 22.0458 61.7853 23.9692 63.4178 23.9692Z" fill="url(#paint11_linear_1493_15108)"/>
      <path d="M63.4854 24.7824C65.5295 24.7824 67.1865 22.4992 67.1865 19.6826C67.1865 16.8661 65.5295 14.5829 63.4854 14.5829C61.4413 14.5829 59.7842 16.8661 59.7842 19.6826C59.7842 22.4992 61.4413 24.7824 63.4854 24.7824Z" fill="#5484FF"/>
      <path d="M63.4854 41.1014C65.5295 41.1014 67.1865 38.8181 67.1865 36.0016C67.1865 33.1851 65.5295 30.9019 63.4854 30.9019C61.4413 30.9019 59.7842 33.1851 59.7842 36.0016C59.7842 38.8181 61.4413 41.1014 63.4854 41.1014Z" fill="#5484FF"/>
      <path d="M18.7278 58.9124C19.0691 57.4321 21.1771 57.4321 21.5184 58.9124C21.639 59.4356 22.0466 59.8485 22.5671 59.9801C24.0662 60.359 24.0662 62.5039 22.5671 62.8828C22.0466 63.0144 21.639 63.4273 21.5184 63.9505C21.1771 65.4308 19.0691 65.4308 18.7278 63.9505C18.6072 63.4273 18.1996 63.0144 17.6791 62.8828C16.18 62.5039 16.18 60.359 17.6791 59.9801C18.1996 59.8485 18.6072 59.4356 18.7278 58.9124Z" fill="url(#paint12_linear_1493_15108)"/>
      <path d="M63.4178 23.9692C65.0503 23.9692 66.3737 22.0458 66.3737 19.6732C66.3737 17.3006 65.0503 15.3772 63.4178 15.3772C61.7853 15.3772 60.4619 17.3006 60.4619 19.6732C60.4619 22.0458 61.7853 23.9692 63.4178 23.9692Z" fill="url(#paint13_linear_1493_15108)"/>
      <path d="M63.4178 40.2883C65.0503 40.2883 66.3737 38.3649 66.3737 35.9923C66.3737 33.6197 65.0503 31.6963 63.4178 31.6963C61.7853 31.6963 60.4619 33.6197 60.4619 35.9923C60.4619 38.3649 61.7853 40.2883 63.4178 40.2883Z" fill="url(#paint14_linear_1493_15108)"/>
      <path d="M86.5999 6.05277C86.9411 4.57246 89.0492 4.57246 89.3904 6.05277C89.511 6.57598 89.9186 6.98885 90.4392 7.12044C91.9383 7.49939 91.9383 9.64425 90.4392 10.0232C89.9186 10.1548 89.511 10.5677 89.3904 11.0909C89.0492 12.5712 86.9411 12.5712 86.5999 11.0909C86.4793 10.5677 86.0717 10.1548 85.5511 10.0232C84.052 9.64425 84.052 7.49939 85.5511 7.12044C86.0717 6.98885 86.4793 6.57598 86.5999 6.05277Z" fill="url(#paint15_linear_1493_15108)"/>
      <mask id="path-24-inside-1_1493_15108" fill="white">
      <ellipse cx="20.1228" cy="40.3591" rx="2.38746" ry="2.50011"/>
      </mask>
      <path d="M19.884 40.3591C19.884 40.3638 19.8841 40.342 19.9116 40.3132C19.9256 40.2986 19.95 40.2785 19.9885 40.2615C20.0292 40.2434 20.0768 40.2329 20.1228 40.2329V45.4855C23.0046 45.4855 25.1366 43.0749 25.1366 40.3591H19.884ZM20.1228 40.2329C20.1688 40.2329 20.2164 40.2434 20.2571 40.2615C20.2956 40.2785 20.3201 40.2986 20.334 40.3132C20.3615 40.342 20.3616 40.3638 20.3616 40.3591H15.1091C15.1091 43.0749 17.241 45.4855 20.1228 45.4855V40.2329ZM20.3616 40.3591C20.3616 40.3544 20.3615 40.3763 20.334 40.4051C20.3201 40.4196 20.2956 40.4397 20.2571 40.4568C20.2164 40.4748 20.1688 40.4853 20.1228 40.4853V35.2327C17.241 35.2327 15.1091 37.6433 15.1091 40.3591H20.3616ZM20.1228 40.4853C20.0768 40.4853 20.0292 40.4748 19.9885 40.4568C19.95 40.4397 19.9256 40.4196 19.9116 40.4051C19.8841 40.3763 19.884 40.3544 19.884 40.3591H25.1366C25.1366 37.6433 23.0046 35.2327 20.1228 35.2327V40.4853Z" fill="url(#paint16_linear_1493_15108)" mask="url(#path-24-inside-1_1493_15108)"/>
      <mask id="path-26-inside-2_1493_15108" fill="white">
      <ellipse cx="97.6257" cy="86.0315" rx="2.38746" ry="2.50011"/>
      </mask>
      <path d="M97.3869 86.0315C97.3869 86.0362 97.3871 86.0143 97.4146 85.9855C97.4285 85.971 97.4529 85.9509 97.4915 85.9338C97.5322 85.9158 97.5797 85.9053 97.6257 85.9053V91.1579C100.508 91.1579 102.64 88.7473 102.64 86.0315H97.3869ZM97.6257 85.9053C97.6718 85.9053 97.7193 85.9158 97.76 85.9338C97.7985 85.9509 97.823 85.971 97.8369 85.9855C97.8644 86.0143 97.8646 86.0362 97.8646 86.0315H92.612C92.612 88.7473 94.7439 91.1579 97.6257 91.1579V85.9053ZM97.8646 86.0315C97.8646 86.0268 97.8644 86.0486 97.8369 86.0774C97.823 86.092 97.7985 86.1121 97.76 86.1291C97.7193 86.1472 97.6718 86.1577 97.6257 86.1577V80.9051C94.7439 80.9051 92.612 83.3157 92.612 86.0315H97.8646ZM97.6257 86.1577C97.5797 86.1577 97.5322 86.1472 97.4915 86.1291C97.4529 86.1121 97.4285 86.092 97.4146 86.0774C97.3871 86.0486 97.3869 86.0268 97.3869 86.0315H102.64C102.64 83.3157 100.508 80.9051 97.6257 80.9051V86.1577Z" fill="url(#paint17_linear_1493_15108)" mask="url(#path-26-inside-2_1493_15108)"/>
      <path d="M96.2825 55.4968C95.9581 54.1643 97.134 52.9626 98.3981 53.3346C99.6612 53.7063 100.065 55.3706 99.1255 56.3311C98.1857 57.2915 96.6067 56.8281 96.2825 55.4968Z" fill="url(#paint18_linear_1493_15108)"/>
      <path d="M32.7159 88.68C33.988 88.3422 35.131 89.5718 34.7743 90.8943C34.4173 92.2181 32.8259 92.6421 31.9105 91.6574C30.9951 90.6727 31.4426 89.0182 32.7159 88.68Z" fill="url(#paint19_linear_1493_15108)"/>
      <path d="M107.997 33.6231C107.67 36.2028 106.724 38.6537 105.247 40.7465C103.77 42.8394 101.81 44.5058 99.5512 45.5901C97.2921 46.6744 94.8073 47.1411 92.3291 46.9467C89.8509 46.7522 87.4603 45.9029 85.3807 44.4782L79.7265 46.4946C79.5549 46.5547 79.3704 46.5617 79.195 46.5149C79.0197 46.4681 78.8609 46.3695 78.7377 46.2307C78.6144 46.0919 78.5319 45.919 78.5001 45.7324C78.4682 45.5459 78.4883 45.3537 78.558 45.1788L80.8322 39.4947C79.6293 37.3863 78.9259 35.007 78.7806 32.5557C78.6354 30.1043 79.0525 27.6522 79.9973 25.4042C80.942 23.1563 82.3868 21.1778 84.2111 19.6341C86.0353 18.0904 88.1858 17.0264 90.483 16.531C92.7801 16.0356 95.1572 16.1232 97.4154 16.7865C99.6736 17.4498 101.747 18.6694 103.463 20.3436C105.18 22.0177 106.488 24.0977 107.279 26.4097C108.071 28.7216 108.323 31.1984 108.013 33.6329L107.997 33.6231Z" fill="url(#paint20_linear_1493_15108)"/>
      <mask id="path-31-inside-3_1493_15108" fill="white">
      <ellipse cx="47.7566" cy="2.80358" rx="2.38746" ry="2.50011"/>
      </mask>
      <path d="M47.5178 2.80358C47.5178 2.80826 47.5179 2.78643 47.5454 2.75764C47.5594 2.74306 47.5838 2.72299 47.6223 2.70593C47.663 2.6879 47.7106 2.6774 47.7566 2.6774V7.92999C50.6384 7.92999 52.7704 5.51939 52.7704 2.80358H47.5178ZM47.7566 2.6774C47.8026 2.6774 47.8502 2.6879 47.8909 2.70593C47.9294 2.72299 47.9539 2.74306 47.9678 2.75764C47.9953 2.78643 47.9954 2.80826 47.9954 2.80358H42.7428C42.7428 5.51939 44.8748 7.92999 47.7566 7.92999V2.6774ZM47.9954 2.80358C47.9954 2.79891 47.9953 2.82073 47.9678 2.84952C47.9539 2.8641 47.9294 2.88417 47.8909 2.90123C47.8502 2.91926 47.8026 2.92976 47.7566 2.92976V-2.32283C44.8748 -2.32283 42.7428 0.087766 42.7428 2.80358H47.9954ZM47.7566 2.92976C47.7106 2.92976 47.663 2.91926 47.6223 2.90123C47.5838 2.88417 47.5594 2.8641 47.5454 2.84952C47.5179 2.82073 47.5178 2.79891 47.5178 2.80358H52.7704C52.7704 0.087766 50.6384 -2.32283 47.7566 -2.32283V2.92976Z" fill="url(#paint21_linear_1493_15108)" mask="url(#path-31-inside-3_1493_15108)"/>
      <path d="M93.0652 28.773H94.9052L95.3212 23.365L95.4172 20.837H92.5532L92.6492 23.365L93.0652 28.773ZM93.9772 33.317C94.9692 33.317 95.6732 32.533 95.6732 31.541C95.6732 30.565 94.9692 29.781 93.9772 29.781C93.0012 29.781 92.2972 30.565 92.2972 31.541C92.2972 32.533 93.0012 33.317 93.9772 33.317Z" fill="white"/>
      <rect x="36.4092" y="63.5406" width="19.4799" height="7.34364" rx="3.67182" fill="url(#paint22_linear_1493_15108)"/>
      <rect x="36.4092" y="77.8198" width="19.4799" height="7.34364" rx="3.67182" fill="url(#paint23_linear_1493_15108)"/>
      <defs>
      <filter id="filter0_f_1493_15108" x="1.30755" y="18.4732" width="97.6603" height="53.4149" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_1493_15108"/>
      </filter>
      <filter id="filter1_f_1493_15108" x="61.6757" y="18.1161" width="68.3283" height="53.4149" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_1493_15108"/>
      </filter>
      <filter id="filter2_f_1493_15108" x="17.6777" y="66.6895" width="97.6603" height="53.4149" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_1493_15108"/>
      </filter>
      <linearGradient id="paint0_linear_1493_15108" x1="60.1025" y1="-10.0385" x2="61.1064" y2="154.301" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="1" stop-color="#9DB3FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1493_15108" x1="25.1105" y1="33.601" x2="5.61589" y2="15.305" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1493_15108" x1="91.7471" y1="78.7537" x2="110.251" y2="90.6502" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1493_15108" x1="87.9295" y1="81.5657" x2="63.3889" y2="37.3232" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1493_15108" x1="70.8661" y1="82.4289" x2="41.7548" y2="36.2189" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint5_linear_1493_15108" x1="43.4075" y1="76.8697" x2="71.067" y2="76.6837" gradientUnits="userSpaceOnUse">
      <stop stop-color="#B4C9FF"/>
      <stop offset="1" stop-color="#E8F1FF"/>
      </linearGradient>
      <linearGradient id="paint6_linear_1493_15108" x1="60.1743" y1="70.068" x2="50.9755" y2="57.8031" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint7_linear_1493_15108" x1="60.1743" y1="84.3472" x2="50.9755" y2="72.0823" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint8_linear_1493_15108" x1="34.2659" y1="48.2887" x2="32.4085" y2="-10.1532" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint9_linear_1493_15108" x1="63.096" y1="39.517" x2="54.7829" y2="-12.5667" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint10_linear_1493_15108" x1="49.6548" y1="30.3374" x2="77.3164" y2="30.1514" gradientUnits="userSpaceOnUse">
      <stop stop-color="#B4C9FF"/>
      <stop offset="1" stop-color="#E8F1FF"/>
      </linearGradient>
      <linearGradient id="paint11_linear_1493_15108" x1="57.6401" y1="24.1288" x2="80.7186" y2="22.4638" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint12_linear_1493_15108" x1="25.9048" y1="64.7937" x2="14.8752" y2="44.7178" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint13_linear_1493_15108" x1="61.3426" y1="16.4189" x2="82.9345" y2="22.2363" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint14_linear_1493_15108" x1="61.3426" y1="32.738" x2="82.9345" y2="38.5554" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint15_linear_1493_15108" x1="93.7768" y1="11.9341" x2="82.7473" y2="-8.14179" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint16_linear_1493_15108" x1="21.8091" y1="41.3398" x2="18.5922" y2="35.4843" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint17_linear_1493_15108" x1="99.3121" y1="87.0121" x2="96.0951" y2="81.1567" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint18_linear_1493_15108" x1="100.593" y1="54.2936" x2="91.7611" y2="51.5512" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint19_linear_1493_15108" x1="33.8542" y1="93.1966" x2="36.9604" y2="84.1144" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint20_linear_1493_15108" x1="78.4969" y1="16.2884" x2="94.4315" y2="46.398" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AEF5FF"/>
      <stop offset="1" stop-color="#4A8CF5"/>
      </linearGradient>
      <linearGradient id="paint21_linear_1493_15108" x1="49.4429" y1="3.78424" x2="46.226" y2="-2.07122" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      <linearGradient id="paint22_linear_1493_15108" x1="53.0287" y1="68.6527" x2="41.0286" y2="57.2341" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F7FF"/>
      </linearGradient>
      <linearGradient id="paint23_linear_1493_15108" x1="53.0287" y1="82.9319" x2="50.8978" y2="72.158" gradientUnits="userSpaceOnUse">
      <stop stop-color="#5484FF"/>
      <stop offset="1" stop-color="#F7F8FF"/>
      </linearGradient>
      </defs>
      </svg>
    </div>
    <!-- 摄像头信息 -->
    <div class="info">
      <!-- {{ data.monitor_name }} -->
      <span class="name" v-if="data.type_name">{{ data.type_name }}</span>
      <span class="type-icon">
        <i
          class="iconfont"
          :class="data.yun_tai_status === 1 ? 'iconqiujijiankong' : 'iconqiangjijiankong'"
          :style="{ color: data.onLine == 1 ? '#409eff' : '' }"
        />
      </span>
      <!-- 在线状态 -->
      <!-- <span class="type-icon">
        <i class="iconfont iconwifi" :style="data.onLine == 1 ? 'color: #00aa00' : 'color: #aaa'" />
      </span> -->
    </div>
  </div>
</template>
<script>
/**
 * @desc 通用视频组件
 */
import { Empty, Image } from 'element-ui'
import Player from './player';
export default {
  components: {
    Player,
    'el-empty': Empty,
    'el-image': Image
  },
  props: {
		// 组件数据
    element: {
      type: Object,
      default: () => {},
      required: true
    },
    fullData: {
			type: [Object, Array],
      required: false
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    mapData: {
			type: Object,
			default: () => {}
		}
  },
  computed: {
    /**
     * @description: 视频组件配置内容
     * @return {Object} 视频组件配置对象
     */    
    config() {
      let data = {}
      if (this.element?.statusConfig) {
        data = this.element?.statusConfig
      }
      return data
    },
    /**
     * @description: 获取映射值中的字段
     * @return {Object} 映射值字段对象
     */    
    data() {
      const mapConfig = this.element?.mapConfig || [];
      const { renderData = {} } = this.mapData || {};
      const hash = {};
      mapConfig.forEach(el => {
        if(el.from) {
          const data = mapConfig.find(ele => ele.key === el.from);
          if (data) {
            if(Array.isArray(renderData[data.bindField]) && renderData[data.bindField].length) {
              hash[el.key] = renderData[data.bindField][0][el.bindField];
            }
          }
        } else {
          hash[el.key] = renderData[el.bindField];
        }
      });
      return hash;
    },
    coverImage() {
      const imageURLData = this.data.monitor_img
      if (typeof imageURLData === 'string') {
        return this.data.monitor_img || ''
      } else if (Array.isArray(this.data.monitor_img) && this.data.monitor_img.length) {
        return this.data.monitor_img[0].url || ''
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      // 封面--播放器  切换标志
      isPlay: false
    }
  },
  created() {
    // 如果默认是播放
    if(this.config.autoplay) {
      this.isPlay = true;
    }
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
  .common-video-container{
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .cover-img{
      width: 100%;
      height: 100%;
      object-fit: fill;
      :deep(.image-slot){
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .outline-icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .play-btn{
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0px 0px 4px rgba(125, 125, 125, .5);
    }
    .info{
      position: absolute;
      top: 16px;
      left: 16px;
      display: flex;
      align-items: center;
      .name{
        background: #fff;
        color: #000;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 5px;
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .type-icon{
        margin-left: 8px;
        color: #fff;
        .iconfont{
          font-size: 24px;
        }
      }
    }
  }
</style>