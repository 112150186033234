<!--
 * @Description: 折柱混合
 * @Author: luocheng
 * @Date: 2022-05-31 09:30:51
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-11 09:17:05
-->
<template>
	<div
		class="chart-bar"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option && canShow"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';

export default {
	name: 'ChartBar',
	mixins: [mixin],
	data() {
		return {
			option: null,
			attributes: null
		};
	},
	created() {
		this.getOptions();
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {Array} data
		 * @param {Boolean} isStatic 是否为静态数据
		 */
		setOptions(data, isStatic = false) {
			if (!data || !data.length) return false;
			const { attributes = {}, configs = [] } = this.chartData;
			this.attributes = attributes;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				legenFontSize = 12,
				colorRange = [],
				barWidth = 20,
				showLine,
				barChart = false,
				axisFontSize = 12,
				axisTextColor = '#ffffff',
				xAxisText = '',
				xAxisTextFontSize = 12,
				xAxisTextColor = '#ffffff',
				yAxisText = '',
				yAxisTextFontSize = 12,
				yAxisTextColor = '#ffffff',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				yUnitLeft = '',
    		yUnitRight = '',
				differentY= false,
				minInterval = 0,
				labelRotate = 0,
				dataZoomBackGround ='rgba(47,69,84,0)',
				dataZoomFill = 'rgba(167,183,204,0.4)',
				areaStyleColor = '#d2dbee',
				areaStyleOpacity=  0.2,
				areaStyleShadowColor = '',
				selectedDataAreaColor ='#8fb0f7',
				selectedDataAreaOpacity = 0.2,
				selectedDataAreaShadowColor = '',
				dateZoomHeight = 30,
				moveHandleColor = '#D2DBEE',
				hiddenXAxis = false,
				hiddenYAxis = false
			} = attributes;
			// const { xData = [], names = '' } = data[0];
			const xData = data[0]?.xData || [];
			if (!xData?.length) {
				this.canShow = false;
				return false;
			}
			const series = [];
			data.forEach((ele, index) => {
				series.push({
					data: ele.series || [],
					chartType: isStatic ? ele.chartType : configs[index]?.chartType || 'bar'
				});
			});
			const xAxis = {
				show: !hiddenXAxis,
				type: 'category',
				data: xData,
				name: xAxisText,
				nameTextStyle: {
					color: xAxisTextColor,
					fontSize: this.scaleSize(xAxisTextFontSize),
					align: 'right'
				},
				axisTick: {
					alignWithLabel: true,
					show: false
				},
				splitLine: {
					show: false //去掉网格线
				},
				axisLabel: {
					textStyle: {
						color: axisTextColor,
						fontSize: this.scaleSize(axisFontSize)
					},
					rotate: labelRotate,
					margin: 16 // 文案与坐标轴间距
				},
				axisLine: {
					show: true // 隐藏坐标轴
				}
			};
			const yAxis = [
				{
					show: !hiddenYAxis,
					type: 'value',
					name: yAxisText,
					nameTextStyle: {
						color: yAxisTextColor,
						fontSize: this.scaleSize(yAxisTextFontSize),
						align: 'center'
					},
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: true,
						formatter: `{value} ${yUnitLeft}`,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				},
				{
					show: !hiddenYAxis,
					type: 'value',
					name: yAxisText,
					nameTextStyle: {
						color: yAxisTextColor,
						fontSize: this.scaleSize(yAxisTextFontSize),
						align: 'center'
					},
					// 隐藏刻度线
					axisTick: {
						show: differentY ? true : false
					},
					// 隐藏轴线
					axisLine: {
						show: differentY ? true : false
					},
					splitLine: {
						show: differentY ? true : false,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: differentY ? true : false,
						formatter: `{value} ${yUnitRight}`,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				}
			];
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				yAxis.forEach(el => {
					el.minInterval = number
				})
			}
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					},
					appendToBody: true
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(data, legendTextColor, showLine),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
					color: colorRange,
					textStyle: {
						fontSize: this.scaleSize(legenFontSize)
					}
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: dataZoomBackGround,
						fillerColor: dataZoomFill,
						dataBackground: {
							areaStyle: {
								color: areaStyleColor,
								opacity: areaStyleOpacity,
								shadowColor: areaStyleShadowColor
							}
						},
						selectedDataBackground: {
								areaStyle: {
									color: selectedDataAreaColor,
									opacity:selectedDataAreaOpacity
								},
								shadowColor: selectedDataAreaShadowColor
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
						height: dateZoomHeight,
						moveHandleStyle: {
							color: moveHandleColor
						}
					}
				],
				xAxis: barChart ? yAxis : xAxis,
				yAxis: barChart ? xAxis : yAxis,
				series: this.getSeries(series, barWidth)
			};
		},
		/**
		 * @desc: 获取series
		 * @param {Array} series
		 * @param {Number} barWidth
		 */
		getSeries(series = [], barWidth) {
			let s = [];
			if (!series) {
				return [];
			}
			const { attributes = {}, specialAttributes = {} } = this.chartData;
			const { XColorRange = [], showBarLabel = true, showLineLabel = true,useGradient = false, gradientRange = [], differentY= false, userStack = false, useArea = false, useOverlap = false, markLines, smooth } = attributes;
			for (let i = 0, len = series.length; i < len; i++) {
				const { data = [], chartType = 'bar', } = series[i];
				let result = [];
				for (let j = 0, l = data.length; j < l; j++) {
					// const name = data[0]?.name
					const name = data[j].name;
					const dataList = data[j].data;
					result = dataList?.map((value, index) => {
						const dataResult = {
							value
						}
						if(chartType === 'bar') {
							// change start 当接口为合并动态统计时，会将数据合并，这时候j的值始终为0，故无法正常计算，当data长度为1时，取消j参与运算
							if (data.length === 1) {
								dataResult.itemStyle = {
									color: XColorRange[i % XColorRange.length]
								}
							// change end
							} else {
								dataResult.itemStyle = {
									color: XColorRange[(i + 1) * j % XColorRange.length]
								}
							}
						}
						if(chartType === 'bar' && useGradient) {
							dataResult.itemStyle.color = {
								type: 'linear',
								x: 0.5,
								y: 1,
								x2: 0.5,
								y2: 0,
								colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
								gradientRange[i % gradientRange.length].map((el, index) => {
									if(index === 0) {
										return ({
											offset: 0,
											color: el
										})
									}
									return ({
										offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
										color: el
									})
								}) : [],
								global: false // 缺省为 false
							}
						}
						return dataResult
					})
					let lineStackStyle = null
					if(chartType !== 'bar' && (userStack || useArea)) {
						lineStackStyle = {}
						if(userStack) {
							lineStackStyle.stack = 'Total'
						}
						const areaStyle = {
							opacity: 0.3
						}
						if(useGradient) {
							areaStyle.color = {
								type: 'linear',
								x: 0.5,
								y: 1,
								x2: 0.5,
								y2: 0,
								colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
								gradientRange[i % gradientRange.length].map((el, index) => {
									if(index === 0) {
										return ({
											offset: 0,
											color: el
										})
									}
									return ({
										offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
										color: el
									})
								}) : [],
								global: false // 缺省为 false
							}
						}
						lineStackStyle.areaStyle = areaStyle
					}
					if(this.indialog) {
						barWidth = barWidth * 2
					}
					let res = {
						name,
						type: chartType,
						barWidth,
						data: result,
						label: {
							show: showBarLabel,
							position: this.attributes.barChart ? 'right' : 'top',
							color: useGradient ? this.attributes.textColor : 'inherit', // 设置跟随为视觉映射得到的颜色，如系列色
							fontSize: this.scaleSize(12),
							...this.getSeriesLabelConfig(specialAttributes?.seriesLabel?.[i])
						},
						// stack: 'Total', // 堆叠
						stack: userStack ? 'total' : ''
					}
					if(chartType === 'line') {
						res.label.show = showLineLabel
						if(smooth) {
							res.smooth = !!smooth
						}
					}
					const markLine = {
						data: []
					}
					if(Array.isArray(markLines) && markLines.length) {
						const markLineData = []
						markLines.forEach(item => {
							const { labelName = '默认标题', lineColor = '', type = '', fixedValOne = '', fixedValTow = '', attachmentVal = '' } = item
							const obj = {
								name: labelName,
								label: {
									formatter: '{b}-{c}',
									position: 'insideStart'
								}
							}
							if(type === 'attachment' && !isNaN(attachmentVal) && i === 0) {
								obj.yAxis = attachmentVal
							} else if (type === 'fixed' && !isNaN(fixedValOne) && !isNaN(fixedValTow) && i === 0) {
								const startObj = {
									name: 'start: ' + labelName,
									coord: [0, Number(fixedValOne)],
									label: {
										formatter: labelName,
										position: 'insideStart'
									}
								}
								const endObj = {
									name: 'end: ' + labelName,
									coord: [dataList.length - 1, Number(fixedValTow)]
								}
								if(lineColor) {
									startObj.lineStyle = {
										color: lineColor
									}
									endObj.lineStyle = {
										color: lineColor
									}
								}
								markLineData.push([startObj, endObj])
							} else if(type === 'average') {
								const sum = dataList.reduce((a, b) =>{
									const av = a.value ?? a
									const bv = b.value ?? b
									return Number(av) + Number(bv)
								}, 0)
								const val = sum / dataList.length
								obj.yAxis = val
							}
							if(lineColor) {
								obj.lineStyle = {
									color: lineColor
								}
							}
							if(type !== 'fixed' && type !== 'attachment') {
								markLineData.push(obj)
							}
							if(type === 'attachment' && i === 0) {
								markLineData.push(obj)
							}
						})
						if(markLineData.length) {
							markLine.data = markLineData
						}
					}
					res.markLine = markLine
					// 仅使用双边下为第二个指定第二个y轴
					if(i === 1 && differentY) {
						res.yAxisIndex = 1
					}
					if(lineStackStyle) {
						res = Object.assign({}, res, lineStackStyle)
					}
					if(chartType === 'line' && this.indialog) {
						res = Object.assign({}, res, {
						lineStyle: {
							width: 4
						},
						symbolSize: 8
						})
					}
					if (useOverlap) {
						s.push({
							...res,
							// 重叠柱状图
							z: j,
							barGap: '-100%'
						});
					} else {
						s.push(res);
					}
				} 
			}
			return s;
		},
		/**
		 * @desc: 获取图例数据
		 * @param {Object} dataFrom 配置数据
		 */
		getLegendData(dataList, color = '#000', showLine = false) {
			let s = [];
			for (let i = 0; i < dataList.length; i++) {
				const { series = [] } = dataList[i];
				for (let j = 0; j < series.length; j++) {
					s.push({
						name: series[j].name,
						textStyle: {
							color,
							// fontSize: this.scaleSize(12)
						}
					});
				}
				if (showLine && series.length > 1) {
					s.push({
						name: '累计',
						textStyle: {
							color,
							fontSize: this.scaleSize(12)
						}
					});
				}
			}
			return s;
		},
		/**
		 * @desc: 获取数据label说明配置
		 */
		getSeriesLabelConfig(config = null) {
			if (!config) return {};
			const { position = 'top', distance = 5, padding = 0 } = config;
			let paddingResult = 0;
			try {
				if (padding) {
					if (padding.length) {
						paddingResult = padding.split(',').map(ele => {
							return +ele;
						});
					} else {
						paddingResult = isNaN(+padding) ? 0 : +padding
					}
				}
			} catch (err) {
				console.log(err);
				paddingResult = 0;
			}
			return {
				position,
				distance: isNaN(+distance) ? 5 : +distance,
				padding: this.scaleSize(paddingResult)
			}
		}
	}
};
</script>

<style lang="less" scoped>
.chart-bar {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
	}
}
</style>
