<!--
    @name: FlowPicker
    @description：FlowPicker
    @author: ZengWei
    @date: 2021-10-27 13:44
-->
<template>
  <div class="flow-picker" v-loading="loading" element-loading-text="拼命加载中">
    <div v-if="selectStepList.length > 1 || turn || checkStep.length > 0 || sendStep.length > 0">
      <template v-if="selectStepList.length > 1 && !turn">
        <div class="type-label">选择流转分支</div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="流转分支">
            <el-select
              v-model="selectStep"
              placeholder="请选择流转分支"
              style="width: 286px"
              @change="getSelectStep"
            >
              <el-option
                v-for="(item, index) in selectStepList"
                :key="index"
                :label="item.nodeType === 'end' ? '结束' : item.name"
                :value="item.nodeId"
              >
                {{ item.nodeType === 'end' ? '结束' : item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-if="turn">
        <div class="type-label">选择转交人</div>
        <el-timeline style="margin-left: 15px">
          <el-timeline-item color="#0bbd87" :hollow="true" type="primary">
            <div style="display: flex; justify-content: space-between">
              <h4>
                <span style="color: red">*</span>
                请选择转交人
              </h4>
            </div>
            <div style="width: 100%">
              <select-user
                picker-node="turn"
                picker-type="check"
                position-type="left"
                :show-choose-button="true"
                @on-select-change="onSelectChange"
              />
            </div>
          </el-timeline-item>
        </el-timeline>
      </template>
      <template v-else>
        <template v-if="checkStep.length > 0">
          <div class="type-label">选择审批人</div>
          <el-timeline style="margin-left: 15px">
            <el-timeline-item
              v-for="(item, i) in checkStep"
              :key="i"
              color="#0bbd87"
              :hollow="true"
              type="primary"
            >
              <div style="display: flex; justify-content: space-between;margin-bottom: 10px">
                <h4>
                  <span style="color: red">*</span>
                  {{ item.name }}
                </h4>
              </div>
              <div style="width: 100%;margin-top: 8px" v-if="item.userType === 1 && item.userScope">
                <el-select style="width: 100%;" multiple v-model="item.rePicker" @change="rePicker">
                  <el-option
                    v-for="user in item.targetUser"
                    :key="user.id"
                    :label="user.name"
                    :value="user.id">
                    {{ user.name }}
                  </el-option>
                </el-select>
              </div>
              <div style="width: 100%" v-else>
                <select-user
                  :key="'check-'+item.nodeId + companyId(item)"
                  :company-id="companyId(item)"
                  :role-id="roleId(item)"
                  :departId="departId(item)"
                  :picker-node="item.nodeId"
                  :show-choose-button="true"
                  picker-type="check"
                  position-type="left"
                  @on-select-change="onSelectChange"
                />
              </div>
            </el-timeline-item>
          </el-timeline>
        </template>
        <template v-if="sendStep.length > 0">
          <div class="type-label">选择抄送人</div>
          <el-timeline style="margin-left: 15px">
            <el-timeline-item
              v-for="(item, i) in sendStep"
              :key="i"
              color="#0bbd87"
              :hollow="true"
              type="primary"
            >
              <div style="display: flex; justify-content: space-between">
                <h4>{{ item.name }}</h4>
              </div>
              <div style="width: 100%">
                <select-user
                  :key="'send-'+item.nodeId + companyId(item)"
                  :company-id="companyId(item)"
                  :role-id="roleId(item)"
                  :departId="departId(item)"
                  :picker-node="item.nodeId"
                  :show-choose-button="true"
                  picker-type="send"
                  position-type="left"
                  @on-select-change="onSelectChange"
                />
              </div>
            </el-timeline-item>
          </el-timeline>
        </template>
      </template>
    </div>
    <el-empty v-else description="流程无需选人"></el-empty>
  </div>
</template>

<script>
  import selectUser from '@/custom-component/form/formParser/selectUser/index'
  import { flowEngine } from '@/apis/data/workflow'
  import {Form, FormItem, Timeline, TimelineItem, Select , Option} from 'element-ui'
  import {Empty} from "element-ui";
  import eventBus from "@/plugins/eventBus";

  // let timer;
  export default {
    name: 'FlowPicker',
    components: {selectUser,
			'el-empty': Empty,
			'el-form': Form,
			'el-form-item': FormItem,
			'el-select': Select,
			'el-option': Option,
			'el-timeline': Timeline,
			'el-timeline-item': TimelineItem // 'el-tag': Tag,
		},
    inject: ["parser"],
    props: {
      module: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'self',
      },
      flowId: {
        type: [String, Number],
        default: 0,
      },
      dataId: {
        type: [String, Number],
        default: 0,
      },
      turn: {
        type: Boolean,
        default: false,
      },
      archId: {
        type: [String, Number],
        default: 0,
      },
      archTable: {
        type: String,
        default: 'org_projects',
      },
      //流程中修改的数据
      flowEdit:{
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return {
        loading: true,
        checkStep: [],
        sendStep: [],
        checkedUsers: [],
        userModalType: 'check',
        step_node: '',
        picker: [],
        checkPicker: [],
        sendPicker: [],
        depart: {},

        selectStepList: [],
        selectStep: '',

        targetTemp: {},
        sendTemp: {},
        dynamicArchId: ''
      }
    },
    watch:{
      archId(val){
        if(val) this.getFlowPicker()
      },
      turn(val) {
        if(!val) this.getFlowPicker()
      },
      // flowEdit:{
      //   handler(){
      //     // this.getFlowPicker()
      //     /*const that = this
      //     clearTimeout(timer);
      //     timer = setTimeout(()=>{
      //     },100)*/
      //   },
      //   deep:true
      // }
    },
    computed:{
      userSelected(){
        return function(type, node) {
          if (type === 'check' && this.targetTemp[node]) {
            return this.targetTemp[node]
          }
          if (type === 'send' && this.sendTemp[node]) {
            return this.sendTemp[node]
          }
          return []
        }
      },
      companyId(){
        return function (node) {
          let companyId = 0;
          if(node.userType === 5 || node.userType === 6) return companyId;
          if (node.userFrom === 3) {
            let archObj = JSON.parse(localStorage.getItem('targetArchi'))
            companyId = archObj.data.company_id || 0
          }
          return companyId
        }
      },
      departId(){
        return function (node) {
          let departId = [];
          if (node.userType === 5) {
            const section = node?.userSection || []
            departId = Array.from(section,t=>t.id)
          }
          return departId
        }
      },
      roleId() {
        return function (node) {
          let roleId = [];
          if (node.userType === 6) {
            roleId = node?.userJob || []
          }
          return roleId
        }
      }
    },
    methods:{
      // @陈忠 指定人员作为选择范围二次选人审批
      rePicker(){
        const picker = []
        for (let node of this.checkStep){
          if(node.rePicker){
            const user = node.targetUser.filter(item=>node.rePicker.includes(item.id))
            let itemData = {nodeId: node.nodeId, nodeUser: user}
            picker.push(itemData)
          }
        }
        let checkedUsers = {
          target_users: picker,
          send_users: [],
        }
        let number = 1
        if (this.checkStep.length > 0) number = this.checkStep.length
        this.$emit('checked-users', checkedUsers, number)
      },
      getSelectStep(value) {
        let selectStep = this.selectStepList.filter((item) => item.nodeId == value) || []
        if (selectStep instanceof Array) {
          if (selectStep[0].user_type && selectStep[0].user_type === 7) {
            this.checkStep = selectStep
          } else {
            this.checkStep = []
          }
        }
        this.$emit('selected-step', value)
      },
      onSelectChange(users, param) {
        let userData = []
        for (let item of users) {
          let user = {
            id: item.user_id,
            name: item.name,
            user_table: item.user_table,
            section_id: item.section_id
          }
          userData.push(user)
        }
        let { pickerNode, pickerType } = param
        if (pickerType === 'check' && userData.length >= 0) {
          this.targetTemp[pickerNode] = userData
        } else if (pickerType === 'send' && userData.length >= 0) {
          this.sendTemp[pickerNode] = userData
        }
        let selectedCheck = [],
          selectedSend = []
        for (let key in this.targetTemp) {
          if(this.targetTemp[key].length){
            let targetUser = { nodeId: key, nodeUser: this.targetTemp[key] }
            selectedCheck.push(targetUser)
          }
        }
        for (let key in this.sendTemp) {
          let sendUser = { nodeId: key, nodeUser: this.sendTemp[key] }
          selectedSend.push(sendUser)
        }
        this.checkPicker = selectedCheck
        this.sendPicker = selectedSend
        let checkedUsers = {
          target_users: this.checkPicker,
          send_users: this.sendPicker,
        }
        let number = -1
        if (this.turn) number = 1 //转交
        if (this.checkStep.length > 0) number = this.checkStep.length
        this.$emit('checked-users', checkedUsers, number)
      },
      getFlowPicker() {
        let params = { module: this.module }
        if (this.type === 'self') {
          if(this.archId){
            params.archi_id = this.archId
            params.archi_table = this.archTable
          }
          // 上级巡检使用下级组织流程
          if(this.dynamicArchId){
            params.archi_id = this.dynamicArchId
            params.archi_table = 'org_projects'
          }
          if (this.flowId) {
            params = { flow_id: this.flowId }
          }
        } else {
          params = { module: this.module, dataId: this.dataId }
        }
        if(this.parser) {
          const formData = this.parser.getFormData()
          if(Object.keys(formData).length > 0) {
            params.editFields = formData
          }
        }

        flowEngine.getNextPicker(params).then((res) => {
          if (res.data.code === 200) {
            let allNode = res.data.data
            this.checkStep = allNode.target_picker
            this.sendStep = allNode.send_picker
            this.selectStepList = allNode.select_step || []
            let number = this.checkStep.length
            if (this.turn) number = 1
            let checkedUsers = {
              target_users: [],
              send_users: [],
            }
            this.$emit('checked-users', checkedUsers, number)
          }
          this.loading = false
        })
      },
    },
    created () {
      this.getFlowPicker()
      eventBus.$on('TRIGGER_flow_branch',()=>{
        this.getFlowPicker() // 手动配置表单值改变触发流程分支
      })
    },
    destroyed() {
      eventBus.$off('TRIGGER_flow_branch')
    }
  }
</script>

<style scoped>
.flow-picker {
  min-height: 300px;
}
.type-label {
  border-left: 3px solid #007fe0 !important;
  padding: 0 10px;
  line-height: 1;
  font-size: 14px;
  margin: 0 0 16px;
  font-weight: 600;
}
</style>
