<!--
    @name: widget-number
    @description：widget-number
    @author: ZengWei
    @date: 2022-03-25 09:26
-->
<template>
  <div class="input-number-wrap">
    <el-input-number
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :controls="element.config.controls"
      :controls-position="element.config['controls-position']"
      :min="element.config.min"
      :max="element.config.max"
      :step="element.config.step"
      @change="onChange"
    ></el-input-number>
  </div>

</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {InputNumber} from "element-ui";

export default {
  name: "WidgetNumber",
  components: {
    'el-input-number': InputNumber
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {
    onChange(value){
      this.$emit('change-calc')
      if(this.element.config.__config__?.numberType) {
        let type = this.element.config.__config__.numberType;
        if(type === 1) value = Math.floor(value) // 向下取整
        if(type === 2) value = Math.ceil(value) // 向上取整
        if(type === 3) value = Math.round(value) // 四舍五入
      }
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        value = Number(value).toFixed(precision);
        if(value === 'NaN') value = Number('0').toFixed(precision);
      }
      this.element.value = value
    },
  },
}
</script>

<style lang="less" scoped>
.input-number-wrap {
  :deep(.el-input-number){
    border: 1px solid #eaebed;
    border-radius: 5px;
    width: 100%;
    input {
      text-align: right;
    }
  }
}
</style>
