import IBehaviors from './IBehaviors';

export default class ModelBehaviors extends IBehaviors {
  constructor(viewer, comp) {
    super(viewer, comp);
    this.viewer = viewer;
    this.comp = comp;

    this.init();
  }

  init() {
    const list = this.getBehaviors();

    this.registerBehaviors(list);
  }

  getBehaviors() {
    const parseParamDbids = (_dbids) => {
      return _dbids.split(',').map((str) => {
        return parseInt(str);
      });
    };

    return [
      {
        label: '[模型]加载模型',
        desc: `
                    参数[ modelId, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isLocation=>是否加载完成后定位模型 例如[true] 备注:参数选择：true/false，选填
                    `,
        value: 'model_create',
        func: (params) => {
          if (!params?.modelId) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, isLocation = 'false' } = params;
          this.viewer.Model.createModel(modelId, JSON.parse(isLocation));
        }
      },
      {
        label: '[模型]删除模型',
        desc: `
                    参数[ modelId ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    `,
        value: 'model_remove',
        func: (params) => {
          if (!params?.modelId) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId } = params;
          this.viewer.Model.removeModel(modelId);
        }
      },
      {
        label: '[模型]定位模型',
        desc: `
                    参数[ modelId ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948],
                    angle=>相机定位后的视角角度，选填，默认为30,
                    `,
        value: 'model_location',
        func: (params) => {
          if (!params?.modelId) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, angle } = params;
          this.viewer.Model.locationModel(modelId, angle);
        }
      },
      {
        label: '[模型]显示隐藏模型',
        desc: `
                    参数[ modelId, isShow ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isShow=>模型显示或隐藏 例如[true] 备注:参数选择：true/false，默认false
                    `,
        value: 'model_show',
        func: (params) => {
          if (!params?.modelId) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, isShow = 'false' } = params;
          this.viewer.Model.showModel(modelId, JSON.parse(isShow));
        }
      },
      {
        label: '[模型]选中构件',
        desc: `
                    参数[ modelId, dbids, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isLocation=>是否定位构件，选填，默认false
                    `,
        value: 'model_selectFeature',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, dbids, isLocation = 'false' } = params;
          this.viewer.Model.selectFeature(
            modelId,
            parseParamDbids(dbids),
            JSON.parse(isLocation)
          );
        }
      },
      {
        label: '[模型]隔离构件',
        desc: `
                    参数[ modelId, dbids, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isLocation=>是否定位构件，选填，默认false
                    `,
        value: 'model_insulateFeature',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, dbids, isLocation = 'false' } = params;
          this.viewer.Model.insulateFeature(
            modelId,
            parseParamDbids(dbids),
            JSON.parse(isLocation)
          );
        }
      },
      {
        label: '[模型]显示隐藏构件',
        desc: `
                    参数[ modelId, dbids, isShow, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isShow=>构件显示或隐藏，默认false
                    isLocation=>是否定位构件，选填，默认false
                    `,
        value: 'model_showFeature',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const {
            modelId,
            dbids,
            isShow = 'false',
            isLocation = 'false'
          } = params;
          this.viewer.Model.showFeature(
            modelId,
            parseParamDbids(dbids),
            JSON.parse(isShow),
            JSON.parse(isLocation)
          );
        }
      },
      {
        label: '[模型]显示隐藏全部构件',
        desc: `
                    参数[ modelId, isShow ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isShow=>全部构件显示或隐藏，默认false
                    `,
        value: 'model_showAllFeature',
        func: (params) => {
          if (!params?.modelId) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, isShow = 'true' } = params;
          this.viewer.Model.showAllFeature(modelId, JSON.parse(isShow));
        }
      },
      {
        label: '[模型]定位构件',
        desc: `
                    参数[ modelId, dbids, duration ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    duration=>定位到构件的飞行时长，默认0.5s
                    `,
        value: 'model_locationFeature',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, dbids, duration = '0.5' } = params;
          this.viewer.Model.locationFeature(
            modelId,
            parseParamDbids(dbids),
            Number(duration)
          );
        }
      },
      {
        label: '[模型]构件着色',
        desc: `
                    参数[ modelId, dbids, color ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    color=>构件着色颜色，例如rgba(255, 0, 0, 0.5) 或者 #ff0000
                    `,
        value: 'model_setFeatureColor',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, dbids, color } = params;
          this.viewer.Model.setFeatureColor(
              modelId,
              parseParamDbids(dbids),
              color
          );
        }
      },
      {
        label: '[模型]清除构件着色',
        desc: `
                    参数[ modelId, dbids ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    `,
        value: 'model_clearFeatureColor',
        func: (params) => {
          if (!params?.modelId || !params?.dbids) return;
          if (!this.viewer)
            return this.comp.$message.error('操作失败，viewer未初始化');

          const { modelId, dbids } = params;
          this.viewer.Model.clearFeatureColor(
              modelId,
              parseParamDbids(dbids)
          );
        }
      },
      {
        label: '[模型特殊]通用数据处理',
        desc: `
                    参数[data] 传入数据
                    参数[command] 处理数据的方法具体有{ [select: 选中构件], [hide: 隐藏构件], [color: 着色], [show: 显示构件] , [location: 定位到中心]}
                    可以进行多个组合，组合用","分割。例如[select, color] -> 选中并着色; [location, select] -> 定位到构件并选中
                    `,
        value: 'model_command',
        func: (params) => {
          if(!this.viewer) return this.$message.error("操作失败，viewer未初始化");
          if(!params.command || !params.data) return console.warn("[通用数据处理参数出错]");

          const commands = params.command.split(",");
          let data = params.data;

          if(typeof data === "string"){
            data = JSON.parse(data);
          }

          const modelMap = {};
          const colorMap = {};

          for(let obj of data ){
            if(!obj.selection || obj.selection === null) continue;
						if((Object.prototype.toString.call(obj.selection) === '[object Array]') || (obj.selection.models && Object.prototype.toString.call(obj.selection.models) === '[object Array]' && obj.selection.models?.length)){
							let selectionData = obj.selection
							if(obj.selection.models){
								selectionData = obj.selection.models
							}
							for(let i=0;i<selectionData.length;i++){
								let item = selectionData[i]
								if(!modelMap[item.model_id]){
									modelMap[item.model_id] = {
										dbids: []
									};
								}

								modelMap[item.model_id].dbids.push(...item.dbids);
								// console.log(commands.indexOf('color') !== -1,2222)
								if(commands.indexOf('color') !== -1 && obj.taskColor){
									modelMap[item.model_id].color = obj.taskColor;

									if(!colorMap[obj.taskColor]){
										colorMap[obj.taskColor] = [];
									}

									colorMap[obj.taskColor].push({
										model_id:item.model_id,
										dbids:item.dbids,
									});
								}
							}
						}
          }

          const selection = [];
					const loadedMode = this.viewer.Model.getModelKeys()
          for(let model_id in modelMap ){
            const { dbids } = modelMap[model_id];
            selection.push({
              model_id, dbids: Array.from(new Set(dbids))
            });
          }

          if(commands.indexOf('color') !== -1){
						console.log(colorMap, loadedMode)
            for(let color in colorMap){
              const list = colorMap[color];
              for(let {dbids, model_id} of list){
								if(loadedMode?.length && loadedMode.filter((v)=>v === model_id).length){
									this.viewer.Model.setFeatureColor(model_id, dbids, color);
								}

              }
            }
          }

          for(let command of commands){
            switch(command){
              case "select" :
                for (let {model_id, dbids} of selection){
                  this.viewer.Model.selectFeature(model_id, dbids, false);
                }
                break;
              case "hide":
                for (let {model_id, dbids} of selection){
                  this.viewer.Model.showFeature(model_id, dbids, false, false);
                }
                break;
              case "show":
                for (let {model_id, dbids} of selection){
                  this.viewer.Model.showFeature(model_id, dbids, true, false);
                }
                break;
              case "location":
                for (let {model_id, dbids} of selection){
                  this.viewer.Model.locationFeature(model_id, dbids, 0);
                }
                break;
            }
          }

        }
      },
      {
        label: '[模型特殊]通用数据处理-BIM商住',
        desc: `
                    参数[data] 传入数据
                    参数[command] 处理数据的方法具体有{ [select: 选中构件], [hide: 隐藏构件], [color: 着色], [show: 显示构件] , [location: 定位到中心]}
                    可以进行多个组合，组合用","分割。例如[select, color] -> 选中并着色; [location, select] -> 定位到构件并选中
                    `,
        value: 'model_command2',
        func: (params) => {
          if(!this.viewer) return this.$message.error("操作失败，viewer未初始化");
          if(!params.command || !params.data) return console.warn("[通用数据处理参数出错]");

          const commands = params.command.split(",");
          let data = params.data;

          if(typeof data === "string"){
            data = JSON.parse(data);
          }

          let obj = data.data;
          if(!obj.models) return;

          if(obj.models.length === 0 && commands.findIndex(x => x === 'location') !== -1){
            if(!obj.position) return console.warn("[GIS无参数可以定位]");

            this.viewer.Scene.flyTo(obj.position, 300);
            return;
          }

          const modelMap = {};
          for(let i = 0; i < obj.models.length; i++){
            let item = obj.models[i];
            if(!modelMap[item.model_id]){
              modelMap[item.model_id] = {
                dbids: []
              };
            }

            modelMap[item.model_id].dbids.push(...item.dbids);
          }

          for(let command of commands){
            switch(command){
              case "select" :
                for(let model_id in modelMap){
                  const { dbids } = modelMap[model_id];

                  this.viewer.Model.selectFeature(model_id, dbids, false);
                }
                break;
              case "hide":
                for(let model_id in modelMap){
                  const { dbids } = modelMap[model_id];

                  this.viewer.Model.showFeature(model_id, dbids, false, false);
                }
                break;
              case "show":
                for(let model_id in modelMap){
                  const { dbids } = modelMap[model_id];

                  this.viewer.Model.showFeature(model_id, dbids, true, false);
                }
                break;
              case "location":
                for(let model_id in modelMap){
                  const { dbids } = modelMap[model_id];

                  this.viewer.Model.locationFeature(model_id, dbids);
                }
                break;
            }
          }
        }
      },
      {
        label: '[模型特殊]通用数据处理-数据结构',
        desc: `
                    参数[data] => 通用数据对象
                    只处理模型相关操作，比如定位，选中，着色，显示隐藏等
                    `,
        value: 'model_command3',
        func: (params) => {
          if(!this.viewer) return this.$message.error("操作失败，viewer未初始化");
          if(!params.data) return console.warn("[通用数据处理参数出错]");

          let data = params.data;
          if(typeof data === "string"){
            data = JSON.parse(data);
          }

          for (let temp of data) {
            let modelId = temp.model_id;

            if(temp.select){
              this.viewer.Model.selectFeature(modelId, temp.select, false);
            }

            if(temp.hide){
              this.viewer.Model.showFeature(modelId, temp.hide, false, false);
            }

            if(temp.show){
              this.viewer.Model.showFeature(modelId, temp.hide, true, false);
            }

            if(temp.color){
              this.viewer.Model.setFeatureColor(modelId, temp.color.dbids, temp.color.value);
            }

            if(temp.location){
              this.viewer.Model.locationFeature(modelId, temp.location);
            }

          }
        }
      },
    ];
  }
}
