<!--
 * @Description: 年周选择器
 * @Author: luocheng
 * @Date: 2022-08-01 10:23:37
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-09 14:27:09
-->
<template>
  <div class="common-date year-week">
    <CalendarHeader
      :headerType="'year'"
      :year="year"
      @setQuickDate="getQuickDate"
    ></CalendarHeader>
    <article class="content" v-if="activeWeek">
      <ul class="date-list" v-if="weekList && weekList.length">
        <li class="date-item" v-for="(item, index) in weekList" :key="index"
          :class="{
            'is-today': item.isTargetWeek,
            'is-active': activeWeek && isActiveWeek(item)
          }"
          @click="onWeek(item)"
        >
          <!-- 基础类型 -->
          <div class="base-item" v-if="calendarType === 'base' || item.type !== 'target'">
            {{ item.isTargetWeek ? '本周' : item.desc }}
          </div>
          <!-- 符合类型 -->
          <div class="with-data-item" v-else-if="calendarType === 'withDatabase' || calendarType === 'justTotal'">
            <p class="date-text">
              {{ item.isTargetWeek ? '本周' : item.desc }}
            </p>
            <p class="count-text">
              <span class="finish-count"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !(+item.finishCount)
                }"
              >{{ item.finishCount }}</span>
              <template v-if="calendarType === 'withDatabase'">
                <span class="separate" :class="{
                  'null-count': (!(+item.totalCount)) && (!(+item.finishCount))
                }">/</span>
                <span class="total-count" :class="{
                  'null-count': !(+item.totalCount)
                }">{{ item.totalCount }}</span>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </article>
  </div>
</template>

<script>
import mixin from './mixin';
import CalendarHeader from './CalendarHeader';
import { formatTime } from '@/utils/tools';

export default {
  name: 'YearWeek',
  mixins: [mixin],
  components: {
    CalendarHeader
  },
  data() {
    return {
      year: '',
      weekList: [],
      currentWeek: null,
      activeWeek: null
    }
  },
  created() {
    this.initDate(true);
  },
  methods: {
    /**
     * @desc: 获取数据
     * @param {Boolean} isInit
     */
    initDate(isInit = false) {
      this.weekList = [];
      const now = new Date();
      if (isInit) {
        this.year = now.getFullYear();
        this.currentWeek = {
          year: this.year,
          month: now.getMonth() + 1,
          date: now.getDate(),
          desc: '',
          result: this.getWeekDuring(this.year, now.getMonth() + 1, now.getDate()),
        }
        this.onWeek(this.currentWeek)
      }
      if (this.year > now.getFullYear()) {
        return;
      }
      const firstDay = new Date(`${this.year}-01-01`).getDay() || 7;
      let startObj = new Date(`${this.year}-01-01 00:00:00`);
      if (firstDay !== 1) {
        // 非周一
        startObj = new Date(`${this.year}-01-${7 - firstDay + 2} 00:00:00`)
      }
      let startYear = now.getFullYear();
      // 年度结束时间
      const endTime = new Date(`${this.year + 1}-01-01 00:00:00`);
      let currentDate = null;
      let weekEndTime = null;
      for (let i = 0; i < 80; i++) {
        currentDate = new Date(startObj.getTime() + i * 7 * 86400000);
        weekEndTime = currentDate.getTime() + 7 * 86400000;
        if (weekEndTime > endTime) {
          // 超过本年
          startYear = startYear + 1;
        }
        this.weekList.push({
          isTargetWeek: this.isTargetWeek(this.year, currentDate.getMonth() + 1, currentDate.getDate()),
          year: this.year,
          month: currentDate.getMonth() + 1,
          desc: `第${i + 1}周`,
          result:  this.getWeekDuring(this.year, currentDate.getMonth() + 1, currentDate.getDate())
        });
        if (weekEndTime > now.getTime()) {
          break;
        }
      }
    },
    /**
     * @desc: 快捷切换
     * @param {Object} dateObj
     */
    getQuickDate(dateObj) {
      this.year = dateObj.year;
      this.initDate();
    },
    /**
     * @desc: 获取一周的开始结束时间段
     */
    getWeekDuring(year, month, date) {
      const dateObj = new Date(`${year}-${month}-${date} 00:00:00`);
      const day = dateObj.getDay() || 7;
      const startTime = dateObj.getTime() - (day - 1) * 86400000;
      const endTime = dateObj.getTime() + (7 - day + 1) * 86400000 - 1000;
      return [formatTime(startTime) + ' 00:00:00', formatTime(endTime) + ' 23:59:59'];
    },
    /**
     * @desc: 是否为当前周
     */
    isTargetWeek(cYear, cMonth, cDay) {
      const { year, month, date } = this.currentWeek;
      return year === cYear && month === cMonth && (cDay + 7 >= date);
    },
    /**
     * @desc: 获取周
     * @param {Object} item
     */
    onWeek(item) {
      this.activeWeek = item;
      this.$emit('setDate', item.result);
    },
    /**
     * @desc: 是否为当前选中的周
     * @param {Object} item
     */
    isActiveWeek(item) {
      const itemResult = item.result;
      const activeResult = this.activeWeek.result;
      return JSON.stringify(itemResult) === JSON.stringify(activeResult);
    }
  }
}
</script>

<style lang="less" scoped>
@import "./common.less";
.year-week{
  .date-item{
    width: 20%!important;
  }
}
</style>