<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-05-12 09:53:50
 * @Description:
-->
<template>
  <div style="height:100%;width:100%;">
    <!-- 从零选择 -->
    <el-select class="new-select-style" ref="outSelect" style="display: block;" :disabled="disabled" multiple :value="seatValue">
      <template #prefix>
        <div
          v-if="positionName && checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ positionName }}
        </div>
        <div
          v-else-if="showValue && !checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ showValue }}
        </div>
        <div
          v-else
          class="show-value"
          style="color: #999; cursor: pointer"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          请选择部位
        </div>
      </template>
      <template #empty>
        <div class="pover">
          <el-checkbox style="margin:10px 0 0 10px" v-model="checkbox" @change="checkboxChange">
            手动填写
          </el-checkbox>
          <div v-if="checkbox" class="position-name">
            <el-input v-model="positionName" placeholder="请输入部位名字" @change="inputChange" />
          </div>
          <div
            v-if="!checkbox"
            v-loading="showTree"
            class="pover-content"
            :style="'height:' + maxHeight + 'px'"
          >
            <div class="content-left">
              <div class="content-left-search">
                <el-input v-model="searchValue" :disabled="loading" placeholder="请输入部位名称搜索" @blur="searchPosition">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="content-left-tree scroller" v-loading="isMating">
                <div class="content-left-check">
                  <el-select 
                    v-model="showOptionValue"
                    :disabled="optionDisabled"
                    :filter-method="filterTree"
                    clearable
                    filterable
                    placeholder="请选择分部分项"
                    :popper-append-to-body="false"
                    @clear="handleClear"
                  >
                    <el-option class="select-tree scroller" :value="optionTreeList">
                      <el-tree
                        v-if="!showTree"
                        ref="optionTreeRef"
                        :data="optionTreeList"
                        :filter-node-method="filterNode"
                        node-key="nodeKey"
                        :props="{
                          label: 'name',
                          children: 'children'
                        }"
                        :show-checkbox="false"
                        @check="handleOptionCheckChange"
                        @node-click="handleNodeOptionClick"
                      >
                        <template #default="{ data }">
                          <span class="custom-tree-node">
                            <el-tooltip
                              v-if="data.name && (data.name + '').length > 8"
                              class="item"
                              :content="data.name"
                              effect="dark"
                              placement="top-start"
                              :open-delay="500"
                            >
                              <span>{{ data.name }}</span>
                            </el-tooltip>
                            <span v-else>{{ data.name }}</span>
                          </span>
                        </template>
                      </el-tree>
                    </el-option>
                  </el-select>
                  <div class="check" style="margin: 8px 8px 0 0">
                    <el-radio-group v-model="checkType" size="small">
                      <el-radio-button label="选择"></el-radio-button>
                      <el-radio-button :disabled="!showOptionValue" label="录入"></el-radio-button>
                    </el-radio-group>
                  </div>
                  <div class="check" style="margin: 8px 8px 0 0" v-if="checkType === '选择'">
                    <span>全选</span>
                    <i
                      :class="'iconfont ' + getIconStatus()"
                      :style="getIconStatus() !== 'iconjijia_fuxuan1' ? 'color: #409eff' : ''"
                      @click="changeStatus()"
                    />
                  </div>
                </div>
                <el-scrollbar
                  v-show="checkType === '选择'"
                  id="scrollbar-container"
                  class="scroller"
                  style="height: 100%; padding-top: 8px;"
                  v-loading="loading"
                >
                  <div class="position-item" v-for="item in positionDataList" :key="item.id">
                    <el-checkbox v-if="isCheckType" :value="getCheckValue(item)" @change="changeCheckbox($event, item)">{{ item.name }}</el-checkbox>
                    <span v-else>{{ item.name }}</span>
                  </div>
                  <!-- <el-tree
                    ref="treeRef"
                    :data="positionDataList"
                    :default-checked-keys="showTreeKeys"
                    :default-expand-all="defaultExpand"
                    :filter-node-method="filterNode"
                    node-key="id"
                    :props="{
                      label: 'name',
                      children: 'children',
                    }"
                    :show-checkbox="isCheckType"
                    @check="handleCheckChange"
                    @node-click="handleNodeClick"
                  >
                    <template #default="{ data }">
                      <span class="custom-tree-node">
                        <el-tooltip
                          v-if="data.name && (data.name + '').length > 8"
                          class="item"
                          :content="data.name"
                          effect="dark"
                          placement="top-start"
                          :open-delay="500"
                        >
                          <span>{{ data.name }}</span>
                        </el-tooltip>
                        <span v-else>{{ data.name }}</span>
                      </span>
                    </template>
                  </el-tree> -->
                </el-scrollbar>
                <el-scrollbar
                  v-show="checkType === '录入'"
                  id="scrollbarType"
                  class="scroller"
                  style="height: 100%; padding-top: 8px;"
                  v-loading="loading"
                >
                  <el-form
                    :model="formData"
                    ref="formData"
                    style="margin-right: 4px; height: 100%; display: flex; flex-direction: column"
                  >
                    <el-form-item label="类型选择" required style="padding-right: 20px">
                      <el-select 
                        ref="typeSelect"
                        v-model="formDataTypeLabel"
                        placeholder="请选择部位类型"
                        style="width: 100%"
                        :popper-append-to-body="false"
                      >
                        <el-option class="select-tree scroller" :value="typeList">
                          <p 
                            v-for="item in formaTypeList()"
                            :key="item.value"
                            class="select-postion-type"
                            :class="{'is-disabled': item.disabled, 'choose': formData.type === item.value}"
                            :style="formData.type === item.value ? 'color:#409eff' : ''"
                            @click.stop="chooseTypeList(item)"
                          >
                            {{ item.label }}
                          </p>
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <div class="form-data-contaiier" v-if="formData.type === 'component'">
                      <el-form-item label="代号" size="small" required style="padding-right: 20px">
                        <el-input v-model="formData.code" placeholder="请输入构件类别">
                        </el-input>
                      </el-form-item>
                      <div class="flex-contaienr">
                        <el-form-item label="起始编号" size="small" required style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.start_code"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入起始编号"
                              style="width: 100%"
                            />
                        </el-form-item>
                        <el-form-item label="终止编号" size="small" required style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.end_code"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入终止编号"
                              style="width: 100%"
                            />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="form-data-contaiier" v-if="formData.type === 'height'">
                      <el-form-item label="代号" size="small" required style="padding-right: 20px">
                        <el-input v-model="formData.code" placeholder="请输入代号">
                        </el-input>
                      </el-form-item>
                      <div class="flex-contaienr">
                        <el-form-item label="起始编号" size="small" required style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.start_code"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入起始编号"
                              style="width: 100%"
                            />
                        </el-form-item>
                        <el-form-item label="终止编号" size="small" required style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.end_code"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入终止编号"
                              style="width: 100%"
                            />
                        </el-form-item>
                      </div>
                      <div class="flex-contaienr">
                        <el-form-item label="底标高(米)"  style="padding-right: 20px">
                          <el-input-number
                            v-model="formData.start_height"
                            controls-position="right"
                            placeholder="请输入底标高（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                        <el-form-item label="顶标高(米)"  style="padding-right: 20px">
                          <el-input-number
                            v-model="formData.end_height"
                            controls-position="right"
                            placeholder="请输入顶标高（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="form-data-contaiier" v-if="formData.type === 'mile'">
                      <el-form-item label="部位前缀" size="small" style="padding-right: 20px">
                        <el-input v-model="formData.prefix" placeholder="请输入部位前缀">
                        </el-input>
                      </el-form-item>
                      <el-form-item label="代号" size="small" required style="padding-right: 20px">
                        <el-input v-model="formData.name" placeholder="请输入代号">
                        </el-input>
                      </el-form-item>
                      <el-form-item label="起始桩号" size="small" required style="padding-right: 20px">
                        <div class="flex-contaienr">
                          <el-input-number
                            v-model="formData.start_mile"
                            controls-position="right"
                            :min="0"
                            placeholder="起始里程"
                            :precision="0"
                            style="width: 100%"
                          /> 
                          <i class="iconfont iconxinzeng1" style="margin: 0 10px"></i>
                          <el-input-number
                            v-model="formData.start_meter"
                            controls-position="right"
                            :min="-1000"
                            placeholder="-999~999"
                            :precision="0"
                            style="width: 100%"
                          />
                        </div>
                      </el-form-item>
                      <el-form-item label="总长度（米）" size="small" required style="padding-right: 20px">
                        <el-input-number
                          v-model="formData.total"
                          controls-position="right"
                          :min="0"
                          placeholder="总长（米）"
                          :precision="0"
                          style="width: 100%"
                        />
                      </el-form-item>
                      <el-collapse v-model="activeNames" style="padding-right: 20px">
                        <el-collapse-item name="expand">
                          <template #title>
                            <div class="type-label">断面及高程</div>
                          </template>
                          <div class="type-label">断面（W）</div>
                          <div class="flex-contaienr">
                            <el-form-item label="断面开始(米)"  style="padding-right: 20px;">
                              <el-input-number
                                v-model="formData.start_width"
                                controls-position="right"
                                placeholder="请输入断面（数字）"
                                style="width: 100%"
                              />
                            </el-form-item>
                            <el-form-item label="断面结束(米)" >
                              <el-input-number
                                v-model="formData.end_width"
                                controls-position="right"
                                placeholder="请输入顶标高（数字）"
                                style="width: 100%"
                              />
                            </el-form-item>
                          </div>
                          <div class="type-label">高程（H）</div>
                          <div class="flex-contaienr">
                            <el-form-item label="高程开始(米)" style="padding-right: 20px;">
                              <el-input-number
                                v-model="formData.start_height"
                                controls-position="right"
                                placeholder="请输入高程（数字）"
                                style="width: 100%"
                              />
                            </el-form-item>
                            <el-form-item label="高程结束(米)" >
                              <el-input-number
                                v-model="formData.end_height"
                                controls-position="right"
                                placeholder="请输入高程（数字）"
                                style="width: 100%"
                              />
                            </el-form-item>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                    <div class="form-data-contaiier" v-if="formData.type === 'axis'">
                      <div class="flex-contaienr" style="justify-content: flex-start;padding-right: 20px;">
                        <el-form-item label="X向-起始轴号" style="flex: 1; margin-right: 20px;">
                          <el-input
                            v-model="formData.start_x"
                            maxlength="1"
                            placeholder="请输入字母（A-Z）"
                          />
                        </el-form-item>
                        <el-form-item label="X向-终止轴号" style="flex: 1;">
                          <el-input
                            v-model="formData.end_x"
                            maxlength="1"
                            placeholder="请输入字母（A-Z）"
                          />
                        </el-form-item>
                      </div>
                      <div class="flex-contaienr" style="justify-content: flex-start;padding-right: 20px;">
                        <el-form-item label="Y向-起始轴号" style="flex: 1; margin-right: 20px;">
                          <el-input-number
                            v-model="formData.start_y"
                            controls-position="right"
                            :min="0"
                            placeholder="请输入起始轴号（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                        <el-form-item label="Y向-终止轴号" style="flex: 1;">
                          <el-input-number
                            v-model="formData.end_y"
                            controls-position="right"
                            :min="0"
                            placeholder="请输入终止轴号（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                      </div>
                      <el-form-item label="部位生成方式" style="padding-right: 20px">
                        <el-select 
                          ref="axisType"
                          v-model="axisTypeLabel"
                          placeholder="请选择生成方式"
                          style="width: 100%"
                          :popper-append-to-body="false"
                        >
                          <el-option class="select-tree scroller" :value="axisTypeList">
                            <p
                              v-for="item in axisTypeList"
                              :key="item.value"
                              class="select-postion-type"
                              :style="formData.axisType === item.value ? 'color:#409eff' : ''"
                              @click.stop="chooseAxisTypeList(item)"
                            >
                              {{ item.label }}
                            </p>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="form-data-contaiier" v-if="formData.type === 'floor'">
                      <div
                        class="form-switch"
                        :style="formData.switch ? 'padding-bottom:10px' : ''"
                      >
                        <el-checkbox v-model="formData.switch">
                          指定楼层
                        </el-checkbox>
                      </div>
                      <div v-if="!formData.switch" class="form-item">
                        <div class="flex-contaienr">
                          <el-form-item class="div-item" label="起始楼层(F)" style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.start_f"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入起始楼层（数字）"
                              style="width: 100%"
                            />
                          </el-form-item>
                          <el-form-item class="div-item" label="终止楼层(F)" style="padding-right: 20px">
                            <el-input-number
                              v-model="formData.end_f"
                              controls-position="right"
                              :min="0"
                              placeholder="请输入终止楼层（数字）"
                              style="width: 100%"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <div v-if="formData.switch" class="form-item" style="padding-right: 20px">
                        <el-input-number
                          v-model="formData.start_f"
                          controls-position="right"
                          :min="0"
                          placeholder="请输入指定楼层（数字）"
                          style="width: 100%"
                        />
                      </div>
                      <div class="flex-contaienr" style="justify-content: flex-start;padding-right: 20px;">
                        <el-form-item label="X向-起始轴号" style="flex: 1; margin-right: 20px;">
                          <el-input
                            v-model="formData.start_x"
                            maxlength="1"
                            placeholder="请输入字母（A-Z）"
                          />
                        </el-form-item>
                        <el-form-item label="X向-终止轴号" style="flex: 1;">
                          <el-input
                            v-model="formData.end_x"
                            maxlength="1"
                            placeholder="请输入字母（A-Z）"
                          />
                        </el-form-item>
                      </div>
                      <div class="flex-contaienr" style="justify-content: flex-start;padding-right: 20px;">
                        <el-form-item label="Y向-起始轴号" style="flex: 1; margin-right: 20px;">
                          <el-input-number
                            v-model="formData.start_y"
                            controls-position="right"
                            :min="0"
                            placeholder="请输入起始轴号（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                        <el-form-item label="Y向-终止轴号" style="flex: 1;">
                          <el-input-number
                            v-model="formData.end_y"
                            controls-position="right"
                            :min="0"
                            placeholder="请输入终止轴号（数字）"
                            style="width: 100%"
                          />
                        </el-form-item>
                      </div>
                      <el-form-item label="部位生成方式" style="padding-right: 20px">
                        <el-select 
                          ref="axisType"
                          v-model="axisTypeLabel"
                          placeholder="请选择生成方式"
                          style="width: 100%"
                          :popper-append-to-body="false"
                        >
                          <el-option class="select-tree scroller" :value="axisTypeList">
                            <p
                              v-for="item in floorTypeList"
                              :key="item.value"
                              class="select-postion-type"
                              :style="formData.axisType === item.value ? 'color:#409eff' : ''"
                              @click.stop="chooseAxisTypeList(item)"
                            >
                              {{ item.label }}
                            </p>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-form>
                </el-scrollbar>
                <el-button v-if="checkType === '录入'" type="primary" size="small" @click.stop="matchClick">开始匹配</el-button>
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-top">
                <span>
                  已选中部位数
                  <span class="content-right-top-number">
                    {{ selectPositionList.length }}
                  </span>
                </span>
                <span
                  style="float: right; color: #0076ff; cursor: pointer"
                  @click="clearChoose()"
                >
                  清空
                </span>
              </div>
              <div class="content-right-choose scroller">
                <div
                  v-for="(item, index) in selectPositionList"
                  :key="index"
                  class="content-show"
                >
                  <div class="content-show-name">
                    <el-tooltip
                      class="item"
                      :content="item.name"
                      effect="dark"
                      placement="top-start"
                      :open-delay="500"
                    >
                      <p>{{ item.name }}</p>
                    </el-tooltip>
                  </div>
                  <div class="content-show-delete">
                    <i
                      class="iconfont iconbimgis_guanbi"
                      style="cursor: pointer"
                      @click.stop="deleteClick(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-select>
  </div>
</template>
<script>
import {
  Tree, Select, Input, Tooltip, Checkbox, Scrollbar, RadioGroup, RadioButton, InputNumber, Collapse, collapseItem
} from 'element-ui';
import { dataInterface } from '@/apis/data';
import { getChooseName } from "./handleData";
export default {
  name: '',
  components: {
		'el-tree': Tree,
    'el-select': Select,
    'el-input': Input,
    'el-tooltip': Tooltip,
    'el-checkbox': Checkbox,
    'el-scrollbar': Scrollbar,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-input-number': InputNumber,
    'el-collapse': Collapse,
    'el-collapse-item': collapseItem,
  },
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Array,
      default: () => []
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: Boolean,
      default: true
    },
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 370
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    /* 展示内容 */
    showTree: {
      type: Boolean,
      default: true
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    },
    /* 默认选择的分部分项 */
    defaultOptionChecked: {
      type: Array,
      default: () => []
    },
    optionDisabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      seatValue: '',
      treeRef: null,
      showTreeKeys: [],
      selectPositionList: [],
      selectPositionListKeys: [],
      searchValue: '',
      firsetIn: false,
      checkbox: false,
      positionName: '',
      positionDataList: [],

      /* 分部分项下拉树相关 */
      optionArr: [],
      selectOptionList: [],
      page: 1,
      isUpdate: true, // 允许下拉加载更多状态
      loading: false, // 请求数据状态
      showOptionValue: '',
      optionTreeList: [], // 分部分项树数据

      checkType: '选择', // 选择录入类型
      typeList: [
        { label: "构件编号", value: "component" },
        { label: "构件-标高", value: "height" },
        { label: "里程", value: "mile" },
        { label: "轴线", value: "axis" },
        { label: "楼层-轴线", value: "floor" },
      ],
      axisTypeList: [
        { label: "按轴线-交点", value: 1 },
        { label: "按轴线-线性", value: 2 },
        { label: "按轴线-区域", value: 3 },
      ],
      floorTypeList: [
        { label: "按楼层-轴线-交点", value: 1 },
        { label: "按楼层-轴线-线性", value: 2 },
        { label: "按楼层-轴线-区域", value: 3 },
      ],
      formDataTypeLabel: '',
      formData: {
        type: ''
      },
      activeNames: [],
      axisTypeLabel: '', // 轴线生成方式
      typeKeys: [], // 录入限制类型
      isMating: false, // 匹配接口请求中状态
    }
  },
  computed: {
    /* 计算当前选择的人员回显 */
    showValue () {
      return this.getChooseNameFunc()
    },
  },
  watch: {
    dataInfo: {
      handler (val) {
        this.firsetIn = false
        this.optionTreeList = val ? JSON.parse(JSON.stringify(val)) : []
        this.showTreeInfo()
      }
    },
    showList: {
      handler () {
        this.showTreeInfo()
      },
      deep: true
    },
    showOptionValue: {
      handler (val) {
        if (!val) {
          this.filterTree('')
        }
      },
      deep: true
    },
    defaultOptionChecked: {
      handler (val) {
        if (val && val.length) {
          this.$nextTick(() => {
            this.setOptionSelect()
          })
        }
      },
      deep: true
    },
    selectPositionList: {
      handler (val) {
        if (val && val.length) {
          this.selectPositionListKeys = []
          val.forEach(element => {
            this.selectPositionListKeys.push(element.id)
          });
        } else {
          this.selectPositionListKeys = []
        }
      },
      deep: true
    },
  },
  mounted () {
    this.optionTreeList = this.dataInfo ? JSON.parse(JSON.stringify(this.dataInfo)) : []
    this.showTreeInfo()
    const rightBox = document.querySelector('#scrollbar-container').firstChild
    rightBox.addEventListener('scroll', this.load, true)
    this.setOptionSelect()
  },
  methods: {
    /* 搜索部位名称 */
    searchPosition() {
      this.optionArr = []
      this.selectOptionList.forEach(element => {
        this.optionArr.push(element.id)
      });
      this.page = 1
      this.positionDataList = []
      this.isUpdate = true
      this.getPositionList(this.optionArr, true)
    },
    /* 设置分部分项默认选择 */
    setOptionSelect() {
      if (Array.isArray(this.defaultOptionChecked) && this.defaultOptionChecked.length) {
        this.$nextTick(() => {
          this.$refs.optionTreeRef.setCheckedKeys(this.defaultOptionChecked)
          this.selectOptionList = []
          const loopTree = (arr) => {
            arr.forEach(element => {
              if (element.nodeKey && this.defaultOptionChecked.indexOf(element.nodeKey) > -1) {
                this.selectOptionList.push(element)
              }
              if (element.children && element.children.length) {
                loopTree(element.children)
              }
            })
          }
          if (this.optionTreeList && Array.isArray(this.optionTreeList) && this.optionTreeList.length) {
            loopTree(this.optionTreeList)
          }
          this.optionArr = []
          this.selectOptionList.forEach(element => {
            this.optionArr.push(element.id)
          });
          this.page = 1
          this.positionDataList = []
          this.isUpdate = true
          this.getOptionValue()
          this.getPositionList(this.optionArr, true)
          let positionArr = []
          if (this.selectOptionList?.length) {
            this.selectOptionList.forEach(element => {
              if (element?.nodeKey) {
                positionArr = positionArr.concat(element.positionChildren)
              }
            });
          }
          this.typeKeys = []
          const arr = ['component', 'height', 'mile', 'axis', 'floor']
          if (positionArr?.length) {
            positionArr.forEach(element => {
              let config = element?.option_config && (typeof(element.option_config) === 'string') ? JSON.parse(element.option_config) : element?.option_config
              const type = +arr.findIndex((item) => config?.type === item) + 2
              if (this.typeKeys.findIndex((item) => item === type) < 0) {
                this.typeKeys.push(type)
              }
            });
          }
        })
      }
    },
     /* 计算当前选择的人员回显 */
    getOptionValue(){
      let str = ''
      this.selectOptionList.forEach((item) => {
        if (!str) {
          str += item.name
        } else {
          str += ',' + item.name
        }
      })
      this.showOptionValue = str
    },
    checkboxChange () {
      if (this.checkbox) {
        this.$emit('on-select-change', [])
      }
    },
    inputChange () {
      this.$emit('onSelectChange', [{
        name: this.positionName,
        checkbox: true,
        abbreviatedName: this.positionName
      }])
    },
    /* 单选分部分项 */
    handleNodeOptionClick(data){
      if (data.option_id !== "sectional_works" && data.option !== "分项工程") {
        return
      }
      if (data.nodeKey) {
        this.showOptionTreeKeys = []
        this.showOptionTreeKeys = [data.nodeKey]
      }
      this.returnOptionTreeInfo(this.showOptionTreeKeys)
      this.formDataTypeLabel = ''
      this.formData.type = ''
    },
    /* 当前选择的nodekey数组 */
    handleOptionCheckChange () {
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.optionTreeRef.getCheckedKeys()
      let arr = []
      if (keys && keys.length) {
        arr = keys.filter((item) => item > 0)
        this.showOptionTreeKeys = arr
      } else {
        this.showOptionTreeKeys = []
      }
      this.returnOptionTreeInfo(arr)
      this.formDataTypeLabel = ''
      this.formData.type = ''
    },
    /* 当前选择的分部分项数组数据（item包含所有信息） */
    returnOptionTreeInfo (list) {
      const selectArr = []
      let positionArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.nodeKey === element) {
              selectArr.push(itemy)
              positionArr = positionArr.concat(itemy.positionChildren)
            }
            if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.dataInfo)
      })
      this.selectOptionList = selectArr
      this.optionArr = []
      this.selectOptionList.forEach(element => {
        this.optionArr.push(element.id)
      });
      this.page = 1
      this.positionDataList = []
      this.isUpdate = true
      this.getOptionValue()
      this.getPositionList(this.optionArr, true)
      const arr = ['component', 'height', 'mile', 'axis', 'floor']
      this.typeKeys = []
      if (positionArr?.length) {
        positionArr.forEach(element => {
          let config = element?.option_config && (typeof(element.option_config) === 'string') ? JSON.parse(element.option_config) : element?.option_config
          const type = +arr.findIndex((item) => config?.type === item) + 2
          if (this.typeKeys.findIndex((item) => item === type) < 0) {
            this.typeKeys.push(type)
          }
        });
      }
    },
    /* 当前选择的nodekey数组 */
    handleCheckChange () {
      /* 级联单选 */
      if (!this.isCheckType) {
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.treeRef.getCheckedKeys()
      let arr = []
      if (keys && keys.length) {
        arr = keys.filter((item) => item > 0)
        this.showTreeKeys = arr
      } else {
        this.showTreeKeys = []
      }
      this.returnShowTreeInfo(arr)
    },
    /* 级联单选 */
    handleNodeClick (data) {
      if (this.isCheckType) {
        return
      }
      if (data.nodeKey) {
        this.showTreeKeys = []
        this.showTreeKeys = [data.nodeKey]
      }
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 当前选择的人员数组数据（item包含所有信息） */
    returnShowTreeInfo (list) {
      const selectArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.id === element) {
              selectArr.push(itemy)
            }
            if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.positionDataList)
      })
      for (let index = 0; index < this.selectPositionList.length; index++) {
        const selectItem = this.selectPositionList[index]
        let inDataList = false
        let inList = false
        for (let index = 0; index < this.positionDataList.length; index++) {
          const item = this.positionDataList[index]
          if (+item.id === +selectItem.id) {
            inDataList = true
            break
          }
        }
        list.forEach(listItem => {
          if (+listItem === +selectItem.id) {
            inList = true
          }
        })
        if (inDataList && !inList) {
          this.selectPositionList.splice(index, 1)
          index--
        }
      }
      const positionList = this.unique(selectArr.concat(this.selectPositionList), 'id')
      this.selectPositionList = positionList
      if (positionList && positionList.length) {
        positionList.forEach(item => {
          item.checkbox = false
          item.abbreviatedName = this.getChooseNameFunc()
        })
      }
      this.$emit('onSelectChange', positionList)
    },
    /* 传入回显的showList 获取勾选数组  进行默认回显 */
    showTreeInfo () {
      if (this.showList && this.showList.length) {
        this.selectPositionList = this.unique(JSON.parse(JSON.stringify(this.showList)).concat(this.selectPositionList), 'id')
      }
      if (this.showList && this.showList.length && this.showList[0] && this.showList[0].checkbox) {
        this.checkbox = true
        this.positionName = this.showList[0].abbreviatedName
        this.firsetIn = true
        return
      }
      if (!this.showList || this.showList.length === 0 || !this.dataInfo || this.dataInfo.length === 0 || this.firsetIn) {
        return
      }
      this.firsetIn = true
      let showList = JSON.parse(JSON.stringify(this.showList.concat(this.selectPositionList)))
      showList = this.unique(showList, 'id')
      const arr = []
      showList.forEach((element) => {
        arr.push(element.id)
      })
      this.showTreeKeys = arr
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 删除单个勾选 */
    deleteClick (data) {
      for (let index = 0; index < this.selectPositionList.length; index++) {
        if (this.selectPositionList[index].id === data.id) {
          this.selectPositionList.splice(index, 1)
        }
      }
      const positionList = this.unique(this.selectPositionList, 'id')
      if (positionList && positionList.length) {
        positionList.forEach(item => {
          item.checkbox = false
          item.abbreviatedName = this.getChooseNameFunc()
        })
      }
      this.$emit('onSelectChange', positionList)
      // let arr = []
      // this.selectPositionList.forEach(element => {
      //   arr.push(element.id)
      // });
      // this.showTreeKeys = arr
      // this.returnShowTreeInfo(arr)
      // this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
    },
    /* 清空所有勾选 */
    clearChoose () {
      if (!this.isCheckType) {
        // const treeRef = ref(this.treeRef)
        // this.$refs.treeRef.setCurrentKey(null)
        this.showTreeKeys = []
        this.selectPositionList = []
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      this.selectPositionList = []
      // const treeRef = ref(this.treeRef)
      // this.$refs.treeRef.setCheckedKeys([])
      this.$emit('onSelectChange', [])
    },
    /* tree组件的过滤规则 */
    filterNode (value, data) {
      if (!value) return true
      return !!(data.name && data.name.indexOf(value) !== -1)
    },
    /* 处理当前选择的合并名称 */
    getChooseNameFunc () {
      let str = ''
      if (this.selectPositionList && this.selectPositionList.length) {
        str = getChooseName(this.selectPositionList)
      }
      return str
    },
    /* 获取数组连续的1.2.3二维数组 */
    fn (arr, interval) {
      var result = []
      var i = 0
      result[i] = [arr[0]]
      arr.reduce(function (prev, cur) {
        parseFloat((parseFloat(cur) - parseFloat(prev)).toFixed(5)) <= parseFloat(parseFloat(interval).toFixed(5)) ? result[i].push(cur) : result[++i] = [cur]
        return cur
      })
      return result
    },
    unique (arr, attrName) {
      const res = new Map()
      return arr.filter(
        (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
      )
    },
    /* 根据分布分项选择 请求部位数据 */
    getPositionList(list, bool) {
      if (!this.isUpdate || !list || list.length === 0) {
        return
      }
      let params = {
        parent_id: list?.length && (list.length === 1) ? list[0] : '',
        page: this.page,
        size: 50,
        search: this.searchValue ? [{
          code: 'name',
          ruleType: 'like',
          value: this.searchValue
        }] : []
      }
      this.loading = true
      dataInterface(params, 'api/graph/37').then((res) => {
        if (bool) {
          this.positionDataList = []
        }
        this.loading = false
        if(res.status === 200) {
          const list = res.data.data.data || []
          if (list && list.length) {
            list.forEach((item) => {
              this.positionDataList.push(item)
            })
          } else {
            this.page -= 1
            this.isUpdate = false
          }
          this.firsetIn = false
          this.$nextTick(() => {
            this.showTreeInfo()
          })
        }
      })
    },
    load() {
      if (!this.selectOptionList || this.selectOptionList.length === 0 || this.loading) {
        return
      }
      const treeHeight = document.querySelector('#scrollbar-container').firstChild.offsetHeight
      const treeScrollTop = document.querySelector('#scrollbar-container').firstChild.scrollTop
      const treeScrollHeight = document.querySelector('#scrollbar-container').firstChild.scrollHeight
      if (treeScrollHeight - (treeScrollTop + treeHeight) < 7 && !this.loading) {
        this.page += 1
        this.optionArr = []
        this.selectOptionList.forEach(element => {
          this.optionArr.push(element.id)
        });
        this.getPositionList(this.optionArr)
      }
    },
    /* 选择分部分项清除 */
    handleClear() {
      this.showOptionValue = ''
      this.checkType = '选择'
      this.selectOptionList = []
      this.positionDataList = []
      if(this.$refs.optionTreeRef) {
        this.$refs.optionTreeRef.setCheckedKeys([])
      }
      this.getOptionValue()
    },
    /* 搜索分部分项 */
    filterTree (val) {
      const mapTree = (value, arr) => {
        const newarr = []
        arr.forEach(element => {
          if (element.name.indexOf(value) > -1) { // 判断条件
            newarr.push(element)
          } else {
            if (element.children && element.children.length > 0) {
              const redata = mapTree(value, element.children)
              if (redata && redata.length > 0) {
                const obj = {
                  ...element,
                  children: redata
                }
                newarr.push(obj)
              }
            }
          }
        })
        return newarr
      }
      if (val) {
        this.optionTreeList = mapTree(val, this.dataInfo)
      } else {
        this.optionTreeList = this.dataInfo
      }
      this.showOptionValue = val
    },
    /* 获取树数据勾选状态 */
    getIconStatus() {
      if (!this.$refs.optionTreeRef || !this.positionDataList || this.positionDataList.length === 0) {
        return 'iconjijia_fuxuan1'
      }
      let idNum = 0
      if (this.selectPositionList?.length) {
        this.selectPositionList.forEach(element => {
          for (let index = 0; index < this.positionDataList.length; index++) {
            const item = this.positionDataList[index];
            if (+element.id === +item.id) {
              idNum += 1
            }
          }
        })
      }
      if (this.positionDataList.length === +idNum) {
        return 'iconjijia_fuxuan2'
      } else if (+idNum > 0 && idNum < this.positionDataList.length) {
        return 'iconjijia_fuxuan3'
      } else {
        return 'iconjijia_fuxuan1'
      }
    },
    /* 点击切换全选状态 */
    changeStatus() {
      if (!this.positionDataList || this.positionDataList.length === 0) {
        return
      }
      const currentStatus = this.getIconStatus()
      // const num = this.$refs.treeRef.getCheckedKeys().length || 0
      // 当前部位列表里面被选中的id数组
      let num = []
      // 当前选中的部位的id数组
      let arr = []
      this.positionDataList.forEach(item => {
        arr.push(item.id)
      });
      if (this.selectPositionList?.length) {
        this.selectPositionList.forEach(element => {
          if (arr.indexOf(element.id) > -1) {
            num.push(parseInt(element.id))
          }
        });
      }
      if (+num?.length >= 0 && num?.length < this.positionDataList.length) {
        // this.$refs.treeRef.setCheckedKeys(arr)
        // this.$nextTick(() => {
        //   this.handleCheckChange()
        // })
        this.selectPositionList = this.unique(this.selectPositionList.concat(this.positionDataList), 'id')
      } else if (currentStatus === 'iconjijia_fuxuan2') {
        // this.$refs.treeRef.setCheckedKeys([])
        // this.$nextTick(() => {
        //   this.handleCheckChange()
        // })
        for (let index = 0; index < this.selectPositionList.length; index++) {
          const element = this.selectPositionList[index];
          if (num?.length && num.indexOf(element.id) > -1) {
            this.selectPositionList.splice(index, 1)
            index--;
          }
        }
      }
      const positionList = this.unique(this.selectPositionList, 'id')
      if (positionList && positionList.length) {
        positionList.forEach(item => {
          item.checkbox = false
          item.abbreviatedName = this.getChooseNameFunc()
        })
      }
      this.$emit('onSelectChange', positionList)
    },
    /* 触发选择 */
    chooseTypeList(data) {
      if (data.disabled) {
        return
      }
      this.formData = {}
      if (data.value === 'axis' && (this.formData.type !== data.value)) {
        this.formData.axisType = 1
        this.axisTypeLabel = '按轴线-交点'
      }
      if (data.value === 'floor' && (this.formData.type !== data.value)) {
        this.formData.axisType = 1
        this.axisTypeLabel = '按楼层-轴线-交点'
      }
      this.formData.type = data.value
      this.formDataTypeLabel = data.label
      this.$refs.typeSelect.blur()
    },
    /* 轴线生成方式选择 */
    chooseAxisTypeList(data) {
      this.formData.axisType = data.value
      this.axisTypeLabel = data.label
      this.$refs.axisType.blur()
    },
    /* 点击匹配 */
    matchClick() {
      const arr = ['component', 'height', 'mile', 'axis', 'floor']
      const query = {...this.formData}
      query.type = +arr.findIndex((item) => this.formData.type === item) + 2
      if (+query.type === 2 && (+query.start_code > +query.end_code)) {
          this.$message.error('起始轴号应小于终止轴线!');
          return 
      }
      if (+query.type === 5 || +query.type === 6) {
        query.start_x = query?.start_x?.toUpperCase()
        query.end_x = query?.end_x?.toUpperCase()
      }
      if ((+query.type === 5 || +query.type === 6) && +query.axisType === 2) {
        let numb = 0;
        const obj = {
          1: this.formData.start_x,
          2: this.formData.end_x,
          3: this.formData.start_y,
          4: this.formData.end_y,
        };
        for (var i in obj) {
          if (obj[i]) {
            numb += 1;
          }
        }
        if (numb !== 3) {
          this.$message.error("请输入且只输入三个值");
          return
        }
        if (!query.start_x) {
          query.start_x = ''
        }
        if (!query.end_x) {
          query.end_x = ''
        }
        if (!query.start_y) {
          query.start_y = ''
        }
        if (!query.end_y) {
          query.end_y = ''
        }
        if (query.start_x && query.end_x && (query.start_x.charCodeAt() > query.end_x.charCodeAt())) {
          this.$message.error('x向起始轴号应小于x向终止轴线!');
          return 
        }
        if (query.start_y && query.end_y && (query.start_y > query.end_y)) {
          this.$message.error('x向起始轴号应小于x向终止轴线!');
          return 
        }
      } else if (+query.type === 5 || +query.type === 6) {
        if (!query.start_x || !query.end_x || !query.start_y || !query.end_y) {
          this.$message.error('填写未完成!');
          return 
        }
        if (query.start_x.charCodeAt() > query.end_x.charCodeAt()) {
          this.$message.error('x向起始轴号应小于x向终止轴线!');
          return 
        }
        if (query.start_y > query.end_y) {
          this.$message.error('x向起始轴号应小于x向终止轴线!');
          return 
        }
      }
      query.parent_id = this.optionArr
      this.isMating = true
      dataInterface(query, 'api/otherController/positionSearch').then((res) => {
        this.isMating = false
        if (res && res.data.code === 200) {
          if (!res?.data?.data?.length) {
            this.$message.error('输入范围未匹配到部位数据，请重新输入!');
            return
          }
          this.selectPositionList = (this.selectPositionList.concat(res.data.data))?.length ? this.unique(this.selectPositionList.concat(res.data.data), 'id') : []
          const positionList = JSON.parse(JSON.stringify(this.selectPositionList))
          if (positionList && positionList.length) {
            positionList.forEach(item => {
              item.checkbox = false
              item.abbreviatedName = this.getChooseNameFunc()
            })
          }
          this.$emit('onSelectChange', positionList)
        }
      })
    },
    formaTypeList() {
      const TypeList = JSON.parse(JSON.stringify(this.typeList))
      const arr = ['component', 'height', 'mile', 'axis', 'floor'] //  [2, 4]
      const newTypeStr = []
      if (this.typeKeys?.length) {
        this.typeKeys.forEach(item => {
          newTypeStr.push(arr[item - 2])
        });
      }
      for (let index = 0; index < TypeList.length; index++) {
        const disabled = newTypeStr.indexOf(TypeList[index].value) < 0
        TypeList[index].disabled = disabled
      }
      return TypeList
    },
    /* 
      勾选状态判断
    */
    getCheckValue(item) {
      return this.selectPositionListKeys?.length && (this.selectPositionListKeys.indexOf(item.id) > -1) ? true : false
    },
    changeCheckbox(boo, item) {
      if (boo) {
        this.selectPositionList = this.unique(this.selectPositionList.concat([item]), 'id')
      } else {
        let keys = []
        if (this.selectPositionList?.length) {
          this.selectPositionList.forEach(element => {
            keys.push(parseInt(element.id))
          });
        }
        if (keys.indexOf(item.id) > -1) {
          for (let index = 0; index < this.selectPositionList.length; index++) {
            const element = this.selectPositionList[index];
            if (element.id === item.id) {
              this.selectPositionList.splice(index, 1)
              index--;
              break;
            }
          }
        }
      }
      const positionList = this.unique(this.selectPositionList, 'id')
      if (positionList && positionList.length) {
        positionList.forEach(item => {
          item.checkbox = false
          item.abbreviatedName = this.getChooseNameFunc()
        })
      }
      this.$emit('onSelectChange', positionList)
    }
  }
}
</script>
<style lang="less">
/* 修改select组件样式 */
.new-select-style {
  width: 100%;
  height: 36px;
  .el-input__suffix {
    display: none !important;
  }
  .el-input__inner {
    display: none !important;
  }
  .el-input__prefix {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    transform: translateX(-4px);
  }
  .el-input--suffix {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    box-sizing: border-box !important;
  }
}
.el-input__validateIcon {
  display: none !important;
}
</style>
<style lang="less" scoped>
.option-empty {
  width: 100%;
  height: auto;
  max-height: 300px;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}
.position-name {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.show-value {
  width: 100%;
  line-height: 32px;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 0 16px;
  text-align: left;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.pover {
  width: 100%;
  height: auto;
  .pover-content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    .content-left {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-left-search {
        height: 40px;
        padding: 5px 10px;
      }
      .content-left-tree {
        padding: 0 10px;
        flex: 1;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        .content-left-check {
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content:space-between;
          .check {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right:8px 8px 0 0;
            span {
              margin-right: 8px;
              white-space: nowrap;
            }
          }
        }
        .check {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right:8px 8px 0 0;
          span {
            margin-right: 8px;
            white-space: nowrap;
          }
        }
        #scrollbar-container, #scrollbarType {
          height: 100%;
          .select-tree {
            height: auto;
            max-height: 200px;
            overflow-y: auto;
            background-color: white;
            padding: 0;
            .select-postion-type {
              display: flex;
              padding-left: 16px;
              .choose {
                color: #409eff;
              }
            }
            .select-postion-type:hover {
              background-color: #eee;
            }
            .is-disabled {
              cursor: not-allowed;
              color: #eee;
              .choose {
                color: #eee;
              }
            }
            .is-disabled:hover {
              background-color: #fff;
            }
          }
          .flex-contaienr {
            width: 100%;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
          }
          .position-item {
            height: 30px;
            display: flex;
          }
        }
        .select-tree {
          height: auto;
          max-height: 200px;
          overflow-y: auto;
          background-color: white;
          padding: 0;
        }
      }
    }
    .content-right {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-right-top {
        height: 50px;
        line-height: 40px;
        padding: 0 10px;
        .content-right-top-number {
          color: #0076ff;
        }
      }
      .content-right-choose {
        flex: 1;
        padding: 0 10px;
        overflow-y: auto;
        .content-show {
          width: 100%;
          height: 30px;
          display: flex;
          .content-show-name {
            width: 88%;
            height: 30px;
            display: flex;
            flex-direction: column;
            p {
              height: 30px;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:nth-child(2) {
              font-size: 12px;
              color: #999;
            }
          }
          .content-show-delete {
            width: 10%;
            height: 30px;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
