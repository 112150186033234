<!--
    @name: widget-longlat
    @description：widget-longlat
    @author: ZengWei
    @date: 2022-04-24 16:21
-->
<template>
	<div v-if="show">
		<BindLatLng
			ref="chooseLngLat"
			@set-data="setData"
			componentType="latlng"
			:modelData="bindInfo"
		>
			<template v-slot:showType>
				<div class="content-box" v-if="element.value && element.value != null">
					<el-button
						v-if="!disabled"
						style="width: 118px; margin-bottom: 10px"
						@click.stop="openModelDrawer('chooseLngLat')"
						size="medium"
						type=""
					>
						<i class="el-icon-s-flag el-icon--left"></i>
						重新选择
					</el-button>
					<div v-if="Array.isArray(element?.value) && element.value.length" class="result-box">
						<ul
							class="lng-lat-yet"
							:class="{
								'lng-lat-yet-disabled': disabled
							}"
						>
							<li  class="item">
								<span class="label">详细地址：</span>
								<span class="value">{{ addressStr || '获取失败' }}</span>
							</li>
							<li class="item">
								<span class="label">经&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
								<span class="value">{{ lng || '' }}</span>
							</li>
							<li class="item">
								<span class="label">纬&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
								<span class="value">{{ lat || '' }}</span>
							</li>
						</ul>
						<div class="remove" v-if="!disabled">
							<i class="el-icon-delete" @click="onRemove"></i>
						</div>
					</div>
				</div>
				<!-- :type="element.value && element.value != null ? 'warning' : 'primary'"
          v-html="element.value && element.value != null ? '重新选择' : '请选择'" -->
				<template v-else>
					<span v-if="disabled">无</span>

					<el-button v-else style="width: 118px" @click.stop="openModelDrawer('chooseLngLat')" size="medium" type="">
						<i class="el-icon-s-flag el-icon--left"></i>
						请选择
					</el-button>
				</template>
			</template>
		</BindLatLng>
	</div>
</template>

<script>
import BindLatLng from '@/custom-component/bindLatLng/ButtonType.vue';
import axios from 'axios'

export default {
	name: 'WidgetLonglat',
	components: {
		BindLatLng
	},
	props: ['element', 'disabled', 'displayData'],
	data() {
		return {
			addressStr: '',
			lng:'',
			lat:'',
			bindInfo:[],
			show:true
		};
	},
	created() {
		this.bindInfo = this.element.value || []
		// 默认有值则获取器坐标点信息
		if (Object.prototype.toString.call(this.element?.value) === '[object Object]') {
			this.lat = this.element.value.lat
			this.lng = this.element.value.lng
		} else if(Object.prototype.toString.call(this.element?.value) === '[object Array]' && this.element?.value.length){
			this.lat = this.element.value[0].location_gis.lat
			this.lng = this.element.value[0].location_gis.lng
		}
		const url = `https://api.tianditu.gov.cn/geocoder?postStr={'lon':${this.lng},'lat':${this.lat},'ver':1}&type=geocode&tk=c5aaa7bebd44c40e6e03f73df75582d2`;
			axios.get(url).then((res) => {
				if (res?.data?.result?.addressComponent) {
					this.addressStr = ''
					const data = res.data.result.addressComponent;
					let arr = ['nation','province','city','county','address','poi']
					if(data.nation && data.nation === '中国' ){
						arr = arr.splice(1, arr.length - 1)
					}
					arr.forEach((item,i)=>{
						if(data[item]){
							if(i === 0){
								this.addressStr += data[item]
							} else if (data[arr[i - 1]] !== data[item]) {
								this.addressStr += data[item]
							}
						}
					})

				}
				if ( res.data?.result?.addressComponent ) {        
					this.info.area = ''
					this.info.fullName = ''
					this.modelsInfo[0].location_name = ''
          const data = res.data.result.addressComponent;
					let arr = ['nation','province','city','county','address','poi']
					let n = 4
					if(data.nation && data.nation === '中国' ){
						arr = arr.splice(1, arr.length - 1)
						n = 3
					}
					arr.forEach((item,i)=>{
						if(data[item]){
							console.log(data[item],i,n)
							if(i === 0){
								if(i < n){
									this.info.area += data[item] + '/'
								}
								this.info.fullName += data[item]
							} else if (data[arr[i - 1]] !== data[item]) {
								if(i < n){
									this.info.area += data[item] + '/'
								}
								this.info.fullName += data[item]
							}
						}
					})
					if(this.info.area && this.info.area.includes('/')){
						this.info.area = this.info.area.substring(0,this.info.area.length - 1)
					}
					this.modelsInfo[0].location_name = this.info.area && this.info.fullName? this.info.area +'|'+ this.info.fullName : ''
        }
			})
			.catch((err) => {
				console.log(err);
			});
	},
	methods: {
		setData(data) {
			this.element.value = data || [];
			this.$emit('input', data || []);
			if(data?.length){
				if(data[0].location_name && data[0].location_name.includes('|') ){
					this.addressStr = data[0].location_name.split('|')[1]
				} else {
					this.addressStr = ''
				}
				this.lat = data[0].location_gis.lat
				this.lng = data[0].location_gis.lng
				this.show = false
				this.$nextTick(()=>{
					this.bindInfo = this.element.value
					this.show = true
				})
			} else if( data === null ){
				this.show = false
				this.$nextTick(()=>{
					this.bindInfo = []
					this.show = true
				})
				this.addressStr = '';
				this.lat = ''
				this.lng = ''
			}
		},
		openModelDrawer(name) {
			this.$nextTick(() => {
				this.$refs[name].show = true;
			});
		},
		/**
		 * @desc: 移除
		 */
		onRemove() {
			this.$confirm('是否确认删除已选点?', '提示')
				.then(() => {
					this.setData(null);
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.content-box {
	// display: flex;
	flex-direction: column;
	overflow: hidden;
	.result-box {
		display: flex;
		.lng-lat-yet {
			flex: 1;
			position: relative;
			box-sizing: border-box;
			background: #f5f7fa;
			border-radius: 6px;
			padding: 6px 12px;
			border: 1px solid #f5f7fa;
			overflow: hidden;
			width: 90%;
			&.lng-lat-yet-disabled {
				color: #c0c4cc;
			}
			.item {
				display: flex;
				span {
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.2px;
					&.label {
						// width: 72px;
					}
					&.value {
						flex: 1;
					}
				}
			}
		}
		.remove {
			display: flex;
			align-items: center;
			margin-left: 8px;
			i {
				font-size: 18px;
				font-weight: 600;
				color: @dangerColor;
				z-index: 10;
				box-sizing: border-box;
				padding: 0 4px;
			}
		}
	}
}
</style>
