<template>
    <div class="moduleWrap">
        <div class="load-wrap">
            <div class="title-wrap">
                <span class="title-wrap_content">标绘</span>

                <el-dropdown trigger="click" hide-on-click class="title-wrap_btn" style="margin-right: 10px;" @command="onCreate">
                    <a class="iconfont iconbim_jia"></a>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="createPlot">新增标绘</el-dropdown-item>
                        <el-dropdown-item command="createGroup">新增分组</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <el-tooltip :content="isShowAll ? '全部隐藏' : '全部显示'" placement="bottom">
                    <a :class="['title-wrap_btn iconfont', isShowAll ? 'iconbimgis_yincang' : 'iconbimgis_xianshi']" @click="onShowAll"></a>
                </el-tooltip>

                <el-tooltip :content="isExpandAll ? '全部折叠' : '全部展开'" placement="bottom">
                    <a :class="['title-wrap_btn iconfont', isExpandAll ? 'iconshouqiquanbu' : 'iconzhankaiquanbu']" @click="onExpandAll"></a>
                </el-tooltip>
            </div>

            <el-tree ref="plotTree"
                     v-loading="isLoading"
                     :element-loading-background="loadingBackColor"
                     class="plotTree-wrap"
                     empty-text=""
                     :data="plotList"
                     :props="treeProps"
                     node-key="id"
                     :lazy="false"
                     :highlight-current="true"
                     :expand-on-click-node="false"
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @check=onShowPlot>
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node']">
                        <a :class="['tree-node-icon iconfont', getTreeNodeIcon(data.icon)]" @click.stop="onExpand(node)"></a>
                        <span class="tree-node-content" :title="data.name" @click.stop="onExpand(node)">{{ data.name}}</span>
                        <a class="tree-node-location iconfont icondingwei1" @click="onLocationPlot(data, node)"></a>
                        <el-dropdown trigger="click" hide-on-click class="tree-node-more" @command="onMorePlot($event, data)">
                            <a class="iconfont icongengduomore"></a>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="data.type==='Child'" command="rename">重命名</el-dropdown-item>
                                <el-dropdown-item command="update">编辑</el-dropdown-item>
                                <el-dropdown-item command="delete">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-tree>

            <el-empty v-if="plotList.length === 0" description="什么都没有" class="empty-wrap"></el-empty>
        </div>

        <div :class="['createPlot-wrap', `createPlot-wrap-${subItemSpread}`]" v-show="isShowCreatePlot">
            <div class="title-wrap">
                <span class="title-wrap_content">选择标绘对象</span>
                <a class="title-wrap_btn iconfont iconbimgis_guanbi" @click="onCloseCreatePlot"></a>
            </div>

            <el-collapse class="createPlotTree-wrap" v-model="plotTemplateExpand">
                <el-collapse-item v-for="item in plotTemplateList" :title="item.name" :key="item.id" :name="item.id">
                    <div style="margin: 10px">
                        <el-row :gutter="10">
                            <el-col :span="6" v-for="obj in item.children" :key="obj.id">
                                <div class="vertical-flex" @click="onSelectPlotTemplate(obj)">
                                    <img :class="['back-template', 'back-' + obj.id, {'back-template_active': plotTemplateActive === obj.id}]">
                                    <div style="text-align: center">{{obj.name}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <div class="horizontal-flex btnBottom-wrap">
                <div class="btnBottom-wrap_yes" @click="onApplyPlotTemplate">确认</div>
            </div>
        </div>

        <div :class="['editPlot-wrap', `editPlot-wrap-${subItemSpread}`]" v-show="isShowEditPlot">
            <div class="title-wrap">
                <span class="title-wrap_content">编辑标绘</span>
                <a class="title-wrap_btn iconfont iconbimgis_guanbi" @click="onCloseEditPlot"></a>
            </div>

            <div class="editPlotList-wrap">
                <EditorList
                        ref="editor"
                        :element.sync="element"
                        :bindViewerId="bindViewerId"
                        :editList="editList"></EditorList>
            </div>

            <div class="horizontal-flex btnBottom-wrap">
                <div class="btnBottom-wrap_yes" @click="onSaveEditTemplate">保存</div>
                <div class="btnBottom-wrap_yes" @click="onApplyEditTemplate">应用</div>
                <div class="btnBottom-wrap_cancel" @click="onCloseEditPlot">取消</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Empty, Tree, Dropdown, DropdownMenu, DropdownItem, Collapse, CollapseItem, Row, Col, Tooltip } from 'element-ui';
    import EditorList from "./components/EditorList";
    import PlotUtils from "./components/PlotUtils";

    export default {
        name: "PlotSingle",
        components: {
            'el-empty': Empty,
            'el-tree': Tree,
            'el-dropdown': Dropdown,
            'el-dropdown-menu': DropdownMenu,
            'el-dropdown-item': DropdownItem,
            'el-collapse': Collapse,
            'el-collapse-item': CollapseItem,
            'el-row': Row,
            'el-col': Col,
            'el-tooltip': Tooltip,
            'EditorList': EditorList
        },
        data(){
            return {
                isLoading: false,
                loadingBackColor: 'rgba(0, 0, 0, 0.1)',
                isShowAll: false,
                isExpandAll: false,

                isShowCreatePlot: false,
                plotTemplateList: [
                    {id: 'fileGeometry', name: '文件类', children: [
                            {id: 'kml', name: "KML", drawType: 'file'},
                        ]},
                    {id: '2DGeometry', name: '二维平面类', children: [
                            {id: 'polyline', name: '折线', drawType: 'polyline'},
                            {id: 'polylineGround', name: '折线-贴地', drawType: 'polyline'},
                            {id: 'curve', name: '曲线', drawType: 'polyline'},
                            {id: 'curveGround', name: '曲线-贴地', drawType: 'polyline'},
                            {id: 'rectangle', name: '矩形', drawType: 'rectangle'},
                            {id: 'rectangleGround', name: '矩形-贴地', drawType: 'rectangle'},
                            {id: 'circle', name: '圆', drawType: 'circle'},
                            {id: 'circleGround', name: '圆-贴地', drawType: 'circle'},
                            {id: 'polygon', name: '多边形', drawType: 'polygon'},
                            {id: 'polygonGround', name: '多边形-贴地', drawType: 'polygon'},
                        ]},
                    {id: '3DGeometry', name: '三维立体类', children: [
                            {id: 'wall', name: "墙体", drawType: 'polyline'},
                            {id: 'corridor', name: "走廊", drawType: 'polyline'},
                            {id: 'extrudedPolygon', name: "多边立体", drawType: 'polygon'},
                            {id: 'extrudedRectangle', name: "立方体", drawType: 'rectangle'},
                            {id: 'box', name: "盒子", drawType: 'point'},
                            {id: 'cylinder', name: "圆柱", drawType: 'circle'},
                            {id: 'ellipsoid', name: "球体", drawType: 'circle'},
                        ]}
                ],
                plotTemplateExpand: '2DGeometry',
                plotTemplateActive: 'polyline',

                isShowEditPlot: false,
                editStatus: '',
                editObject: null,
                originEditList: [
                    {id: 'radius', type: 'input', name: '半径(单位：米)', value: '', tag: 'Number'},
                    {id: 'width', type: 'input', name: '宽度(单位：米)', value: '', tag: 'Number'},
                    {id: 'minHeight', type: 'input', name: '最小海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'maxHeight', type: 'input', name: '最大海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxLong', type: 'input', name: '盒子长度(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxWidth', type: 'input', name: '盒子宽度(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxHeight', type: 'input', name: '盒子高度(单位：米)', value: '', tag: 'Number'},
                    {id: 'ellipsoidHeight', type: 'input', name: '球心海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'perPositionHeight', type: 'input', name: '固定海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'height', type: 'input', name: '海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'fill', type: 'radio', name: '是否填充', value: true},
                    {id: 'fillType', type: 'objSelector', name: '填充类型', options: [], value: ''},

                    // {id: 'gridCount', type: 'input', name: '网格数量', value: 6, filter: false, tag: 'Number'},
                    // {id: 'gridWidth', type: 'input', name: '网格宽度', value: 2, filter: false, tag: 'Number'},
                    // {id: 'stripeColor', type: 'colorPicker', name: '条纹衬色', value: '#FFFFFF', filter: false},
                    // {id: 'stripeCount', type: 'input', name: '条纹数量', value: 6, filter: false, tag: 'Number'},
                    // {id: 'checkerboardColor', type: 'colorPicker', name: '棋盘衬色', value: '#FFFFFF', filter: false},
                    // {id: 'checkerboardCount', type: 'input', name: '棋盘数量', value: 6, filter: false, tag: 'Number'},
                    // {id: 'animationSpeed', type: 'input', name: '动画速度(单位：ms)', value: 3000, filter: false, tag: 'Number'},
                    // {id: 'animationDirection', type: 'objSelector', name: '动画方向', options: PlotUtils.getAnimationDirection(), value: 'flowToTop', filter: false},
                    // {id: 'animationCount', type: 'input', name: '重复数量', value: 10, filter: false, tag: 'Number'},

                    {id: 'fillColor', type: 'colorPicker', name: '填充颜色', value: '#FFFFFF'},
                    {id: 'fillWidth', type: 'input', name: '填充宽度', value: 3, tag: 'Number'},
                    {id: 'alpha', type: 'slider', name: '不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                    {id: 'outline', type: 'radio', name: '是否边框', value: true},
                    {id: 'outlineColor', type: 'colorPicker', name: '边框颜色', value: '#FFFFFF'},
                    {id: 'outlineAlpha', type: 'slider', name: '边框不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                    {id: 'outlineWidth', type: 'input', name: '边框线宽', value: 1, tag: 'Number'},
                    {id: 'labelColor', type: 'colorPicker', name: '字体颜色', value: '#FFFFFF'},
                    {id: 'labelSize', type: 'input', name: '字体大小', value: 1, tag: 'Number'},
                    {id: 'isClampToGround', type: 'radio', name: '是否贴地', value: true},
                    {id: 'isDepth', type: 'radio', name: '是否遮挡', value: true},
                    {id: 'rotation', type: 'input', name: '旋转角度', value: 1, tag: 'Number'},
                    {id: 'stRotation', type: 'input', name: '材质角度', value: 1, tag: 'Number'},
                ],
                editList: [],

                plotList: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: 'isLeaf'
                }
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
            bindViewerType: {
                type: String,
                default: 'GIS'
            },
            subItemSpread: {
                type: String,
                default: 'right'
            }
        },
        mounted() {

        },
        methods: {
            /**
             * @description: 获取树节点图标
             * @param {String} type 数据节点类型
             */
            getTreeNodeIcon(type){
                let arr = type.split('_');
                switch (arr[0]) {
                    case 'file':
                        return 'icona-wenjianjiadakai tree-node-icon-file';
                    case 'kml':
                        return 'icongis_kml';
                    case 'kmlItem':
                        if(arr[1] === 'Region'){
                            return 'iconwubianxing tree-node-icon-obj';
                        }else if(arr[1] === 'Label'){
                            return 'iconwenbenbiaoqian tree-node-icon-obj';
                        }
                }

                return 'icongis_biaohui';
            },
            /**
             * @description: 获取对象的绘制类型
             * @param {String} type 对象类型
             */
            getPlotDrawType(type){
                let res;
                for (let key in this.plotTemplateList){
                    let item = this.plotTemplateList[key];
                    let index = item.children.findIndex(x => x.id === type);
                    if(index === -1) continue;

                    res = item.children[index];
                    break;
                }

                return res.drawType;
            },
            /**
             * @description: 获取编辑页面数据对象
             * @param {String} list 编辑页面内容
             */
            getEditOptions(list){
                let res = {};
                for (let item of list){
                    if(item.tag === 'Number'){
                        res[item.id] = Number(item.value);
                    }else{
                        res[item.id] = item.value;
                    }
                }

                return res;
            },
            /**
             * @description: 获取编辑页面内容
             * @param {String} type 编辑对象类型
             * @param {Object} opt 编辑页面数据对象
             * @param {Boolean} isNeedGroup 是否需要分组数据
             */
            getEditList(type, opt = {}, isNeedGroup = true){
                let res = [];

                if(isNeedGroup){
                    let groupOptions = [];
                    this.plotList.forEach(x => {
                        groupOptions.push({id: x.id, name: x.name});
                    });
                    res.push({id: 'pId', type: 'objSelector', name: '指定分组', options: groupOptions, value: groupOptions[0].id})
                }

                for (let item of this.originEditList){
                    if(opt[item.id] === undefined) continue;

                    let temp;
                    if(item.id === 'fillType'){
                        temp = Object.assign(item, {
                            value: opt[item.id],
                            options: PlotUtils.getFillTypeByType(type)
                        });
                    }else{
                        temp = Object.assign(item, {
                            value: opt[item.id]
                        });
                    }

                    res.push(temp);
                }

                return res;
            },
            /**
             * @description: 获取标绘树的全部对象
             */
            getAllPlotItem(){
                const el = this.$refs.plotTree;
                let allNodes = el.store._getAllNodes();
                let nodes = [];

                for (let i = 0; i < allNodes.length; i++) {
                    if(allNodes[i].level === 2) {
                        nodes.push(allNodes[i].data);
                    }
                }

                return nodes;
            },
            /**
             * @description: 获取标绘树的指定对象
             * @param {String} key 标签对象id
             */
            getPlotItem(key){
                const el = this.$refs.plotTree;
                let node = el.getNode(key);
                if (!node) return undefined;

                return node.data;
            },
            /**
             * @description: 获取标绘树的已经勾选的对象
             */
            getPlotCheckedNodes(){
                const el = this.$refs.plotTree;

                let nodes = el.getCheckedNodes();
                let keys = el.getCheckedKeys();

                return {
                    nodes,
                    keys
                };
            },

            /**
             * @description: 设置是否Loading
             * @param {Boolean} isShow 是否显示隐藏
             */
            setIsLoading(isShow = false) {
                this.isLoading = isShow;
            },
            /**
             * @description: 设置树节点数据对象是否勾选
             * @param {String} keys 数据对象id数据
             * @param {Boolean} isChecked 是否勾选
             */
            setTreeNodeCheck(keys, isChecked = true) {
                const el = this.$refs.plotTree;
                for (let item of keys) {
                    let node = el.getNode(item);
                    if (!node) continue;

                    el.setChecked(item, isChecked, true);
                }
            },
            /**
             * @description: 设置全部树节点数据对象是否勾选
             * @param {Boolean} isChecked 是否勾选
             */
            setAllNodeCheck(isChecked = true){
                const el = this.$refs.plotTree;

                let allNodes = el.store._getAllNodes();
                for (let i = 0; i < allNodes.length; i++) {
                    if(allNodes[i].level !== 1) continue;

                    el.setChecked(allNodes[i], isChecked, true);
                }
            },
            /**
             * @description: 显示编辑面板--创建标绘时的临时对象
             * @param {String} data 数据对象
             */
            setEditorByActive(data){
                let {plotType, options} = data;
                this.editList = this.getEditList(plotType, options, true);
                this.isShowEditPlot = true;
                this.editStatus = 'active';
                this.editObject = data;
            },
            /**
             * @description: 显示编辑面板--修改标绘时的对象
             * @param {String} data 数据对象
             */
            setEditorByUpdate(data){
                let {plotType, options} = data;
                this.editList = this.getEditList(plotType, options, false);
                this.isShowEditPlot = true;
                this.editStatus = 'update';
                this.editObject = data;
            },
            /**
             * @description: 清空编辑面板
             */
            setEditorByEmpty(){
                this.isShowEditPlot = false;
                this.editList = [];
                this.editStatus = '';
                this.editObject = null;
            },

            /**
             * @description: 添加树节点对象
             * @param {String} data 数据对象
             * @param {String} pId 数据对象父节点Id
             * @param {Boolean} isChecked 是否勾选
             */
            appendTreeData(data, pId = null, isChecked = false){
                const el = this.$refs.plotTree;
                let node = el.getNode(data.id);

                if (node) return false;
                el.append(data, pId);

                if(isChecked) {
                    this.$nextTick(() => {
                        this.setTreeNodeCheck([data.id]);
                    });
                }
                return true;
            },
            /**
             * @description: 更新树节点对象
             * @param {String} id 数据对象id
             * @param {String} name 修改键名称
             * @param {String} value 修改键值
             */
            updateTreeData(id, name, value){
                const el = this.$refs.plotTree;
                let node = el.getNode(id);
                if (!node) return false;

                node.data[name] = value;
                return true;
            },
            /**
             * @description: 删除树节点对象
             * @param {String} id 数据对象id
             */
            deleteTreeData(id){
                const el = this.$refs.plotTree;
                let node = el.getNode(id);
                if (!node) return false;

                el.remove(id);
                return true;
            },
            /**
             * @description: 清空树节点对象
             */
            emptyTreeData(){
                this.plotList = [];
            },

            /**
             * @description: 展开全部
             */
            onExpandAll(){
                this.isExpandAll = !this.isExpandAll;
                const el = this.$refs.plotTree;

                for (var i = 0; i < el.store._getAllNodes().length; i++) {
                    el.store._getAllNodes()[i].expanded = this.isExpandAll;
                }
            },
            /**
             * @description: 展开指定对象
             * @param {Object} node 指定数据对象
             */
            onExpand(node){
                node.expanded = !node.expanded;
            },
            /**
             * @description: 显示全部
             */
            onShowAll(){
                this.isShowAll = !this.isShowAll;
                this.$emit('showAll', this.isShowAll);
            },

            /**
             * @description: 创建下拉事件
             * @param {String} command 选择的创建命令
             */
            onCreate(command){
                switch (command) {
                    case 'createPlot':
                        if(this.plotList.length === 0){
                            this.$message.error('暂无分组，请先创建分组');
                            return;
                        }
                        this.plotTemplateActive = 'polyline';
                        this.isShowCreatePlot = true;
                        break;
                    case 'createGroup':
                        this.$emit('createGroup');
                        break;
                }
            },
            /**
             * @description: 标绘模板选择事件
             * @param {Object} item 选择的标签模板对象
             */
            onSelectPlotTemplate(item){
                this.plotTemplateActive = item.id;
            },
            /**
             * @description: 标绘模板应用事件
             */
            onApplyPlotTemplate(){
                this.onCloseCreatePlot();

                let drawType = this.getPlotDrawType(this.plotTemplateActive);
                this.$emit('createActivePlot', this.plotTemplateActive, drawType);
            },
            /**
             * @description: 标绘模板关闭事件
             */
            onCloseCreatePlot(){
                this.isShowCreatePlot = false;
            },

            /**
             * @description: 编辑标绘保存事件
             */
            onSaveEditTemplate(){
                let options = this.getEditOptions(this.editList);
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);

                if(this.editStatus === 'active'){
                    this.$emit('createPlot', this.editObject);
                }else if(this.editStatus === 'update'){
                    this.$emit('saveUpdatePlot', this.editObject);
                }
            },
            /**
             * @description: 编辑标绘应用事件
             */
            onApplyEditTemplate(){
                let options = this.getEditOptions(this.editList);
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);

                if(this.editStatus === 'active'){
                    this.$emit('updateActivePlot', this.editObject);
                }else if(this.editStatus === 'update'){
                    this.$emit('updatePlot', this.editObject);
                }
            },
            /**
             * @description: 编辑标绘关闭事件
             */
            onCloseEditPlot(){
                if(this.editStatus === 'active'){
                    this.$emit('removeActivePlot');
                }else if(this.editStatus === 'update'){
                    this.$emit('resetPlot');
                }
            },

            /**
             * @description: 标绘列表显示隐藏事件
             * @param {Object} data 树节点对象
             */
            onShowPlot(data){
                const el = this.$refs.plotTree;
                const node = el.getNode(data.id);
                const isChecked = node.checked;

                this.$emit('showPlot', data, isChecked);
            },
            /**
             * @description: 标绘列表对象定位事件
             * @param {Object} data 数据对象
             * @param {Object} node 节点对象
             */
            onLocationPlot(data, node){
                this.$emit('locationPlot', data, node.checked);
            },
            /**
             * @description: 标绘列表更多事件
             * @param {string} command 命令类型
             * @param {Object} data 树节点对象
             */
            onMorePlot(command, data){
                switch (command) {
                    case 'rename':
                        this.$emit('renamePlot', data);
                        break;
                    case 'update':
                        if(data.type === 'Group'){
                            this.$emit('updateGroup', data);
                        }else if(data.type === 'Child'){
                            this.$emit('preUpdatePlot', data);
                        }else if(data.type === 'ChildItem'){
                            this.$emit('preUpdatePlot', data);
                        }
                        break;
                    case 'delete':
                        if(data.type === 'Group'){
                            this.$emit('removeGroup', data);
                        }else if(data.type === 'Child'){
                            this.$emit('removePlot', data);
                        }else if(data.type === 'ChildItem'){
                            this.$emit('removePlot', data);
                        }
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .load-wrap {
        width: 100%;
        height: 100%;
    }

    .createPlot-wrap{
        z-index: 2000;
        position: absolute;
        top: 0;
        /*left: calc(100% + 10px);*/
        width: 400px;
        height: 400px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: inherit;
        background-color: white;
        color: inherit;
    }

    .createPlot-wrap-left{
        right: calc(100% + 10px);
    }

    .createPlot-wrap-right{
        left: calc(100% + 10px);
    }

    .editPlot-wrap{
        z-index: 2000;
        position: absolute;
        top: 0;
        /*left: calc(100% + 10px);*/
        width: 300px;
        height: 100%;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: inherit;
        background-color: white;
        color: inherit;
    }

    .editPlot-wrap-left{
        right: calc(100% + 10px);
    }

    .editPlot-wrap-right{
        left: calc(100% + 10px);
    }

    .title-wrap {
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .title-wrap_content {
        float: left;
        padding-left: 10px;
        font-size: 14px;
        color: inherit;
    }

    .title-wrap_btn {
        float: right;
        margin-top: 7px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
        border-radius: 4px;
    }

    .title-wrap_btn:hover{
        background-color: rgba(0, 118, 255, 0.1);
    }

    .btnBottom-wrap{
        width: 100%;
        height: 50px;
        line-height: 50px;

        .btnBottom-wrap_cancel {
            float: right;
            margin-right: 10px;
            margin-top: 10px;
            width: 60px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            background-color: #EFF1F5;
            color: black;
            font-weight: 500;
            text-align: center;
        }

        .btnBottom-wrap_cancel:hover {
            opacity: 0.7;
        }

        .btnBottom-wrap_yes{
            float: right;
            margin-right: 15px;
            margin-top: 10px;
            width: 60px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            background-color: #206EFC;
            color: white;
            font-weight: 500;
            text-align: center;
        }

        .btnBottom-wrap_yes:hover {
            opacity: 0.7;
        }
    }

    .plotTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 38px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: inherit;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree-node__expand-icon){
            color: inherit;
            font-size: 14px;
        }

        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 34px !important;
            line-height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent !important;
            border: none;
            width: 24px;
            height: 24px;
            line-height: 24px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: #0076FF;
            font-size: 18px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
            background-color: transparent !important;
        }
    }

    .createPlotTree-wrap{
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 90px);

        :deep(.el-collapse-item__header){
            padding-left: 10px;
        }
    }

    .editPlotList-wrap{
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 90px);
    }

    .empty-wrap {
        position: absolute;
        top: 38px;
        width: 100%;
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-empty__description p) {
            color: inherit !important;
        }
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 88%;
        height: 35px;
        line-height: 35px;
        padding-right: 60px;
    }

    .tree-node-icon {
        margin-right: 3px;
        margin-left: -5px;
        width: 24px;
        height: 24px;
        line-height: 24px;
    }

    .tree-node-icon-file {
        color: #6DD3FD;
    }

    .tree-node-icon-obj {
        color: inherit;
    }

    .tree-node-content {
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-location{
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
    }

    .tree-node-location:hover{
        color: #1E90FF;
    }

    .tree-node-more{
        position: absolute;
        right: 10px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px !important;
        color: inherit;
    }

    .tree-node-more:hover{
        color: #1E90FF;
    }

    .back-template{
        width: -webkit-fill-available;
        height: 80px;
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        opacity: unset !important;
    }

    .back-kml{
        background-image: url("./background/plot/kml.jpg");
    }

    .back-polyline{
        background-image: url("./background/plot/polyline.jpg");
    }

    .back-polylineGround{
        background-image: url("./background/plot/polylineGround.jpg");
    }

    .back-curve{
        background-image: url("./background/plot/curve.jpg");
    }

    .back-curveGround{
        background-image: url("./background/plot/curveGround.jpg");
    }

    .back-rectangle{
        background-image: url("./background/plot/rectangle.jpg");
    }

    .back-rectangleGround{
        background-image: url("./background/plot/rectangleGround.jpg");
    }

    .back-circle{
        background-image: url("./background/plot/circle.jpg");
    }

    .back-circleGround{
        background-image: url("./background/plot/circleGround.jpg");
    }

    .back-polygon{
        background-image: url("./background/plot/polygon.jpg");
    }

    .back-polygonGround{
        background-image: url("./background/plot/polygonGround.jpg");
    }

    .back-wall{
        background-image: url("./background/plot/wall.jpg");
    }

    .back-corridor{
        background-image: url("./background/plot/corridor.jpg");
    }

    .back-extrudedPolygon{
        background-image: url("./background/plot/extrudedPolygon.jpg");
    }

    .back-extrudedRectangle{
        background-image: url("./background/plot/extrudedRectangle.jpg");
    }

    .back-box{
        background-image: url("./background/plot/box.jpg");
    }

    .back-cylinder{
        background-image: url("./background/plot/cylinder.jpg");
    }

    .back-ellipsoid{
        background-image: url("./background/plot/ellipsoid.jpg");
    }

    .back-template:hover{
        border: 1px solid #1E90FF;
    }

    .back-template_active{
        border: 1px solid #1E90FF !important;
    }
</style>
