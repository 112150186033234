<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-04-25 17:07:59
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-05-08 15:17:31
 * @FilePath: \dataview-viewer-test\src\custom-component\form\formParser\flowEngine\components\FlowOperateBate.vue
 * @Description: 流程审批组件 bate 版本
-->

<template>
  <div class="flow-operate-wrap" v-if="editData.btn && editData.btn.length !== 0">
    <div class="action">
      <span class="expand" @click.stop="handleExpand">
        <i v-if="isExpand" class="iconfont iconjijia_xiangxia" />
        <i v-else class="iconfont iconjijia_xiangshang" />
      </span>
      <div>
        <el-button v-if="!isExpand || !btnRadio" type="primary" class="btn" size="mini" @click="isExpand = !isExpand">
          {{ isExpand ? '收起审批' : '审批' }}
        </el-button>
        <el-button v-else :loading="submitLoading" class="btn" size="mini" :type="btnInfo.type" @click.stop="flowOperate">
          {{ btnInfo.text || '提交' }}
        </el-button>
      </div>
    </div>
    <transition name="fade">
      <div v-show="isExpand" class="float-operate-wrap">
        <div class="label">
          审批意见
        </div>
        <div class="type-select-wrap">
          <el-radio-group v-model="btnRadio" @change="btnChange">
            <template v-for="(item, index) in editData.btn">
              <el-radio
                v-if="item.show"
                :label="item.status"
                :key="index">
                {{ item.btn }}
              </el-radio>
            </template>
          </el-radio-group>
        </div>
        <div class="operate-content">
          <el-form ref="baseForm" label-position="top" :model="applyMsg">
            <el-form-item
              v-if="buttonStatus !== 7 && buttonStatus !== 9"
              style="margin-bottom: 10px;margin-top: 10px"
              prop="remarks"
              :rules="suggestRequired ? suggestRule :[]">
              <el-input
                v-model="applyMsg.remarks"
                placeholder="请输入审批意见"
                :rows="3"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              v-if="buttonStatus !== 7 && buttonStatus !== 9"
              style="margin-bottom: 10px"
              label="审批附件" prop="files"
              :rules="attachRequired ? attachRule :[]">
              <fileUpload :files="applyMsg.files" @extra-files="extraFiles" />
            </el-form-item>
          </el-form>
          <PcParser
            ref="otherForm"
            v-if="showOtherForm"
            :form-data="curStepNode.extendFields"
          >
          </PcParser>
          <div v-if="buttonStatus === -1">
            <el-form label-position="top">
              <el-form-item label="选择退回类型">
                <el-radio-group v-model="back_type" size="small">
                  <el-radio-button :label="1">退回发起人</el-radio-button>
                  <el-radio-button :label="2">退回上一步</el-radio-button>
                  <el-radio-button :label="3">指定步骤退回</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="back_type === 3" label="步骤选择">
                <el-select
                  v-model="back_step"
                  placeholder="请选择退回的步骤"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in editData.doneStep"
                    :key="index"
                    :value="item.nodeId"
                    :label="item.name"
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="buttonStatus > 0 && buttonStatus !== 4 && buttonStatus !== 6 && buttonStatus !== 7 && buttonStatus !== 9">
            <FlowPicker
              :data-id="dataId"
              :module="module"
              :turn="buttonStatus === 2 ? true : false"
              type="next"
              :flow-edit="flowEdit"
              @checked-users="dealWithPicker"
              @selected-step="getSelectStep"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import fileUpload from '@/components/fileUpload/newFiles/index.vue'
import FlowPicker from '@/custom-component/flow/flow-designer/src/operation/FlowPicker.vue'
import { flowEngine } from '@/apis/data/workflow'
import {Form, FormItem, Input, Select, Option, RadioGroup, Radio} from 'element-ui'

export default {
  name: 'FlowOperate',
  components: {
    fileUpload,
    FlowPicker,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
  },
  inject:{
    parser: {
      default: null
    }
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //流程中修改的数据
    flowEdit:{
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    btnInfo() {
      const buttonStatus = this.buttonStatus
      const btns = this.editData.btn
      if(Array.isArray(btns)) {
        const btnItem = btns.find(el => el.status === buttonStatus && el.show)
        if(btnItem) {
          return {
            type: btnItem.status === -1 || btnItem.status === 7? 'danger' : 'primary',
            text: `确认${btnItem.btn}`
          }
        } else {
          return {}
        }
      }
      return {}
    }
  },
  data(){
    return{
      isExpand: false,
      curStepNode: {},
      buttonStatus: 0,
      back_type: 1,
      back_step: '',
      module:'',
      dataId:'',
      applyMsg:{
        remarks: '',
        files: [],
      },
      selectedUsers: [],
      checkPickerNum: 0,
      selectStep: '',

      btnRadio:'',
      submitLoading: false,
      suggestRequired: false,
      attachRequired: false,
      suggestRule: {
        required: true, message: '审批意见不能为空',type: 'string', trigger: 'blur'
      },
      attachRule:{
        required: true, message: '审批意见不能为空', type: 'array', trigger: 'change'
      },
      showOtherForm: false
    }
  },
  methods:{
    handleExpand() {
      this.isExpand = !this.isExpand
    },
    formItemShow(list){
      this.$emit('input-show',list)
    },
    getSelectStep(nodeId) {
      this.selectStep = nodeId
    },
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    extraFiles(files){
      this.applyMsg.files = files
    },
    actionEvent(status){
      this.buttonStatus = status
    },
    flowOperate() {
      const oldFlow = this.editData?.recover?.pens || null
      let invalid = false
      try {
        this.$refs.baseForm.validate((valid)=>{
          if(!valid){
            throw new Error('表单验证失败')
          }
        })
        if(this.$refs.otherForm) {
          this.$refs.otherForm.validateFormInput()
        }
      } catch (e){
        invalid = true
        this.$message.error('审批意见必填项未填报完整')
      }
      if(invalid) return

      // 兼容手写功能流程审批
      if(this.parser){
        this.parser.formParserValid().then(valid=>{
          if(valid){
            if (
              this.checkPickerNum > 0 &&
              this.buttonStatus !== 4 &&
              this.buttonStatus !== 6 && this.buttonStatus !== 7 && this.buttonStatus !== 9 &&
              this.checkPickerNum !== this.selectedUsers.target_users.length
            ) {
              this.$message.error('审批人未选择完整！')
              return false
            }
            let formData = {
              status: this.buttonStatus,
              module: this.module,
              remarks: this.applyMsg.remarks,
              appendix: this.applyMsg.files,
            }
            if(this.$refs.otherForm){
              const otherData = this.$refs.otherForm.getFormData()
              formData.extend_fields = otherData
            }
            if (this.buttonStatus === -1) {
              formData.back_type = this.back_type
              if (this.back_type === 3) formData.back_step = this.back_step
            }
            if (
              this.selectedUsers.target_users &&
              this.selectedUsers.target_users.length > 0
            ) {
              formData.target_users = this.selectedUsers.target_users
            }
            if (
              this.selectedUsers.send_users &&
              this.selectedUsers.send_users.length > 0
            ) {
              formData.send_users = this.selectedUsers.send_users
            }
            if (this.selectStep) formData.select_step = this.selectStep
            if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit
            if (this.submitLoading) {
              return
            }
            this.submitLoading = true
            if (oldFlow){
              flowEngine.flowOperate(this.dataId, formData).then((res) => {
                if (res.data.code === 200) {
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
                this.$emit('change-end')
                setTimeout(() => {
                  this.submitLoading = false
                }, 500)
              })
            } else {
              flowEngine.newFlowOperate(this.dataId, formData).then((res) => {
                if (res.data.code === 200) {
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
                this.$emit('change-end')
                setTimeout(() => {
                  this.submitLoading = false
                }, 500)
              })
            }
          } else {
            this.$message.error('数据验证失败，请核对必填字段数据');
          }
        })
      } else {
        if (
          this.checkPickerNum > 0 &&
          this.buttonStatus !== 4 &&
          this.checkPickerNum !== this.selectedUsers.target_users.length
        ) {
          this.$message.error('审批人未选择完整！')
          return false
        }
        let formData = {
          status: this.buttonStatus,
          module: this.module,
          remarks: this.applyMsg.remarks,
          appendix: this.applyMsg.files,
        }
        if(this.$refs.otherForm){
          const otherData = this.$refs.otherForm.getFormData()
          formData.extend_fields = otherData
        }
        if (this.buttonStatus === -1) {
          formData.back_type = this.back_type
          if (this.back_type === 3) formData.back_step = this.back_step
        }
        if (
          this.selectedUsers.target_users &&
          this.selectedUsers.target_users.length > 0
        ) {
          formData.target_users = this.selectedUsers.target_users
        }
        if (
          this.selectedUsers.send_users &&
          this.selectedUsers.send_users.length > 0
        ) {
          formData.send_users = this.selectedUsers.send_users
        }
        if (this.selectStep) formData.select_step = this.selectStep
        if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit
        if (this.submitLoading) {
          return
        }
        this.submitLoading = true

        if(oldFlow){
          flowEngine.flowOperate(this.dataId, formData).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
            this.$emit('change-end')
            setTimeout(() => {
              this.submitLoading = false
            }, 500)
          })
        } else {
          flowEngine.newFlowOperate(this.dataId, formData).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
            this.$emit('change-end')
            setTimeout(() => {
              this.submitLoading = false
            }, 500)
          })
        }
      }
    },
    /* 操作按钮选择 */
    btnChange(val) {
      if(val === -1){
        this.checkPickerNum = 0
      }
      this.actionEvent(val)
    },
    /* 隐藏操作区域 */
    hidOperate() {
      this.$emit('hid-operate')
    }
  },
  created () {
    this.module = this.editData.module
    this.dataId = this.editData.id
    this.curStepNode = this.editData?.curStepNode
    const extendFields = this.curStepNode?.extendFields || {}
    if(extendFields instanceof Object && extendFields?.fields?.length){
      this.showOtherForm = true
    }
    this.attachRequired = this.curStepNode?.attachRequired || false
    this.suggestRequired = this.curStepNode?.suggestRequired || false
  }
}
</script>
<style scoped>

</style>
<style lang="less" scoped>
.flow-operate-wrap{
  text-align: initial;
  flex-shrink: 0;
  //height: 56px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .action{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .expand{
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F4F6FA;
      border-radius: 50%;
      user-select: none;
    }
    .btn{
      min-width: 122px;
    }
  }
  .float-operate-wrap{
    position: absolute;
    bottom: 48px;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: 999;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px -4px 12px -1px rgba(12, 29, 61, 0.08), 0px 0px 2px -1px rgba(12, 29, 61, 0.04);
    border-radius: 12px 12px 0px 0px;
    .label{
      flex-shrink: 0;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #161C1F;
    }
    .type-select-wrap{
      margin: 10px 0;
      flex-shrink: 0;
    }
    .operate-content{
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
.fade-enter-active{
  animation: bounce-in .3s;
}
.fade-leave-active {
  animation: bounce-in .2s reverse;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
@keyframes bounce-in {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 400px;
    opacity: 1;
  }
}
</style>
