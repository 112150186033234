<!--
 * @Author: damarkday damarkday@163.com
 * @Date: 2022-06-15 10:54:14
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-04-25 16:02:02
 * @Description: AIOT显示
-->
<template>
	<el-tabs
		ref="tabs"
		v-model="activeName"
		@tab-click="handleClick"
	>
		<el-tab-pane
			label="BIM模型"
			name="second"
		>
			<template>
				<!-- content header -->
				<div
					class="flex"
					style="justify-content:space-between;align-items:center;padding:10px"
				>
					<p style="color:#3FB0FF;font-size:14px;font-weight:500">AIOT</p>
					<div
						class="flex"
						style="align-items:center"
					>
						<span
							class="fz12"
							style="color:#A4C3F0;margin-right:10px"
						>全部显示: </span>
						<el-switch
							v-model="value"
							inactive-color="#0095FF55"
							@change="showAll"
						>
						</el-switch>
					</div>
				</div>
				<!-- content -->
				<div
					class="flex"
					style="flex-direction:row;flex-wrap:wrap;padding:10px;"
				>
					<div
						v-for="item in iCONList"
						:key="item.type"
						style="height:51px;width:80px;"
					>
						<!-- item -->
						<div class="tab-item" @click="clickItem(item)">
							<!-- icon -->
							<i
								:class="`iconfont ${item.icon}`"
								:style="{background:checkedType.includes(item.type)?'radial-gradient(44% 44% at 50% 50%,'+item.color+' 10%,'+item.color+' 0%,rgba(255,132,0,0.00) 100%, rgba(255,132,0,0.00) 100%)':''}"
							></i>
							<!-- icon text -->
							<p class="fz12 type-name">{{ item.name}}</p>
						</div>
					</div>
				</div>
			</template>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
import { TabPane, Tabs } from 'element-ui';
import eventBus from '@/plugins/eventBus';
import { initParams, getComponentById, getLinkByTemplate } from '@/utils/tools';
import { mapState } from 'vuex';
// icon list
const iCONList = [
	{
		icon: 'iconshexiangtou',
		type: 'monitor',
		name: '视频监控',
		color: '#00B7FF',
		object_uuid: '54afa883-1236-4cda-9524-fd744181b861',
    view_uuid: 'view62568cd7d7243',
	},
	{ 
		icon: 'icontadiao',
    type: 'towerCrane',
		name: '塔吊',
		color: '#3EDA70',
		object_uuid: 'object628361894a23b',
    view_uuid: 'view628368d89e25f',
	}
	// { icon: 'iconzhiliangxunjian1', name: '质量巡检',color:'#FF8400'},
	// { icon: 'iconnotification', name: '预警中心',color:'#CF2D2D'},
	// { icon: 'icondianya', name: '用电养护',color:'#FBD22E'},
	// { icon: 'iconwuziguanli', name: '材料管理',}
];

export default {
	name: 'AIOTTabs',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		// 操作配置
		actionConfig() {
			return this.element?.actionConfig || {};
		},
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData;
			}
			return this.componentData || [];
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			activeName: 'second', // choosed pane
			value: false, // switch default value
			iCONList,
			checkedType: []
		};
	},
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane
	},
	mounted() {
		this.resetTabActivePosition(this.$refs.tabs.$el);
		eventBus.$on('doComponentBehavior', (config) => {
			const { component, list = [] } = config;
			if (component !== this.element.id) return false;
			list.forEach((ele) => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(
					params,
					this.isGroup,
					this.subComponentData,
					this.groupComponents
				);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach((funName) => {
						try {
							eval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		});
	},
	watch: {
		activeName() {
			this.resetTabActivePosition(this.$refs.tabs.$el);
		}
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
		/**
		 * @Des 重新设置底部下划线的位置
		 */
		resetTabActivePosition($el) {
			this.$nextTick(() => {
				const activeEl = $el.querySelector('.el-tabs__item.is-active');
				const lineEl = $el.querySelector('.el-tabs__active-bar');
				const style = getComputedStyle(activeEl);
				// const pl = style.paddingLeft.match(/\d+/)[0] * 1;
				// const pr = style.paddingRight.match(/\d+/)[0] * 1;
				const ml = style.marginLeft.match(/\d+/)[0] * 1;
				const mr = style.marginRight.match(/\d+/)[0] * 1;
				const w = style.width.match(/\d+/)[0] * 1;
				// lineEl.style.transform = 'translateX(' + (activeEl.offsetLeft + pl) + 'px)';
				lineEl.style.transform = `translateX(${activeEl.offsetLeft - ml}px)`;
				lineEl.style.width = `${w + ml + mr}px`;
			});
		},

		/**
		 * 单击图标
		 */
		clickItem(item) {
			if (this.checkedType.includes(item.type)) {
				//取消选中
				if(this.checkedType.length === 1){
					this.value = false
					this.showAll(false)
				} else {
					let index = this.checkedType.findIndex((el) => el === item.type);
					this.checkedType.splice(index, 1);
					let data = Object.assign(item, { show: false });
					this.changeAiotType(data);
				}
			} else {
				if(this.checkedType.length === this.iCONList.length-1){
					this.value = true
					this.showAll(true)
				} else {
					this.checkedType.push(item.type);
					let data = Object.assign(item, { show: true });
					this.changeAiotType(data);

				}
			}
		},
		/**
		 * 全部显示
		 */
		showAll(bol) {
			//延迟执行函数changeAiotType
			for(let i = 0; i < this.iCONList.length; i++){
				let item = this.iCONList[i]
				if (bol) {
					if (!this.checkedType.includes(item.type)) {
						this.checkedType.push(item.type);
						let data = Object.assign(item, { show: true });
						this.changeAiotType(data);
					}
				} else {
					if (this.checkedType.includes(item.type)) {
						let index = this.checkedType.findIndex((el) => el === item.type);
						this.checkedType.splice(index, 1);
						let data = Object.assign(item, { show: false });
						this.changeAiotType(data);
					}
				}
			}
		},
		/**
		 * @desc: 切换选中类型
		 * @param {Object} data
		 */
		changeAiotType(data) {
			let result = {};
			if (Object.prototype.toString.call(data) === '[object Object]') {
				for (let key in data) {
					result = {
						...result,
						[key]: key
					};
				}
			} else {
				result = data;
			}
			this.element.resolveData = {
				checked: data
			};
			this.onAction(data);
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData: this.element.resolveData
			});
		},
		/**
		 * @desc: 操作
		 */
		onAction(data) {
			const comEvents = this.element?.events?.click || {};
			const { pattern, specialEventList = [] } = comEvents;
			if (!pattern) {
				this.doActionItem(comEvents);
			} else {
				specialEventList.forEach((ele) => {
					this.doActionItem(ele, data);
				});
			}
		},
		/**
		 * @desc: 触发单个事件
		 * @param {Object} ele 事件配置
		 * @param {Object} output 当前选中数据
		 */
		doActionItem(ele, output) {
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList.forEach((queryItem) => {
							let component = getComponentById(
								this.subComponentData,
								queryItem.componentId
							);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(
									this.groupComponents,
									queryItem.componentId
								);
							}
							this.$set(
								query,
								queryItem.key,
								component.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						return;
					} else if (ele.linkType === 'outPage') {
						window.open(ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate, output);
						window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					// 搜集参数
				} else {
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
				// 触发组件行为
				const { behaviors } = ele;
				behaviors.forEach(behavior => {
					this.$store.commit('triggerEvents', {
						config: {
							behavior,
							isBehavior: true
						},
						element: this.element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				});
			}
		}
	},
	beforeDestroy() {
		eventBus.$off('doComponentBehavior');
	},
};
</script>
<style lang="less" scoped>
:deep(.el-tabs) {
	.el-tabs__header.is-top {
		box-shadow: inset 0px 0px 5px 0px #0082d8;
		backdrop-filter: blur(30px);
		background-color: rgba(0, 23, 63, 0.6);
	}

	.el-tabs__item {
		color: #ffffff;
		font-size: 20px;
		font-weight: bold;
		// line-height: 28px;
		padding: 0px;
		margin: 0 10px;
		text-align: center;

		&.is-active {
			color: #3fb0ff;
			text-shadow: 0px 0px 10px #3fb0ff;
		}
	}

	.el-tabs__active-bar {
		background-color: #00dff6;
	}

	.el-tabs__nav-wrap::after {
		background-color: rgba(0, 130, 216, 1);
	}

	.el-tabs__content {
		box-shadow: inset 0px 0px 5px 0px #0082d8;
		backdrop-filter: blur(30px);
		background-color: rgba(0, 23, 63, 0.6);
		height: calc(100% - 55px);
	}
}

:deep(.el-switch__core) {
	// width: 31px;
	height: 18px;
	// border-radius: 7px;
	background: rgba(0, 149, 255, 0.01);
	box-shadow: inset 0px 0px 10px 0px #2876f3;

	&:after {
		width: 14px;
		height: 14px;
		background: rgba(255, 255, 255, 0.2);
		box-shadow: inset 0px 0px 5px 0px #ffffff;
	}
}
i.iconfont {
	color: #ffffff;
	font-size: 35px;
	padding: 8px;
	cursor: pointer;
}
.type-name {
	font-weight: normal;
	line-height: 28px;
	color: #ebebeb;
}
.flex {
	display: flex;
}
.fz12 {
	font-size: 12px;
}
.tab-item{
	text-align: center!important;
}
</style>