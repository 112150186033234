<!--
 * @Description: tabs
 * @Author: luocheng
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-07-21 11:18:16
-->
<template>
  <div class="tabs-menu-container">
    <div class="inner-wrap" :class="layout">
      <div class="pre-btn-wrap" :style="btnStyle">
        <div v-if="current !== 0" class="pre-btn" :style="btnStyle" @click="doPre" v-hoverEffect="leftBtnImgs">
        </div>
      </div>
      <div class="tabs-menu-wrap">
        <div 
          class="tabs-item"
          v-for="item in tabConfig"
          :style="item.style"
          :key="item.type"
          v-buttonHoverEffect="{ item, activeName }"
          @click="onTabClick(item)"
          @dblclick.stop="onTabdblClick(item)"
        >
          <img />
          <span v-if="useLabel">{{ item.label }}</span>
        </div>
      </div>
      <div class="next-btn-wrap" :style="btnStyle">
        <div v-if="current < tabConfig.length - size" class="next-btn" :style="btnStyle" @click="doNext" v-hoverEffect="rightBtnImgs">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  name: 'TabsMenu',
  props: {
    element: {
      type: Object,
      required: false,
      default: () => {}
    },
    componentList: {
      default: null
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      current: 0,
      activeName: ''
    };
  },
  directives: {
    // 前进后退 的 hover 指令
    hoverEffect: {
      bind (el, binding) {
        el.style.backgroundImage = `url(${binding.value.defaultIcon})`;
        el.onmouseenter = () => {
          el.style.backgroundImage = `url(${binding.value.activeIcon})`;
        }
        el.onmouseout = () => {
          el.style.backgroundImage = `url(${binding.value.defaultIcon})`;
        }
      }
    },
    // 菜单项的hover指令
    buttonHoverEffect: {
      bind (el, binding) {
        const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/43b05f646ac317b63c5f563fa9f0e317.png';
        const activeImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/3f316172b903a5584dcdb52451dee6fb.png';
        if (binding.value.item.type === binding.value.activeName) {
          if(el.tagName.match(/img/i)) {
            el.src = binding.value.item.activeIcon || activeImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.activeIcon || activeImg;
            }
          }
        } else {
          if(el.tagName.match(/img/i)) {
             el.src = binding.value.item.defaultIcon || defaultImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.defaultIcon || defaultImg;
            }
          }
        }
        el.addEventListener('mouseenter', () => {
          if(el.tagName.match(/img/i)) {
            el.src = binding.value.item.activeIcon || activeImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.activeIcon || activeImg;
            }
          }
        }, true)
        el.addEventListener('mouseout', () => {
          if (binding.value.item.type === binding.value.activeName) {
            if(el.tagName.match(/img/i)) {
              el.src = binding.value.item.activeIcon || activeImg;
            } else {
              const img = el.querySelector('img')
              if(img) {
                img.src = binding.value.item.activeIcon || activeImg;
              }
            }
          } else {
            if(el.tagName.match(/img/i)) {
              el.src = binding.value.item.defaultIcon || defaultImg;
            } else {
              const img = el.querySelector('img')
              if(img) {
                img.src = binding.value.item.defaultIcon || defaultImg;
              }
            }
          }
        }, true)
      },
      update (el, binding) {
        const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/43b05f646ac317b63c5f563fa9f0e317.png';
        const activeImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/3f316172b903a5584dcdb52451dee6fb.png';
        if (binding.value.item.type === binding.value.activeName) {
          if(el.tagName.match(/img/i)) {
            el.src = binding.value.item.activeIcon || activeImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.activeIcon || activeImg;
            }
          }
        } else {
          if(el.tagName.match(/img/i)) {
            el.src = binding.value.item.defaultIcon || defaultImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.defaultIcon || defaultImg;
            }
          }
        }
        el.addEventListener('mouseenter', () => {
          if(el.tagName.match(/img/i)) {
            el.src = binding.value.item.activeIcon || activeImg;
          } else {
            const img = el.querySelector('img')
            if(img) {
              img.src = binding.value.item.activeIcon || activeImg;
            }
          }
        }, true)
        el.addEventListener('mouseout', () => {
          if (binding.value.item.type === binding.value.activeName) {
            if(el.tagName.match(/img/i)) {
              el.src = binding.value.item.activeIcon || activeImg;
            } else {
              const img = el.querySelector('img')
              if(img) {
                img.src = binding.value.item.activeIcon || activeImg;
              }
            }
          } else {
            if(el.tagName.match(/img/i)) {
              el.src = binding.value.item.defaultIcon || defaultImg;
            } else {
              const img = el.querySelector('img')
              if(img) {
                img.src = binding.value.item.defaultIcon || defaultImg;
              }
            }
          }
        }, true)
      }
    }
  },
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    // 布局
    layout() {
      return this.statusConfig?.layout || 'horizontal';
    },
    // tabs 配置
    tabConfig() {
      const distance = 100 / this.size;
      const result = (this.element?.tabConfig || []).map((el, index) => {
        let obj = {};
        if (this.layout === 'horizontal') {
          obj = {
            style: {
              top: '50%',
              left: ((index - this.current) * distance + distance / 2) + '%',
              transform: 'translate(-50%, -50%)',
              transition: 'left .5s'
            },
            index: index
          }
        } else {
          obj = {
            style: {
              left: '50%',
              top: ((index - this.current) * distance + distance / 2) + '%',
              transform: 'translate(-50%, -50%)',
              transition: 'top .5s'
            },
            index: index
          }
        }
        return Object.assign(obj, el);
      });
      return result;
    },
    // 显示条目数
    size() {
      return this.statusConfig?.size || 4;
    },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    // 按钮大小
    btnStyle() {
      const size = (this.statusConfig?.iconSize ?? 32) + 'px';
      return {
        width: size,
        height: size,
        backgroundSize: '100% 100%'
      }
    },
    leftBtnImgs() {
      return {
        defaultIcon: this.statusConfig?.preIconDefault || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/97711f2424d66d63ae99ce8c170cf5c4.png',
        activeIcon: this.statusConfig?.preIconActive || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/e7c7b1980e7c5af5ca21798074bd1193.png'
      }
    },
    rightBtnImgs() {
      return {
        defaultIcon: this.statusConfig?.nextIconDefault || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c63f68281844f9acd8db5eda3a5f7a74.png',
        activeIcon: this.statusConfig?.nextIconActive || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/1ddd62233aed7eb199a1b40f01476c67.png'
      }
    },
    useLabel() {
      return !!this.statusConfig?.useLabel
    }
  },
  watch: {
    // 固定参数
    tabConfig: {
      handler() {
        if (!this.tabConfig?.length) {
          return;
        }
        for (let i = 0; i < this.tabConfig.length; i++) {
          if (this.tabConfig[i].isFirst && this.activeName === '') {
            if (!this.fixedInit) {
              this.fixedInit = true;
              this.onTabClick({ index: i, type: this.tabConfig[i].type});
            }
            return;
          }
        }
        // test 此处代码仍有待考证
        if (!this.activeName) {
          this.activeName = this.tabConfig[0].type;
        }
      },
      immediate: true
    },
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: data => {
        if (this.tabOrigin !== 'dynamic') return;
        let { paramsConfig } = this.element.database;
        let isTarget = false;
        for (let i = 0; i < paramsConfig.length; i++) {
          if (paramsConfig[i].componentId === data.componentId) {
            isTarget = true;
          }
        }
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this.getTabList(this.dynamicConfig);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
    /**
     * @description: 按钮后退
     * @return {*}
     */    
    doPre() {
      const size = Math.min(this.size, this.statusConfig.scrollSize);
      if(this.current > 0) {
        this.current = Math.max(0, this.current - size);
      }
    },
    /**
     * @description: 按钮前进
     * @return {*}
     */    
    doNext() {
      const size = Math.min(this.size, this.statusConfig.scrollSize);
      if(this.current < this.tabConfig.length - this.size) {
        this.current = Math.min(this.tabConfig.length - size, this.current + size);
      }
    },
    /**
     * @description: 被点击时触发回调
     * @param {Object} tab
     */
    onTabClick(tab) {
      this.activeName = tab.type;
      // 固定
      const index = +tab.index;
      const comEvents = this.tabConfig[index]?.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        if (pattern === undefined) {
          if (comEvents[i].key === 'click') {
            comEvents[i].effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...comEvents[i],
                  ...effect
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
          }
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach(ele => {
          if (ele.key === 'click') {
            ele.effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...ele,
                  ...effect
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
            const behaviors = ele?.behaviors || [];
            // 触发行为
            behaviors.forEach(behavior => {
              this.$store.commit('triggerEvents', {
                config: {
                  behavior,
                  isBehavior: true
                },
                tab
              });
            });
          }
        });
      }
      // 暴露出绑定的数据
      let resolveData = null;
      // 暴露数据
      resolveData = {
        label: this.tabConfig[index].label,
        value: this.tabConfig[index].type
      };
      this.element.resolveData = resolveData;

      this.$store.commit('updatePageCustomStatus', {
        origin: this.element.id,
        resolveData
      });
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
      // 触发事件
      // event bus  挂载卸载问题
      setTimeout(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          action: 'any',
          isSearch: true,
          output: resolveData
        });
      }, 0);
    },
    /**
     * @description: 处理双击
     * @return {*}
     */    
    onTabdblClick(tab) {
      this.activeName = tab.type;
      // 固定
      const index = +tab.index;
      const comEvents = this.tabConfig[index]?.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        if (pattern === undefined) {
          if (comEvents[i].key === 'dblclick') {
            comEvents[i].effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...comEvents[i],
                  ...effect
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
          }
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach(ele => {
          if (ele.key === 'dblclick') {
            ele.effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...ele,
                  ...effect
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
          }
        });
      }
      // 暴露出绑定的数据
      let resolveData = null;
      // 暴露数据
      resolveData = {
        label: this.tabConfig[index].label,
        value: this.tabConfig[index].type
      };
      this.element.resolveData = resolveData;

      this.$store.commit('updatePageCustomStatus', {
        origin: this.element.id,
        resolveData
      });
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
      // 触发事件
      // event bus  挂载卸载问题
      setTimeout(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          action: 'any',
          isSearch: true,
          output: resolveData
        });
      }, 0);
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
     removeEventBus('databaseTrigger', this.element.id);
    }
  }
};
</script>

<style lang="less" scoped>
  .tabs-menu-container{
    width: 100%;
    height: 100%;
    .inner-wrap{
      width: 100%;
      height: 100%;
      .pre-btn-wrap{
        flex-shrink: 0;
        .pre-btn{
          color: inherit;
          cursor: pointer;
        }
      }
      .next-btn-wrap{
        flex-shrink: 0;
        .next-btn{
          cursor: pointer;
        }
      }
      .tabs-menu-wrap{
        position: relative;
        overflow: hidden;
        .tabs-item{
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            transition: all .2s;
            cursor: pointer;
          }
          span{
            white-space: nowrap;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
          }
        }
      }
    }
    .horizontal{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabs-menu-wrap{
        flex-grow: 1;
        height: 100%;
        .tabs-item{
          height: 100%;
          img {
            height: 100%;
            width: auto;
            // object-fit: scale-down;
          }
        }
      }
    }
    .vertical{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .tabs-menu-wrap{
        flex-grow: 1;
        width: 100%;
        .tabs-item{
          width: 100%;
          img {
            height: auto;
            width: 100%;
            // object-fit: scale-down;
          }
        }
      }
    }
  }
</style>
