<!--
 * @Description: 日历
 * @Author: luocheng
 * @Date: 2022-01-26 15:18:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-09 14:24:13
-->
<template>
  <div class="common-date">
    <!-- eslint-disable -->
    <header class="header">
      <span @click="onQuickSwitch('prevYear')"><<</span>
      <span @click="onQuickSwitch('prevMonth')"><</span>
      <p class="title">{{ year }}年 {{ month }}月</p>
      <span @click="onQuickSwitch('nextMonth')">></span>
      <span @click="onQuickSwitch('nextYear')">>></span>
    </header>
    <article class="content">
      <ul class="sign-list">
        <li class="sign-item" v-for="item in signList" :key="item.value"
          :class="{
            'is-weekend': item.value === 0 || item.value === 6
          }"
        >{{ item.label }}</li>
      </ul>
      <ul class="date-list" v-if="showDateList.length">
        <li class="date-item" v-for="(item, index) in showDateList" :key="index"
          :class="{
            'is-weekend': item.weekDay === 0 || item.weekDay === 6,
            'disabled': item.type === 'prev' || item.type === 'next',
            'is-today': item.isToday,
            'is-active': currentDateObj && currentDateObj.year === item.year && +currentDateObj.month === +item.month && currentDateObj.date === item.date
          }"
          @click="onDate(item)"
        >
          <!-- 基础类型 -->
          <div class="base-item" v-if="calendarType === 'base' || item.type !== 'target'">
            {{ item.isToday ? '今' : item.date }}
          </div>
          <!-- 符合类型 -->
          <div class="with-data-item" v-else-if="calendarType === 'withDatabase' || calendarType === 'justTotal'">
            <p class="date-text">
              {{ item.isToday ? '今' : item.date }}
            </p>
            <p class="count-text">
              <span class="finish-count"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !(+item.finishCount)
                }"
              >{{ item.finishCount }}</span>
              <template v-if="calendarType === 'withDatabase'">
                <span class="separate" :class="{
                  'null-count': (!(+item.totalCount)) && (!(+item.finishCount))
                }">/</span>
                <span class="total-count" :class="{
                  'null-count': !(+item.totalCount)
                }">{{ item.totalCount }}</span>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </article>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'CommonCalendar',
  mixins: [mixin],
  data() {
    return {
      dateList: [], // 日期列表
      year: '', // 年
      month: '', // 月
      todayObj: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: new Date().getDate(),
        weekDay: new Date().getDay(),
        isToday: true,
        type: 'target',
        totalCount: 0,
        finishCount: 0,
        lessCount: 0
      }, // 当前日期
      currentDateObj: null
    }
  },
  computed: {
    // 头部标志位
    signList() {
      const baseSignList = [
        {
          label: '一',
          value: 1
        },
        {
          label: '二',
          value: 2
        },
        {
          label: '三',
          value: 3
        },
        {
          label: '四',
          value: 4
        },
        {
          label: '五',
          value: 5
        },
        {
          label: '六',
          value: 6
        }
      ];
      baseSignList[this.headerFormat === 'sundayEnd' ? 'push' : 'unshift']({
        label: '日',
        value: 0
      });
      return baseSignList;
    }
  },
  watch: {
    headerFormat: {
      handler() {
      this.initDate(true);
    },
    immediate: true
    }
  },
  created() {
    // this.initDate(true);
    this.currentDateObj = this.todayObj;
  },
  methods: {
    /**
     * @desc: 点击诶器
     * @param {Object} date
     */
    onDate(date) {
      if (date.type === 'target') {
        // 当前月
        this.currentDateObj = date;
        this.$emit('setDate', date.result);
      } else if (date.type === 'prev') {
        // 上月
        this.onQuickSwitch('prevMonth')
      } else if (date.type === 'next') {
        // 下月
        this.onQuickSwitch('nextMonth')
      }
    },
    /**
     * @desc: 快捷切换
     * @param {String} type 类型
     */
    onQuickSwitch(type) {
      switch(type) {
        case 'prevYear':
          this.year--;
          break;
        case 'prevMonth':
          if (+this.month === 1) {
            this.month = 12;
            this.year--;
          } else {
            this.month = this.addZero(this.month - 1);
          }
          break;
        case 'nextYear':
          this.year++;
          break;
        case 'nextMonth':
          if (+this.month === 12) {
            this.month = '01';
            this.year++;
          } else {
            this.month = this.addZero(++this.month);
          }
          break;
      }
      // 重新获取渲染
      this.initDate();
    },
    /**
     * @desc: 初始化日历列表(未完成)
     * @param {Boolean} isInit 是否为初始化
     */
    initDate(isInit = false) {
      this.dateList = [];
      const date = isInit ? new Date() : new Date(`${this.year}/${this.month}/1`);
      if (isInit) {
        this.year = date.getFullYear();
        this.month = this.addZero(date.getMonth() + 1);
      }
      // 当月第一天 周几
      const startDate = new Date(`${this.year}/${this.month}/1`).getDay();
      const prevMonthObj = this.getSiblingMonth('prev');
      const nextMontObj = this.getSiblingMonth('next');
      // 本月有多少天
      const monthDayCount = this.getMonthDayCount(this.year, +this.month);
      const totalDayCount = 42;
      // 生成
      // 上月数据
      const prevMonthCount = this.getMonthDayCount(prevMonthObj.year, +prevMonthObj.month);
      let prevNeedCount = 0;
      if (this.headerFormat === 'sundayEnd') {
        prevNeedCount = startDate === 0 ? 6 : startDate - 1;
      } else if (this.headerFormat === 'sundayStart') {
        prevNeedCount = startDate === 0 ? 0 : startDate;
      }
      for (let i = prevMonthCount - prevNeedCount + 1; i <= prevMonthCount; i++) {
        const targetDate = new Date(`${prevMonthObj.year}/${prevMonthObj.month}/${i} 00:00:00`);
        this.dateList.push({
          date: i,
          year: prevMonthObj.year,
          month: prevMonthObj.month,
          weekDay: targetDate.getDay(),
          type: 'prev',
          totalCount: 0,
          finishCount: 0,
          lessCount: 0
        });
      }
      // 本月
      for (let i = 1; i <= monthDayCount; i++) {
        const targetDate = new Date(`${this.year}/${this.month}/${i} 00:00:00`);
        this.dateList.push({
          date: i,
          year: this.year,
          month: this.month,
          weekDay: targetDate.getDay(),
          isToday: this.todayObj.year === this.year && this.todayObj.month === +this.month && this.todayObj.date === i,
          type: 'target',
          totalCount: 0,
          finishCount: 0,
          lessCount: 0,
          result: [
            `${this.year}-${this.addZero(this.month)}-${this.addZero(targetDate.getDate())} 00:00:00`,
            `${this.year}-${this.addZero(this.month)}-${this.addZero(targetDate.getDate())} 23:59:59`
          ]
        });
      }
      // 下月
      for (let i = 1, len = totalDayCount - monthDayCount - prevNeedCount; i <= len; i++) {
        const targetDate = new Date(`${nextMontObj.year}/${nextMontObj.month}/${i} 00:00:00`);
        this.dateList.push({
          date: i,
          year: nextMontObj.year,
          month: nextMontObj.month,
          type: 'next',
          weekDay: targetDate.getDay(),
          totalCount: 0,
          finishCount: 0,
          lessCount: 0
        });
      }
    },
    /**
     * @desc: 获取上下月
     * @param {String} type 类型 prev 上一月 next 下一月
     */
    getSiblingMonth(type) {
      if (type === 'prev') {
        // 上一个月
        if (+this.month === 1) {
          return {
            month: 12,
            year: this.year - 1
          }
        }
        return {
          month: +this.month - 1,
          year: this.year
        }
      }
      if (type === 'next') {
        // 下一个月
        if (+this.month === 12) {
           return {
            month: 1,
            year: this.year + 1
          }
        }
        return {
          month: +this.month + 1,
          year: this.year
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./common.less";
</style>