<!--
 * @Author: your name
 * @Date: 2021-12-02 15:31:01
 * @LastEditTime: 2023-06-16 14:14:11
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\modelList.vue
-->
<template>
  <div class="detail-title">
    <el-tree
      ref="treeRef"
			class="scroller"
      v-loading="loading"
      :data="treeData"
      node-key="id"
      :props="props"
      show-checkbox
      :default-expanded-keys="expandedKeys"
      :default-checked-keys="checkedKeys"
      style="padding: 0 5px;height:100%;overflow-y: auto;"
      @check="change"
    />
  </div>
</template>

<script>
import {dataInterface} from "@/apis/data/index";
import {Tree} from "element-ui";

export default {
  name: "Index",
  components:{
    'el-tree': Tree
  },
  props: {
    //回显数据
    modelData: {
      type: [Array,String],
      default: () => [],
    },
    search: {
        type: Array,
        default: () => [], 
    }
  },
  data() {
    return {
      treeData: [],
      loading: true,
      props: {
        label: "name",
        children: "models",
      },
      loadedData: [],
      expandedKeys: [], //回显展开的数据
      checkedKeys: [], //回显选中的数据
    };
  },
  methods: {
    change(data, checked) {
      const keys = this.$refs.treeRef.getCheckedNodes(true, false);
      if (keys.length != 0) {
        if (this.loadedData.length == 0) {
          //新增
          this.loadedData = keys;
          this.loopFn(keys, true);
        } else {
          let arr = this.diffent(this.loadedData, keys, "id");
          this.loadedData = keys;
          // checked.checkedKeys 选中的id
          if (
            arr.length != 0 &&
            checked &&
            checked.checkedKeys &&
            checked.checkedKeys.length != 0
          ) {
            if (
              checked.checkedKeys.filter((it) => it == arr[0].id)
                .length == 0
            ) {
              //减去
              this.loopFn(arr, false);
            } else {
              //新增
              this.loopFn(arr, true);
            }
          }
        }
      } else {
        let listChecked = JSON.parse(JSON.stringify(this.loadedData));
        this.loadedData = [];
        listChecked.forEach((item) => {
          let version = item.allversions.filter(
            (v) => v.id == item.used_version
          )[0];
          if (version && version.model_id != "") {
            this.$emit("show-model", version.model_id, false);
          }
        });
        this.$emit("clear-obk");
      }
    },
    loopFn(data, bol) {
      // bol 是否显示
      if (data && data.length != 0) {
        data.forEach((item) => {
          if (
            item.allversions != undefined &&
            item.allversions.length != 0
          ) {
            let version = item.allversions.filter(
              (v) => v.id == item.used_version
            )[0];
            if (version && version.model_id != "") {
              let modelId = version.model_id;
              if (modelId) {
                this.$emit("show-model", modelId, bol);
              }
            }
          }
        });
      }
    },
    diffent(fArr, cArr, field) {
      let diffRes = [];
      let fDatas = [];
      let cDatas = [];
      for (let i in fArr) {
        let flg = false;
        for (let j in cArr) {
          if (cArr[j][field] === fArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          fDatas.push(fArr[i]);
        }
      }
      for (let i in cArr) {
        let flg = false;
        for (let j in fArr) {
          if (fArr[j][field] === cArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          cDatas.push(cArr[i]);
        }
      }
      diffRes.push(...cDatas.concat(fDatas));
      return diffRes;
    },

    getData() {
      let searchParam = [{
          code: 'archi_type',
          ruleType: 'eq',
          value: JSON.parse(localStorage.getItem('targetArchi')).data_id
      }]
      let obj ={
        object_uuid: "object6176693334cff",
        view_uuid: "view617cf772586a0",
        __method_name__: "dataList",
        transcode: 0,
				search: this.search.length? JSON.parse(JSON.stringify(this.search)) : searchParam
      }
      dataInterface(obj).then((res) => {
        if (res.data.data?.length) {
          let arr = [];
          res.data.data.forEach((item) => {
            let it = item;
            if (it.models?.length) {
              //回显数据
              if (Array.isArray(this.modelData)  && this.modelData?.length) {
                this.checkFn(item, it.id);
              }
              arr.push(it);
            }
          });
          this.treeData = arr;
          this.loading = false;
        } else {
          this.treeData = [];
          this.loading = false;
        }
      });
    },
    checkFn(item, parentId) {
      let models = item.models;
      models.forEach((val) => {
				val.id = JSON.stringify(parentId) + JSON.stringify(val.id)
        let version = val.allversions.filter(
          (v) => v.id == val.used_version
        )[0];
        if (version && version.model_id != "") {
          if (
            this.modelData.filter(
              (it) => it.model_id == version.model_id
            ).length != 0
          ) {
						this.loadedData.push(version.model_id)
            this.expandedKeys.push(item.id);
            this.expandedKeys = Array.from(
              new Set(this.expandedKeys)
            );
            this.checkedKeys.push(val.id);
          }
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

