<!--
 * @Description: 3.0消息弹窗
 * @Author: luocheng
 * @Date: 2021-12-20 19:09:02
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-13 10:00:10
 * @FilePath: /dataview-viewer-test/src/components/global/Dailog.vue
-->
<template>
  <div class="global-dialog global-dialog-three" v-loading="loading">
    <el-dialog :title="title" custom-class="global-dialog-overscroll" :visible.sync="showDialog" :before-close="onFormSubmit" width="60%">
      <PcParser
        v-if="prepared"
        @submit="onFormSubmit"
        :form-data="formDesignData"
        ref="dialogForm"
        :displayData="displayData"
        :disabled="false"
        :editFields="editFields"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        :hideBtn="false"
				@on-close="onEndFlow"
        @on-cancel="close"
      >
      </PcParser>
      <div v-if="!prepared" v-loading="true"
        style="height:200px"
      ></div>
    </el-dialog>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser";
import { transFieldsUUid,  } from "@/apis/data/index";
// import eventBus from '@/plugins/eventBus';dataInterface
import {formRequest} from "@/apis/data/form";

export default {
  components: {
    PcParser,
  },
  props:{
    form_uuid:{
      type:String,
      default:''
    },
    object_uuid:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      archi_type:'',
      tag:'',
      title:'新增',
      showDialog: false,
      displayData: {},
      editFields: {},
      formDisabled: "dataInfo",
      formDesignData: {},
      // 流程
      needFlow: true,
      needParentFlow: false,
      formUUID: "",
      requestType: "detail",
      loading: true,
      prepared:false
    };
  },
  mounted() {
    this.formUUID = this.form_uuid;
    this.showDialog = true;
    this.loading = true;
    transFieldsUUid("", this.formUUID)
      .then((res) => {
        if (res.data.code == 200) {
          // this.needFlow = !!res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == "form") {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          this.formDesignData = res.data.data;
          console.log(this.formDesignData,'formDesignData')
          this.loading = false;
          this.prepared = true
        }
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  },
  methods: {
    /**
     * @desc: 表单提交
     */
    onFormSubmit(data) {
      // console.log(data,'formData')
      const url = '/api/mapi'
      let param = {
        ...data,
        object_uuid: this.object_uuid,//安全
        __method_name__: "createData"
      }
      formRequest('post',url,param).then(res=>{
        console.log(res)
        this.showDialog = false;
        this.loading = false;
        this.$emit('close')
      })
    },
    /**
     * @desc: 操作完毕关闭弹窗
     */
    onEndFlow() {
      this.showDialog = false;
      // 更新状态列表
      // eventBus.$emit('updateTodoList');
    },
    close(){
      this.$emit('close')
    }
  },
};
</script>

<style lang="less">
.global-dialog-overscroll{
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 6px!important;
  box-sizing: border-box;
  padding: 5px;
  .el-dialog__header{
    border-bottom: 1px solid #f2f3f5;
  }
  .el-dialog__body{
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}
</style>
