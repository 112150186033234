<!--
 * @Description: 图表盒子
 * @Author: luocheng
 * @Date: 2022-01-10 11:07:04
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-04 15:49:11
-->
<template>
  <div class="common-chart" :id="boxId" v-loading="loading" ref="commonChart"
		:element-loading-background="loadingBg"
	>
    <div
      class="eject-icon"
      @click.stop="dialogVisible = !dialogVisible"
      :style="transformStyle"
    >
      <i :style="iconStyle" class="iconfont icondaping-quanping" />
    </div>
    <component
      :is="chartData.component"
      v-if="initEnd"
      :chartData="chartData"
      :height="chartHeight"
      :chartUUID="chartUUID"
      :paramsConfig="paramsConfig"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :scaleWidth="scaleWidth"
    >
    </component>
    <el-empty description="配置错误" v-else></el-empty>
    <el-dialog
      :visible.sync="dialogVisible"
      title=""
      :modal="false"
      :append-to-body="true"
      custom-class="chart-container-dialog"
      width="60%"
    >
      <div style="">
        <component
          :indialog="true"
          :is="chartData.component"
          v-if="initEnd"
          :chartData="chartData"
          :height="600"
          :chartUUID="chartUUID"
          :paramsConfig="paramsConfig"
          :isGroup="isGroup"
          :groupComponents="groupComponents"
          :scaleWidth="scaleWidth"
          :scaleHeight="scaleHeight"
        >
        </component>
        <el-empty description="配置错误" v-else></el-empty>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isJSONStr } from "@/utils/tools";
import { Empty, Dialog } from "element-ui";
import { dataInterface } from "@/apis/data";
import { mapState } from "vuex";
// 注册组件
const componentsList = require.context(
  "@/chart-components/components",
  true,
  /\.+vue$/
);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
  let name = fileName.replace(/.vue/, "");
  name = name.replace(/\.\//, "");
  if (name.indexOf("/") < 0) {
    customComponents[name] = componentsList(fileName).default;
  }
});
import eventBus from "@/plugins/eventBus";
import databaseTriggerMixin from "@/custom-component/databaseTriggerMixin";

export default {
  name: "CommonChart",
  mixins: [databaseTriggerMixin],
  props: {
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => [],
    },
    scaleWidth: {
      type: Number,
      default: 1,
    },
    scaleHeight: {
      type: Number,
      default: 1,
    },
  },
  components: {
    ...customComponents,
    "el-empty": Empty,
    "el-dialog": Dialog,
  },
  data() {
    return {
      chartHeight: "",
      boxId: `component${this.element.id}`,
      domInit: false,
      // 图表数据
      chartData: null,
      loading: false,
      dialogVisible: false,
      editorType: "",
    };
  },
  computed: {
    ...mapState(["globalInterval"]),
    chartUUID() {
      return this.element?.chartConfig?.chartUUID || "";
    },
    initEnd() {
      return this.domInit && this.chartUUID && this.chartData;
    },
    height() {
      return this.element?.style?.height;
    },
    width() {
      return this.element?.style?.width;
    },
    paramsConfig() {
      return this.element?.paramsConfig;
    },
    transformStyle() {
      return {
        top: `${this.scaleHeightSize(5)}px`,
        right: `${this.scaleSize(5)}px`,
        width: `${this.scaleSize(16)}px`,
        height: `${this.scaleHeightSize(16)}px`,
        lineHeight: 1,
      };
    },
    iconStyle() {
      return {
        fontSize: `${Math.min(this.scaleSize(16), this.scaleHeightSize(16))}px`
      }
    },
    // 请求定时器，若存在全局则以全局为准，若无全局则取当前组件自定义
    interfaceInterval() {
      if (this.globalInterval && this.globalInterval.useInterval) {
        return this.globalInterval;
      }
      return this.element?.interfaceInterval;
    },
		loadingBg() {
			if (this.editorType === 'dataview') {
				// rgba(0, 0, 0, 0.8) @林冉  大屏遮罩透明
				return 'transparent';
			}
			return 'hsla(0,0%,100%,.9)';
		}
  },
  created() {
    this.editorType = sessionStorage.getItem("editorType");
    this.boxId = `component${this.$elementId}`;
    this.getChartData();
    // 定时器
    this.setInterval();
  },
  mounted() {
    this.resetBoxSize();
    window.addEventListener("resize", this.resetBoxSize);
    const databaseTrigger = {
      [this.$elementId]: (data) => {
        if (data.componentId === this.$elementId && data.isUpdate) {
          this.getChartData();
        }
      },
    };
    eventBus.$on("databaseTrigger", databaseTrigger[this.$elementId]);
  },
  watch: {
    chartUUID() {
      if (this.chartUUID) {
        this.getChartData();
      }
    },
    height: {
      handler() {
        this.$nextTick(() => {
          this.resetBoxSize();
        });
      },
      deep: true,
      immediate: true,
    },
    width() {
      this.$nextTick(() => {
        this.resetBoxSize();
      });
    },
  },
  methods: {
    /**
     * @desc: 获取图表配置数据
     */
    getChartData() {
      this.chartData = null;
      if (!this.chartUUID) {
        return;
      }
      // 获取
      this.loading = true;
      dataInterface({
        __method_name__: "dataList",
        page_uuid: this.chartUUID,
        object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
        view_uuid: "view61b951c6a8186",
        transcode: 0,
      })
        .then((res) => {
          this.loading = false;
          if (!res || res.status !== 200) {
            return;
          }
          const { page_data } = res.data.data?.[0] || {};
          if (!page_data) {
            return;
          }
          // attributes 属性
          const { chartData } = isJSONStr(page_data)
            ? JSON.parse(page_data)
            : page_data;
          this.chartData = chartData;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * @desc: 样式resize
     */
    resetBoxSize() {
      this.$nextTick(() => {
        const less = this.editorType === "dataview" ? 32 : 0;
        this.chartHeight =
          (this.$refs.commonChart?.offsetHeight || 200 * this.scaleHeight) -
          less * this.scaleHeight;
        this.domInit = true;
      });
    },
    /**
     * @desc: 缩放适配2/4K屏幕
     * @param {Number} num
     */
    scaleSize(num) {
      if (isNaN(+num)) {
        return num;
      }
      // const ratio = window.devicePixelRatio;
      const ratio = 1;
      return (+num * this.scaleWidth * ratio).toFixed(2);
    },
    scaleHeightSize(num) {
      if (isNaN(+num)) {
        return num;
      }
      // const ratio = window.devicePixelRatio;
      const ratio = 1;
      return (+num * this.scaleHeight * ratio).toFixed(2);
    },
    /**
     * @description: 设置定时请求
     */
    setInterval() {
      // 清除定时器
      if (this.intervalObj) {
        clearInterval(this.intervalObj);
      }
      if (!this.interfaceInterval) return false;
      const {
        useInterval = false,
        type = "timeout",
        timeout = 3000,
        times = -1,
        unit = "day",
        weekDay = "",
        month = "",
        day = "",
        hms = "06:00",
      } = this.interfaceInterval;
      if (!useInterval) return false;
      this.intervalObj = null;
      this.intervalTimes = 0;
      // 定时循环
      if (type === "timeout") {
        if (timeout <= 0) return;
        this.intervalObj = setInterval(() => {
          this.intervalTimes++;
          this.getChartData();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, timeout * 1000);
        return;
      }
      // 周期_时分秒
      if (["second", "minutes", "hour"].includes(unit)) {
        let count = 1;
        if (unit === "minutes") {
          count = 60;
        } else if (unit === "hour") {
          count = 60 * 60;
        }
        this.intervalObj = setInterval(() => {
          this.intervalTimes++;
          this.getChartData();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, count * 1000);
      } else {
        // 每天 每分钟循环一次
        this.intervalObj = setInterval(() => {
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentDay = date.getDate();
          const currentWeekDay = date.getDay();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          this.intervalTimes++;
          if (unit === "day" && hms === `${hours}:${minutes}`) {
            // 日
            this.getChartData();
          } else if (unit === "week") {
            // 周
            if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
              this.getChartData();
            }
          } else if (unit === "month") {
            // 月
            if (day === currentDay && hms === `${hours}:${minutes}`) {
              this.getChartData();
            }
          } else if (unit === "year") {
            // 年
            if (
              month === currentMonth &&
              day === currentDay &&
              hms === `${hours}:${minutes}`
            ) {
              this.getChartData();
            }
          }
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, 59 * 1000);
      }
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.intervalObj) {
      clearInterval(this.intervalObj);
    }
  },
};
</script>

<style lang="less" scoped>
.common-chart {
  height: 100%;
  width: 100%;
  position: relative;
  &:hover {
    .eject-icon {
      visibility: visible;
    }
  }
  .eject-icon {
    visibility: hidden;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    z-index: 1000;
    color: #eee;
    &:hover {
      color: #409eff;
    }
  }
  :deep(.el-empty) {
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    .el-empty__image {
      width: 35%;
      max-width: 120px;
      min-width: 50px;
    }
  }
}
</style>
