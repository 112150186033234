/*
 * @Description: 工具函数
 * @Author: luocheng
 * @Date: 2021-09-13 15:16:42
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-08-15 16:52:00
 * @LastEditTime: 2022-06-12 15:41:30
 */
import Vue from 'vue';
import Cookies from 'js-cookie';
import httpConfig from '@/apis/http/config';
import store from '@/store';
const vm = new Vue();
import eventBus from '@/plugins/eventBus';
import { onToggleArchi } from './systemActions';

/**
 * @desc: 深拷贝
 * @param {Object} target 需要被拷贝的对象
 * @return {Object} 输出对象
 */
export function deepCopy(target) {
	if (typeof target == 'object') {
		const result = Array.isArray(target) ? [] : {};
		for (const key in target) {
			if (typeof target[key] == 'object' && target[key]) {
				result[key] = deepCopy(target[key]);
			} else {
				result[key] = target[key];
			}
		}
		return result;
	}
	return target;
}

/**
 * @desc: 生成组件Id name + Timestamp
 * @param {String} name 组件代号(类型)
 */
export function getComponentId(name) {
	return `${name || 'component'}-${new Date().getTime()}`;
}

/**
 * @desc: 元素选择器
 * @param {String} 选择器字符串
 */
export function $(selector) {
	return document.querySelector(selector);
}

/**
 * @desc: 将数组中某个下标元素移动到指定下标
 * @param {Array} arr 数组
 * @param {Number} i 被移动
 * @param {Number} j 需要移动到的位置
 */
export function arrSwap(arr, i, j) {
	const temp = arr[i];
	arr[i] = arr[j];
	Vue.set(arr, i, arr[j]);
	Vue.set(arr, j, temp);
}

/**
 * @desc: 节流函数
 * @param {Function} func 需要执行的放
 * @param {number} wait 等待的时长(毫秒)
 */
export function throttle(fn, delay = 1000) {
	let prevTime = Date.now();
	return function () {
		let curTime = Date.now();
		if (curTime - prevTime > delay) {
			fn.apply(this, arguments);
			prevTime = curTime;
		}
	};
}

/**
 * @desc: 防抖
 * @param {Function} func zhixing fangfa
 * @param {Number} delay 延迟时长 (毫秒)
 */
export function debounce(func, delay) {
	let timeout;
	return function () {
		let context = this; // 指向全局
		let args = arguments;
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func.apply(context, args); // context.func(args)
		}, delay);
	};
}

/**
 * @desc: 扁平化树形数据
 * @param {Array} arr 需要扁平化的数据
 * @param {String} childKey 存放后代节点的key
 * @param {Object} config 输出结果的配置  label&value的对应key,为空时候默认返回全部
 * @param {Array} result 结果数组
 */
export function flatTree(treeData, childKey, config = null, excludes = [], result = []) {
	if (treeData && Array.isArray(treeData) && treeData.length) {
		treeData.forEach((ele) => {
			// 排除
			if (!excludes || !excludes.length || !excludes.includes(ele.id)) {
				if (config) {
					result.push({
						label: ele[config.label],
						value: ele[config.value]
					});
				} else {
					result.push(ele);
				}
			}
			if (ele[childKey] && Array.isArray(ele[childKey])) {
				result = result.concat(flatTree(ele[childKey], childKey, config, excludes, []));
			}
		});
	}
	return result;
}

/**
 * @desc: 根据ID获取组件对象
 * @param {Array} componentList 组件列表
 * @param {String} comId 组件ID
 * @return {Object} 组件对象/null
 */
export function getComponentById(componentList = [], comId, isFirst = true) {
	if(store.state.signComponentData?.length && isFirst){
		//先在标记内寻找数据
		const signComponent = getSignComponentById(store.state.signComponentData,comId)
		if(signComponent !== null){
			return {...signComponent}
		}
	}
	if(Array.isArray(componentList)){
		for (let i = 0, len = componentList.length; i < len; i++) {
			const item = componentList[i];
			if (item.id === comId) {
				return item;
			}
			if (item.children && Array.isArray(item.children) && item.children.length) {
				const result = getComponentById(item.children, comId, false) || null;
				if (result) {
					return result;
				}
			}
		}
	}
	return null;
}

export function getSignComponentById(componentList = [], comId) {
	if(Array.isArray(componentList)){
		for (let i = 0, len = componentList.length; i < len; i++) {
			const item = componentList[i];
			if (item.id === comId) {
				return item;
			}
			if (item.children && Array.isArray(item.children) && item.children.length) {
				const result = getSignComponentById(item.children, comId) || null;
				if (result) {
					return result;
				}
			}
		}
	}
	return null;
}

/**
 * @desc: 获取兄弟节点列表
 * @param {Array} arr 组件列表
 * @param {Object} target 组件对象
 * @return {Array} 兄弟节点列表
 */
export function getSiblings(arr, target) {
	if (arr.find((ele) => ele.id === target.id)) return arr;
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		if (item.children) {
			if (item.children.find((ele) => ele.id === target.id)) {
				return item.children;
			}
			const result = getSiblings(item.children, target);
			if (result) {
				return result;
			}
		}
	}
	return null;
}

export const TOKEN_KEY = 'bimcctoken';
export const METATOKEN = 'metadatatoken';
/**
 * @desc: 设置token
 */
export const setToken = (token) => {
	Cookies.set(TOKEN_KEY, token, { expires: httpConfig.cookieExpires || 1 });
	// TIPS iframe下通过param设置cookies中token可能失效 故在localStorage中再存储一次
	localStorage.setItem(TOKEN_KEY, token)
};

/**
 * @desc: 获取token
 */
export const getToken = () => {
	const token = Cookies.get(TOKEN_KEY);
	// console.log(token, 'getTokengetTokengetToken', TOKEN_KEY)
	if (token != undefined && token != '') {
		return token;
	}
	// TIPS iframe下通过param设置cookies中token可能失效 故在localStorage中兼容
	if (localStorage.getItem(TOKEN_KEY)) {
		return localStorage.getItem(TOKEN_KEY)
	}
	return '';
};

/**
 * @desc: 获取用户ID
 */
export const getUserIP = () => {
	let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
	let pc = new MyPeerConnection({
		iceServers: []
	});
	let noop = () => {
	};
	let localIPs = {};
	let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
	let iterateIP = (ip) => {
		if (!localIPs[ip]) {
			localStorage.setItem('innerIp', ip)
		}
		localIPs[ip] = true;
	};
	pc.createDataChannel('');
	pc.createOffer().then((sdp) => {
		sdp.sdp.split('\n').forEach(function (line) {
			if (line.indexOf('candidate') < 0) return;
			line.match(ipRegex).forEach(iterateIP);
		});
		pc.setLocalDescription(sdp, noop, noop);
	}).catch((reason) => {
		console.log(reason);
	});
	pc.onicecandidate = (ice) => {
		if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
		ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
	};
}


/**
 * @desc: 解析时间
 * @param {*}
 * @return {*}
 */
export const parseTime = (dateObj) => {
	return dateObj && dateObj instanceof Date ? `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}` : '';
}

/**
 * @desc: 从localStorage/sessionStorage获取存储的对象属性或对象属性的属性
 * @param {String} source  来源标识
 * @param {String} name  取值的存储对象名称
 * @param {String} type  取值类型
 * @param {String} key	需要取值的字段的key
 */
export const getAttrFromLocal = (source = '', name = '', type = 'object', key = '') => {
	if (!source && source !== 'localStorage' && source !== 'sessionStorage') {
		return '请指定数据来源';
	}
	if (!name || typeof name !== 'string') {
		return '请指定数据Key';
	}
	let result = window[source].getItem(name);
	if (!result) {
		return '';
	}
	if (type !== 'object') {
		return result;
	}
	result = JSON.parse(result);
	if (!key || typeof key !== 'string') {
		return '';
	}
	return result[key] || undefined;
}

/**
 * @desc: 获取当前登录的组织架构
 * @param {String} key 需要获取的子属性
 * @return {Object|String|Any}
 */
export const getTargetArchi = (key = '') => {
	let targetArchi = localStorage.getItem('targetArchi');
	if (!targetArchi) return '';
	targetArchi = JSON.parse(targetArchi);
	if (key && typeof key === 'string' && typeof targetArchi === 'object') {
		return targetArchi[key] || undefined;
	}
	return targetArchi;
}

/**
 * @desc: 获取用户信息
 * @param {String} key 需要获取的子属性
 * @return {Object|String|Any}
 */
export const getUserInfo = (key = '') => {
	let userInfo = localStorage.getItem('userInfo');
	if (!userInfo) return '';
	userInfo = JSON.parse(userInfo);
	if (key && typeof key === 'string' && typeof userInfo === 'object') {
		return userInfo[key] || undefined;
	}
	return userInfo;
}


/**
 * @desc: 验证菜单路由
 * @param {String}menuAuth 菜单权限
 */
export const checkMenuAuth = (menuAuth) => {
	if (!menuAuth) return true;
	if (
		getUserInfo('name') === 'admin' &&
		+getTargetArchi('id') === 1
	)
		return true;
	const userAuths =
		localStorage.getItem('userRoles') &&
		localStorage.getItem('userRoles').split();
	if (
		!userAuths ||
		!Array.isArray(userAuths) ||
		!userAuths.length
	)
		return false;
	if (typeof menuAuth === 'string') {
		return userAuths.includes(menuAuth);
	}
	// 数组的情况暂时选择包含其中一种即可
	for (let i = 0; i < menuAuth.length; i++) {
		if (userAuths.includes(menuAuth[i])) {
			return true;
		}
	}
	return false;
}


/**
 * @desc: 判断字符串是否为JSON字符串
 * @param {any} str
 * @return {Boolean}
 */
export const isJSONStr = str => {
	if (typeof str == 'string') {
		try {
			const obj = JSON.parse(str);
			if (typeof obj == 'object' && obj) {
				return true;
			} else {
				return false;
			}

		} catch (e) {
			// console.log('error：'+str+'!!!'+e);
			return false;
		}
	}
}

/**
 * 获取当前日期时间
 * @param type 1:yyyy-MM-dd 2:yyyy-MM-dd HH:ii:ss 3:HH:ii:ss
 * @return {string}
 */
export const getNowTime = (type = 1) => {
	let yy = new Date().getFullYear();
	let mm = new Date().getMonth() + 1;
	let dd = new Date().getDate();
	let hh = new Date().getHours();
	if (hh < 10) {
		hh = `0${hh}`;
	}
	let ii = new Date().getMinutes() < 10
		? '0' + new Date().getMinutes()
		: new Date().getMinutes();
	let ss = new Date().getSeconds() < 10
		? '0' + new Date().getSeconds()
		: new Date().getSeconds();

	if (type === 1) {
		return yy + '-' + mm + '-' + dd;
	} else if (type === 2) {
		return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
	} else {
		return hh + ':' + ii + ':' + ss;
	}
}


/**
 * @desc: 获取URL参数
 * @param {STring} queryName 获取url的参数名
 * @return {*}
 */
export const getQueryValue = (queryName = '') => {
	if (!queryName || typeof queryName !== 'string') return '';
	const reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
	const r = window.location.search.substr(1).match(reg);
	if (r) {
		return decodeURI(r[2]);
	}
	return '';
}


/**
 * @desc: 判断当前架构和权限配置是否允许操作
 * 当前架构在架构限制列表之一 且 当前用户具有权限限制列表其中之一，同时满足， 有限判断架构信息
 * @param {Array} archiLimit 架构限制列表
 * @param {Array} permissions 权限列表
 * @param {Boolean} adminNeed 是否需要校验 userId 为 1 的账号(用于admin)
 * @return {Boolean}
 */
export const judgingArchiAPermission = (archiLimit, permissions, adminNeed = false) => {
	// 用于兼容admin 账户，不需要鉴权业务内容权限，菜单依然需要鉴权
	if (!adminNeed) {
		const userId = getUserInfo('id');
		if (+userId === 1) return true;
	}
	if ((!archiLimit || !archiLimit.length) && (!permissions || !permissions.length)) {
		return true;
	}
	const targetArchi = getTargetArchi('archiType');
	if (!targetArchi || (archiLimit && archiLimit.length && !archiLimit.includes(targetArchi))) {
		return false;
	}
	if (!permissions || !permissions.length) {
		return true;
	}
	// 权限配置
	let userRoles = localStorage.getItem('userRoles');
	if (!userRoles) {
		return false;
	}
	// let userRoleObj = localStorage.getItem('userRoleObj');
	// if (!userRoleObj) {
	// 	return false;
	// }
	// userRoleObj = JSON.parse(userRoleObj);
	// if (!Object.keys(userRoleObj).length) {
	// 	return false;
	// }
	let userRoleList = userRoles.toString().split(',');
	if (!userRoleList || !userRoleList.length) {
		return false;
	}
	let permissionsList = permissions || [];
	if (!Array.isArray(permissions)) {
		permissionsList = permissions.split(',') || []
	}
	for (let i = 0, len = userRoleList.length; i < len; i++) {
		if (permissionsList.includes(userRoleList[i])) {
			return true;
		}
	}
	return false;
}


/**
 * @desc: 获取项目环境变量
 */
export const getProjectEnv = () => {
	const originHost = window.location.origin;
	let projectName = '';
	if (originHost === 'https://cggc.bimcc.com') {
		projectName = 'gzb';
	} else if (originHost === 'https://ltsd-bim.com' || originHost === 'https://bim.ltsd-bim.com') {
		projectName = 'ltsd';
	} else if (originHost === 'https://jgsc.bimcc.com') {
		projectName = 'cqjg';
	} else if (['https://bim.nanhuapm.com', 'https://bim.xwjgpt.com'].includes(originHost)) {
		projectName = 'nh';
	} else if (originHost === 'https://zssjzx.bimcc.net') {
		projectName = 'zssjzx';
	} else {
		projectName = 'dev';
	}
	// 测试登录页
	// projectName = 'nh';
	return projectName;
}
// 检查后端返回数据是否包含分页
export const checkPaginate = (data) => {
	if (Reflect.has(data, 'current_page')) {
		return true;
	}
	return false;
}

/**
 * @desc: 判断UI渲染逻辑
 * @param {null/ Object} UILogic 逻辑配置
 * @param {Array} componentData 组件数据
 * @param {Object} mapData 映射值
 */
export const judgingUILogic = (UILogic, componentData, mapData = null) => {
	if (!UILogic || !UILogic.enable) return true;
	const { logicConfigs } = UILogic;
	const logicList = [];
	for (let i = 0; i < logicConfigs.length; i++) {
		const item = logicConfigs[i];
		const { dataAttr, field, itemLogic, logicRule, type, value, valueType,
			componentId,
			dataOrigin,
			valueField,
			urlParamsKey,
			unitType,
			unitLogic,
			mapField
		} = item
		// 逻辑关系
		let itemBoolean = true;
		let logicValue = null;
		if (type === 'userInfo') {
			// 用户信息
			const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
			logicValue = userInfo && userInfo[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		} else if (type === 'framework') {
			// 架构信息
			const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
			logicValue = targetArchi && targetArchi[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		} else if (type === 'component') {
			// 组件取值
			let originComponent = getComponentById(componentData, componentId);
			if (dataOrigin === 'resolveData') {
				// 暴露值
				logicValue = originComponent?.resolveData?.[valueField];
			} else if (dataOrigin === 'rowData') {
				// 当前行数据（树形、表格等可迭代的数据特有）
				logicValue = mapData?.[valueField];
			} else {
				// componentData 绑定值
				logicValue = originComponent.propValue;
			}
		} else if (type === 'url') {
			// URL参数
			logicValue = getQueryValue(urlParamsKey);
		} else if (type === 'enterpriseType') {
			// 五方
			logicValue = unitType || [];
		} else if (type === 'mapValue') {
			// 映射值
			logicValue = mapData?.renderData?.[mapField];
		}
		// 逻辑关系
		if (type !== 'enterpriseType') {
			if (logicRule === '===') {
				if (valueType === 'string') {
					itemBoolean = `${logicValue}` === `${value}`;
				} else if (valueType === 'number') {
					itemBoolean = +logicValue === +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() === value;
				}
			} else if (logicRule === '!=') {
				if (valueType === 'string') {
					itemBoolean = `${logicValue}` !== `${value}`;
				} else if (valueType === 'number') {
					itemBoolean = logicValue !== +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() !== value;
				}
			} else if (logicRule === '>') {
				if (valueType === 'number') {
					itemBoolean = logicValue > +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() > value;
				}
			} else if (logicRule === '<') {
				if (valueType === 'number') {
					itemBoolean = logicValue < +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() < value;
				}
			} else if (logicRule === '<=') {
				if (valueType === 'number') {
					itemBoolean = logicValue <= +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() <= value;
				}
			} else if (logicRule === '>=') {
				if (valueType === 'number') {
					itemBoolean = logicValue >= +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() >= value;
				}
			} else if (logicRule === 'like') {
				if (valueType === 'string') {
					itemBoolean = logicValue.toString()?.includes(value.toString());
				}
			} else if (logicRule === 'whereIn') {
				// 包含
				try {
					let valArr = [];
					if (Array.isArray(logicValue)) {
						valArr = logicValue;
					} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
						valArr = JSON.parse(logicValue);
					}
					itemBoolean = valArr.includes(value);
				} catch (err) {
					console.log(err);
				}
			}
		} else {
			// 五方逻辑判断
			const targetUnitTypes = getTargetArchi('external_company_type') || [];
			const unitArr = targetUnitTypes.map(ele => +ele.code);
			// 包含一个  in
			// 完全相等  equal
			// 全不包含  none
			if (unitLogic === 'in') {
				itemBoolean = false;
				for (let j = 0; j < logicValue.length; j++) {
					if (unitArr.includes(+logicValue[j])) {
						itemBoolean = true;
						break;
					}
				}
			} else if (unitLogic === 'equal') {
				itemBoolean = true;
				for (let j = 0; j < logicValue.length; j++) {
					if (!unitArr.includes(+logicValue[j])) {
						itemBoolean = false;
						break;
					}
				}
			} else if (unitLogic === 'none') {
				itemBoolean = true;
				for (let j = 0; j < logicValue.length; j++) {
					if (unitArr.includes(+logicValue[j])) {
						itemBoolean = false;
						break;
					}
				}
			}
		}
		logicList.push({
			itemBoolean,
			itemLogic
		});
	}
	if (logicList.length === 1) {
		return logicList[0].itemBoolean;
	}
	// 组合逻辑判断
	let prevLogicBoolean = null;
	for (let j = 1; j < logicList.length; j++) {
		const prev = logicList[j - 1];
		const prevItemLogic = prev.itemLogic;
		if (prevItemLogic === 'and') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
			}
			if (!prevLogicBoolean) {
				return false;
			}
		} else if (prevItemLogic === 'or') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
			}
			if (!prevLogicBoolean) {
				return false;
			}
		}
	}
	return true;
};

/**
 * @desc: 通过配置的模板获取跳转链接
 * @param {String} temp
 * @param {Object} rowData 普通取值对象
 */
export const getLinkByTemplate = (temp, rowData = {}) => {
	return temp.replace(/{([\s\S]*?)}/g, (_, name) => {
		if (name === 'hosts') {
			return window.location.origin;
		}
		if (name === 'token') {
			return getToken();
		}
		// 普通参数
		return rowData[name];
	});
}

/**
 * @desc: 获取请求参数
 * @param {Array} paramsConfig 参数配置
 * @param {Boolean} isGroup 是否为分组
 * @param {Array} componentData 组件数据
 * @param {Array} groupComponents 分组数据
 * @return {Object}
 */
export const initParams = (paramsConfig = [], isGroup = false, componentData = [], groupComponents = []) => {
	let canPost = true;
	if (!paramsConfig || !paramsConfig.length) return { canPost };
	let search = [];
	let signComponentData = store.state.signComponentData
	const param = {};
	const { _PageCustomStatus = {}, _APPCustomStatus = {} } = store?.state || {};
	for (let i = 0; i < paramsConfig.length; i++) {
		const ele = paramsConfig[i];
		let originComponent = getComponentById(componentData, ele.componentId);
		if (!originComponent && isGroup && groupComponents.length) {
			originComponent = getComponentById(groupComponents, ele.componentId);
		}
		if(signComponentData?.length){
			if(originComponent){
				// 其他组件取值依赖标记的数据，需要通过resolveId反向查找标记组件
				if(originComponent.resolveId){
					for(let i=0; i<signComponentData.length;i++){
						if(signComponentData[i].id){
							const idArr = signComponentData[i].id.split('-')
							if(signComponentData[i].id.includes(ele.componentId) && originComponent.resolveId === idArr[3]){
								originComponent = signComponentData[i]
							}
						}
					}
				}
			}
		}
		if (ele.key !== 'search') {
			if (ele.sourceType === 'url') {
				// url参数
				const queryVal = getQueryValue(ele.urlParamsKey);
				vm.$set(param, ele.key, queryVal);
				if (ele.isRequired && !queryVal) {
					canPost = false;
					break;
				}
			} else if (!ele.sourceType) {
				// 来源于组件
				// 兼容三端组件！！！ 如果不存在三端组件那么将不验证其值是否存在(南华专用)
				// 存在暴露单条数据和数据列表的情况
				const { archiLimit = [], permission = [], UILogic = [] } = originComponent || {};
				if ((!originComponent
					|| !originComponent?.statusConfig?.isShow
					|| !judgingArchiAPermission(archiLimit, permission)
					|| !judgingUILogic(UILogic)
				) &&
					ele.componentId.indexOf('CommonTree-') === 0) {
					canPost = true;
					continue;
				}
				if (ele.comValueType === 'selectList') {
					// 当前选中的列表(特殊的如甘特图，列表之类)
					if (ele.isRequired && (!originComponent || !originComponent.resolveSelectData)) {
						canPost = false;
						break;
					}
					const { resolveSelectData } = originComponent;
					vm.$set(param, ele.key, resolveSelectData || []);
					if (ele.isRequired && (!resolveSelectData || !Array.isArray(resolveSelectData) || !resolveSelectData.length)) {
						canPost = false;
						break;
					}
				} else {
					// 常规单条数据
					// 验证必填
					if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
						canPost = false;
						break;
					} else if (originComponent && originComponent.resolveData) {
						let queryVal = null;
						if (Array.isArray(originComponent?.resolveData)) {
							queryVal = [];
							originComponent.resolveData.forEach(item => {
								if (Object.prototype.toString.call(ele) === '[object Object]' && item?.[ele?.paramKey] !== undefined) {
									queryVal.push(item?.[ele?.paramKey]);
								} else {
									queryVal.push(item);
								}
							})
						} else {
							queryVal = originComponent && originComponent.resolveData[ele.paramKey];
						}
						vm.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					}
				}
			} else if (ele.sourceType === 'system') {
				// 系统及架构参数 用户信息，架构信息
				let systemObject = null;
				if (ele.systemKey) {
					if (ele.systemKey === 'userInfo') {
						systemObject = getUserInfo()
					} else if (ele.systemKey === 'targetArchi') {
						systemObject = getTargetArchi()
					}
				}
				let systemValue = '';
				if (systemObject && ele.systemCode) {
					systemValue = systemObject[ele.systemCode];
				}
				vm.$set(param, ele.key, systemValue);
				if (ele.isRequired && (!systemObject || !systemValue)) {
					canPost = false;
					break;
				}
			} else if (ele.sourceType === 'fixed') {
				// 固定参数
				vm.$set(param, ele.key, ele.fixedValue || '');
			} else if (ele.sourceType === 'pageStatus') {
				// 页面状态
				const statusCodeValue = _PageCustomStatus[ele.statusCode] || _APPCustomStatus[ele.statusCode];
				vm.$set(param, ele.key, statusCodeValue || '');
			}
		} else {
			if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
				search = search.concat(originComponent.resolveData);
			}
		}
	}
	return {
		search,
		param,
		canPost
	};
}

/**
 * @desc: 验证配置的事件逻辑
 * @param {Array} logics
 * @param {Array} componentData
 * @param {Object} rowData 行数据
 */
export const judgingEventLogic = (logics, componentData, rowData) => {
	if (!logics || !Array.isArray(logics) || !logics.length) return true;
	let logicBoolean = true;
	for (let i = 0; i < logics.length; i++) {
		const item = logics[i];
		// type: 'component/ system
		// dataAttr: "value"
		// dataField: "field61a885dcdbd42"
		// dataKey: "resolveData"
		// itemLogic: "and"
		// logicRule: "!="
		// target: "CommonTree-1642581368412"
		// type: "component" 暂未启用
		// value: "version"
		// valueType: "string"
		const { type, dataAttr, dataField, dataKey, logicRule, value, valueType, valueObj, valueKey } = item;
		let resolveData = null;
		// console.log(type, '-----type 取值类型')
		if (type === 'component') {
			// 对象参数
			const resolveTargetKey = item.target;
			if (!dataKey || !resolveTargetKey || !valueType || !logicRule || !dataAttr) {
				break;
			}
			const resolveTarget = getComponentById(componentData, resolveTargetKey);
			if (!resolveTarget) {
				break;
			}
			if (!resolveTarget.statusConfig.isShow) {
				logicBoolean = false;
				break;
			}
			if (dataKey === 'propValue') {
				resolveData = resolveTarget.propValue;
			} else if (dataAttr === 'value') {
				resolveData = resolveTarget.resolveData?.[dataField];
			} else if (dataAttr === 'length') {
				resolveData = resolveTarget.resolveData.length;
			}
		} else if (type === 'system') {
			// 前端取值对象
			let result = sessionStorage.getItem(valueObj);
			if (!result) {
				result = localStorage.getItem(valueObj);
			}
			try {
				const value = JSON.parse(result)?.[valueKey];
				if (dataAttr === 'value') {
					resolveData = value;
				} else if (dataAttr === 'length') {
					resolveData = value.length;
				}
			} catch (err) {
				console.log(err);
			}
		} else if (type === 'inner') {
			// 组件内部取值
			resolveData = rowData?.[dataField]
		}
		// 判断关系
		let booleanItem = false;
		if (logicRule === '===') {
			if (valueType === 'string') {
				booleanItem = `${resolveData}` === `${value}`;
			} else if (valueType === 'number') {
				booleanItem = +resolveData === +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() === +value;
			}
		} else if (logicRule === '!=') {
			if (valueType === 'string') {
				booleanItem = `${resolveData}` !== `${value}`;
			} else if (valueType === 'number') {
				booleanItem = +resolveData !== +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() !== +value;
			}
		} else if (logicRule === '>') {
			if (valueType === 'number') {
				booleanItem = +resolveData > +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() > +value;
			}
		} else if (logicRule === '<') {
			if (valueType === 'number') {
				booleanItem = +resolveData < +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() < +value;
			}
		} else if (logicRule === '<=') {
			if (valueType === 'number') {
				booleanItem = +resolveData <= +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() <= +value;
			}
		} else if (logicRule === '>=') {
			if (valueType === 'number') {
				booleanItem = resolveData >= +value;
			} else if (valueType === 'date') {
				booleanItem = new Date(resolveData).getTime() >= value;
			}
		} else if (logicRule === 'like') {
			if (valueType === 'string') {
				booleanItem = resolveData.toString()?.includes(value.toString());
			}
		} else if (logicRule === 'whereIn') {
			// 包含
			try {
				let valArr = [];
				if (Array.isArray(resolveData)) {
					valArr = resolveData;
				} else if (resolveData.toString()?.startsWith('[') && resolveData.toString()?.endsWith(']')) {
					valArr = JSON.parse(resolveData);
				}
				booleanItem = valArr.includes(value);
			} catch (err) {
				console.log(err);
			}
		}
		if (i === 0) {
			logicBoolean = booleanItem;
		} else {
			// 要使用前一个的逻辑进行判断
			let itemLogicPrev = logics[i - 1].itemLogic;
			if (itemLogicPrev === 'and') {
				logicBoolean = logicBoolean && booleanItem;
			} else if (itemLogicPrev === 'or') {
				logicBoolean = logicBoolean || booleanItem;
			}
		}
	}
	return logicBoolean;
}

/**
* @desc: 获取状态值
* @param {Object} config 状态配置
* @param {Array} componentData 组件数据
*/
export const getStatusVal = (config, componentData = []) => {
	if (!config) return {};
	const {
		type = '',
		fixedValue = '',
		componentId = '',
		componentValueOrigin,
		resolveKey = ''
	} = config;
	if (type === 'fixed') {
		return {
			type,
			value: fixedValue
		}
	}
	// 组件来源
	const component = getComponentById(componentData, componentId);
	if (!component) return {};
	if (componentValueOrigin === 'propValue') {
		return {
			type,
			value: component?.propValue
		}
	} else if (componentValueOrigin === 'resolveData') {
		const resolveData = component?.resolveData;
		return {
			type,
			value: resolveData?.[resolveKey]
		}
	}
	return {};
}

/**
 * @desc: 后端事件附加操作
 * @param {*} config
 * @return {*}
 */
export const doEEActionHandle = (configList) => {
	if (!configList || !Array.isArray(configList) || !configList.length) return;
	configList.forEach(ele => {
		const { action = '', payload = {} } = ele;
		if (action === 'downloadFile') {
			// 下载文件
			downloadFile(payload);
		}
	})
}


/**
 * @desc: 根据
 * @param {Object} config 下载配置
 */
export const downloadFile = (config) => {
	const { url } = config;
	if (!url) return '';
	const aDom = document.createElement('a');
	aDom.href = url;
	const arr = url.toString().split('/') || [];
	aDom.download = config.name || arr[arr.length ? arr.length - 1 : 0];
	aDom.style.display = 'none';
	console.log(aDom, '----aDom------')
	aDom.click();
}

/**
 * @desc: 前端下载二进制流文件
 */
// export const downloadFileBlob = (data, filename) => {
// 	if (window.navigator.msSaveBlob) {
//     let blobObject = new Blob([data]);
//     window.navigator.msSaveBlob(blobObject, filename);
//   } else {
//     let url = window.URL.createObjectURL(new Blob([data]));
//     let link = document.createElement("a");
//     link.style.display = "none";
//     link.href = url;
//     link.setAttribute("download", filename);
//     document.body.appendChild(link);
//     link.click();
//   }
// }

//传入url路径以及文件名即可  
export const downloadFileBlob = async (url, fileName) => {
	const res = await getBlob(url)
	saveAs(res, fileName)
}

function getBlob(url) {
	return new Promise(resolve => {
		const xhr = new XMLHttpRequest()
		xhr.open('GET', url, true)
		xhr.responseType = 'blob'
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response)
			}
		}
		xhr.send()
	})
}

function saveAs(blob, filename) {
	if (window.navigator.msSaveOrOpenBlob) {
		navigator.msSaveBlob(blob, filename)
	} else {
		const link = document.createElement('a')
		const body = document.querySelector('body')

		link.href = window.URL.createObjectURL(blob)
		link.download = filename

		// fix Firefox
		link.style.display = 'none'
		body.appendChild(link)

		link.click()
		body.removeChild(link)

		window.URL.revokeObjectURL(link.href)
	}
}

/**
 * @desc: 得到一个唯一的id 用于重绘组件
 */
export const uniqid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}

/**
 * @desc: 验证自定义逻辑
 * @param {Array} logicConfigs
 */
export const judgingCustomLogic = (logicConfigs = []) => {
	if (!logicConfigs || !Array.isArray(logicConfigs) || !logicConfigs.length) return true;
	const logicList = [];
	for (let i = 0; i < logicConfigs.length; i++) {
		const item = logicConfigs[i];
		const { dataAttr, field, itemLogic, logicRule, type, value, valueType } = item
		// 逻辑关系
		let itemBoolean = true;
		let logicValue = null;
		if (type === 'userInfo') {
			// 用户信息
			const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
			logicValue = userInfo && userInfo[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		} else if (type === 'framework') {
			// 架构信息
			const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
			logicValue = targetArchi && targetArchi[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		}
		// 逻辑关系
		if (logicRule === '===') {
			if (valueType === 'string') {
				itemBoolean = logicValue === value;
			} else if (valueType === 'number') {
				itemBoolean = +logicValue === +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() === value;
			}
		} else if (logicRule === '!=') {
			if (valueType === 'string') {
				itemBoolean = logicValue !== value;
			} else if (valueType === 'number') {
				itemBoolean = logicValue !== +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() !== value;
			}
		} else if (logicRule === '>') {
			if (valueType === 'number') {
				itemBoolean = logicValue > +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() > value;
			}
		} else if (logicRule === '<') {
			if (valueType === 'number') {
				itemBoolean = logicValue < +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() < value;
			}
		} else if (logicRule === '<=') {
			if (valueType === 'number') {
				itemBoolean = logicValue <= +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() <= value;
			}
		} else if (logicRule === '>=') {
			if (valueType === 'number') {
				itemBoolean = logicValue >= +value;
			} else if (valueType === 'date') {
				itemBoolean = new Date(logicValue).getTime() >= value;
			}
		} else if (logicRule === 'like') {
			if (valueType === 'string') {
				itemBoolean = logicValue.toString()?.includes(value.toString());
			}
		} else if (logicRule === 'whereIn') {
			// 包含
			try {
				let valArr = [];
				if (Array.isArray(logicValue)) {
					valArr = logicValue;
				} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
					valArr = JSON.parse(logicValue);
				}
				itemBoolean = valArr.includes(value);
			} catch (err) {
				console.log(err);
			}
		}
		logicList.push({
			itemBoolean,
			itemLogic
		});
	}
	if (logicList.length === 1) {
		return logicList[0].itemBoolean;
	}
	// 组合逻辑判断
	let prevLogicBoolean = null;
	for (let j = 1; j < logicList.length; j++) {
		const prev = logicList[j - 1];
		const prevItemLogic = prev.itemLogic;
		if (prevItemLogic === 'and') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
			}
			if (!prevLogicBoolean) {
				return false;
			}
		} else if (prevItemLogic === 'or') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
			}
			if (!prevLogicBoolean) {
				return false;
			}
		}
	}
	return true;
}

/**
 * @desc: 数组去重
 * @param {Array} arr 待去重数组
 * @return {Array}
 */
export const unique = (arr) => {
	if (!arr || !Array.isArray(arr)) return [];
	let res = new Set();
	return arr.filter((item) => {
		let id = JSON.stringify(item);
		if (res.has(id)) {
			return false;
		} else {
			res.add(id);
			return true;
		}
	})
}

/**
 * @desc: 补零
 * @param {Number/Number} val 时间补零
 * @return {String/Number}
 */
export const addZero = (val) => {
	if (isNaN(+val)) return val;
	if (val >= 0 && val < 10) {
		return `0${+val}`;
	}
	return val;
}

/**
 * @desc: 格式化时间
 * @param {Number} time 时间戳
 * @param {String} type 类型
 */
export const formatTime = (time, type = 'YMD') => {
	let result = '';
	try {
		const obj = new Date(time);
		const year = obj.getFullYear();
		const month = addZero(obj.getMonth() + 1);
		const date = addZero(obj.getDate());
		switch (type) {
			case 'YMD':
				result = `${year}-${month}-${date}`;
				break;
			default:
				break;
		}
	} catch (err) {
		console.log(err, '--formatTime--err')
	}
	return result;
}

/**
 * @desc: 根据id查找找id全称
 */
export function findModelComponentId(componentList = [], field) {
	for (let i = 0, len = componentList.length; i < len; i++) {
		const item = componentList[i];
		if (item.id.includes(field)) {
			return item.id;
		}
		if (item.children && Array.isArray(item.children) && item.children.length) {
			const result = findModelComponentId(item.children, field) || null;
			if (result) {
				return result;
			}
		}
	}
	return null;
}

/**
* @description 转换数据大小
* @retrun 数据大小
*/
export function bytesToSize(limit) {
	var size = ''
	if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
		size = limit.toFixed(2) + 'B'
	} else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
		size = (limit / 1024).toFixed(2) + 'KB'
	} else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
		size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
	} else { // 其他转化成GB
		size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
	}
	var sizeStr = size + ''
	// console.log(sizeStr, 'sizeStr')
	var len = sizeStr.indexOf('/.')
	var dec = sizeStr.substr(len + 1, 2)
	if (dec === '00') { // 当小数点后为00时 去掉小数部分
		return sizeStr.substring(0, len) + sizeStr.substr(len + 3, 2)
	}
	return sizeStr
}

/**
 * @description: 设置权限对象
 * @param {String} permissions 权限字段
 */
export const setPermissionObj = permissions => {
	if (typeof permissions !== 'string') {
		return false;
	}
	const list = permissions.split(',');
	let result = Object.create(null);
	list.forEach(ele => {
		result[ele] = ele;
	});
	window.localStorage.setItem('userRoleObj', JSON.stringify(result));
}
/**
 * @description: 移除事件总线（排除重复移除）
 * @param {String/Array} eventKey 事件总线名称
 * @param {String} funcName 当前组件方法名
 */
export function removeEventBus(eventKeys, funcName) {
	if (Array.isArray(eventKeys)) {
		eventKeys.forEach(ele => {
			const eventList = eventBus?._events?.[ele] || [];
			for (let i = 0; i < eventList.length; i++) {
				if (eventList[i].name === funcName) {
					eventBus._events[ele].splice(i, 1);
					break;
				}
			}
		})
	} else {
		const eventList = eventBus?._events?.[eventKeys] || [];
		for (let i = 0; i < eventList.length; i++) {
			if (eventList[i].name === funcName) {
				eventBus._events[eventKeys].splice(i, 1);
				break;
			}
		}
	}
}

/**
 * @description: 执行事件前置操作
 * @param {Array} list
 */
export const doFrontOperation = async (list, paramOrigin) => {
	let promiseList = [];
	for (let i = 0; i < list.length; i++) {
		const {
			type,
			innerName,
			paramsConfig
		} = list[i];
		if (!type) {
			// 系统内置操作
			if (innerName === 'toggleArchi') {
				// 切换架构
				const { param = {} } = initParams(
					paramsConfig,
					paramOrigin.isGroup,
					paramOrigin.componentList || paramOrigin.componentData,
					paramOrigin.groupComponents
				);
				// console.log(param, '----param----');
				let itemResult = false;
				itemResult = onToggleArchi(param?.id)
				promiseList.push(itemResult)
			}
		}
	}
	const result = await Promise.all(promiseList);
	// console.log(result, '-------')
	return !result.includes(false);
}