<!--
 * @Author: your name
 * @Date: 2021-12-02 15:31:01
 * @LastEditTime: 2023-02-09 18:15:54
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\modelList.vue
-->
<template>
	<!-- eslint-disable -->
	<div class="drawing-list">
		<el-tree
			ref="treeRef"
			v-loading="loading"
			:data="treeData"
			:default-checked-keys="checkedKeys"
			default-expand-all
			node-key="id"
			:props="props"
			show-checkbox
			style="padding: 0 5px"
			@check="change"
		>
			<template #default="{ node, data }">
				<span class="customize-tree-p"> {{ data.name }}</span>
			</template>
		</el-tree>
	</div>
</template>
<script>
import { dataInterface } from '@/apis/data/index';
import { Tree } from 'element-ui';

export default {
	name: 'Index',
	components: {
		'el-tree': Tree
	},
	props: {
		// 模型数据
		drawingData: {
			type: Array,
			default: () => []
		},
		search: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			treeRef: null,
			treeData: [],
			loading: true,
			props: {
				label: 'name',
				children: 'children'
			},
			loadedData: [],
			expandedKeys: [], // 回显展开的数据
			checkedKeys: [] // 回显选中的数据
		};
	},
	methods: {
		change(data, checked) {
			let keys = this.$refs.treeRef.getCheckedNodes(true, false);
			if (keys.length !== 0) {
				if (this.loadedData.length === 0) {
					// 新增
					this.loadedData = keys;
					console.log(keys, '新增');
					this.$emit('show-model', keys, true);
				} else {
					let arr = this.diffent(this.loadedData, keys, 'id');
					this.loadedData = keys;
					// checked.checkedKeys 选中的id
					if (arr.length !== 0 && checked && checked.checkedKeys && checked.checkedKeys.length !== 0) {
						if (checked.checkedKeys.filter(it => it === arr[0].id).length === 0) {
							// 减去
							console.log('-', arr);
							this.$emit('show-model', arr, false);
						} else {
							// 新增
							console.log('+', arr);
							this.$emit('show-model', arr, true);
						}
					}
				}
			} else {
				this.loadedData = [];
				this.$emit('clear-obk');
			}
		},
		diffent(fArr, cArr, field) {
			let diffRes = [];
			let fDatas = [];
			let cDatas = [];
			for (let i in fArr) {
				let flg = false;
				for (let j in cArr) {
					if (cArr[j][field] === fArr[i][field]) {
						flg = true;
						break;
					}
				}
				if (!flg) {
					fDatas.push(fArr[i]);
				}
			}
			for (let i in cArr) {
				let flg = false;
				for (let j in fArr) {
					if (fArr[j][field] === cArr[i][field]) {
						flg = true;
						break;
					}
				}
				if (!flg) {
					cDatas.push(cArr[i]);
				}
			}
			diffRes.push(...cDatas.concat(fDatas));
			return diffRes;
		},

		getData() {
			dataInterface({
				object_uuid: 'object6245586f3eee5',
				view_uuid: 'view63e4b686af651',
				__method_name__: 'dataList',
				transcode: 0,
				search: this.search
			}).then(res => {
				if (res.data.data?.length) {
					if (this.drawingData?.length) {
						this.drawingData.forEach(val => {
							this.checkedKeys.push(val.id);
						});
					}
					const tree = this.formatData(res.data.data,'paper_manage');
					this.treeData = [tree]
					console.log(this.treeData,'treeData')
					this.loading = false;
				} else {
					this.treeData = [];
					this.loading = false;
				}
			});
		},

		formatData(data,field){
			for(var i = 0; i < data.length; i++){
				data[i].name = data[i].type_name || data[i].paper_name
				data[i].children = data[i][field] || []
				if(data[i][field]?.length){
					this.formatData(data[i][field],field)
				}
				return data[i];
			}
		}
	},
	mounted() {
		this.getData();
	}
};
</script>

<style lang="less">
.drawing-list {
	.el-tree-node__content {
		white-space: normal;
		height: auto;
	}
}
</style>
