<!--
    @name: widget-select
    @description：widget-select
    @author: ZengWei
    @date: 2022-03-25 09:30
-->
<template>
  <div style="position: relative">
    <div :class="{'outer-viewer':element.config.__config__.outerViewer}">
      <el-select
        v-model="element.value"
        :disabled="disabled || element.config.disabled"
        :placeholder="element.config.placeholder"
        :clearable="element.config.clearable"
        :multiple="element.config.multiple"
        :filterable="element.config.filterable"
        @change="triggerInteractive"
        @focus="dynamicFilter"
        :loading="loading"
      >
        <template v-for="(val, key) in element.config.__slot__.options">
          <template v-if="val.label.length>20">
            <el-tooltip :key="key" effect="dark" :content="val.label" placement="top">
              <el-option
                :value="'' + val.value"
                :label="val.label.toString()"
                :key="key"
              >
                {{ val.label.toString().substr(0,20) + '...' }}
              </el-option>
            </el-tooltip>
          </template>
          <template v-else>
            <el-option
              :value="'' + val.value"
              :label="val.label.toString()"
              :key="key"
            >
              {{ val.label.toString() }}
            </el-option>
          </template>
        </template>
      </el-select>
    </div>
    <el-button
      type="primary"
      v-if="element.config.__config__.outerViewer"
      style="position: absolute;top:0;right: 0;"
      @click="openOuterViewer"
    >查看
    </el-button>


    <el-dialog
      top="5vh"
      :custom-class="'common-dialog'"
      title="外部内容查看"
      :visible.sync="outerViewerVisible"
      :width="outerViewerWidth"
      append-to-body
    >
      <div v-loading="outerViewerLoading" style="height: calc(100vh - 200px)">
        <iframe
          ref="iframe" :src="outerRouter"
          style="width: 100%; height: 100%;border: none"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Select,Dialog,Tooltip} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {getSearchData, optionData} from "@/custom-component/form/newParser/scripts/tools";
import {formRequest} from "@/apis/data/form";

export default {
  name: "WidgetSelect",
  components: {
    'el-select': Select,
    'el-dialog': Dialog,
    'el-tooltip': Tooltip
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {
      outerViewerVisible: false,
      outerViewerLoading: false,
      outerViewerWidth: '100%',
      outerViewerParam: {},
      outerRouter: '',
    }
  },
  created() {
    const dynamic = this.element.config.__config__.dataType
    if(dynamic === 'static'){
      this.triggerInteractive(this.element.value,'auto')
    } else {
      this.initDynamicData();
    }
  },
  methods: {
    initDynamicData() {
      const filterRule = this.element.config.__config__.filterRule ?? []
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid
      const dynamic = this.element.config.__config__.dataType
      if(this.element.type === 'select' && dynamic === 'static'){
        return false; //select 静态数据
      }
      let url= '/api/mapi', params;
      let searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, search: searchData, size: 1000,
        __now_archi_type: this.parser.nowArchiType,
      }
      if(searchData.length) params.search = searchData
      if(objectUuid && viewUuid){
        formRequest('post', url, params).then(res => {
          let respData;
          respData = res.data.data;
          if (res.data.data.data) {
            respData = res.data.data.data;
          }
          const tag = this.element.config.__config__.tagIcon;
          const propLabel = this.element.config.props.props.label;
          const propValue = this.element.config.props.props.value;
          const options = optionData(respData,tag,propLabel,propValue)
          if(options){
            this.element.config.__slot__.options = options
          }
          this.triggerInteractive(this.element.value,'auto')
          this.loading = false
        });
      }
    },
    dynamicFilter(){
      const childFilter = this.element.config.__config__?.childFilter || false
      const filterRule = this.element.config.__config__?.filterRule || []
      if(filterRule.length && !childFilter){
        this.loading = true
        this.initDynamicData();
      }
    },
    openOuterViewer() {
      this.outerViewerVisible = true
      let route = this.element.config.__config__.outerRouter
      const width = this.element.config.__config__.windowWidth
      if (width) this.outerViewerWidth = width
      const obj = this.outerViewerParam
      const keys = Object.keys(obj)
      const regex = /\{(.+?)\}/g
      const arr = route.match(regex)
      if (arr !== null) {
        for (let item of arr) {
          let param = item.replace('{', '').replace('}', '')
          if (keys.includes(param)) {
            route = route.replace(item, obj[param])
          }
        }
      }
      this.outerRouter = route
      this.outerViewerLoading = true
      this.$nextTick(() => {
        this.$refs.iframe.onload = () => {
          this.outerViewerLoading = false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
