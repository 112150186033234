<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-11-11 11:31:07
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-07-17 16:46:59
 * @FilePath: \dataview-next\src\custom-component\weather\WeatherWeek.vue
 * @Description: 天气情况组件， 周视图
-->
<template>
  <div class="weather-week-container">
    <header class="waether-week-header">
      天气情况
    </header>
    <section class="weather-week-main" >
      <div v-for="(item, index) in list" :key="index" class="weather-item" :class="index === 1 ? 'current-day' : ''">
        <p class="day">{{ item | weekFilter }}</p>
        <p class="date">{{ item.date | timeFilter }}</p>
        <p class="temperature">{{ item.tem }}°</p>
        <div class="weather-icon">
          <svg class="icon-svg">
            <use :xlink:href="getWeatherIcon(item.data.wea_img)" />
          </svg>
        </div>
        <p class="weather-text">
          {{ item.wea }}
        </p>
        <p class="wind-speed-text">
          {{ item.win }}
        </p>
        <p class="wind-speed-text">
          {{ item.win_speed }}
        </p>
        <p class="humidity-text">
          湿度：{{ item.humidity | humidityFilter }}
        </p>
        <p class="air-warp">
          <span class="air-quality" :class="getAirLeave(item.air_level)">
          {{ item.air_level }}
          </span>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index'
import Minin from '../functionCom/mixin'
/**
 * @description 天气
 */
export default {
  name: "WeatherWeek",
  props: {
    scaleHeight: {
      type: Number,
      default: 1
    },
    scaleWidth: {
      type: Number,
      default: 1
    }
  },
  mixins: [Minin],
  data() {
    return {
      list: []
    };
  },
  filters: {
    /**
     * @description: 日期格式化过滤器
     * @param {Object} value
     * @return {string}
     */    
    weekFilter(value) {
      const now = new Date().getTime();
      const nowDate = now - (now % 86400000);
      const targerDate = new Date(value.date);
      if(targerDate - nowDate === -86400000) {
        return '昨天';
      } else if(targerDate - nowDate === 0) {
        return '今天';
      }  else if (targerDate - nowDate === 86400000) {
        return '明天';
      } else {
        return value.week;
      }
    },
    /**
     * @description: 时间格式化过滤器
     * @param {Object} value
     * @return {String}
     */    
    timeFilter(value) {
      const targerDate = new Date(value);
      const m = targerDate.getMonth() + 1;
      const d = targerDate.getDate();
      return `${ m > 9 ? m : '0' + m}-${ d > 9 ? d : '0' + d}`;
    },
    /**
     * @description: 百分比格式化过滤器
     * @param {String} value
     * @return {String}
     */    
    humidityFilter(value) {
      if(value.endsWith('%')) {
        return value;
      } else {
        return `${value}%`;
      }
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getWeatherInfo();
    })
  },
  methods: {
    // 获取天气情况
    getWeatherInfo() {
      const REQDATA = {
        __method_name__: 'dataList',
        object_uuid:'object635246a1edca4',
        view_uuid:'view6355e8f02dff8',
        transcode: 0
      }
      dataInterface(REQDATA).then(res => {
        if(res.status === 200 && res.data.code === 200) {
          this.list = res.data.data.sort((a, b) => { return new Date(a.date) - new Date(b.date)});
        }
      })
    },
    /**
     * @description: 获取空气质量class
     * @param {*} value
     * @return {String}
     */    
    getAirLeave(value) {
      switch(value) {
        case '优' : {
          return 'you';
        }
        case '良': {
          return 'liang';
        }
        case '轻度污染' : {
          return 'qingdu';
        }
        case '重度污染': {
          return 'zhongdu';
        }
        case '严重污染': {
          return 'yangzhong';
        }
        default: {
          return '';
        }
      }
    },
    /**
     * @description: 获取天气icon
     * @param {*} value
     * @return {String}
     */    
    getWeatherIcon(value) {
      switch(value) {
        case 'xue': {
          return '#iconzhongxuedaxue';
        }
        case 'lei': {
          return '#iconlei';
        }
        case 'shachen': {
          return '#iconshachen';
        }
        case 'wu': {
          return '#iconwu1';
        }
        case 'bingbao': {
          return '#iconbingbao';
        }
        case 'yun': {
          return '#iconyun1';
        }
        case 'yu': {
          return '#icondayuzhongyu';
        }
        case 'yin': {
          return '#iconyin';
        }
        case 'qing': {
          return '#iconqing';
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 注释掉颜色是因为@林冉，需要颜色可配置
.weather-week-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  .waether-week-header{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    // color: #272829;
  }
  .weather-week-main {
    margin-top: 16px;
    flex-grow: 1;
    height: 10px;
    display: flex;
    .weather-item{
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 8px;
      line-height: 1;
      & + .weather-item{
        margin-left: 8px;
      }
      text-align: center;
      .day{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        // color: #363636;
      }
      .date{
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        // color: #767676;
      }
      .temperature{
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        // color: #363636;
      }
      .weather-icon{
        margin-top: 16px;
        .icon-svg{
          height: 47px;
          width: 47px;
        }
      }
      .weather-text{
        margin-top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        // color: #363636;
      }
      .wind-speed-text{
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        // color: #363636;
      }
      .humidity-text{
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        // color: #363636;
      }
      .air-warp{
        margin-top: 38px;
        .air-quality{
          padding: 6px 18px;
          border-radius: 4px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          text-align: center;
          color: #FFFFFF;
        }
        .you{
          background: #81DA7F;
        }
        .liang{
          background: #FFBD16;
        }
        .qingdu{
          background: #8A99D7;
        }
        .zhongdu{
          background: #7B69EA;
        }
        .yangzhong{
          background: #E24C4C;
        }
      }
    }
  }
  .current-day{
    background: #FFFFFF;
  }
}
</style>
