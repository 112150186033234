<!--
 * @Description: 漏斗图
 * @Author: luocheng
 * @Date: 2022-01-13 17:52:31
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-15 10:21:17
-->
<template>
	<div
		class="chart-line"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option && canShow"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
import mixin from './mixin';

export default {
	name: 'ChartLine',
	mixins: [mixin],
	data() {
		return {
			statisticalData: null,
			option: null
		};
	},
	created() {
		this.getOptions();
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {*} data
		 * @return {*}
		 */
		setOptions(data) {
			if (!data || !data.length) return false;
			const { xData = [], series = [], names = [] } = data[0];
			if (!xData?.length) {
				this.canShow = false;
				return false;
			}
			const { attributes = {} } = this.chartData;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				legenFontSize = 12,
				smooth = false,
				xAxisText = '',
				yAxisText = '',
				colorRange = [],
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				legendOrient = 'vertical',
				legendType = 'plain',
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				axisTextColor = '#ffffff',
				axisFontSize = '14px'
			} = attributes;
			// 设置option 暂未实现
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					},
					appendToBody: true
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(names, legendTextColor),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
					textStyle: {
						fontSize: this.scaleSize(legenFontSize)
					}
				},
					color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					name: xAxisText,
					data: xData || [],
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				},
				yAxis: {
					type: 'value',
					name: yAxisText,
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				},
				series: series.map((ele) => {
					return {
						name: ele.name,
						type: 'line',
						symbol: smooth ? 'none' : '', //去掉折线图中的节点
						smooth, //true 为平滑曲线，false为直线
						data: ele.data
					};
				})
			};
		},
		/**
		 * @desc: 设置图例
		 * @param {Array} names 名称
		 * @param {String} color 文本颜色
		 * @return {*}
		 */
		getLegendData(names, color) {
			let s = [];
			if (!names) {
				return [];
			}
			for (let i = 0; i < names.length; i++) {
				s.push({
					name: names[i],
					textStyle: {
						color,
						// fontSize: this.scaleSize(12)
					}
				});
			}
			return s;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-line {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>