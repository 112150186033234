<template>
  <ModelTreeSingle
          ref="modelTree"
          class="moduleWrap"
          :element.sync="element"
          :bindViewerId="bindViewerId"
          :modelTreeSpread="modelTreeSpread"
          :loadModelNodeCallback="loadModelNodeCallback"
          @clickToolbar="onClickToolbar"
          @addModel="onAddModel"
          @deleteModel="onDeleteModel"
          @locationModel="onLocationModel"
          @checkModel="onCheckModel">
  </ModelTreeSingle>
</template>

<script>
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import {initParams} from '@/utils/tools';
import ModelTreeSingle from "@/custom-component/viewerComponent/ModelTreeSingle";

let treeCollection = {};

export default {
  name: 'ViewerGISModelTree',
  components: {
    ModelTreeSingle
  },
  data() {
    return {
      modelTreeSpread: 'right',
      bindViewerId: null,
      isViewerInited: false,
      db: {
        object_uuid: 'object62624ddb0a688',
        view_uuid: 'view62624f04dabbb'
      },
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    isGroup: {
      type: Boolean,
    },
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  mixins: [CustomComponentMixin],
  computed: {
    ...mapState(['componentData', 'originComponentData'])
  },
  mounted() {
    this.initEventListener();

    let {objectData, viewData} = this.element.database;
    if(objectData && viewData){
      this.db.object_uuid = objectData.uuid;
      this.db.view_uuid = viewData.uuid;
    }

    if (this.element.viewerBindId) {
      if (this.checkBind(this.element.viewerBindId)) {
        this.bindViewerId = this.element.viewerBindId;
      }
    }

    if(this.element.modelTreeSpread){
      this.modelTreeSpread = this.element.modelTreeSpread;
    }

    const {
      search = [],
      param = {}
    } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents
    );
    this.$refs.modelTree.getGISUnloadListData(this.db, this.bindViewerId, search, param).then(() => {
      if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
        eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, ({viewerInited = false, dynamicLoaded = false}) => {
          if(viewerInited && dynamicLoaded){
            this.getModelData(this.bindViewerId);
          }else{
            this.initViewerInitedEventListener();
          }
        });
      }else{
        this.initViewerInitedEventListener();
      }
    });
  },
  methods: {
    /**
     * @description: 注册组件事件
     */
    initEventListener() {
      eventBus.$on("ViewerGISModelTree.GetSource", (eid, cb) => {
        if (eid !== this.element.id) return;

        const loop = (array = [], resList = []) => {
          for (let item of array) {
            if (item.component !== "ViewerGIS") {
              if (item.children && item.children.length > 0) loop(item.children, resList);
              continue;
            }

            if (resList.findIndex(x => x.id === item.id) !== -1) continue;
            resList.push({
              id: item.id,
              name: item.name
            });
          }

          return resList;
        };

        const comps = [];
        loop(this.originComponentData, comps);
        loop(this.componentData, comps);

        cb({
          list: comps,
          bindId: this.bindViewerId,
          spreadDirection: this.modelTreeSpread
        });
      });

      eventBus.$on("ViewerGISModelTree.BindViewer", (eid, bindId) => {
        if (eid !== this.element.id) return;
        if (!this.checkBind(bindId)) return;

        this.element.viewerBindId = bindId;
        this.bindViewerId = bindId;
        this.$message.success(`[${this.element.name}] 绑定成功`);
        this.$refs.modelTree.getGISUnloadListData(this.db, this.bindViewerId);
      });

      eventBus.$on("ViewerGISModelTree.ModelTreeSpread", (eid, direction) => {
        if (eid !== this.element.id) return;

        this.element.modelTreeSpread = direction;
        this.modelTreeSpread = direction;
      });

      //Viewer初始化及模型树组件初始化后，监听模型加载事件
      eventBus.$on("ViewerGIS.onModelLoaded", (eid, model_id) => {
        if (eid !== this.bindViewerId) return;
        if(!this.isViewerInited) return;

        this.$refs.modelTree.setModelNodeLoaded(model_id);
      });
    },
    /**
     * @description: 注册Viewer组件初始化监听事件
     */
    initViewerInitedEventListener(){
      eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
        if (eid !== this.bindViewerId) return;

        let {viewerInited = false, dynamicLoaded = false} = opt;
        if(viewerInited && dynamicLoaded){
          this.getModelData(this.bindViewerId);
        }
      });
    },
    /**
     * @description: 检验绑定的Viewer是否有效
     * @param {String} bindId Viewer标识值
     */
    checkBind(bindId = this.bindViewerId) {
      if(!bindId) return false;

      const checkFunc = (bid, list) => {
        let hasComp = false;
        if(!list) return hasComp;

        for (let comp of list) {
          if (comp.children) {
            const flag = checkFunc(bid, comp.children);

            if (flag) {
              hasComp = true;
              break;
            }
          }
          if (comp.id !== bid) continue;

          hasComp = true;
          break;
        }

        return hasComp;
      };

      const hasComp = checkFunc(bindId, this.componentData);
      const hasOriginComp = checkFunc(bindId, this.originComponentData);

      if (!hasComp && !hasOriginComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

      return hasComp;
    },
    /**
     * @description: 获取初始化时默认加载的模型数据
     * @param {String} bindId Viewer标识值
     */
    getModelData(bindId = this.bindViewerId){
      if (!bindId) return;

      eventBus.$emit("ViewerGIS.getModelInfo", this.bindViewerId, async (res) => {
        let keys = await this.$refs.modelTree.setGISModelExtraData(res);
        this.$refs.modelTree.setUnloadNodeCheck(keys, false);
        this.isViewerInited = true;

        this.$nextTick(() => {
          for (let item of res){
            this.$refs.modelTree.setModelNodeProps(item.modelId, {
              type: 'model',
              modelId: item.modelId,
              modelType: item.modelType,
              dbid: 1,
              root: true,
              // isLeaf: item.modelType === 'bim' ? false : true
              isLeaf: item.modelType === 'bim' ? true : true  //bim模型树暂屏蔽
            });
            this.$refs.modelTree.setModelNodeLoaded(item.modelId);
            treeCollection[item.modelId] = item.tree;
          }
        });
      });
    },
    /**
     * @description: 模型树懒加载方法
     * @param {Object} node 模型节点对象
     */
    async loadModelNodeCallback(node) {
      return await new Promise((resolve) => {
        let children = [];
        if (node.modelType === 'bim') {
          const tree = treeCollection[node.modelId];
          for (let key in tree) {
            if (tree[key].pid === node.dbid) {
              let childIndex = tree.findIndex(x => x.pid === tree[key].dbid);
              let temp = Object.assign(tree[key], {
                type: 'feature',
                id: node.modelId + tree[key].dbid,
                modelId: node.modelId,
                modelType: node.modelType,
                isLeaf: childIndex === -1 ? true : false
              });
              children.push(temp);
            }
          }
        }
        resolve({leafs: children});
      });
    },
    /**
     * @description: 遍历模型树模型节点获取构件节点集
     * @param {Object} node 模型节点对象
     * @param {Array} leaf 构件节点结果集
     */
    getFeatureNodeByModel(node, leaf = []) {
      const tree = treeCollection[node.id];
      const getLeafDbid = (tree, key, leaf) => {
        let childIndex = tree.findIndex(x => x.pid === key);

        if (childIndex === -1) {
          leaf.push(key);
          return;
        }

        for (let index in tree) {
          if (tree[index].pid !== key) continue;
          getLeafDbid(tree, tree[index].dbid, leaf);
        }
      };

      if (node.isLeaf) {
        leaf.push(node.dbid);
        return;
      }

      getLeafDbid(tree, node.dbid, leaf);

      return leaf;
    },
    /**
     * @description: 模型节点或构件节点显示隐藏
     * @param {Object} data 模型或构件节点对象
     * @param {Boolean} isShow 是否显示
     */
    setModelShow(data, isShow) {
      if (data.modelType === 'bim') {
        //bim模型树暂屏蔽
        // let leaf = this.getFeatureNodeByModel(data);
        // eventBus.$emit('ViewerGIS.showFeature', this.bindViewerId, data.id, leaf, isShow);
        eventBus.$emit('ViewerGIS.showModel', this.bindViewerId, data.id, isShow);
      } else {
        eventBus.$emit('ViewerGIS.showModel', this.bindViewerId, data.id, isShow);
      }
    },

    /**
     * @description: 模型树其他功能按钮事件
     * @param {String} type 功能按钮类型
     */
    onClickToolbar(type = '') {
      if (type === 'local') {
        window.open(`https://models.bimcc.net/local/viewer`, '_blank');
      } else {
        this.$message.warning("功能开发中...");
      }
    },
    /**
     * @description: 添加模型事件
     * @param {Array} keys 模型ID集
     */
    onAddModel(keys) {
      for (let key of keys) {
        eventBus.$emit('ViewerGIS.createModel', this.bindViewerId, key, true, (props, tree) => {
          this.$refs.modelTree.setModelNodeProps(key, {
            type: 'model',
            modelId: key,
            modelType: props.modelType,
            dbid: 1,
            root: true,
            // isLeaf: item.modelType === 'bim' ? false : true
            isLeaf: props.modelType === 'bim' ? true : true  //bim模型树暂屏蔽
          });
          treeCollection[key] = tree;
        });
      }
    },
    /**
     * @description: 删除模型事件
     * @param {Array} nodes 模型ID集
     */
    onDeleteModel(nodes) {
      for (let item of nodes) {
        eventBus.$emit('ViewerGIS.removeModel', this.bindViewerId, item.id);
        delete treeCollection[item.id];
      }
    },
    /**
     * @description: 定位模型事件
     * @param {Object} node 模型节点对象
     */
    onLocationModel(node) {
      eventBus.$emit('ViewerGIS.locationModel', this.bindViewerId, node.id);
    },
    /**
     * @description: 勾选模型事件
     * @param {Object} node 模型节点对象
     * @param {Boolean} isChecked 是否勾选
     */
    onCheckModel(node, isChecked) {
      if (node.type === 'file') {
        let modelList = this.$refs.modelTree.getModelNodeByFile(node);
        for (let item of modelList) {
          this.setModelShow(item, isChecked);
        }
      } else {
        this.setModelShow(node, isChecked);
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .moduleWrap {
    width: 100%;
    height: 100%;
  }
</style>
