<!--
 * @Description: 网盘整体组件 vue2
 * @Author: luocheng
 * @Date: 2022-03-02 09:28:30
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-16 16:39:48
-->
<template>
  <div class="common-net-disk">
    <DiskHeader
      :pathList="pathList"
      @changeModel="onChangeModel"
      @updateList="getList"
      @changeBreadcrumb="onChangeBread"
      @filterFile="onFilter"
      @updateClassify="handleUpdateClassify"
      :classify="classify"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      :newFolder="statusConfig?.newFolder"
      :useCreateLink="statusConfig?.useCreateLink"
      :usePlaceholder="statusConfig?.usePlaceholder"
      :mode="mode"
    ></DiskHeader>
    <article class="disk-main" v-loading="loading">
      <!-- 表格视图 -->
      <TableView
        v-if="viewType === 'table'"
        :tableData="list"
        :mode="mode"
        @updateList="getList"
        @selectFile="onSelectFile"
        :isGroup="isGroup"
        :groupComponents="groupComponents"
        :componentList="componentList"
      >
      </TableView>
      <!-- 文件夹模式 -->
      <FolderView
        v-else-if="viewType === 'folder'"
        :mode="mode"
        :fileList="list"
        @selectFile="onSelectFile"
        @updateList="getList"
        :isGroup="isGroup"
        :groupComponents="groupComponents"
        :componentList="componentList"
      >
      </FolderView>
    </article>
    <!-- 预览图片 -->
    <el-image-viewer
      v-if="showPreview"
      :on-close="closePreview"
      :url-list="previewList"
    ></el-image-viewer>
    <!-- 图纸预览 -->
    <div v-if="drawingShow" class="drawing-cont">
			<div  :id="'viewerDom'+element.id" style="width: 100%; height: 100%" />
			<i @click="closeDrawing" class="iconfont iconc-close"></i>
		</div>
  </div>
</template>

<script>
import DiskHeader from "./components/Header";
import TableView from "./components/TableView";
import FolderView from "./components/FolderView";
import { dataInterface } from "@/apis/data";
import { mapState } from "vuex";
import eventBus from "@/plugins/eventBus";
// import mixin from './mixin';
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue";
import { initParams, removeEventBus } from "@/utils/tools";
import axios from "axios";
let viewer = null;
export default {
  name: "CommonNetDisk",
  props: {
    element: {
      type: Object,
      required: true,
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
  },
  components: {
    DiskHeader,
    TableView,
    FolderView,
    "el-image-viewer": ElImageViewer,
  },
  data() {
    return {
      viewType: "table", // 视图类型 table 表格模式，folder 文件夹模式
      fileList: [], // 文件列表
      loading: false,
      // 父级id
      parentId: 0,
      // 模块名称
      pathList: [],
      // 筛选名称
      filterName: "",
      // 预览视图
      previewList: [],
      showPreview: false,
      outParam: null,
      // 图纸预览
      drawingShow: false,
      viewerId: '',
      classify: ''
    };
  },
  inject: ["EDITOR_pageUUID"],
  provide() {
    return {
      element: this.element,
      getParentId: () => this.parentId,
    };
  },
  computed: {
    ...mapState(["componentData"]),
    statusConfig() {
      return this.element?.statusConfig;
    },
    database() {
      return this.element?.database;
    },
    // 模式
    mode() {
      return this?.statusConfig?.mode;
    },
    // 列表计算
    list() {
      return this.fileList.filter(el => {
        if(this.classify) {
          switch(this.classify) {
            case 'doc': {
              return ['doc', 'docx', 'text', 'md', 'xls', 'xlsx', 'cvs', 'pdf'].includes(el.ext) || +el.type_id === 1;
            }
            case 'img': {
              return ['png', 'jpg', 'jpeg', 'gif', 'tif', 'bmp', 'dds', 'svg', 'eps'].includes(el.ext) || +el.type_id === 1;
            }
            case 'video': {
              return ['mp4', 'webm', 'avi', 'wmv'].includes(el.ext) || +el.type_id === 1;
            }
            case 'cad': {
              return ['cad'].includes(el.ext) || +el.type_id === 1;
            }
            case 'model': {
              return ['obj', 'gitf'].includes(el.ext) || +el.type_id === 1;
            }
            case 'other': {
              return !['doc', 'docx', 'text', 'md', 'xls', 'xlsx', 'cvs', 'pdf', 'png', 'jpg', 'jpeg', 'gif', 'tif', 'bmp', 'dds', 'svg', 'eps', 'mp4', 'webm', 'avi', 'wmv', 'cad', 'obj', 'gitf'].includes(el.ext) || +el.type_id === 1;
            }
          }
        }
        return true;
      })
    }
  },
  created() {
    this.getList();
    this.pathList.push({
      label: this.statusConfig.partName, // 模块名称
      type: "folder", // 类型 folder 文件夹， file 文件
      partId: 0, // 父级id
    });
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: (data) => {
        if (data.originComponent === this.element.id) return;
        let { paramsConfig, requestType } = this.element.database;
        if (!Array.isArray(paramsConfig)) return;
        // 是否当前组件响应
        let isTarget = false;
        for (let i = 0; i < paramsConfig.length; i++) {
          if (paramsConfig[i].componentId === data.componentId && this.isSameAction(requestType, data.action)) {
            isTarget = true;
            break;
          }
        }
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this.getList();
      }
    };
    eventBus.$on("databaseTrigger", databaseTrigger[this.element.id]);
  },
  methods: {
    /**
     * @description: 更新分类
     * @param {*} res
     * @return {*}
     */    
    handleUpdateClassify(res) {
      this.classify = res;
    },
     /**
     * @desc: 判断前后端操作标识是否已知
     * @param {String} beAction 后端标识
     * @param {String} feAction 前端标识
     * @return {Boolean}
     */
     isSameAction(beAction, feAction) {
      if (feAction === 'any') return true;
      const feToBe = {
        list: 'dataList',
        add: 'createData',
        detail: 'dataInfo',
        modify: 'updateDate',
        delete: 'deleteData'
      };
      return beAction === feAction || beAction === feToBe[feAction];
    },
    /**
     * @desc: 筛选
     * @param {String} name 名称
     */
    onFilter(name) {
      this.filterName = name;
      this.getList(true);
    },
    /**
     * @desc: 获取数据列表
     */
    getList(isSearch = false) {
      // TODO NET
      this.fileList = [];
      const { objectData, viewData } = this.database;
      if (!objectData || !viewData) {
        this.$message.error("获取数据失败!");
        return false;
      }
      const {
        param = {},
        search = [],
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      if (!canPost) return;
      if (param && this.outParam) {
        let needClear = false;
        for (let key in param) {
          if (param[key] !== this.outParam[key]) {
            needClear = true;
          }
        }
        if (needClear) {
          this.parentId = 0;
          this.pathList = [];
          this.pathList.push({
            label: this.statusConfig.partName, // 模块名称
            type: "folder", // 类型 folder 文件夹， file 文件
            partId: 0, // 父级id
          });
        }
      }
      this.outParam = param;
      const objectUUID = objectData.uuid;
      const defaultView = viewData.uuid;
      this.loading = true;
      const fixParams = isSearch
        ? {}
        : {
            parent_id: this.parentId || 0,
          };
      dataInterface({
        __method_name__: "dataList",
        object_uuid: objectUUID,
        view_uuid: defaultView,
        ...param,
        search: [
          ...search,
          {
            code: "name",
            ruleType: "like",
            value: this.filterName,
          },
        ],
        ...fixParams,
        transcode: 0,
      })
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            const fileList = res?.data?.data || [];
            let files = [],
              folder = [],
              other = [];
            fileList.forEach((ele) => {
              if (+ele.type_id === 1) {
                folder.push(ele);
              } else if (+ele.type_id === 2) {
                files.push(ele);
              } else {
                other.push(ele);
              }
            });
            this.fileList = folder.concat(files).concat(other);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "---");
          this.$message.error("获取文件失败！");
          this.loading = false;
        });
    },
    /**
     * @desc: 切换面包屑
     * @param {Object} bread 当前选中面包屑
     */
    onChangeBread(bread) {
      if (!bread || this.parentId === bread.partId) {
        return;
      }
      const index = this.pathList.findIndex(
        (ele) => ele.partId === bread.partId
      );
      if (index !== -1) {
        this.pathList = this.pathList.slice(0, index + 1);
        this.parentId = bread.partId;
        this.getList();
      }
    },
    /**
     * @desc: 选择文件
     * @param {Object} file 文件对象
     */
    onSelectFile(file) {
      console.log(file, "=----file----");
      if (
        +file.type_id === 1 &&
        !this.pathList.find((ele) => ele.partId === file.id)
      ) {
        this.parentId = file.id;
        // 文件夹
        this.pathList.push({
          label: file.name, // 模块名称
          type: "folder", // 类型 folder 文件夹， file 文件
          partId: file.id, // 父级id
        });
        this.getList();
      } else if (+file.type_id === 2 || (+file.type_id === 4 && file.path)) {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        if (
          ["bmp", "jpg", "png", "gif", "jpeg", "cdr", "psd"].indexOf(
            file.ext.toLowerCase()
          ) >= 0
        ) {
          // 图片
          this.previewList =
            file.path.indexOf("http") === -1
              ? [`${baseUrl}/${file.path.replace("_thumb", "")}`]
              : [file.path.replace("_thumb", "")];
          this.showPreview = true;
        } else if (
          ["doc", "docx", "pdf", "ppt", "pptx", "xlsx", "xls", "txt"].indexOf(
            file.ext.toLowerCase()
          ) >= 0
        ) {
          // 文档
          /* eslint-disable */
          if (file.path.includes("http://") || file.path.includes("https://")) {
            let url = "https://weboffice.bimcc.net?furl=";
            if (file.path.includes("https://")) {
              url = "https://weboffice.bimcc.net?ssl=1&furl=";
            }
            window.open(url + file.path);
          } else {
            let url = "https://weboffice.bimcc.net?furl=";
            if (baseUrl.includes("https://")) {
              url = "https://weboffice.bimcc.net?ssl=1&furl=";
            }
            window.open(url + baseUrl + "/" + file.path);
          }
          // window.open(`${url}${file.path.indexOf('http') === -1 ? baseUrl : ''}${file.path}`);
        } else if (["mp4"].indexOf(file.ext.toLowerCase()) >= 0) {
          window.open(
            `${file.path.indexOf("http") === -1 ? baseUrl : ""}${file.path}`
          );
        } else if (["dwg"].indexOf(file.ext.toLowerCase()) >= 0) {
          // 图纸
          if (!file.full_data || !file.full_data.model_file_id) {
            this.$message.error("打开失败！");
            return;
          }
          this.previewDwg(file);
        } else {
          this.$message.error("无法打开此格式的文件！");
        }
      } else if(+file.type_id === 3) {
        window.open(file.url || file.path)
      }
    },
    /**
     * @description: 预览图纸
     * @return {Object} 数据
     */
    previewDwg(data) {
      axios({
        method: "post",
        url: "https://models.bimcc.net/api/v3/auth/token",
        data: {
          appId: "bdafce12a2d9465d89821ec14214a382",
          appSecret: "3EYcdAjbnWxP",
        },
      }).then((res) => {
        if (res.data?.data?.token) {
          axios({
            method: "get",
            url:
              "https://models.bimcc.net/api/v3/model/tranlate?file_id=" +
              data?.full_data?.model_file_id,
            headers: {
              authorization: `Bearer ${res.data.data.token}`,
            },
          }).then((re) => {
            if (re.data.data?.progress === "Finish") {
              let modelId = "";
              if (
                re.data?.data?.data?.f2d &&
                re.data.data.data.f2d.length !== 0
              ) {
                this.viewerId = this.element.id;
                modelId = re.data.data.data.f2d[0].model_id;
                this.drawingShow = true;
                setTimeout(() => {
                  const appId = "bdafce12a2d9465d89821ec14214a382";
                  const appSecret = "3EYcdAjbnWxP";
                  viewer = new window.BIMCC.Viewer("viewerDom" + this.element.id, {});
                  viewer.clearUI();
                  viewer.init(modelId, { appId, appSecret, UIType: "UI.Base" });
                }, 100);
              }
            } else {
              this.$message.info("dwg文件转换中,请稍后再试！");
            }
          });
        } else {
          this.$message.info("请稍后再试！");
        }
      });
    },
    /**
		 * @description 关闭图纸预览
		 */
		closeDrawing(){
			this.drawingShow = false;
			viewer = null
		},
    /**
     * @desc: 关闭预览
     */
    closePreview() {
      this.showPreview = false;
    },
    /**
     * @desc: 切换显示模式
     * @param {String} type 类型
     */
    onChangeModel(type) {
      this.viewType = type;
    },
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus("databaseTrigger", this.element.id);
    }
  },
};
</script>

<style lang="less" scoped>
.common-net-disk {
  position: relative;
  height: 100%;
  // width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow: hidden;
  .disk-main {
    overflow: hidden;
    flex: 1;
    width: 100%;
  }
  .drawing-cont{
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    i{
      position:absolute;
      top: 0px;
      right:5px;
      font-size: 25px;
    }
  }
}
</style>
