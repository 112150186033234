import Utils from '../Utils.js';
import EventEmitter from './EventEmitter';
import GraffitiTool from './tools/graffitiTool';
export default class Viewer extends  EventEmitter{
    constructor( rootDom , opt = {}){
        super();

        this.rootDom = rootDom;

        this.host = opt.host;
        this.port = opt.port;
        this.isHttps = opt.isHttps;

        this.appId = opt.appId;
        this.appSecret = opt.appSecret;

        this.client = null;
        this.viewer = null;

        this.ModelInfoMap = {};

        this.positionMap = {};
        this.unloadCache = {};

        this.graffitiTool = new GraffitiTool(this);
        
        this.cameraRotationBP = null
    }


    static get Events(){
        return {
            Error :{
                ViewerLoadModelInfoError : 'ViewerLoadModelInfoError',
            },
            ViewerInited : "ViewerInited",
            ViewerSelect : "ViewerSelect",
            ViewerLoadedModel : 'ViewerLoadedModel',
            ViewerCameraChange : 'ViewerCameraChange',
            ViewerSingleClick : 'ViewerSingleClick',
        }
    }

    getBIMViewer(){
        return this.viewer.viewer;
    }

    getGlobal(){
        return window
    }

    getEndpoint (modelID){
        let url = '';
        if(this.host){
            url = `${this.isHttps?'https://':'http://'}${this.port?`${this.host}:${this.port}`:`${this.host}`}/models/${modelID}/`;
        }else{
            url = '/';
        }
        return url;
    }

    _handleCache(){
        for( let key in this.unloadCache){
            switch (key) {
                case 'resize':
                    this.resize();
                    break;
                case 'setBackgroundColor':
                    this.setBackgroundColor( ...this.unloadCache[key] );
                    break;
                case 'restoreState':
                    this.restoreState( this.unloadCache[key] );
                    break;
            }
        }
    }

    async initResource(){
        const host = 'https://models.bimcc.net/';
        const global = this.getGlobal();

        const loadUrl = [
            `${host}javascripts/viewer/7.47/viewer3D.js`,
            `${host}javascripts/viewer/7.47/style.min.css`,
            `${host}javascripts/client.js`,
            `${host}javascripts/viewer/viewerBIMBase.js`, //todo 切换线上
            // 'http://b.bimphp.com:3333/javascripts/viewer/viewerBIMBase.js',
        ];

        const dependentAutodeskLoadUrl = [
            // `${host}javascripts/viewer/7.47/extensions/glTF/glTF.js`,
            `${host}javascripts/viewer/7.47/extensions/BimWalk/BimWalk.js`,
            `${host}javascripts/viewer/7.47/extensions/Markup/Markup.js`,
        ];

        Utils.checkLoadedUrl( [...loadUrl , ...dependentAutodeskLoadUrl] ,{
            onLoaded : ( url )=>{
                if( loadUrl.indexOf(url) != -1){
                    loadUrl.splice( loadUrl.indexOf(url) , 1)
                }

                if( dependentAutodeskLoadUrl.indexOf(url) != -1){
                    dependentAutodeskLoadUrl.splice( dependentAutodeskLoadUrl.indexOf(url) , 1)
                }
            }
        });

        Utils.appendListInHead( loadUrl );

        await Utils.waitLoaded(()=>{
            if(global.Autodesk){
                return true
            }else{
                return false
            }
        });

        Utils.appendListInHead( dependentAutodeskLoadUrl );
    }

    async init( modelId ){
        const global = this.getGlobal();

        Utils.log(`开始进行初始化 `);

        await this.initResource();

        await Utils.waitLoaded(()=>{
            if(global.BIMCC && global.BIMCC_BIM_Base){
                return true
            }else{
                return false
            }
        });

        Utils.log('初始化资源完成...')

        const client = this.client = new global.BIMCC.ModelClient({
            host : this.host,
            port : this.port,
            isHttps :this.isHttps,
        });

        Utils.log('服务器连接成功...');

        const viewer = this.viewer = new global.BIMCC_BIM_Base.Viewer( this.rootDom , {
            host : this.host,
            port : this.port,
            isHttps :this.isHttps,
        });

        Utils.log(`创建对象完成... `);

        await client.initApp({
            appId : this.appId,
            appSecret : this.appSecret,
        });

        await viewer.init({
            endpoint : this.getEndpoint( modelId ),
        });

        viewer.loadExtension('Autodesk.BimWalk');

        this.initEventListener();

        Utils.log('连接服务器完成...');
        await this.loadModel( modelId );
        
        this.setFPS(120);

        this._initAPI();
        this._handleCache();
        
        this.emit(Viewer.Events.ViewerInited , modelId );
    }

    /**
     * 初始化接口
     */
    _initAPI(){
        const APIName = ['select' , 'addSelect' , 'addIsolate' , 'addHide' ,
         'setGhosting' , 'fitToView' , 'isolate' , 'showAll' , 'show' , 'hide' , 'getBoundingSphere' , 'getSelection' , 'setFPS' ,
          'setExplode' , 'setAntialiasing' , 'setGroundShadow' , 'getNodeChildren' ];

        for(let key of APIName){
            if(!this.viewer[key]){
                console.log(key);
                continue
            }
            this[key] = this.viewer[key].bind(this.viewer);
        }
    }

    initEventListener(){
        const viewer = this.viewer;

        viewer.on("Viewer.onSelect", selection =>{
            const result = [];

            for(let { dbIdArray , model  } of selection){
                result.push({
                    dbids : dbIdArray,
                    model_id : model.model_id,
                })
            }

            this.emit(Viewer.Events.ViewerSelect , result);
        });

        viewer.on("Viewer.loadedModel" , model_id =>{
            this.emit(Viewer.Events.ViewerLoadedModel , model_id);
        });

        viewer.on('Viewer.SingleClick' , (event)=>{
            this.emit( Viewer.Events.ViewerSingleClick , {
                x: event.clientX,
                y: event.clientY
            })
        });

        viewer.on("Viewer.onCameraChange" , () =>{
            const obj = {};
            for(let id in this.positionMap ){
                const pos = this.positionMap[id];

                const point = viewer.worldToScreen(pos);

                obj[id] = {
                    position : pos,
                    point,
                    id,
                }
            }

            const cameraPos = viewer.viewer.getCamera().position
            this.emit(Viewer.Events.ViewerCameraChange , obj , cameraPos);
        });
    }

    addPosition( id , position ){
        this.positionMap[id] = position;
		return this.viewer.worldToScreen(position)
    }

    async loadModel( modelId ){
        if(typeof modelId  !== 'string') return console.error('模型加载出错 id为非字符串' , modelId);

        const client = this.client;
        const viewer = this.viewer;

        Utils.log(`开始加载模型 [${modelId}]`);

        if(this.ModelInfoMap[modelId]){
            return Utils.warn(`重复加载模型${modelId}`);
        }

        const { token } = await client.getToken();
        const [merr,modelInfo] = await client.getModelInfo( modelId );
        if(merr){
            this.emit(Viewer.Events.Error.ViewerLoadModelInfoError , modelId , merr );
            return console.error(`模型权限错误 appId[${this.appId}]` , merr);
        }

        const loadOpt = {
            // createWireframe : true,
            headers : {
                'authorization' : `Bearer ${token}`,
                'access-key' : client.getAccessKey(),
            },
        }

        const baseUrl = `${viewer.getBaseUrl()}/`;
        if(baseUrl != '/'){
            loadOpt.baseUrl = baseUrl;
        }

        let option = null;

        if(typeof modelInfo.option === 'string'){
            option = JSON.parse(modelInfo.option);
        }else if(typeof modelInfo.option === 'object'){
            option = modelInfo.option;
        }else{
            option = {};
        }

        let basePath = option.basePath;

        if(basePath){
            const sharedPropertyDbPath = `${viewer.getFullBaseUrl()}/${basePath}/`;
            loadOpt.sharedPropertyDbPath = sharedPropertyDbPath;
        }

        let loadModelUrl = `${viewer.getFullBaseUrl()}/${modelInfo.path}`;

        await viewer.loadModel(modelId , loadModelUrl , loadOpt);

        this.ModelInfoMap[modelId] = {
            type : modelInfo.type,
            created_at : modelInfo.created_at,
            name : modelInfo.file.name,
            originName : modelInfo.file.originName,
            extension : modelInfo.file.extension,
            size : modelInfo.file.size,
        }

        Utils.log(`加载模型 [${modelId}] 完成`);
    }

    async unloadModel(modelId){
        if(!this.ModelInfoMap[modelId]){
            return Utils.warn(`卸载模型失败,未加载该模型${modelId}`);
        }

        await this.viewer.unloadModel(modelId);

        delete this.ModelInfoMap[modelId];
    }

    resize(){
        if(this.viewer){
            this.viewer.resize();
        }else{
            this.unloadCache['resize'] = true;
        }
    }

    getLoadedModelName(){
        const map = {};

        for(let mid in this.ModelInfoMap){
            const { name } = this.ModelInfoMap[mid];

            map[mid] = name;
        }

        return map;
    }

    setBackgroundColor( r, g ,b ){
        if(this.viewer){
            this.viewer.setBackgroundColor( r, g, b);
        }else{
            this.unloadCache['setBackgroundColor'] = [r,g,b];
        }
    }

    getState(){
        if(this.viewer){
            const state = this.viewer.getState();
            const model_ids = [...this.viewer.getModelIds()]

            state.model_ids = model_ids;

            return state;
        }
    }

    restoreState( state ){
        if(this.viewer){
            this.viewer.restoreState(state);
        }else{
            this.unloadCache['restoreState'] = state;
        }
    }

    action(id , ...arg){
        if(!this.viewer || !this.viewer[id])return;
        return this.viewer[id](...arg);
    }

    useSelectWin( partial = true){
        this.viewer.useToolSelectWindow(partial);
    }

    getViewerMCP( type = null ){
        const list = [];

        //todo 判断是否已经初始化完成
        const MCP = this.viewer.mcp.actions.ViewerBIM;

        for(let id in MCP){
            const config = Object.assign({} , MCP[id] );
            if(!type ){
                if(!config.type){
                    list.push(config);
                }
            }else{
                if(config.type === type){
                    list.push(config);
                }
            }

        }

        return list;
    }

    getFristLoadModelId(){
        return this.viewer.fristLoadModelId;
    }

    select( selection ){
        this.viewer.select(selection);
    }

    addSelect( selection ){
        this.viewer.addSelect(selection);
    }

    addIsolate( selection ){
        this.viewer.addIsolate(selection);
    }

    addHide( selection ){
        this.viewer.addHide(selection);
    }

    setGhosting(val){
        this.viewer.setGhosting(val);
    }

    fitToView( dbids = []){
        this.viewer.fitToView(dbids);
    }

    isolate( dbids = []){
        this.viewer.isolate(dbids);
    }

    showAll(){
        this.viewer.showAll();
    }

    show( dbids , model_id ){
        this.viewer.show(dbids , model_id);
    }

    hide( selection = []){
        this.viewer.hide(selection);
    }

    showByList(selection = []){
        this.viewer.showByList(selection);
    }

    getBoundingSphere( dbid, model_id=''){
        return this.viewer.getBoundingSphere(dbid, model_id);
    }

    getSelection(){
        return this.viewer.getSelection();
    }

    setFPS(v){
        this.action('setFPS' , v);
    }

    setExplode(v){
        this.action('setExplode' , v);
    }

    setAntialiasing(v){
        this.action('setAntialiasing' , v);
    }

    setGroundShadow(v){
        this.action('setGroundShadow' , v);
    }

    getNodeChildren(model_id , dbid){
        return this.viewer.getNodeChildren( model_id , dbid );
    }

    getLoadedModelInfo(){
        return Utils.deepCopy(this.ModelInfoMap);
    }

    setCameraRotationBasePoint(opt){
        if(!opt){
            const { eye , up , target} = this.viewer.getState().viewport; 
            this.cameraRotationBP = {
                eye ,
                up,
                target,
            }
        }else{
            this.cameraRotationBP = {
                eye : opt.eye,
                target : opt.target,
                up : opt.up,
            }
        }
    }

    setCameraRotation( h , v , fov = 100  ){
        const THREE = window.THREE;

        const { eye , up ,target } = this.cameraRotationBP;

        let vec = new THREE.Vector3(  target[0] - eye[0] ,target[1] - eye[1]  , target[2] - eye[2] ); //视线轴
        let vz = new THREE.Vector3(...up);//z轴

        vec.applyAxisAngle( new THREE.Vector3(...up) , -h * Math.PI / 180);

        vz.cross(vec.clone().normalize());
        vz.normalize();
        vec.applyAxisAngle( vz , v * Math.PI / 180);
        vz.cross(vec.clone().normalize());
        vz.negate();        

        const tar = [eye[0] + vec.x , eye[1] + vec.y , eye[2] + vec.z];

        const state = {
            viewport :{
                eye,
                target : tar,
                fieldOfView : fov ,
                up : [vz.x , vz.y ,vz.z],
            }
        }

        this.viewer.restoreState(state);
    }
    /**
     * 获取相机旋转
     * @param {*} eye 相机原位置
     * @param {*} target 相机原目标
     * @param {*} up 相机上方向
     * @param {*} targetAfter 相机旋转后目标
     * @returns [h,v]
     */
    getCameraRotation(eye, target, up , targetAfter){
        const  calcVectorAngle = (v1, v2) => {
            const dot = v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2];
            const length1 = Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1] + v1[2] * v1[2]);
            const length2 = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1] + v2[2] * v2[2]);

            const cos = dot / (length1 * length2);
            const angle = Math.acos(Math.clamp(cos, -1, 1)) * 180 / Math.PI;

            return angle;

        }

        const calcProjectivePoint = (eye, target, up, targetAfter) => {
            const n_target = [target[0] - eye[0], target[1] - eye[1], target[2] - eye[2]];
            const n_up = [up[0] - eye[0], up[1] - eye[1], up[2] - eye[2]];

            const n_assist = [
                n_target[1] * n_up[2] - n_target[2] * n_up[1],
                n_target[2] * n_up[0] - n_target[0] * n_up[2],
                n_target[0] * n_up[1] - n_target[1] * n_up[0]
            ];

            const A = {
                x: (eye[0] + target[0]) / 2,
                y: (eye[1] + target[1]) / 2,
                z: (eye[2] + target[2]) / 2,
            };
            const n = {
                x: n_target[1] * n_assist[2] - n_target[2] * n_assist[1],
                y: n_target[2] * n_assist[0] - n_target[0] * n_assist[2],
                z: n_target[0] * n_assist[1] - n_target[1] * n_assist[0]
            };
            const p = {
                x: targetAfter[0],
                y: targetAfter[1],
                z: targetAfter[2]
            };

            const denominator = n.x * n.x + n.y * n.y + n.z * n.z;
            const pp = [
                (n.x * n.y * A.y + n.y * n.y * p.x - n.x * n.y * p.y + n.x * n.z * A.z + n.z * n.z * p.x - n.x * n.z * p.z + n.x * n.x * A.x) / denominator,
                (n.y * n.z * A.z + n.z * n.z * p.y - n.y * n.z * p.z + n.y * n.x * A.x + n.x * n.x * p.y - n.x * n.y * p.x + n.y * n.y * A.y) / denominator,
                (n.x * n.z * A.x + n.x * n.x * p.z - n.x * n.z * p.x + n.y * n.z * A.y + n.y * n.y * p.z - n.y * n.z * p.y + n.z * n.z * A.z) / denominator
            ];

            return pp;
        }

        const n_targetAfter = [targetAfter[0] - eye[0], targetAfter[1] - eye[1], targetAfter[2] - eye[2]];
        const n_target = [target[0] - eye[0], target[1] - eye[1], target[2] - eye[2]];
        // const n_up = [up[0] - eye[0], up[1] - eye[1], up[2] - eye[2]];

        const pp = calcProjectivePoint(eye, target, up, targetAfter);
        const n_pp = [pp[0] - eye[0], pp[1] - eye[1], pp[2] - eye[2]];

        const angle_h = calcVectorAngle(n_target, n_pp);
        const angle_v = calcVectorAngle(n_targetAfter, n_pp);
				console.log(angle_h, angle_v)

        return {
            // h,
            // v 
        }
    }
}