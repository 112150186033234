<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-04-29 17:18:40
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-05-05 16:54:55
 * @FilePath: \dataview-next\src\custom-component\form\newParser\InputItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
    @name: InputItem
    @description：InputItem
    @author: ZengWei
    @date: 2022-03-16 16:41
-->
<template>
  <div>
    <component
      ref="widgetComponent"
      :is="getWidgetName(element)"
      :element="element"
			:isConsole="isConsole"
      :disabled="disabled"
      :editFields="editFields"
      :formData="formData"
      :displayData="displayData"
      :contentConfig="contentConfig"
      :dataIds="dataIds"
      :formMode="formMode"
      @change-calc="calcFormula"
      @trigger-active="triggerActive(arguments)"
    />
  </div>
</template>

<script>
import WidgetComponents from "@/custom-component/form/newParser/FormItem";
import QuesComponents from '@/custom-component/form/quesParser/components/widget'
export default {
  name: "InputItem",
  components: {
    ...WidgetComponents,
    ...QuesComponents
  },
  props: [
    'element','disabled','editFields', 'formData', 'displayData','isConsole', 'contentConfig', 'dataIds', 'formMode'
  ],
  data() {
    return {}
  },
  methods: {
    getWidgetName(widget) {
      if(widget.config.__ques__){
        return `ques-${widget.config.__ques__.quesType}`
      }
      return `widget-${widget.type}`
    },
    calcFormula(){
      this.$emit('change-calc')
    },
    triggerActive(params){
      const compShowHide = params[0]; // 受控控件
      const ctrlType =  params[1] ?? '' // 交互类型 - 默认显示隐藏
      const optionData =  params[2] ?? ''// 级联数据

      this.$emit('trigger-active',compShowHide,ctrlType,optionData)
    },
    validateFormInput() {
      if(typeof this.$refs.widgetComponent.validateFormInput === 'function'){
        this.$refs.widgetComponent.validateFormInput()
      }
    },
    getChildFormData() {
      if(typeof this.$refs.widgetComponent.getChildFormData === 'function'){
        return this.$refs.widgetComponent.getChildFormData()
      }
    }
  }
}
</script>

<style scoped>

</style>
