/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-04-07 16:17:42
 * @FilePath: \dataview-next\src\custom-component\video\player\index.js
 * @Description: 视频组件render，用于控制组件渲染和配置的适配
 */
import HlsPlayer from './HlsPlayer'
// import EzuiPlayer from './EzuiPlayer'
import EzuiFullPlayer from './EzuiFullPlayer'
import ImouPlayer from './ImouPlayer.vue'
import RtmpPlayer from './RtmpPlayer.vue'
import { dataInterface } from '@/apis/data'
import { Message } from 'element-ui'
import { getNowTime } from '@/utils/tools';

/**
 * @desc 通用视频播放组件 这里进行选择渲染
 * HlsPlayer
 * EzuiFullPlayer
 * ImouPlayer
 */
export default {
  functional: true,
  props: {
    // 传递进来的参数
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
    // 传递进来的配置
    config: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  render(h, context) {
    /**
     * @description: 捕获截图事件进行数据处理
     * @param {Object} 序列号及图片地址
     */
    const docapturePictureCB = (data) => {
      const date = getNowTime(2);
      const REQDATA = {
        object_uuid: 'object6375aa758c549',
        __method_name__: 'createData',
        screenshot_time: date,
        name: data.monitor_name + date + '截图',
        ...data
      }
      dataInterface(REQDATA).then(res => {
        if (res.data.data) {
          Message.success({ message: res.data.msg || '操作成功' })
        } else {
          Message.error({ message: res.data.msg || '操作失败' })
        }
      })
    }
    // 获取组装完成后的视频信息
    const VIDEDOINFO = context.props.data;
    // 如果存在 platform_info 则为萤石云或乐橙云
    if (Array.isArray(VIDEDOINFO.app_info) && VIDEDOINFO.app_info.length) {
      switch (VIDEDOINFO.app_name) {
        case 'ys7': {
          return h(EzuiFullPlayer, {
            on: {
              docapturePicture: docapturePictureCB
            },
            props: {
              data: context.props.data,
              config: context.props.config,
            }
          });
        }
        case 'lechange': {
          return h(ImouPlayer, {
            on: {
              docapturePicture: docapturePictureCB
            },
            props: {
              data: context.props.data,
              config: context.props.config,
            }
          });
        }
        default: {
          return h('span', null, '该平台无法识别');
        }
      }
    } else if (VIDEDOINFO.monitor_path && VIDEDOINFO.monitor_path.startsWith('http')) {
      // hls 乐橙云的路径会携带参数
      if (VIDEDOINFO.monitor_path.includes('.m3u8')) {
        return h(HlsPlayer, {
          props: {
            data: context.props.data,
            config: context.props.config,
          }
        });
      } else {
        // 全景
        return h(RtmpPlayer, {
          props: {
            data: context.props.data,
            config: context.props.config,
          }
        });
      }
    } else {
      return h('span', null, '无效的播放地址');
    }
  }
}