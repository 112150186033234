<!--
    @name: FlowRecover
    @description：FlowRecover
    @author: ZengWei
    @date: 2021-10-28 11:23
-->
<template>
  <div class="flow-recover">
    <div class="type-label">
      流程审批记录
      <span style="float: right; cursor: pointer; color: #409EFF" @click="displayFlow">
        <i class="iconfont iconliucheng" />
        {{ flowShow ? '关闭流程图' : '流程图'}}
      </span>
    </div>
    <div>
      <div v-if="flowShow" ref="topology" class="topology"></div>
    </div>
    <div class="logs-warp">
      <div class="log-item" v-for="(item,index) in flowLogs" :key="index">
        <div class="user-avatar">
          <el-avatar :size="32" shape="square">{{ username(item)[0] }}</el-avatar>
        </div>
        <div class="message-warp">
          <div class="info-warp">
            <span class="user-name">{{ username(item) }}</span>
            <span class="time">{{ getDate(item.updated_at) }}</span>
          </div>
          <div class="status-warp">
            <div class="status" :style="item.step_type == -1 ? 'color:#E4323B;background: #FFF1F1' : 'color: #24A148;background:#d9f5e0;'">
              {{ item.name }}
            </div>
          </div>
          <div v-if="item.remarks || (Array.isArray(item.appendix) && item.appendix.length)" class="primary-info-warp">
            <div class="remarks-warp">
              {{ item.remarks }}
            </div>
            <fileUpload :disabled="true" :files="item.appendix"></fileUpload>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Topology } from '@topology/core'
  import { register as registerFlow } from '@topology/flow-diagram'
  import { register as registerActivity } from '@topology/activity-diagram'
  import fileUpload from '@/components/fileUpload/newFiles/index'
  import { Avatar } from 'element-ui'
	// import { Table,TableColumn } from 'element-ui'
  let canvas
  const canvasOptions = {
    hideInput: true,
    grid: false,
    hideRotateCP: true,
    disableScale: false,
    rotateCursor: 'crosshair',
    scroll:false,
  }

  export default{
    name: 'FlowRecover',
    components: { fileUpload, 'el-avatar': Avatar },
    props: {
      logs: {
        type: Array,
        default: () => {
          return []
        },
      },
      nextStep: {
        type: String,
        default: '',
      },
      canvasData: {
        type: Object,
        default: () => {
          return {}
        },
      }
    },
    data(){
      return {
        flowLogs:null,
        flowShow: false
      }
    },
    computed:{
      username(){
        return function (row) {
          let targetUsers = row.target_users
          let nameArr = Array.from(targetUsers, (item) => {
            return item.name || item.user_name
          })
          if (nameArr.length > 0) {
            return nameArr.join(',')
          }
          return row.user_name
        }
      }
    },
    methods:{
      // 处理时间
      getDate(value) {
        if (!value) return '';
        let dateObj = null;
        if (value instanceof Date) {
          dateObj = value;
        } else if (isNaN(value)) {
          dateObj = new Date(value);
        } else {
          dateObj = new Date(value * 1000);
        }
        // @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
        if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
        const year = dateObj.getFullYear();
        // 无效的时间
        if (year === 1970) return '';
        let month = this.addZero(dateObj.getMonth() + 1);
        const day = this.addZero(dateObj.getDate());
        const hour = this.addZero(dateObj.getHours());
        const minute = this.addZero(dateObj.getMinutes());
        return `${month}月${day}日 ${hour}:${minute}`;
      },
      /**
       * @desc: 补零
       * @param {Number} val 需要补零的值
       */
      addZero(val) {
        if (isNaN(val) || val < 0 || val >= 10) return val;
        return `0${val}`;
      },
      handleView() {},
      dealWithCanvas(canvasData) {
        let allNode = canvasData.pens
        let repaint = [] //重绘数据
        let stepDone = [] //已处理的步骤
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            stepDone.push(node.id)
          }
        }
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            node.strokeStyle = '#40b67c'
            node.lineWidth = 2
          }
          if (node.name === 'polyline') {
            if (this.nextStep) {
              stepDone.push(this.nextStep)
              if (
                stepDone.indexOf(node.to.id) !== -1 &&
                stepDone.indexOf(node.from.id) !== -1
              ) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            } else {
              if (stepDone.indexOf(node.from.id) !== -1) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            }
          }
          if (node.data.target_user !== undefined) {
            let nameArr = Array.from(
              node.data.target_user,
              (item) => item.name
            )
            if (nameArr.length > 0) {
              let name = '审批人：' + nameArr.join(',')
              node.text = name
            }
          }
          repaint.push(node)
        }
        canvasData.pens = repaint
        return canvasData
      },
      displayFlow() {
        this.flowShow = !this.flowShow
        if (this.flowShow) {
          this.$nextTick(() => {
            canvas = new Topology(this.$refs.topology, canvasOptions)
            canvas.data.lineName = 'polyline'
            let recoverJson = this.dealWithCanvas(this.canvasData)
            canvas.open(recoverJson)
            canvas.centerView()
            canvas.fitView(5)
            canvas.lock(1)
            canvas.render()
          })
        } else {
          canvas.destroy()
        }
      },
    },
    created () {
      this.flowLogs = this.logs
      registerFlow()
      registerActivity()
    },
  }
</script>

<style lang="less" scoped>
  .flow-recover {
    .type-label {
      border-left: 3px solid #007fe0 !important;
      padding: 0 10px;
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 600;
    }
    .topology {
      width: 100%;
      height: 400px;
    }
    .logs-warp{
      .log-item{
        padding: 12px 12px 20px 12px;
        box-sizing: border-box;
        display: flex;
        .user-avatar{
          flex-shrink: 0;
        }
        .message-warp{
          margin-left: 12px;
          flex-grow: 1;
          height: auto;
          .info-warp{
            display: flex;
            align-items: center;
            .user-name{
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2A2F3D;
              line-height: 22px;
            }
            .time{
              margin-left: 8px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #787E8F;
              line-height: 20px;
            }
          }
          .status-warp{
            margin-top: 4px;
            .status{
              display: inline-block;
              padding: 1px 8px;
              border-radius: 10px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #31A753;
              line-height: 18px;
            }
          }
          .primary-info-warp{
            margin-top: 12px;
            padding-left: 14px;
            box-sizing: border-box;
            border-left: 2px dashed #D7DAE0;
            .remarks-warp{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #121619;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
</style>
