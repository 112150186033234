<template>
    <div class="moduleWrap">
        <template v-for="item in editList">
            <div v-if="item.type === 'objSelector'" :key="item.id" class="vertical-flex editorItem-wrap">
                <span class="editorItem-wrap_title">{{item.name}}</span>
                <el-select v-model="item.value" placeholder="请选择" size="small" class="editorItem-wrap_content">
                    <el-option v-for="obj in item.options" :key="obj.id" :value="obj.id" :label="obj.name"></el-option>
                </el-select>
            </div>

            <div v-else-if="item.type === 'input'" :key="item.id" class="vertical-flex editorItem-wrap">
                <span class="editorItem-wrap_title">{{item.name}}</span>
                <el-input v-model="item.value" placeholder="请输入" size="small" class="editorItem-wrap_content"></el-input>
            </div>

            <div v-else-if="item.type === 'iconSelectorNumber'" :key="item.id" class="vertical-flex editorItem-wrap">
                <span class="editorItem-wrap_title">{{item.name}}</span>
                <div class="horizontal-flex">
                    <el-select v-model="item.value" placeholder="请选择" size="small" class="editorItem-wrap_content">
                        <el-option v-for="obj in item.options" :key="obj.id" :value="obj.id" :label="obj.name">
                            <img :src="obj.img" class="editorItem-wrap_content_selectorImg">
                            <span class="editorItem-wrap_content_selectorSpan">{{obj.name}}</span>
                        </el-option>
                    </el-select>
                    <el-input-number v-model="item.num" :min="0" :max="128" :step="1" size="small"
                                     controls-position="right" class="editorItem-wrap_content" style="margin-left: 7px"/>
                </div>
            </div>

            <div v-else-if="item.type === 'colorPicker'" :key="item.id" class="vertical-flex editorItem-wrap">
                <span class="editorItem-wrap_title">{{item.name}}</span>
                <div class="horizontal-flex editorItem-wrap_content">
                    <el-input v-model="item.value" placeholder="请选择" size="small"></el-input>
                    <el-color-picker v-model="item.value" size="small" style="margin-left: 10px"></el-color-picker>
                </div>
            </div>

            <div v-else-if="item.type === 'radio'" :key="item.id" class="horizontal-flex editorItem-wrap" style="margin-top: 7px">
                <el-checkbox v-model="item.value" size="small" style="height: 40px; line-height: 40px;"></el-checkbox>
                <span class="editorItem-wrap_title">{{item.name}}</span>
            </div>

            <div v-else-if="item.type === 'slider'" :key="item.id" class="vertical-flex editorItem-wrap">
                <span class="editorItem-wrap_title">{{item.name}}</span>
                <div class="horizontal-flex editorItem-wrap_content">
                    <el-slider v-model="item.value" :min="item.min" :max="item.max" :step="item.step" style="width: 300%"></el-slider>
                    <el-input v-model="item.value" size="small" style="margin-left: 10px"></el-input>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { Select, option, Input, InputNumber, ColorPicker, Slider, Checkbox } from 'element-ui';

    export default {
        name: "EditorList",
        components: {
            'el-select': Select,
            'el-option': option,
            'el-input': Input,
            'el-input-number': InputNumber,
            'el-color-picker': ColorPicker,
            'el-slider': Slider,
            'el-checkbox': Checkbox
        },
        data() {
            return {

            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
            editList: {
                type: Array,
                default: () => []
            },
        }
    }
</script>

<style scoped>
    .vertical-flex{
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .horizontal-flex{
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .editorItem-wrap{
        width: 280px;
        margin-left: 10px;
    }

    .editorItem-wrap_title{
        text-align: left;
        margin-left: 5px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }

    .editorItem-wrap_content{
        display: flex;
        margin-bottom: 5px;
        /*height: 40px;*/
        /*line-height: 40px;*/
    }

    .editorItem-wrap_content_selectorImg{
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        float: left;
    }

    .editorItem-wrap_content_selectorSpan{
        float: left;
        margin-left: 15px;
        line-height: 20px;
    }
</style>
