<!--
 * @Author: Shiltin
 * @Date: 2021-10-22 11:43:57
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-06-16 11:08:24
 * @Description:
-->
<template>
    <div id="main" style="width: 100%; height: 100%">
        <div id="viewerDom" ref="viewerDom" style="width: 100%; height: 100%" />
    </div>
</template>

<script>
let viewer = null;
export default {
    name: "Preview",
    props: {
        //回显得模型格式[{model_id:'XXXX',select:[111,1513]}]
        modelData: {
            type: [Array,String],
            default: () => [],
        },
        //模型和视点model/view
        chooseType: {
            type: String,
            default: "model",
        },
				appId: {
					type: String,
					default: ""
				},
				appSecret: {
					type: String,
					default: ""
				}
    },
    data() {
        return {
            baseObk: "",
            initState: "stop", //初始化状态
            selectData: [],
            viewList: [],
        };
    },
    methods: {
        //dbids是回显的构件id
        addModel(id, info) {
            if (this.initState == "stop") {
                //加载第一个模型
                this.initModel(id, info);
            } else {
                let timer = setInterval(() => {
                    if (this.initState == "done") {
                        clearInterval(timer);
                        viewer.loadModel(id).then(() => {
                            console.log("加载完成");
                            //视图列表
                            this.getViewStateList();
                            if (info != undefined && viewer) {
                                //回显构件选中或视点
                                if (this.chooseType == "model") {
                                    viewer.select(info);
                                    this.selectData = this.selectData.concat(
                                        JSON.parse(JSON.stringify(info))
                                    );
                                } else {
                                    this.setViewState(info);
                                }
                            }
                        });
                    }
                }, 200);
            }
        },
        initModel(id, info) {
            this.initState = "loading";
            const appId = this.appId || process.env.VUE_APP_MODEL_APPID ;
            const appSecret = this.appSecret || process.env.VUE_APP_MODEL_APPSECRET ;
            viewer = new window.BIMCC.Viewer("viewerDom", {});
            viewer.init(id, { appId, appSecret });

            viewer.on("viewerLoaded", async () => {
                this.initState = "done";
                //回显构件选中或视点
                if (info != undefined) {
                    if (this.chooseType == "model") {
                        this.selectData = JSON.parse(JSON.stringify(info));
                        setTimeout(()=>{
													viewer.select(info);
												},2000)
                    } else {
                        this.setViewState(info);
                    }
                }
                //视图列表
                setTimeout(() => {
                    this.getViewStateList();
                }, 500);
            });
            viewer.on("Viewer.inited", async () => {
                viewer.hideNavbar();
            });
            viewer.on("Viewer.onSelect", async (selections) => {
								if(selections?.length){
									selections.map(item=>{
										if(item.dbids){
											item.select = item.dbids
										}
									})
								}
                this.selectData = selections;
            });
        },
        minusModel(id) {
            viewer.unloadModel(id).then(() => {
                //视图列表
                this.getViewStateList();
            });
        },
        //视图回显
        setViewState(data) {
            if (viewer != null) {
                viewer.viewViewState(data);
            }
        },
        //添加视点
        async setViews() {
            if (viewer != null && this.initState != "loading") {
                const res = await viewer.addViewState();
                if (res) {
                    this.$emit("setViewData", res);
                }
            }
        },
        async getViewStateList() {
            if (viewer != null) {
                this.viewList = await viewer.getViewStateList();
            }
        },
    },
    mounted() {
        if (Array.isArray(this.modelData) && this.modelData?.length) {
            this.modelData.forEach((item, i) => {
                if (this.chooseType == "model") {
                    if (item.dbids?.length) {
                        this.addModel(item.model_id, [{model_id:item.model_id,dbids:item.dbids}]);
                    }else if (item.select?.length) {
                        this.addModel(item.model_id, [{model_id:item.model_id,dbids:item.select}]);
                    } else {
                        this.addModel(item.model_id);
                    }
                } else {
                    //只显示第一个视图，中控台可显示多个
                    if (i == 0) {
                        this.addModel(item.model_id, {
                            id: item.model_id,
                            data: item.data,
                        });
                    }
                }
            });
        }
    },
};
</script>

