import IBehaviors from './IBehaviors';

export default class GltfBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        return [
            {
                label: '[Gltf]创建Gltf对象',
                desc: `
                    参数[ data ]。 
                    data=>通用数据对象
                    `,
                value: 'gltf_createGltf',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    for (let temp of data){
                        if (!temp.gltfAnimation || temp.gltfAnimation.length === 0) continue;

                        let modelId = temp.model_id;
                        let array = temp.gltfAnimation;
                        for (let item of array){
                            let {options} = item;
                            let position = options.position ? options.position : undefined;
                            if(options.dbids && modelId){
                                if(options.dbids.length === 2){
                                    let start = this.viewer.Model.getBoundByKeys(modelId, [options.dbids[0]]);
                                    let end = this.viewer.Model.getBoundByKeys(modelId, [options.dbids[1]]);
                                    if(start && end){
                                        position = {
                                            start: {
                                                lng: (start.west + start.east) / 2,
                                                lat: (start.south + start.north) / 2,
                                                height: (start.minHeight + start.maxHeight) / 2,
                                            },
                                            end: {
                                                lng: (end.west + end.east) / 2,
                                                lat: (end.south + end.north) / 2,
                                                height: (end.minHeight + end.maxHeight) / 2,
                                            }
                                        }
                                    }
                                }else{
                                    let bound = this.viewer.Model.getBoundByKeys(modelId, options.dbids);
                                    if(bound){
                                        position = {
                                            lng: (bound.west + bound.east) / 2,
                                            lat: (bound.south + bound.north) / 2,
                                            height: (bound.minHeight + bound.maxHeight) / 2,
                                        }
                                    }
                                }
                            }

                            this.viewer.Gltf.createGltfAnimation({
                                id: options.id,
                                url: options.url,
                                scale: options.scale,
                                position: position
                            });
                        }
                    }
                }
            },
            {
                label: '[Gltf]更新Gltf位置',
                desc: `
                    参数[ data ]。 
                    data=>通用数据对象
                    `,
                value: 'gltf_updateGltfPosition',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    for (let temp of data) {
                        if (!temp.gltfAnimation || temp.gltfAnimation.length === 0) continue;

                        let modelId = temp.model_id;
                        let array = temp.gltfAnimation;
                        for (let item of array) {
                            let {options} = item;
                            if (!options.id || !this.viewer.Gltf.collection[options.id]) continue;

                            let position = options.position ? options.position : undefined;
                            if (options.dbids && modelId) {
                                if(options.dbids.length === 2){
                                    let start = this.viewer.Model.getBoundByKeys(modelId, [options.dbids[0]]);
                                    let end = this.viewer.Model.getBoundByKeys(modelId, [options.dbids[1]]);
                                    if(start && end){
                                        position = {
                                            start: {
                                                lng: (start.west + start.east) / 2,
                                                lat: (start.south + start.north) / 2,
                                                height: (start.minHeight + start.maxHeight) / 2,
                                            },
                                            end: {
                                                lng: (end.west + end.east) / 2,
                                                lat: (end.south + end.north) / 2,
                                                height: (end.minHeight + end.maxHeight) / 2,
                                            }
                                        }
                                    }
                                }else{
                                    let bound = this.viewer.Model.getBoundByKeys(modelId, options.dbids);
                                    if(bound){
                                        position = {
                                            lng: (bound.west + bound.east) / 2,
                                            lat: (bound.south + bound.north) / 2,
                                            height: (bound.minHeight + bound.maxHeight) / 2,
                                        }
                                    }
                                }
                            }

                            let gltf = this.viewer.Gltf.collection[options.id];
                            gltf.updatePosition(position);
                        }
                    }

                }
            },
            {
                label: '[Gltf]Gltf动画开始',
                desc: `
                    参数[ data ]。 
                    data=>通用数据对象
                    `,
                value: 'gltf_startGltfAnimation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");
                    let comp = this.comp;
                    function getRangeItem(range, number){
                        for (let i = 0; i <= range.length - 1; i++){
                            if(i < range.length - 1){
                                if(number >= range[i].distance && number < range[i + 1].distance){
                                    return range[i];
                                }
                            }else if(i === range.length - 1){
                                if(number >= range[i].distance){
                                    return range[i];
                                }
                            }
                        }
                    }

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    let route = {};
                    for (let temp of data) {
                        if (!temp.gltfAnimation || temp.gltfAnimation.length === 0) continue;

                        let modelId = temp.model_id;
                        let array = temp.gltfAnimation;
                        for (let item of array) {
                            let {options} = item;
                            if (!options.id || !this.viewer.Gltf.collection[options.id]) continue;
                            if(!route[options.id]) route[options.id] = [];

                            let position = options.position ? options.position : undefined;
                            if (options.dbids && modelId) {
                                let bound = this.viewer.Model.getBoundByKeys(modelId, options.dbids);
                                if(bound){
                                    position = {
                                        lng: (bound.west + bound.east) / 2,
                                        lat: (bound.south + bound.north) / 2,
                                        height: (bound.minHeight + bound.maxHeight) / 2,
                                    }
                                }
                            }

                            route[options.id].push({
                                position,
                                date: options.date,
                                linkNumber: options.linkNumber
                            });
                        }
                    }

                    for (let key in route){
                        let line = route[key];

                        let positions = [];
                        line.map((item) => {
                            positions.push(item.position);
                            item.distance = this.viewer.Coordinate.getPolylineLength(positions);
                        });

                        let gltf = this.viewer.Gltf.collection[key];
                        gltf.startAnimation({
                            positions: positions,
                            interval: 1,
                            speed: 100,
                            isLoop: true,
                            callback: (data) => {
                                let {distance} = data;
                                let current = getRangeItem(line, distance);

                                if(current.date !== comp.element.resolveData["gis_gltfAnimation_date"]
                                    || current.linkNumber !== comp.element.resolveData["gis_gltfAnimation_linkNumber"]){
                                    comp.setResolveData("gis_gltfAnimation_date", current.date);
                                    comp.setResolveData("gis_gltfAnimation_linkNumber", current.linkNumber);
                                    comp.onAction('onGltfAnimation');
                                }
                            }
                        });
                    }
                }
            }
        ];
    }
}
