import {Addon, Graph, Shape} from '@antv/x6';
import '@antv/x6-vue-shape'
import TaskNode from "@/custom-component/task-flow/components/TaskNode";
import SwimLane from "@/custom-component/task-flow/components/SwimLane.vue";

export default class TaskGraph {

  constructor(vm, cells = []) {
    // 主要变量
    this.vm = vm;               // vue 实例
    this.$refs = vm.$refs;      // vue实例 refs
    this.graph = null;          // x6画布实例
    this.curCell = null;        // 当前选中cell
    this.isPasting = false;     // 当前是否在粘贴状态
    this.ports = {
      groups: {
        top: {
          position: 'top',
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: '#fff',
              style: {
                visibility: 'hidden',
              },
            },
          },
        },
        right: {
          position: 'right',
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: '#fff',
              style: {
                visibility: 'hidden',
              },
            },
          },
        },
        bottom: {
          position: 'bottom',
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: '#fff',
              style: {
                visibility: 'hidden',
              },
            },
          },
        },
        left: {
          position: 'left',
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: '#5F95FF',
              strokeWidth: 1,
              fill: '#fff',
              style: {
                visibility: 'hidden',
              },
            },
          },
        },
      },
      items: [
        {
          group: 'top',
        },
        {
          group: 'right',
        },
        {
          group: 'bottom',
        },
        {
          group: 'left',
        },
      ],
    }
    this.initX6(cells);
  }
  initX6(cells) {
    this.registerCustomCells();
    this.graph = new Graph(this.initOptions());
    // 拖拽实例化
    this.vm.dnd = new Addon.Dnd({
      target: this.graph,
      scaled: false,
      animation: true,
    })
    // 显示隐藏连接桩
    const showPorts = (ports,show) => {
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = show ? 'visible' : 'hidden'
      }
    }
    if(this.vm.taskMode !== 'recover') {
      this.graph.on('node:mouseenter', () => {
        const ports = this.$refs.x6Container.querySelectorAll('.x6-port-body')
        showPorts(ports, true)
      })
      this.graph.on('node:mouseleave', () => {
        const ports = this.$refs.x6Container.querySelectorAll('.x6-port-body')
        showPorts(ports, false)
      })
    }
    this.graph.on('node:selected', ({node})=>{
      this.vm.key = node.id
      this.vm.contextmenuXY.display = 'none'
      if(['start','end'].includes(node.shape)){
        this.vm.propOpen = false
        this.vm.configOpen = true
      } else {
        this.vm.propOpen = true
        this.vm.configOpen = false
        this.vm.selectedNode = node
        this.vm.currentItem = node.getData()
        this.vm.currentNodeId = node.id
        this.vm.nodeType = node.shape
      }
    })
    this.graph.on('node:contextmenu',({ e, cell })=>{
      const {left,top} = e.currentTarget.getBoundingClientRect()
      this.vm.contextmenuXY.left = e.pageX - left  + "px"
      this.vm.contextmenuXY.top = e.pageY - top  + "px"
      this.vm.contextmenuXY.display = 'block'
      this.vm.curCell = cell
    })
    this.graph.on('edge:click', ({edge})=>{
      this.vm.key = edge.id
      this.vm.propOpen = true
      this.vm.configOpen = false
      this.vm.selectedNode = edge
      this.vm.currentItem = edge.getData()
      this.vm.currentNodeId = edge.id
      this.vm.nodeType = edge.shape
      this.vm.contextmenuXY.display = 'none'
    })
    this.graph.on('blank:click', ()=>{
      this.vm.propOpen = false
      this.vm.configOpen = false
      this.vm.contextmenuXY.display = 'none'
    })
    //delete
    this.graph.bindKey('backspace', () => {
      const cells = this.graph.getSelectedCells()
      if (cells.length) {
        this.graph.removeCells(cells)
      }
    })
    this.graph.bindKey('delete', () => {
      const cells = this.graph.getSelectedCells()
      if (cells.length) {
        this.graph.removeCells(cells)
      }
    })

    if (cells.length > 1) {
      this.graph.fromJSON(cells);
      this.graph.centerContent();
    }
    return this.graph;
  }
  registerCustomCells () {
    Graph.registerNode('start', {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#7E8595',
            fill: '#FFF',
          },
          label: {
            text: '开始',
            fontSize: 16,
            fill: '#7E8595',
          },
        },
        ports: { ...this.ports },
      }, true);
    Graph.registerNode('end', {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 4,
            stroke: '#7E8595',
            fill: '#FFF',
          },
          label: {
            text: '结束',
            fontSize: 16,
            fill: '#7E8595',
          },
        },
        ports: { ...this.ports },
      }, true);
    Graph.registerNode('gateway', {
        inherit: 'polygon',
        attrs: {
          body: {
            refPoints: '0,10 10,0 20,10 10,20',
            strokeWidth: 2,
            stroke: '#7E8595',
            fill: '#EFF4FF',
          },
          label: {
            text: '+',
            fontSize: 50,
            fill: '#7E8595',
          },
        },
        ports: { ...this.ports },
      }, true);
    Graph.registerNode("task", {
      inherit: "vue-shape",
      component: {
        template: `<task-node />`,
        components: {
          'task-node': TaskNode,
        },
      },
      ports: { ...this.ports },
    },true);
    Graph.registerNode("swim", {
      inherit: "vue-shape",
      component: {
        template: `<swim-lane />`,
        components: {
          'swim-lane': SwimLane,
        },
      },
      ports: { ...this.ports },
    },true);
  }
  initOptions() {
    if(this.vm.taskMode === 'recover') {
      return this.recoverOptions()
    } else {
      return this.designerOptions()
    }
  }

  designerOptions () {
    return {
      container: this.$refs.x6Container,
      autoResize: true,
      panning: {
        enabled: true,
      },
      resizing: true,
      snapline: true,
      selecting: {
        enabled: true,
        rubberband: true,
        modifiers: 'ctrl',
        showNodeSelectionBox: true,
        showEdgeSelectionBox: true,
      },
      clipboard: {
        enabled: true,
      },
      keyboard: {
        enabled: true,
      },
      mousewheel: {
        enabled: true,
        factor: 1.1,
      },
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 10,
          },
        },
        connector: {
          name: 'rounded',
          args: {
            radius: 10,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        sourceConnectionPoint: 'anchor',
        targetConnectionPoint: 'anchor',
        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
            data: {},
            zIndex: 0,
          })
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet
        },
      },
    }
  }
  recoverOptions (){
    return {
      container: this.$refs.x6Container,
      panning: {
        enabled: true,
      },
      mousewheel: {
        enabled: true,
        zoomAtMousePosition: true,
        minScale: 0.01,
        maxScale: 3,
      },
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 10,
          },
        },
        connector: {
          name: 'rounded',
          args: {
            radius: 10,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
      },
      keyboard: true,
    }
  }
}
