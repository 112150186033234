<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-08 17:39:00
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-14 16:08:32
 * @FilePath: \dataview-next\src\custom-component\video\player\Control.vue
 * @Description: 视频组件操作
-->
<template>
  <div class="control-wrap">
    <div class="left">
      <span class="action-item" v-if="playing" @click.stop="stop">
        <i class="iconfont iconkaishi" ></i>
      </span>
      <span class="action-item" v-if="!playing" @click.stop="play">
        <i class="iconfont iconzanting"></i>
      </span>
    </div>
    <div class="right">
      <template v-if="!isPlayBack">
        <span v-if="intelligibility === 0" class="action-item" @click.stop="changeIntelligibility">
          <i class="iconfont icongaoqing"></i>
        </span>
        <span v-else class="action-item" @click.stop="changeIntelligibility">
          <i class="iconfont iconbiaoqing"></i>
        </span>
      </template>
      <span v-if="isPlayBack" class="action-item" @click.stop="changeToLive">
        <i class="iconfont iconchexiao2" />
      </span>
      <span v-if="!isPlayBack" class="action-item" @click.stop="changeVisable">
        <i class="iconfont iconhuifang"></i>
      </span>
      <span v-if="isPlayBack" class="action-item" @click.stop="changeVisable">
        <i class="iconfont iconshijian1"></i>
      </span>
      <span v-if="data.yun_tai_status && !isPlayBack" class="action-item" @click.stop="doShowPTZ">
        <i class="iconfont iconyuntai1" />
      </span>
      <span v-if="!isPlayBack" class="action-item" @click.stop="capturePicture">
        <i class="iconfont iconxiangjifill"></i>
      </span>
      <!-- 现在录像存在问题 -->
      <!-- <span class="action-item" @click.stop="record">
        <i class="iconfont iconluping" :style="isRecording ? 'color: #409eff' : ''"></i>
      </span> -->
      <span class="action-item" @click.stop="fullScreen">
        <i v-if="isfull" class="iconfont icondaping-feiquanping"></i>
        <i v-else class="iconfont icondaping-quanping"></i>
      </span>
    </div>
    <el-dialog
      title="选择回放时间"
      top="10px"
      width="440px"
      :visible.sync="visible"
      :modal="false"
    >
      <el-form
        class="play-back-form"
        v-if="visible"
        :model="formData"
        :rules="rules"
        @submit.native.prevent
        ref="formRef"
      >
        <el-form-item label="日期范围" prop="dateRange">
          <el-date-picker
            :append-to-body="!isfull"
            v-model="formData.dateRange"
            :picker-options="pickerOptions"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="回放类型" prop="type">
          <!-- 存在层级和定位问题 -->
          <!-- <el-select v-model="formData.type" placeholder="请选择回放类型">
            <el-option label="本地" value="local"></el-option>
            <el-option label="云存储" value="cloud"></el-option>
          </el-select> -->
          <el-radio v-model="formData.type" label="local">本地</el-radio>
          <el-radio v-model="formData.type" label="cloud">云存储</el-radio>
        </el-form-item>
        <div class="action-row" @click.stop="doCancle">
          <el-button>
            取消
          </el-button>
          <el-button type="primary" @click.stop="doSumbit">
            确认
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default{
  props: {
    // 视频信息
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
    // 是否正在播放
    playing: {
      type: Boolean,
      default: false
    },
    // 是否正在录像
    isRecording: {
      type: Boolean,
      default: false
    },
    // 是否全屏
    isfull: {
      type: Boolean,
      default: false
    },
    // 清晰度
    intelligibility: {
      type: Number,
      default: 0
    },
    dateRange: {
      type: [Array, null],
      default: null
    }
  },
  data() {
    return {
      visible: false,
      showPTZ: false, // 展示云台控制状态
      formData: {
        dateRange: null,
        type: 'local'
      },
      rules: {
        dateRange: [
          { required: true, message: '请输入日期范围', trigger: 'blur', type: 'array' }
        ],
        type: [
          { required: true, message: '请输入日期范围', trigger: 'blur'}
        ]
      },
      pickerOptions: {
        disabledDate(date) {
          const now = new Date();
          if(now < date) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  },
  computed: {
    isPlayBack() {
      return Array.isArray(this.formData.dateRange)
    }
  },
  methods: {
    changeVisable() {
      this.visible = true;
    },
    changeToLive() {
      this.formData.dateRange = null;
      this.$emit('playbackRange', null);
    },
    stop() {
      this.$emit('stop');
    },
    play() {
      this.$emit('play');
    },
    // 触发截图
    capturePicture() {
      this.$emit('capturePicture');
    },
    // 触发录屏
    record() {
      this.$emit('record');
    },
    // 全屏事件
    fullScreen() {
      this.$emit('fullScreen');
    },
    doShowPTZ() {
      this.showPTZ = !this.showPTZ
      this.$emit('ptzvisibleChange', this.showPTZ)
    },
    /**
     * @description: 改变清晰度
     * @return {*}
     */    
    changeIntelligibility() {
      this.$emit('changeIntelligibility', !this.intelligibility)
    },
    /**
     * @description: 提交回放
     * @return {*}
     */    
    doSumbit() {
      if(this.$refs && this.$refs.formRef) {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            this.$emit('playbackRange', this.formData.dateRange)
            this.$emit('playbackType', this.formData.type)
            this.doCancle()
          }
        })
      }
    },
    /**
     * @description: 取消时间段弹窗
     * @return {*}
     */    
    doCancle() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.control-wrap{
  height: 40px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.9));
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .action-item{
    color: #fff;
    font-size: 18px;
    .iconfont{
      font-size: 24px;
    }
    &:hover{
      cursor: pointer;
      color: #4093ff;
    }
  }
  .right{
    .action-item{
      & + .action-item {
        margin-left: 16px;
      }
    }
  }
  :deep(.el-dialog__body) {
    padding: 0px;
  }
  .play-back-form{
    padding: 4px 12px;
    .action-row{
    display: flex;
    justify-content: flex-end;
  }
  }
}
</style>
