<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-09-14 18:03:58
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-08-21 18:50:11
 * @FilePath: \dataview-next\src\console-components\consoleSignCont\ConsoleSignCont.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<!--
 * @Description: 标记容器
 * @Author: Shiltin
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-08-18 09:18:07
-->
<template>
	<div id="signCont" class="console-sign-cont">
		<div
			v-for="(item, index) in signLists"
			:key="item.id"
			:class="isSignScale && cameraHeight > gisMinHeight?'sign-item-scale':'sign-item'"
			@click="clickSign(item)"
			:style="{left:item.style.left + 'px',top:item.style.top + 'px',width:item.style.width + 'px',height:item.style.height + 'px'}"
			v-show = "item.statusConfig && item.statusConfig.isShow && (showAreaLevel? viewHeight < 1.5:true)" 
		>
			<CommonSignContainer
				:index="index"
				:element="item"
				class="sign-container"
				:id="'component' + item.id"
				:propValue="item.propValue"
				:scaleHeight="+scaleHeight"
				:scaleWidth="+scaleWidth"
				:ratio="+ratio"
				:isPreview="true"
				@click-box="clickBox"
			/>
		</div>
		<!-- 聚合 -->
		<div 
			class="aggregation-item"  
			@click="flyTo(item.id,item)"
			v-show="item.position.x > 0 && item.position.y > 0 && viewHeight < 40 " 
			v-for="(item, index) in aggregationData"
			:key="index"
			:style="{top:item.position.y + 'px', left:item.position.x + 'px'}"
			>
			{{ item.num }} 
			<p v-if="showAreaLevel" v-show="item.areaArr?.length === 2" v-html="viewHeight >= 9 ? item.areaArr[0] : item.areaArr[1]" />
		</div>	
	</div>
</template>
<script>
import { judgingArchiAPermission, initParams, getComponentById } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import CommonSignContainer  from '@/custom-component/container/CommonSignContainer.vue'
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';

export default {
name: 'ConsoleSignCont',
components: {
    CommonSignContainer,
},
props: { 
	element: {
		type: Object,
		required: true,
		default: () => {}
	},
	// 是否为预览
	isPreview: {
		type: Boolean,
		required: false,
		default: true
	},
	componentList: {
		default: null
	}
},
watch: {
	isGis: {
		handler(newVal,oldVal){
			if(newVal !== oldVal){
				this.onHideGisSigns(newVal)
			}
		},
		deep: true
	},
	isBim:{
		handler(newVal,oldVal){
			if(newVal !== oldVal){
				this.onHideBimSigns(newVal)
			}
		},
		deep: true
	},
	signLists:{
		handler(newVal){
			if(this.signListsLen === 0){
				this.$store.commit('setSignComponentData',newVal)
				this.signListsLen = 1
			}
		},
		deep:true,
	}
},
inject: ['EDITOR_pageUUID'],
data() {
	return {
		allSignStyle:[],//标记样式合集
		bimComponentId:'',
		gisComponentId:'',
		isAccumulate: true,//标记连续显示
		gisInit:false,
		timer:null,//防止触发多次
		loadedSignsType:null,
		timeOuter: null, //计时器
		firstLevel:1,
		timerIdArr:[],//计时器的id
		signLists:[],
		scaleHeight: 1,
		scaleWidth: 1,
		ratio: 1,
		isNoPositionInfo:false,//没有返回位置信息
		signListsLen: 0,
		aggregationData:[],//聚合的数据
		plotIdArr:[],//绘制面id
		viewHeight:9,// 地图初始的层级
		gisMinHeight:2000, //gis视角最小高度
		cameraHeight:9000000, //相机高度
	}
},
computed: {
	...mapState(['componentData','subsidiaryComponentData','_PageCustomStatus']),
	// 嵌入页面的参数获取
	subComponentData() {
		if (this.EDITOR_pageUUID) {
			return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
		}
		return this.componentList || this.componentData || [];
	},
	//判断标记容器是bim还是gis
	signType () {
		return this.element.signType || this.element.statusConfig.signType;
	},
	isBim () {
		let bimComponent = this.findComponentWithField(this.componentData, 'ViewerBIM')
		if(bimComponent?.statusConfig?.displayShow && bimComponent?.statusConfig?.isShow){
			return true
		}else{
			return false
		}
	},
	//是否是gis页面显示
	isGis () {
		let gisComponent = this.findComponentWithField(this.componentData, 'ViewerGIS')
		if(gisComponent?.statusConfig?.displayShow && gisComponent?.statusConfig?.isShow){
			return true
		} else {
			return false
		}
	},
	//是否显示行政区域
	showAreaLevel () {
		return this.element.statusConfig.areaLevel || false
	},
	//点击标记陆续缩放到最小视图高度才能展开标记
	isSignScale () {
		return this.element.statusConfig.isSignScale || false
	}
},
created(){
	if(this.element.children?.length){
		this.allSignStyle = [...this.element.children]
  } else {
		//兼容之前数据
		this.getSignList()
	}
	/**
 * @description: 解决标记阻挡鼠标mousewheel事件，pointerEvents：none
 * @param {*} event
 * @return {*}
 */		
	document.onmousewheel = () => {
		if (this.timerIdArr.length) {
			for(let i=0;i<this.timerIdArr.length;i++) {
				clearTimeout(this.timerIdArr[i])
			}
			this.timerIdArr = []
		}
		if (this.signLists?.length) {
			let arr = []
			this.signLists.forEach((item) => {
				if (item.id && (item.id.includes('-view')|| item.id.includes('-page'))) {
					const aElements = document.getElementsByClassName('sign-item')
					for(let i=0;i<aElements.length;i++){
						for(let j = 0;j<aElements[i].children.length;j++){
							aElements[i].children[j].style.pointerEvents = 'none';
							arr.push(aElements[i].children[j])
						}
					}
				}
			})
			this.timeOuter = setTimeout(()=>{
				if(arr.length){
					arr.forEach((val)=>{
						val.style.pointerEvents = 'auto';
					})
				}
			},3000)
			this.timerIdArr.push(this.timeOuter)
		}
	}
},
mounted() {
	//监听点击标记暴露的值
	eventBus.$on('signResolve',(data)=>{
		this.$store.commit('modifyComponent', {
			component: {
				...this.element,
				resolveData: data.resolveData
			},
			containerId: null,
			isModify: true
		});
	})
	//把容器pointEvent设置为none
	const aElements=document.getElementsByTagName('div');
	for(let i=0;i<aElements.length;i++){
		if(aElements[i].getAttribute('data-id') === this.element.id){
			aElements[i].style.pointerEvents = 'none';
			break;
		}
	}
	//获取模型构件id以及模型style
	let bimComponent = this.findComponentWithField(this.componentData,'ViewerBIM');
	if(bimComponent){
		this.bimComponentId = bimComponent.id;
	}
	let gisComponent = this.findComponentWithField(this.componentData,'ViewerGIS');
	if(gisComponent){
		this.gisComponentId = gisComponent.id;
	}
	//监听行为
	const doComponentBehavior = {
		[this.element.id]: (config) => {
			//区分gis和bim行为
			if(this.signType && this.signType === 'bim'){
				if(!this.isBim){
					return false
				}
			} else 	if(this.signType && this.signType === 'gis'){
				if(!this.isGis){
					return false
				}
			}
			const { component, list = [] } = config;
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName) || list.length === 0) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				//获取携带的参数id
				const { param = {}, canPost } = initParams(params, false, this.subComponentData, []);
				//获取标记的signConfig
				let signContent = null;
				let type = '';
				if(params?.length){
					for(let i = 0; i < params.length; i++) {
						if(params[i].componentId){
							const paramComponent = getComponentById(this.componentData, params[i].componentId);
							if(paramComponent?.signConfig?.show && paramComponent?.signConfig?.pageUuid){
								if(paramComponent.signConfig?.pageUuid){
									//将标记page作为判断类别的依据
									type = paramComponent.signConfig.pageUuid
								} else if(paramComponent.database?.viewData!==null){
									//将视图作为判断类别的依据
									type = paramComponent.database.viewData.uuid
								}else if(paramComponent.contentConfig?.viewUUID){
									type = paramComponent.contentConfig.viewUUID
								}
								const findData = this.allSignStyle.filter((v)=>v.pageUUid === paramComponent.signConfig?.pageUuid) || []
								if(findData?.length){
									signContent = findData
								}
								break;
							} else if(params[i].key === 'sign_uuid' && params[i].paramKey){
								//标记取值来之组件列表，显示多个不同类别的标记
								if(paramComponent?.database?.objectData?.uuid && paramComponent.database?.viewData?.uuid){
									dataInterface({
										object_uuid: paramComponent.database?.objectData?.uuid,
										page: 1,
										size: 1000,
										view_uuid: paramComponent.database?.viewData?.uuid,
										__method_name__: "dataList",
									}).then((res) => {
										if(res?.data?.code === 200 && res.data.data?.data?.length){
											const list = res.data.data.data
											const signField = params[i].paramKey
											for(var j = 0; j < list.length; j++) {
												if (list[j][signField] && typeof list[j][signField] === "string" && list[j][signField].includes('page')) {
														const findData = this.allSignStyle.filter((v)=>v.pageUUid === list[j][signField] ) || []
														if(findData?.length){
															signContent = findData
															param.sign_uuid = list[j][signField]
															if (canPost) {
																// 调用行为方法
																behaviors.forEach(funName => {
																	try {
																		eval(this[funName])(param,signContent,list[j][signField],true)
																	} catch (err) {
																		console.log(err);
																	}
																});
															}
														}
												}
											}
										}
									})
								}
								return
							}
						} else if(params[i].sourceType === 'fixed' && params[i].key === 'sign_uuid'){
							const findData = this.allSignStyle.filter((v)=>v.pageUUid === params[i].fixedValue) || []
							if(findData?.length){
								signContent = findData
							}
							type = param.sign_uuid
						}else if(params[i].key === 'view_uuid'){
							type = params[i].fixedValue
						}
						
					}
				}
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							console.log(funName,'funName',this.allSignStyle)
							this.signListsLen = 0
							eval(this[funName])(param,signContent,type)
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		}
	}
	eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id]);
	/**
	 * @description: 监听坐标
	 * @param {*} 
	 * @return {*}
	 */		
	if(this.signType && this.signType === 'bim'){
		eventBus.$on('Viewer.onCameraChange', (componentId, res) => {
			if (!res) {
				return
			}
			Object.keys(res).forEach((key) => {
				const findData = this.signLists.filter( (v) => v.id.includes(key));
				//zoom值计算,只计算第一个，减少计算量
				// if(index === 0){
				// 	let dis = position.x*position.x + position.y*position.y + position.z*position.z;
				// 	if(this.firstLevel === 1 && dis){
				// 		this.firstLevel = dis;
				// 	}else{
				// 		let a = this.firstLevel/dis;
				// 		if (a < 1) {
				// 			if (a.toFixed(1) > 0) {
				// 				this.ratio = a.toFixed(1);
				// 			} else {
				// 				this.ratio = 0.001
				// 			}
				// 		} else {
				// 			this.ratio = 1;
				// 		}
				// 	}
				// }
				if (findData?.length) {
					findData[0].style.zoom = this.ratio;
					findData[0].style.left = res[key].point.x - (findData[0].style.width * this.ratio) / 2;
					findData[0].style.top = res[key].point.y -  findData[0].style.height * this.ratio;
				}
			})
		})
	}
	if(this.signType && this.signType === 'gis'){
		eventBus.$emit("ViewerGIS.onViewerInited",this.gisComponentId);
		eventBus.$on("ViewerGIS.onViewerInited",()=>{
			console.log('模型加载完毕')
			this.gisInit = true
		});
		//注册监听相机视角变化事件
		eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
		eventBus.$on('ViewerGIS.onCameraChange', () =>{
			//获取视图高度
				eventBus.$emit('ViewerGIS.getCameraView', this.gisComponentId, (res) => {
					if(res){
						if(this.cameraHeight !== res.height){
							this.viewHeight = Number(res.height / 1000000).toFixed(1)
							this.cameraHeight = res.height
						}
					}
				})
				//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
				this.resetAggregationData()
		});
	}
},
methods: {
	judgingArchiAPermission,
/**
 * @description: 刷新聚合数据
 * @return {*}
 */		
	resetAggregationData(){
		this.aggregationData = []
		let newPoint = []
		if(this.showAreaLevel){
			//显示行政级别
			if(this.signLists?.length){
				this.signLists.forEach((val,i)=>{
					if(this.viewHeight < 1.5){
						if(val.gisPosition){
							eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
								if(position){
									val.style.left = position.x - (Number(val.style.width) / 2);
									val.style.top = position.y - (Number(val.style.height ));
								}
							})
						}		
					} else {
						let areaArr = []
						if(val.positionName){
							if(val.positionName.includes('/')){
								let positionNameArr = val.positionName.split('/')
								if(positionNameArr[0] && positionNameArr[0].includes('省') || positionNameArr[0].includes('市') || positionNameArr[0].includes('自治区') || positionNameArr[0].includes('行政区')){
									areaArr.push('中国')
									areaArr.push(positionNameArr[0])
								}
							} else if(val.positionName.includes('|')){
								areaArr = val.positionName.split('|')
							}
						}
						if(val.gisPosition){
							eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
								if(position){
									if(areaArr.length){
										let areaIndex = 0
										if(this.viewHeight < 9){
											areaIndex = 1
										}
										if(newPoint.length === 0){
											this.signLists[i].statusConfig.isShow = true
											newPoint.push({id:val.id,position:position,num:1,areaArr:areaArr,areaLevel:areaArr[areaIndex],latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
										} else {
											const findIndexNum = newPoint.findIndex(v=>v.areaLevel  === areaArr[areaIndex])
											if(findIndexNum === -1){
												this.signLists[i].statusConfig.isShow = true
												newPoint.push({id:val.id,position:position,num:1,areaArr:areaArr, areaLevel:areaArr[areaIndex],latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
											} else {
												newPoint[findIndexNum].num++
												newPoint[findIndexNum].latArr.push(Number(val.gisPosition.lat))
												newPoint[findIndexNum].lngArr.push(Number(val.gisPosition.lng))
											}
										}
										val.style.left = position.x - (Number(val.style.width) / 2);
										val.style.top = position.y - (Number(val.style.height ));
									}
								}
							});
						}
						this.aggregationData = [...newPoint]	
					}
				})	
			}
		} else {
			//不显示行政区域
			if(this.signLists?.length){
				this.signLists.forEach((val,i)=>{
					if(val.gisPosition){
						eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
								if(position){
									if(newPoint.length === 0){
										this.signLists[i].statusConfig.isShow = true
										newPoint.push({id:val.id,position:position,num:1,latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
									} else {
										const findIndexNum = newPoint.findIndex(v=>Math.abs(v.position.x - position.x) < 40 && Math.abs(v.position.y - position.y) < 40)
										if(findIndexNum === -1){
											this.signLists[i].statusConfig.isShow = true
											newPoint.push({id:val.id,position:position,num:1,latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
										} else {
											newPoint[findIndexNum].num++
											newPoint[findIndexNum].latArr.push(Number(val.gisPosition.lat))
											newPoint[findIndexNum].lngArr.push(Number(val.gisPosition.lng))
											this.signLists[i].statusConfig.isShow = false
											const index = this.signLists.findIndex(v=>v.id === newPoint[findIndexNum].id)
											this.signLists[index].statusConfig.isShow = false
											this.aggregationData.push(newPoint[findIndexNum])
										}
									}
									val.style.left = position.x - (Number(val.style.width) / 2);
									val.style.top = position.y - (Number(val.style.height ));
								}
						});
					}
				})
				this.aggregationData = Array.from(new Set(this.aggregationData))
			}
		}
	},
/**
 * @description: 点击标记
 * @param {*} box
 * @param {*} ele
 * @return {*}
 */		
  clickBox(box){
		console.log(box,'box')
		if (box?.resolveData && box?.resolveData!==null) {
			//resolveData参数传入
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: box.resolveData
				},
				containerId: null,
				isModify: true
			});
    }
		//ele第二个传入参数
		if(this.signLists.length){
			for(let i = 0; i < this.signLists.length;i++) {
				if(this.signLists[i].id === box.id){
					//回显区域
					if(this.signLists[i].signContInfo?.coverData || Array.isArray(this.signLists[i].signContInfo)){
						eventBus.$emit('ViewerGIS.removeAllPlot', this.gisComponentId);
						this.createPlot(this.signLists[i].signContInfo, this.signLists[i].id)
					}
					if (this.element?.actionConfig?.signClick?.eventList?.length) {     
						const eventList = this.element.actionConfig.signClick.eventList;
						if (eventList && eventList.length) {
							eventList.forEach((el) => {
								el.eventList.forEach(ele => {
									if (ele.key === 'click') {
										ele.effects.forEach((effect) => {
											this.$store.commit('triggerEvents', {
												config: {
													...ele,
												...effect
												},
												element: this.element
											});
										});
									}
								})
							});
						}
					}
				}
			}
		}
	},
 /**
  * @description: 特殊情况下的点击标记
  * @param {*} item
  * @return {*}
  */	
	clickSign(item){
		let height = this.gisMinHeight
		if(this.cameraHeight > 150000){
			height = 150000
		} else if (this.cameraHeight  > this.gisMinHeight){
			height = this.cameraHeight / 10
		}
		eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, item.gisPosition, height, 45, 2);
	},
	/**
	 * @description: 定位到
	 * @return {*}
	 */		
	flyTo(id,data){
		if(this.isGis){
			if(data){
				//定位到多坐标的范围
				if(data.latArr?.length && data.lngArr?.length){
					eventBus.$emit('ViewerGIS.flyToBound', this.gisComponentId, {
						west:Math.min(...data.lngArr),
						east:Math.max(...data.lngArr),
						south:Math.min(...data.latArr),
						north:Math.max(...data.latArr)
					}, 90, 2);
				}
			} else {
				const filterData = this.signLists.filter(v=> v.id === id)
				if(filterData?.length){
					eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, filterData[0].gisPosition, 4000, 45, 2);
				}
			}
			
		}
	},
	/**
	 * @desc: 根据字段查找组件
	 */
	findComponentWithField(componentList, field){
		for (let i = 0, len = componentList.length; i < len; i++) {
			const item = componentList[i];
			if (item.component === field) {
				return item;
			}
			if (item.children && Array.isArray(item.children) && item.children.length) {
				const result = this.findComponentWithField(item.children, field) || null;
				if (result) {
					return result;
				}
			}
		}
		return null;
	},
	/**
	 * @desc: 请求获取标记绑定的模板
	 */
	getSignList(){
		dataInterface({
			object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
			page: 1,
			search: [
				// {field_uuid: "field61bc41f645e17", ruleType: "like", value: "console"},
				{field_uuid: "d1f7e149-2af7-468e-85be-5ef9bd97f634", ruleType: "like", value: "样式"}
			],
			size: 1000,
			transcode: 0,
			view_uuid: "view6311790375749",
			__method_name__: "dataList",
		}).then((res) => {
			if(res.data.code === 200){
				let data = res.data.data.data
				if(data?.length){
					for(let i = 0; i < data.length; i++){
						let item = data[i].page_data.componentData[0]
						item.name = data[i].name
						item.pageUUid = data[i].uuid
						this.allSignStyle.push(item)
					}
				}
			}
		})
	},
	/**
	 * @desc: 清除所有标记
	 */
	hideAll(){
		this.$nextTick(()=>{
			this.signLists = [];
			this.loadedSignsType = null
			this.$store.commit('setSignComponentData',[])
			if(this.signType === 'gis'){
				this.resetAggregationData()
			}
		})
	},
	/**
	 * @desc: 获取id集合
	 */
	getAllIds (data = [], arr){
		if(data?.length){
			for(let i=0;i<data.length;i++){
				if(data[i]?.id){
					arr.push(data[i].id)
				}
				if(data[i]?.children){
					this.getAllIds(data[i].children, arr)
				}
			}
		}
		return arr
	},
	/**
	 * @desc: 格式化数据，解决data-id重复得问题
	 */
	formatContent(arr, data, code){
		if(arr?.length){
			for(let j = 0; j < arr.length; j++){
				if(data.length){
					for(let i = 0; i < data.length; i++){
						arr[j] = JSON.parse(JSON.stringify(arr[j]).replaceAll(data[i],data[i] + '-' + code))
					}
				}
			}
		}
		return arr
	},
	/**
	 * @desc: 显示单个标记
	 */
	showSign(param,content,type){
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			let data = JSON.parse(JSON.stringify(content))
			if((param?.data_id  && content && type && ((param.model_info?.length && this.bimComponentId) || (this.isGis && this.gisComponentId && param.gis_info)))) {
				//格式化数据
				let code = `${type}-${param.data_id}`
				let findData = this.signLists.filter(v => v.id.includes(code))
				//已渲染该标记
				if(findData.length){
					for (let i = 0; i < this.signLists.length; i++){
						if(this.signLists[i].id.includes(code)){
							this.signLists[i].statusConfig.displayShow = true
							this.signLists[i].statusConfig.isShow = true
							if(this.signType && this.signType === 'gis'){
								let gisPosition = null
								if(param.gis_info.position){
									gisPosition = param.gis_info.position
								} else if(param.gis_info.latitude && param.gis_info.longitude){
									gisPosition = {lat:param.gis_info.latitude,lng:param.gis_info.longitude}
								} else if(Array.isArray(param.gis_info) && param.gis_info.length){
									if(param.gis_info[0].location_gis){
										//新统一的绑定数据格式
										gisPosition = {lat:param.gis_info[0].location_gis.lat,lng:param.gis_info[0].location_gis.lng}
									}
								}
								eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
							}
						}
					}
				} else {
					let newContent = null
					let idArr = []
					let flyTo = false
					this.getAllIds(JSON.parse(JSON.stringify(content)), idArr)
					let formatContent = this.formatContent(JSON.parse(JSON.stringify(content)), idArr, code)
					//标记样式元素不是在一个容器中需要手动添加个容器
					if(data.length !== 1){
						this.$message.warning('标记模板请用大屏容器套入！')
						return
					} else {
						newContent = formatContent[0]
					}
					if(this.signType && this.signType === 'bim'){
						//bim构件转换世界坐标
						eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, param.model_info[0].dbids[0],param.model_info[0].model_id, async(res)=>{
							let point = await res
							//传值给黄旭凯监听模型的坐标
							eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+param.data_id ,point.center, async(re)=>{
								let position = await re
								newContent.style.left = position.x - (Number(newContent.style.width) / 2);
								newContent.style.top = position.y - (Number(newContent.style.height ));
								newContent.signType = 'bim'
								newContent.signPageUuid = type
								this.signLists.push(newContent)
							})
						})
					}
					if(this.signType && this.signType === 'gis'){
						let gisPosition = null
						let positionName = ''
						let models = []
						if(param.gis_info.lat && param.gis_info.lng){
							gisPosition = param.gis_info
            }else if(param.gis_info.position){
							gisPosition = param.gis_info.position
						} else if(param.gis_info.latitude && param.gis_info.longitude){
							gisPosition = {lat:param.gis_info.latitude,lng:param.gis_info.longitude}
						} else if(param.gis_info.data?.position){
							this.$set(newContent,'signContInfo',param.gis_info.data)
							gisPosition = param.gis_info.data.position
						} else if(Array.isArray(param.gis_info) && param.gis_info.length){
							if(param.gis_info[0].location_gis){
								//新统一的绑定数据格式
								this.$set(newContent,'signContInfo',param.gis_info)
								gisPosition = param.gis_info[0].location_gis
								positionName = param.gis_info[0].location_name
							}
						}
						if(!flyTo && gisPosition!==null){
							//防止ViewerGIS.getCanvasByWgs方法不返回数据
							eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 20000, 45);
							flyTo = true
						}
						if(param.gis_info.models){
							models = param.gis_info.models
						} else if(param.gis_info.data?.models){
							models = param.gis_info.data.models
						} else if(Array.isArray(param.gis_info) && param.gis_info.length){
							//新统一的绑定数据格式
							param.gis_info.forEach((v)=>{
								if(v.model_id && v.select?.length ){
									models.push({model_id:v.model_id,dbids:v.select})
								}
							})
						}
						//选中gis中的构件
						if(models?.length){
							for(let i = 0; i < models.length;i++){
								const item = models[i]
								eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
							}
						}
						//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
						eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
								if(position){
									newContent.style.left = position.x - (Number(newContent.style.width) / 2);
									newContent.style.top = position.y - (Number(newContent.style.height ));
									this.$set(newContent,'gisPosition',gisPosition)
									this.$set(newContent,'positionName',positionName)
									newContent.signType = 'gis'
									newContent.signPageUuid = type
									if(!this.isAccumulate){
										eventBus.$emit("ViewerBIM.Part.ClearAllColor",this.bimComponentId)
										//清除其他的数据方法
										this.hideOtherSign({type:code})
									}
									let findSameComponent = this.signLists.filter(v => v.id.includes(code))
									if(findSameComponent.length === 0){
										this.signLists.push(newContent)
										eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
										eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
									}
									this.isNoPositionInfo = false
								} else {
									if(!this.isNoPositionInfo){
										this.isNoPositionInfo = true
										eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
										setTimeout(()=>{
											this.showSign(param,content,type)
										},2000)
									}
								}
						});
					}
					
				}
			}
		}, 200);
	},
	/**
	 * @desc: 隐藏其他的标记组件
	 */
	hideOtherSign(data){
		if(this.signLists?.length){
			for(var i=0; i <this.signLists.length; i++){
				if (!this.signLists[i].id.includes(data.type)) {
					// this.signLists[i].statusConfig.isShow = false
					// this.signLists[i].statusConfig.displayShow = false
					this.signLists.splice(i, 1);
					if(this.signLists[i].signPageUuid){
						this.loadedSignsType[this.signLists[i].signPageUuid] = false
					}
				}
			}
			if(this.signType === 'gis'){
				this.resetAggregationData()
			}
		}
	},
	//移除标记
	hideSign(param,content,type){
		if(param?.data_id && type){
			let code =`${type}-${param.data_id}`
			for(var i=0; i <this.signLists.length; i++){
				if (this.signLists[i].id.includes(code)) {
					this.signLists.splice(i, 1);
					i-- 
				}
			}
			if(this.signType === 'gis'){
				this.resetAggregationData()
			}
		}
	},
	/**
	 * @desc: 标记连续显示
	 */
	onAccumulate(){
		this.isAccumulate = !this.isAccumulate;
	},
	/**
	 * @desc: 缓存隐藏gis标记
	 */
	onHideGisSigns(bol = false){
		if(this.signLists?.length){
			this.signLists.forEach((val,i)=>{
				if(val.gisPosition){
					this.signLists[i].statusConfig.isShow = bol
				}
			})
			if(this.signType === 'gis'){
				this.resetAggregationData()
			}
		}
	},
	/**
	 * @desc: 缓存隐藏bim标记
	 */
	onHideBimSigns(bol = false){
		if(this.signLists?.length){
			this.signLists.forEach((val,i)=>{
				if(val.statusConfig?.signType === 'bim'){
					this.signLists[i].statusConfig.isShow = bol
				} else {
					this.resetAggregationData()
				}
			})
		}
	},
	/**
	 * @desc: 显示所有列表标记
	 */
	onShowTypeAllSigns(signParam,content,type, bol=false){
		if(this.signType && this.signType === 'gis'){
			if (!this.gisInit) {
				this.$message.warning('GIS模型加载中，请稍后再试！')
				return
			}
		}
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (!content || !signParam) return
			let copyContent = [...content]
			//关闭还打开所有类型的标记
			if(this.loadedSignsType === null){
				this.loadedSignsType = {}
				this.$set(this.loadedSignsType,signParam.sign_uuid,true)
			} else {
				if(this.loadedSignsType[signParam.sign_uuid] === undefined){
					this.$set(this.loadedSignsType,signParam.sign_uuid,true)
				} else  {
					for (let i = 0; i < this.signLists.length; i++){
						if(this.signLists[i].id.includes(type) && this.signLists[i].signPageUuid === signParam.sign_uuid){
							this.signLists.splice(i,1)
							i--
						}
					}	
					delete this.loadedSignsType[signParam.sign_uuid]
					this.resetAggregationData()
					return false
				}
			}
			//关闭还打开所有类型的标记结束
			if (signParam?.view_uuid && signParam?.object_uuid && signParam?.sign_uuid && signParam.sign_field) {
				// let flyTo = false
				let obj = {
					object_uuid:signParam.object_uuid,
					view_uuid:signParam.view_uuid,
					__method_name__: "dataList",
					page:1,
					size:1000,
					transcode: 0
				}
				const paramObj = JSON.parse(JSON.stringify(signParam))
				if(content?.length && content[0]?.database?.paramsConfig?.length){
					//所有标记的请求筛选放在了左侧标记模板内，不分是否是列表或详情
					const { param } = initParams(content[0].database.paramsConfig, false, this.subComponentData, []);
					if(param){
						obj = Object.assign(obj, param);
					}
				}
				dataInterface(obj).then((res) => {
					if(res.data.code === 200){
						let result = []
						if(res.data.data && Object.prototype.toString.call(res.data.data) === '[object Array]') {
							result = res.data.data
						} else if(res.data.data.data && Object.prototype.toString.call(res.data.data.data) === '[object Array]'){
							result = res.data.data.data
						}
						if(result.length){
							result.forEach((item) => {
								if((!bol && item[paramObj.sign_field] && item[paramObj.sign_field] !== null) || (bol && JSON.stringify(item).includes(paramObj.sign_uuid))){
									const code = `${type}-${item.id}`
									let newContent = null
									let idArr = []
									this.getAllIds(JSON.parse(JSON.stringify(copyContent)), idArr)
									let formatContent = this.formatContent(JSON.parse(JSON.stringify(copyContent)), idArr, code)
									//标记样式元素不是在一个容器中需要手动添加个容器
									if(copyContent?.length !== 1){
										this.$message.warning('标记模板请用大屏容器套入！')
										return
									} else {
										newContent = formatContent[0]
									}
									if(this.signType && this.signType === 'bim' && item[paramObj.sign_field].length){
										//bim构件转换世界坐标
										eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, item[paramObj.sign_field][0].dbids[0],item[paramObj.sign_field][0].model_id, async(position)=>{
											let point = await position
											//传值给黄旭凯监听模型的坐标
											eventBus.$emit('ViewerBIM.setSelect', this.bimComponentId, item[paramObj.sign_field]);
											eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+ item.id ,point.center, async(re)=>{
												let position = await re
												newContent.style.left = position.x - (Number(newContent.style.width) / 2);
												newContent.style.top = position.y - (Number(newContent.style.height ));
												newContent.signType = 'bim'
												newContent.signPageUuid = paramObj.sign_uuid
												newContent.resolveData = item
												this.signLists.push(newContent)
											})
										})
									}
									if(this.signType && this.signType === 'gis'){
										let gisPosition = null
										let positionName = ''
										let models = []
										if(item[paramObj.sign_field].lat && item[paramObj.sign_field].lng){
											gisPosition = item[paramObj.sign_field]
										}else if(item[paramObj.sign_field].position){
											gisPosition = item[paramObj.sign_field].position
										} else if(item[paramObj.sign_field].latitude && item[paramObj.sign_field].longitude){
											gisPosition = {lat:item[paramObj.sign_field].latitude,lng:item[paramObj.sign_field].longitude}
										} if(Array.isArray(item[paramObj.sign_field]) && item[paramObj.sign_field].length){
											if(item[paramObj.sign_field][0].location_gis){
												//新统一的绑定数据格式
												gisPosition = item[paramObj.sign_field][0].location_gis
												positionName = item[paramObj.sign_field][0].location_name
											}
										}
										// if(!flyTo && gisPosition){
										// 	//防止ViewerGIS.getCanvasByWgs方法不返回数据
                    //   eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 20000, 45);
										// 	flyTo = true
										// }
										if(item[paramObj.sign_field].models){
											models = item[paramObj.sign_field].models
										} if(Array.isArray(item[paramObj.sign_field]) && item[paramObj.sign_field].length){
											//新统一的绑定数据格式
											item[paramObj.sign_field].forEach((v)=>{
												if(v.model_id && v.select?.length ){
													models.push({model_id:v.model_id, dbids:v.select})
												}
											})
										}
										//选中gis中的构件
										if(models?.length){
											for(let i = 0; i < models.length;i++){
												const item = models[i]
												eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
											}
										}
										//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
										eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
											if (position) {
												newContent.style.left = position.x - (Number(newContent.style.width) / 2);
												newContent.style.top = position.y - Number(newContent.style.height );
												this.$set(newContent,'gisPosition',gisPosition)
												this.$set(newContent,'positionName',positionName)
												newContent.signType = 'gis'
												newContent.signPageUuid = paramObj.sign_uuid
												newContent.resolveData = item
												this.signLists.push(newContent)
												// eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
												eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
											}
										})
									}
								}
							})
							this.resetAggregationData()
						}
					}
				})
			}
		},300)
	},
 /**
   * @description: 绘制区域和清除所有区域绘制
   * @return {*}
   */		
	createPlot(param,id){
		if (param?.clearPlot) {
			eventBus.$emit('ViewerGIS.removeAllPlot', this.gisComponentId);
		} else {
			const findId = this.plotIdArr.filter(v=>v === id)
			if(param?.coverData?.length && !findId.length){
				this.plotFun(id, param.coverData[0])//目前只支持一次只绘制一个面
			} else if(Array.isArray(param) && param.length){
				param.forEach((item, i)=>{
					this.plotFun(id.toString() + i, item.polygon[0].position)
				})
			}
		}
	},
	plotFun(id,arr){
		const obj = {
			plotType: "polygon",
			positions: arr, 
			options: {
				plotType: "polygon",
				perPositionHeight: true,
				height: 5,
				fill: true,
				fillType: "color",
				fillColor: "#eabebf",
				alpha: 50,
				outline: true,
				outlineColor: "#dc2e41",
				outlineWidth: 1,
				isClampToGround: false,
				stRotation: 0,
			}
		}
		eventBus.$emit('ViewerGIS.createPlot', this.gisComponentId, id, obj);
		eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, arr[0], 2000, 45, 2);
	},
},
beforeDestroy() {
	eventBus.$off('doComponentBehavior',this.element.id);
	eventBus.$off('Viewer.onCameraChange');
	eventBus.$off('ViewerGIS.onViewerInited');
	eventBus.$emit('ViewerGIS.logoutCameraChange' , this.gisComponentId);
	// window.removeEventListener('mousewheel');
	clearTimeout(this.timeOuter)
}
}
</script>

<style lang="less" scoped>
.console-sign-cont {
	position: absolute;
}
.sign-item-scale{
	position: absolute;
	pointer-events: auto;
}
.sign-item-scale > :deep(div){
	pointer-events: none;
} 
.sign-item {
	position: absolute;
}
.sign-item > :deep(div){
	pointer-events: auto!important;
} 
.aggregation-item{
	position: absolute;
	width: 36px;
	height: 36px;
	background: #45A4F6;
	color: #fff;
	border-radius: 50%;
	margin:-18px 0 0 -18px;
	font-size: 14px;
	line-height: 36px;
	pointer-events: auto!important;
	text-align: center!important;
	p{
		position: relative;
		pointer-events:none!important;
		width: 100px;
		top: 0px;
		left:-30px;
		text-align: center;
	}
}
</style>
