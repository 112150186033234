<!--
 * @Description: iconBtnMenu组件
 * @Author: hw
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2023-08-11 18:01:36
-->
<template>
	<div class="icon-btn-menu-container">
		<div
			class="inner-wrap"
			:class="layout"
		>
			<div
				class="icon-btn-menu-wrap"
				:style="align"
			>
				<div
					:class="['icon-btn-item', { isHidden: !item.isShow }]"
					v-for="item in iconBtnMenuConfig"
					:style="item.style"
					:key="item.type"
				>
					<img
						v-if="valueType === 'img'"
						:id="'img-' + item.type"
						v-buttonHoverEffect="{
							item
						}"
						:src="item.defaultIcon || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png'"
						:style="imgSize"
						@click.stop="onClick(item)"
						@dblclick.stop="onTabdblClick(item)"
					/>
					<svg
						v-if="valueType === 'svg'"
						class="icon svg-icon"
						aria-hidden="true"
						:style="imgSize"
						@click.stop="onClick(item)"
						@dblclick.stop="onTabdblClick(item)"
					>
						<use
							v-buttonHoverEffect="{
								item
							}"
							:id="'svg-' + item.type"
							:style="imgSize"
							:xlink:href="item.defaultIcon || '#iconshoucang1'"
						></use>
					</svg>
					<span
						class="icon-text"
						:id="'text-' + item.type"
						:style="textConfig"
						@click.stop="onClick(item)"
						@dblclick.stop="onTabdblClick(item)"
						>{{ item.label }}</span
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
import { judgingEventLogic } from '@/utils/tools';

export default {
	name: 'IconBtnMenu',
	props: {
		element: {
			type: Object,
			required: false,
			default: () => {}
		},
		componentList: {
			default: null
		},
		// 是否为分组
		isGroup: {
			type: Boolean
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			current: 0,
			// 单击的菜单项
			activeName: '',
			// 双击的菜单项
			dblActiveName: '',
			// 单击/和激活状态
			clickCount: null,
			isActive: false,
			focused: {},
			dblFocused: {},
			// 单击/双击的定时器,解决双击事件触发单击事件
			clickTimer: null
		};
	},
	directives: {
		/**
		 * @description: 菜单项的hover指令
		 * @return {*}
		 */
		buttonHoverEffect: {
			// 初始化执行一次
			bind(el, binding) {
				const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
				const defaultSvg = '#iconshoucang1';

				el.src = binding.value.item.defaultIcon || defaultImg;
				el.setAttribute('xlink:href', binding.value.item.defaultIcon || defaultSvg);
			}
		}
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		/**
		 * @description: 嵌入页面的参数获取
		 * @return {*}
		 */
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		},
		/**
		 * @description: 布局
		 * @return {*}
		 */
		layout() {
			return this.statusConfig?.layout || 'horizontal';
		},
		/**
		 * @description: 对齐方式
		 * @return {*}
		 */
		align() {
			const { align = 'left', gap = 0 } = this.statusConfig;
			const styleMap = {
				left: {
					'justify-content': 'flex-start'
				},
				center: {
					'justify-content': 'center'
				},
				right: {
					'justify-content': 'flex-end'
				}
			};
			return Object.assign({}, styleMap[align], { gap: `${gap}px` });
		},
		/**
		 * @description: img尺寸
		 * @return {*}
		 */
		imgSize() {
			return `width: ${this.statusConfig?.iconSize}px; height: auto;`;
		},
		/**
		 * @description: iconBtn 配置
		 * @return {*}
		 */
		iconBtnMenuConfig() {
			const result = (this.element?.iconBtnMenuConfig.configList || []).map((el, index) => {
				let obj = {};
				obj = {
					style: {
						flex: 1 / this.size
					},
					index: index
				};
				return Object.assign(obj, el);
			});
			return result;
		},
		/**
		 * @description: 文本属性配置
		 * @return {*}
		 */
		textConfig() {
			const e = this.element?.iconBtnMenuConfig.textConfig || {};
			const textObj = {
				color: e.defaultColor || '#fff',
				fontSize: e.size + 'px' || '14px',
				fontWeight: e.fontWeight || 'normal',
				lineHeight: e.lineHeight + 'px' || '20px'
			};
			return textObj;
		},
		/**
		 * @description: 图片/icon类型
		 * @return {*}
		 */
		valueType() {
			return this.element?.iconBtnMenuConfig.valueType || 'icon';
		},
		/**
		 * @description: 显示条目数
		 * @return {*}
		 */
		size() {
			return this.statusConfig?.size || 4;
		},
		/**
		 * @description: 组件配置
		 * @return {*}
		 */
		statusConfig() {
			return this.element ? this.element.statusConfig || {} : {};
		}
	},
	mounted() {
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			let componentName = component.split('-')[0];
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.componentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							eval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		});
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	},
	methods: {
		/**
		 * @description: 判断图片
		 * @param {*} str
		 * @return {*}
		 */
		isImg(defaultIcon) {
			return /^https:\/\//.test(defaultIcon) || /^http:\/\//.test(defaultIcon);
		},
		/**
		 * @description: 处理双击触发单击事件
		 * @param {Object} tab
		 */
		onClick(tab) {
			clearTimeout(this.clickTimer);
			this.clickTimer = setTimeout(() => {
				this.onTabClick(tab);
			}, 200);
		},
		/**
		 * @description: 切换图片/图标
		 * @param {*} tab
		 * @param {*} resolveDataNum
		 * @param {*} dbClick
		 * @return {*}
		 */
		switchIcon(tab, resolveDataNum, dbClick = false) {
			const { defaultIcon, clickActiveIcon, dblclickActiveIcon } = tab;
			const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
			const defaultSvg = '#iconshoucang1';
			// 单击
			if (!dbClick) {
				switch (resolveDataNum) {
					case 0:
						if (this.valueType === 'img') {
							document.getElementById('img-' + tab.type).src = clickActiveIcon || defaultImg;
						} else {
							document.getElementById('svg-' + tab.type).setAttribute('xlink:href', clickActiveIcon || defaultSvg);
						}
						break;
					case 1:
						if (this.valueType === 'img') {
							document.getElementById('img-' + tab.type).src = defaultIcon || defaultImg;
						} else {
							document.getElementById('svg-' + tab.type).setAttribute('xlink:href', defaultIcon || defaultSvg);
						}
						break;
				}
			} else {
				// 双击
				switch (resolveDataNum) {
					case 0:
						if (this.valueType === 'img') {
							document.getElementById('img-' + tab.type).src = dblclickActiveIcon || defaultIcon || defaultImg;
						} else {
							document
								.getElementById('svg-' + tab.type)
								.setAttribute('xlink:href', dblclickActiveIcon || defaultIcon || defaultSvg);
						}
						break;
					case 1:
						if (this.valueType === 'img') {
							document.getElementById('img-' + tab.type).src = defaultIcon || defaultImg;
						} else {
							document.getElementById('svg-' + tab.type).setAttribute('xlink:href', defaultIcon || defaultSvg);
						}
						break;
					case 2:
						if (this.valueType === 'img') {
							document.getElementById('img-' + tab.type).src = dblclickActiveIcon || defaultIcon || defaultImg;
						} else {
							document
								.getElementById('svg-' + tab.type)
								.setAttribute('xlink:href', dblclickActiveIcon || defaultIcon || defaultSvg);
						}
						break;
				}
			}
		},
		/**
		 * @description: 被单击时触发回调
		 * @param {*} tab
		 * @return {*}
		 */
		onTabClick(tab) {
			this.activeName = tab.type;
			this.clickCount = 1;
			// dom更新后重置，避免每次重复触发buttonHoverEffect指令
			this.$nextTick(() => {
				this.clickCount = 0;
			});
			// 固定
			const index = +tab.index;
			const comEvents = this.iconBtnMenuConfig[index]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === 'click') {
						comEvents[i].effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach(ele => {
					if (ele.key === 'click') {
						this.judgingActionType(ele);
					}
				});
			}
			// 暴露出绑定的数据
			let resolveData = null;
			let resolveDataNum = null;
			const { clickColor, defaultColor } = this.element?.iconBtnMenuConfig.textConfig;
			const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
			const defaultSvg = '#iconshoucang1';
			// 暴露数据：默认激活：0，单击激活：1
			// 3.双击激活状态->未激活
			if (this.dblFocused[tab.type]) {
				resolveData = {
					value: 1
				};
				this.focused = { ...this.focused, [tab.type]: false };
			} else {
				if (this.focused[tab.type]) {
					// 1.激活->未激活
					resolveData = {
						value: 1
					};
					resolveDataNum = 1;
					this.focused = { ...this.focused, [tab.type]: false };
					document.getElementById('text-' + tab.type).style.color = defaultColor;
					this.switchIcon(tab, resolveDataNum);
				} else {
					// 2.未激活->激活
					resolveData = {
						value: 0
					};
					this.focused = { ...this.focused, [tab.type]: true };
					resolveDataNum = 0;
					this.switchIcon(tab, resolveDataNum);
					document.getElementById('text-' + tab.type).style.color = clickColor;
				}
			}
			// 单击，将所有双击激活状态取消
			for (let i in this.dblFocused) {
				if (this.dblFocused[i]) {
					this.dblFocused[i] = false;
					// 将所有文本颜色恢复默认,图片恢复为默认
					document.getElementById('text-' + i).style.color = defaultColor;
					this.element?.iconBtnMenuConfig.configList.forEach(ele => {
						if (ele.type === i) {
							if (this.valueType === 'img') {
								document.getElementById('img-' + i).src = ele.defaultIcon || defaultImg;
							} else {
								document.getElementById('svg-' + i).setAttribute('xlink:href', ele.defaultIcon || defaultSvg);
							}
						}
					});
				}
			}
			this.element.resolveData = resolveData;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData
			});
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			// 触发事件
			// event bus  挂载卸载问题
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action: 'any',
					isSearch: true,
					output: resolveData
				});
			}, 0);
		},
		/**
		 * @description: 处理双击
		 * @return {*}
		 */
		onTabdblClick(tab) {
			// 取消单击事件
			const dbClick = true;
			clearTimeout(this.clickTimer);
			this.clickTimer = null;
			this.dblActiveName = tab.type;
			this.clickCount = 2;
			this.isActive = true;
			this.$nextTick(() => {
				this.clickCount = 0;
			});

			// 固定
			const index = +tab.index;
			const comEvents = this.iconBtnMenuConfig[index]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === 'dblclick') {
						comEvents[i].effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach(ele => {
					if (ele.key === 'dblclick') {
						this.judgingActionType(ele);
					}
				});
			}
			// 暴露出绑定的数据
			let resolveData = null;
			let resolveDataNum = null;
			const { dblColor, defaultColor } = this.element?.iconBtnMenuConfig.textConfig;
			const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
			const defaultSvg = '#iconshoucang1';
			// 双击时：如果不为单击击激活状态->激活时，双击将所有文本颜色/图片恢复默认
			if (!this.focused[tab.type] && !this.dblFocused[tab.type]) {
				for (let i = 0; i < this.iconBtnMenuConfig.length; i++) {
					document.getElementById('text-' + this.iconBtnMenuConfig[i].type).style.color = defaultColor;
					if (this.valueType === 'img') {
						document.getElementById('img-' + this.iconBtnMenuConfig[i].type).src =
							this.iconBtnMenuConfig[i].defaultIcon || defaultImg;
					} else {
						document
							.getElementById('svg-' + this.iconBtnMenuConfig[i].type)
							.setAttribute('xlink:href', this.iconBtnMenuConfig[i].defaultIcon || defaultSvg);
					}
				}
				for (let i in this.focused) {
					this.focused[i] = false;
				}
				// 双击，将其它按钮双击激活状态取消
				for (let i in this.dblFocused) {
					if (i !== tab.type) {
						this.dblFocused[i] = false;
					}
				}
				// 2.未激活->激活
				resolveData = {
					value: 0
				};
				resolveDataNum = 0;
				this.dblFocused = { ...this.dblFocused, [tab.type]: true };
				document.getElementById('text-' + tab.type).style.color = dblColor;
				this.switchIcon(tab, resolveDataNum, dbClick);
			} 

			this.element.resolveData = resolveData;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element.id,
				resolveData
			});
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			// 触发事件
			// event bus  挂载卸载问题
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action: 'any',
					isSearch: true,
					output: resolveData
				});
			}, 0);
		},
		/**
		 * @description: 触发行为逻辑判断
		 * @return {*}
		 */
		judgingBehaviorLogic(ele) {
			for (let i = 0; i < ele.behaviors.length; i++) {
				const item = ele.behaviors[i];
				item.list.forEach(target => {
					const logics = target.logics;
					let logicBoolean = true;
					let behaviorsItem = {
						component: item.component,
						list: []
					};
					if (logics?.length) {
						logicBoolean = judgingEventLogic(logics, this.componentData || []);
					}
					if (logicBoolean) {
						behaviorsItem.list.push(target);
						this.$store.commit('triggerEvents', {
							config: {
								behavior: behaviorsItem,
								isBehavior: true
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					}
				});
			}
		},
		/**
		 * @desc: 判断操作类型
		 * @return {*}
		 */
		judgingActionType(ele) {
			const { actionType } = ele;
			switch (actionType) {
				case 'componentBehavior':
					// 触发行为 setTimeout解决resolveData异步问题
					if (ele.behaviors?.length) {
						setTimeout(() => {
							this.judgingBehaviorLogic(ele);
						}, 0);
					}
					break;
				case 'pageAction':
					// 页面事件
					if (ele.effects?.length) {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
			}
		}
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
			removeEventBus('databaseTrigger', this.element.id);
		}
	}
};
</script>

<style lang="less" scoped>
.icon-btn-menu-container {
	width: 100%;
	height: 100%;
	.inner-wrap {
		width: 100%;
		height: 100%;
		.pre-btn-wrap {
			flex-shrink: 0;
			.pre-btn {
				color: inherit;
				cursor: pointer;
			}
		}
		.next-btn-wrap {
			flex-shrink: 0;
			.next-btn {
				color: inherit;
				cursor: pointer;
			}
		}
		.icon-btn-menu-wrap {
			position: relative;
			overflow: hidden;
			.icon-btn-item {
				// position: absolute;
				color: #000;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				img {
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover {
						transform: scale(1.5);
					}
				}
				svg {
					&:hover {
						transform: scale(1.5);
					}
					transition: all 0.3s ease;
				}
				.icon-text {
					color: #fff;
				}
			}
			.isHidden {
				display: none;
			}
		}
	}
	.horizontal {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.icon-btn-menu-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			// flex-grow: 1;
			height: 100%;
			.icon-btn-item {
				height: 100%;
				img {
					height: 100%;
					width: auto;
					// object-fit: scale-down;
					&:hover {
						transform: scale(1.5);
						transition: all 0.3s ease;
					}
				}
				svg {
					&:hover {
						transform: scale(1.5);
					}
					transition: all 0.3s ease;
				}
			}
		}
	}
	.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.icon-btn-menu-wrap {
			display: flex;
			align-items: center;
			flex-direction: column;
			height: 100%;
			width: 100%;
			.icon-btn-item {
				width: 100%;
				img {
					height: auto;
					width: 100%;
					// object-fit: scale-down;
					&:hover {
						transform: scale(1.5);
						transition: all 0.3s ease;
					}
				}
				svg {
					&:hover {
						transform: scale(1.5);
					}
					transition: all 0.3s ease;
				}
			}
		}
	}
}
</style>
