<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div style="width:100%;height:100%">
		<div v-resize="DomResize" @mouseleave="mouseleaveCanvas()" id="outerCanvasCont" class="canvas-table" >
			<!-- canvas -->
			<div v-if="show && list?.length" id="canvasOuterDiv"  />
			<el-empty v-else description="暂无数据" />
			<!-- tooltip -->
			<div id="toolTipCont" />
			<!-- 伸缩的图标 -->
			<i v-show="showType!== 'table' && !onlyGantt && show && list?.length" class="iconfont iconxiangzuoshouqi fold-icon" :style="{left: onlyTable? width - 46 + 'px' : iconLeftNum + 'px',top:ganttConfig.isPlayAble?'60px':'14px'}" @click="changeShowType('left')" />
    <i v-show="onlyGantt && show && list?.length" class="iconfont iconxiangyoushouqi fold-icon" :style="{left: onlyGantt? '14px' : iconLeftNum + 'px',top:ganttConfig.isPlayAble?'60px':'14px'}" @click="changeShowType('right')" />
		</div>
	</div>	
</template>
<script>
import { CanvasTable } from './js/canvasTable.js'
import { Empty } from 'element-ui';
import countDate from './js/countDate.js'
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
let canvasTable = null
export default {
  name: 'CommonGantt',
	mixins: [countDate],
	props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: true
		},
    componentList: {
      default: null
    }
  },
  inject: ['EDITOR_pageUUID'],
  components: {
		'el-empty': Empty,
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus',
      'subsidiaryComponentData'
		]),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
		//基本配置
		ganttConfig(){
			return this.element?.ganttConfig || this.dataBaseConfig;
		},
		objectUuid(){
			return this.element?.database?.objectData?.uuid || '';
		},
		viewUuid(){
			return this.element?.database?.viewData?.uuid || '';
		},
		paramsConfig() {
			if (!this.element || !this.element.database || !this.element.database.paramsConfig) return [];
			return this.element.database.paramsConfig;
		},
		requestType(){
			return this.element.database.requestType || "dataList";
		},
		//显示字段
		fieldConfig(){
			return this.element.fieldConfig || []
		},
		//父级id显示字段
		parentIdField () {
			return this.element.ganttConfig?.parentIdField || ''
		},
		//计划开始时间fielduuid
		startDateField () {
			return this.element.ganttConfig?.startDateField || ''
		}, 
		//计划结束时间fielduuid
		endDateField(){
			return this.element.ganttConfig?.endDateField || ''
		}, 
		textField(){
			return this.element.ganttConfig?.textField || ''
		},
		wbsField(){
			return this.element.ganttConfig?.wbsField || ''
		},//wbs显示字段
		idField(){
			return this.element.ganttConfig?.idField || ''
		},//id显示字段
		bimField(){
			return this.element.ganttConfig?.bimField || ''
		},//bim显示字段
		gisField(){
			return this.element.ganttConfig?.gisField || ''
		},//gis显示字段
		taskColorField(){
			return this.element.ganttConfig?.taskColField || ''
		},
		//是否是gis页面显示
		isGis () {
			let gisComponent = this.findComponentWithField(this.componentData, 'ViewerGIS')
			if(gisComponent?.statusConfig?.displayShow && gisComponent?.statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
	},
	directives: {  // 使用局部注册指令的方式
    resize: { // 监听容器变化
      mounted (el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        let width = '', height = '';
        function isReize () {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== style.height) {
            binding.value({width:style.width,height:style.height});  // 关键
          }
          width = style.width;
          height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 500);
      },
      beforeUnmount (el) {
        clearInterval(el.__vueSetInterval__);
      }
    }
  },
	data() {
		return {
			show: false,
      dateTypeData: [
        { label: '日', value: 'monthAndDay' },
        { label: '周', value: 'yearAndWeek' },
        { label: '月', value: 'yearAndMonth' },
        { label: '年', value: 'onlyYear' }
      ],
      baseCols: [], // 基础的col
      originCols: [ // 标题栏
				// { field: 'canvas_checked', title: '全选', fixed: true, align: 'center', titleAlign: 'center', width: 65, type: 'selection', canvas_checked: 0 },
        // { field: 'wbs', title: '进度WBS', sort: false, fixed: true, align: 'center', titleAlign: 'center', width: 100 },
        // { field: 'name', title: '任务名称', sort: false, fixed: true, align: 'center', titleAlign: 'center', width: 240, widthAuto: true, treeIcon: true },
        // { field: 'start_date', title: '计划开始时间', sort: false, fixed: true, align: 'center', titleAlign: 'center', width: 120 },
        // { field: 'end_date', title: '计划结束时间', sort: false, fixed: true, align: 'center', titleAlign: 'center', width: 120 }
      ],
      // widthAuto 仅表格情况下该col宽度为auto
      list: [],
      sortObject: null,
      tableLeft: 0,
      cols: null,
      sortedList: [],
			contWidth: 0,
      dateType: 'yearAndMonth', // 日期展示模式 yearAndWeek/monthAndDay/yearAndMonth
      dayWidth: 4, // 每天得宽度
      checkedData: [], // 选中的数据
      isPlay: false, // 播放状态
			onlyGantt:false, //仅gantt
			onlyTable:false,//仅Table
      borderColor: '#CFCFCF', // 边框颜色
			clickTimer: null,
			iconLeftNum:0,
			showType:'',
			//默认配置
			dataBaseConfig:{
				onlyTable: false,
				onlyGantt: false,
				tooltip: true,
				forwardLine: false,
				isPlayAble: false,
				color:'#666',
				headBackgroundColor: '#efefef',
				backgroundColor: '#fff',
				hoverBackgroundColor: '#f6f6f6',
				scrollbarBackgroundColor:'#d8d8d8',
				scrollbarForegroundColor:'#fff',
				borderColor: '#CFCFCF',
				ganttBackgroundColor: '#448aff',
				fontSize: '14',
				headHeight: '50',
				rowHeight:'40',
				ganttAlign:'center'
			},
			param:null,
			cacheBehavior:null,//页面未加载完毕缓存的行为
			requestParam:null,//刷新用的请求参数
    }
	},
	created () {
		//取出配置得字段
		if (this.element.fieldConfig?.length) {
			for(let i = 0; i < this.element.fieldConfig.length; i++){
				const item = this.element.fieldConfig[i]
				if(item.show){
					let itemObj = { field:item.uuid, title:item.label, sort: false, fixed: true, align: item.headerAlign || 'center', titleAlign: item.headerAlign || 'center', width: item.width || 120,format:'String' }
					//时间格式
					if(item.type === 4){
						itemObj.format = 'Date'
					} else if (item.type === 3) {
						itemObj.title = this.filterStatus(item.label, item.statusOptions).label
					}
					if(item.uuid === this.textField){
						itemObj.widthAuto = true
						itemObj.treeIcon = true
					}
					this.originCols.push(itemObj)
				}
			}
		}
		this.baseCols = this.originCols
		if(this.element.ganttConfig?.checkbox){
			const checkObj = { field: 'canvas_checked', title: '全选', fixed: true, align: 'center', titleAlign: 'center', width: 65, type: 'selection', canvas_checked: 0 }
			this.originCols.unshift(checkObj)
		}

		if(this.element.ganttConfig?.detail || this.element.ganttConfig?.edit || this.element.ganttConfig?.del || this.element.ganttConfig?.add){
			let itemObj = {field: 'handle',children:[],width:0, fixed:true,title:'操作'}
			if(this.element.ganttConfig?.add){
				itemObj.children.push({ align: 'center', field: 'add', sort: false, title: '新增', widthItem: 40 })
				itemObj.width += 40
			}
			if(this.element.ganttConfig?.detail){
				itemObj.children.push({ align: 'center', field: 'detail', sort: false, title: '详情', widthItem: 40 })
				itemObj.width += 40
			}
			if(this.element.ganttConfig?.edit){
				itemObj.children.push({ align: 'center', field: 'edit', sort: false, title: '编辑', widthItem: 40 })
				itemObj.width += 40
			}
			if(this.element.ganttConfig?.del){
				itemObj.children.push({ align: 'center', field: 'del', sort: false, title: '删除', widthItem: 40, color: 'red' })
				itemObj.width += 40
			}
			this.originCols.push(itemObj)
		}
    window.onresize = function () {
      console.log('缩放')
    }
  },
  mounted () {
		this.onlyGantt = this.ganttConfig.onlyGantt || false
		this.onlyTable = this.ganttConfig.onlyTable|| false
		let { search = [] } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
		this.getData(search, this.param)
		// 监听行为
    const doComponentBehavior = {
      [this.element.id]: config => {
				if(!this.show){
					this.cacheBehavior = config
				} else {
					this.behaviorFun(config)
				}
      }
    }
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id])
    const databaseTrigger = {
      [this.element.id]: (data) => {
				const { paramsConfig } = this.element.database;
        if (!data || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === data.componentId)) {
          return;
        }
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.param = param;
        this.getData(search, param);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
	methods: {
		/**
		 * @desc: 根据字段查找组件
		 */
    findComponentWithField(componentList, field){
			for (let i = 0, len = componentList.length; i < len; i++) {
				const item = componentList[i];
				if (item.component === field) {
					return item;
				}
				if (item.children && Array.isArray(item.children) && item.children.length) {
					const result = this.findComponentWithField(item.children, field) || null;
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
  /**
   * @description: 调用行为
   * @param {*} config
   * @return {*}
   */		
		behaviorFun(config){
			if (!config) return false
			const { component, list = [] } = config;
			if (component === null) return false
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.subComponentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							eval(this[funName])(param)
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		},
  /**
   * @description: 间隔天数
   * @param {*} days
   * @param {*} time
   * @return {*} 数组
   */		
		getDays(days, time){
			const myDate = time ? new Date(time) : new Date()
			const Y = myDate.getFullYear()
			let M = myDate.getMonth() + 1
			let D = myDate.getDate()
			if (M < 10) {
				M = '0' + M
			}
			if (D < 10) {
				D = '0' + D
			}
			const endTime = Y + '-' + M + '-' + D
			const milliseconds = new Date(endTime).getTime() - parseInt(days) * 1000 * 60 * 60 * 24
			const date = new Date(milliseconds)
			const newY = date.getFullYear() + '-'
			const newM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			const newD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
			const startTime = newY + newM + newD
			return [startTime, endTime]
		},
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
    filterStatus(value, statusOptions = []) {
			if (!Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		
		DomResize (data) {
			if(data && data.width){
				const width = Number(data.width.replace('px',''))
				this.contWidth = width
				this.show = false;
				this.$nextTick(()=>{
					this.show = true;
				})
			}
    }, 
  /**
   * @description: 重置数据
   * @return {*}
   */		
		resetBaseData(){
			this.originCols = this.baseCols
			if (this.originCols?.length) {
				const checkHeaderIndex = this.originCols.findIndex(v => v.field === 'canvas_checked')
        if (this.originCols?.[checkHeaderIndex]) {
          this.originCols[checkHeaderIndex].canvas_checked = 0
        }
			}
			this.show = false
			this.startDate = ''
			this.endDate = ''
			this.list = []
			canvasTable = null
		},
	/**
   * @description: 获取数据
   * @return {*}
   */
		getData(search = [], params = {}) {
			this.resetBaseData()
			clearTimeout(this.clickTimer)
			this.clickTimer = setTimeout(async() => {
				if(!this.objectUuid || !this.viewUuid) return 
				this.requestParam = {search:search,params:params}
				dataInterface({
					object_uuid: this.element.database.objectData?.uuid,
					view_uuid: this.element.database.viewData?.uuid,
					__method_name__: this.requestType,
					search:search,
          ...params
				}).then(async(res) => {
					if (res.data?.code === 200 && res.data?.data?.length) {
						if(this.parentIdField){
							const tasks = await this.translateDataToTree(res.data.data)
							this.list = await this.expandData([...tasks])
						} else {
							this.list = await this.translateDataToTree(res.data.data)
						}
						this.show = true
						const formatData = await this.ganttTitleDate()
						this.originCols = this.originCols.concat(formatData)
						this.init()
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								metadata:res.data.metadata
							},
							containerId: null,
							isModify: true
						});
					}
				})
			},300)  
		},
  /**
   * @description: 展开全部数据
   * @param {*} arr
   * @return {*}
   */		
		expandData (arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].expanded = true
        if (arr[i].children?.length) {
					for(let j = arr[i].children.length - 1; j >= 0; j--){
						arr.splice(i + 1, 0, arr[i].children[j])
					}
        }
      }
			return arr
    },
    /**
   * @description: wbs转换成树形结构数据
   * @param {*} data
   * @return {*}
   */
    translateDataToTree (data) {
      const parent = data.filter(
        (value) => !value[this.parentIdField] || value[this.parentIdField] === null || value[this.parentIdField] === 0 || value[this.wbsField].length === 1
      )
      const children = data.filter(
        (value) => value[this.parentIdField]
      )
      const translator = (parent, children) => {
        parent.forEach((parent) => {
					//取日期的最大值和最小值
					if((!this.startDate || ( new Date(this.startDate) > new Date(parent[this.startDateField]))) && parent[this.startDateField] !== '0000-00-00 00:00:00' && isNaN(parent[this.startDateField]) && !isNaN(Date.parse(parent[this.startDateField]))){
						const dateArr = this.getDays(60,parent[this.startDateField].substring(0, 10))
						this.startDate = dateArr[0]
					}
					if((!this.endDate || new Date(this.endDate) < new Date(parent[this.endDateField])) && parent[this.endDateField] !== '0000-00-00 00:00:00' && isNaN(parent[this.endDateField]) && !isNaN(Date.parse(parent[this.endDateField]))){
						
						const dateArr = this.getDays(-60,parent[this.endDateField].substring(0, 10))
						this.endDate = dateArr[0]
					}
          // parent.actualNum = 8
					parent.id = parent[this.idField]
					parent.name = parent[this.textField]
					parent.wbs = Object.prototype.toString.call(parent[this.wbsField]) === "[object String]"? parent[this.wbsField] : JSON.stringify(parent[this.wbsField])
					parent.start_date = parent[this.startDateField]
					parent.end_date = parent[this.endDateField]
					parent.selection = parent[this.bimField] || [];
					if(this.isGis){
						parent.selection = parent[this.gisField] || [];
					}
					parent.taskColor = parent[this.taskColorField];
          parent.canvas_checked = 0
          parent.expanded = false
          children.forEach((current, index) => {
            // current.actualNum = 8
						current.id = current[this.idField]
						current.name = current[this.textField]
						current.wbs =  Object.prototype.toString.call(current[this.wbsField]) === "[object String]"? current[this.wbsField] : JSON.stringify(current[this.wbsField])
						current.start_date = current[this.startDateField]
						current.end_date = current[this.endDateField]
						current.selection = current[this.bimField] || [];
						if(this.isGis){
							current.selection = current[this.gisField] || [];
						}
						current.taskColor = current[this.taskColorField];
            current.expanded = false
            if (current[this.parentIdField] === parent[this.idField]) {
              const temp = [...children]
              temp.splice(index, 1)
              translator([current], temp)
              typeof parent.children !== 'undefined'
                ? parent.children.push(current)
                : (parent.children = [current])
            }
          })
        })
      }
			if(parent.length){
				translator(parent, children, true)
				return parent
			} else {
				translator(children, [], true)
				return children
			}
      
    },
    /**
     * @description: 初始化canvas
     * @param {*} canvasTable
     * @return {*}
     */
    async init () {
			if (canvasTable !== null) return
      this.sortedList = this.list
      this.cols = this.originCols
      canvasTable = await new CanvasTable(document.querySelector('#canvasOuterDiv'))
      // 获取旗子的日期
      canvasTable.getFlagDate = (date, isPlayEnd = false, isPlaying) => {
				console.log(date,'date')
        this.$emit('change-line-date', date, isPlayEnd ? false : isPlaying)
				this.$store.commit('updatePageCustomStatus', {
					key: 'ganttDate',
					value: date
				});
        if (isPlayEnd) {
          this.isPlay = false
          this.$emit('init-model')
        } else {
          this.isPlay = isPlaying
        }
      }
      canvasTable.onclick = async (cell) => {
        console.log('on click cell: ', cell, cell.col.field)
        // 查找顶部cols的是全选的index
        const checkHeaderIndex = this.cols.findIndex(v => v.field === 'canvas_checked')
        if (cell?.col?.field === 'canvas_checked') {
          // 选中和取消选中操作
          if (cell.row) {
            const listIndex = this.list.findIndex(v => v.id === cell.row.id)
            this.list[listIndex].canvas_checked = this.list[listIndex].canvas_checked === 0 ? 1 : 0
            await this.setTreeCheck(this.list, this.list[listIndex].canvas_checked, [cell.row.id])
            const filterData = this.list.filter(v => v.canvas_checked !== this.list[0].canvas_checked)
            // 改变header选中状态
            if (filterData?.length) {
              this.cols[checkHeaderIndex].canvas_checked = -1
            } else {
              this.cols[checkHeaderIndex].canvas_checked = this.list[0].canvas_checked
            }
          } else {
            // 全选
            if (this.checkedData.length !== this.list.length) {
              this.checkedData = [...this.list]
              this.cols[checkHeaderIndex].canvas_checked = 1
              this.list.map(item => {
                item.canvas_checked = 1
              })
            } else {
              this.cols[checkHeaderIndex].canvas_checked = 0
              this.list.map(item => {
                item.canvas_checked = 0
              })
              this.checkedData = []
            }
          }
          this.checkedData = this.list.filter(v => v.canvas_checked === 1)
          canvasTable.requestRefresh()
					this.triggerEvents('checkTask',this.checkedData)
          // console.log(this.checkedData, '选中数据')
        } else {
					if (cell.row) {
						this.element.resolveData = cell.row
						// this.$store.commit('modifyComponent', {
						// 	component: {
						// 		...this.element,
						// 		resolveData: cell.row
						// 	},
						// 	containerId: null,
						// 	isModify: true
						// });
						if(cell.col.field === 'add'){
							this.triggerEvents('addTask',[cell.row])
						} else if(cell.col.field === 'detail'){
							this.triggerEvents('detailTask',[cell.row])
						} else if(cell.col.field === 'edit'){
							this.triggerEvents('editTask',[cell.row])
						} else if(cell.col.field === 'del'){
							this.triggerEvents('deleteTask',[cell.row])
						} else {
							this.triggerEvents('clickTask',[cell.row])
						}
					} else if(cell.col.field === 'add'){
						this.triggerEvents('addTask',[])
					}
				}
      }
			canvasTable.getFixWidth = (num) => {
        this.iconLeftNum = num + 20
      }
      canvasTable.onsort = (cell, nextSortObject) => {
        if (nextSortObject) {
          // const type = nextSortObject.type
          // if (type !== CanvasTable.SORT_NONE) {}
          this.sortObject = nextSortObject
          this.tableLeft = canvasTable.left
          this.sortedList = this.sortListBy(nextSortObject.field, nextSortObject.type)
          this.renderTable()
        }
      }
      this.renderTable()
    },
    /**
   * @description: 递归改变选中状态
   * @return {*}
   */
    setTreeCheck (data, bol, arr) {
      if (data?.length) {
        for (let i = 0; i < data?.length; i++) {
          if (arr.includes(data[i][this.parentIdField])) {
            arr.push(data[i].id)
            data[i].canvas_checked = bol
            if (data[i]?.children?.length) {
              this.setTreeCheck(data[i].children, data[i].canvas_checked, arr)
            }
          }
        }
      }
      for (let j = data.length - 1; j >= 0; j--) {
        if (data[j].children?.length && data.filter(v => v.id === data[j].children[0].id).length) {
          const bol = data[j].children[0].canvas_checked
          const filterData = data[j].children.filter(v => v.canvas_checked !== bol)
          if (filterData?.length) {
            data[j].canvas_checked = -1
          } else {
            data[j].canvas_checked = bol
          }
        }
      }
    },
    /**
     * @description: 甘特图标题日期分配
     * @return {*}
     */
    ganttTitleDate () {
      // 分解开始和结束日期
      const startDateSpilt = this.startDate.split('-')
      const endDateSpilt = this.endDate.split('-')
      const startYear = Number(startDateSpilt[0])
      const startMonths = Number(startDateSpilt[1])
      const endYear = Number(endDateSpilt[0])
      const endMonths = Number(endDateSpilt[1])
      // 不自动更新日期类型，以dateType固定展示
      if (this.dateType === 'monthAndDay') {
        // 日
        return this.monthsAndDayTitleDate(startYear, startMonths, endYear, endMonths)
      } else if (this.dateType === 'yearAndWeek') {
        // 周 还有20220701这种数据有问题在generationDays里优化
        return this.yearAndWeekTitleDate(startYear, startMonths, endYear, endMonths)
      } else if (this.dateType === 'yearAndMonth') {
        // 月
        return this.yearAndMonthsTitleDate(startYear, startMonths, endYear, endMonths)
      } else {
        // 年
        return this.onlyYearTitleDate(startYear,endYear)
      }
    },
    /**
     * @description: 切换显示时间类型
     * @return {*}
     */
    changeDateType (value) {
      if (value === 'yearAndWeek') {
        this.dayWidth = 10
      } else if (value === 'monthAndDay') {
        this.dayWidth = 40
      } else if (value === 'yearAndMonth') {
        this.dayWidth = 4
      } else {
        this.dayWidth = 2
      }
      this.show = false
      this.isPlay = false // 播放状态
      this.$nextTick(async () => {
        this.show = true
        // 一定要清除之前的鼠标监听事件,不清除鼠标会返回多个数据
        canvasTable.removeEventHandler()
        // 清空画布
        canvasTable.clearCanvas()
        canvasTable = null
        const formatData = await this.ganttTitleDate()
        this.originCols = this.baseCols.concat(formatData)
        this.init()
      })
    },
    /**
     * @description:渲染表格
     * @param {*} canvasTable
     * @return {*}
     */
    renderTable () {
			const { headHeight,rowHeight,fontSize,color,headBackgroundColor,ganttBackgroundColor,
							hoverBackgroundColor, forwardLine, tooltip, isPlayAble, backgroundColor, borderColor,
							ganttAlign, scrollbarBackgroundColor, scrollbarForegroundColor
			} = this.ganttConfig
			let maxHeight = null
			let maxWidth = null
			if(document.getElementById('outerCanvasCont') !== null){
				maxHeight = document.getElementById('outerCanvasCont').clientHeight 
				maxWidth = document.getElementById('outerCanvasCont').clientWidth
			} else {
				maxHeight = this.element.style.height
			}
      canvasTable.init({
        headHeight: headHeight,
        rowHeight: rowHeight,
        fontSize: fontSize,
				color: color,
        headBackgroundColor:headBackgroundColor,
        borderColor: borderColor,
				backgroundColor:backgroundColor,
				hoverBackgroundColor: hoverBackgroundColor,
				ganttBackgroundColor: ganttBackgroundColor,
				scrollbarBackgroundColor: scrollbarBackgroundColor,
				scrollbarForegroundColor: scrollbarForegroundColor,
				forwardLine: forwardLine, // 前锋线开启
        tooltip: tooltip, // 移入提示
        onlyTable: this.onlyTable, // 仅table
        onlyGantt: this.onlyGantt, // 仅gantt
        isPlayAble: isPlayAble, // 允许播放
				addAble:this.element.ganttConfig?.add || false, //是否有新增
				ganttAlign: ganttAlign,//任务对齐方式

				designWidth: maxWidth,
        scrollbarWidth: 6,
				maxHeight: maxHeight,
        cols: this.cols,
        list: this.list,
        left: this.tableLeft,
        dateType: this.dateType,
				checkedData: this.checkedData,//选中数据
				startDate: this.startDate,//大开始时间
        endDate: this.endDate,//大结束日期
				actualStartField: 'actual_start_date',
        actualEndField: 'actual_end_date',
        dayWidth: this.dayWidth,//每天的宽度
				...this.sortObject,
      })
			if(this.cacheBehavior!==null){
				setTimeout(()=>{
					this.behaviorFun(this.cacheBehavior)
					this.cacheBehavior = null
				},1000)
			}
			if (isPlayAble && canvasTable?.setFlagDate) {
				canvasTable.setFlagDate(new Date())
			}
    },
    sortListBy (key, sortType) {
      if (sortType === CanvasTable.SORT_NONE) {
        return this.list
      } else if (sortType === CanvasTable.SORT_ASC) {
        return this.list.slice().sort((a, b) => {
          return a[key] - b[key]
        })
      } else if (sortType === CanvasTable.SORT_DESC) {
        return this.list.slice().sort((a, b) => {
          return b[key] - a[key]
        })
      }
    },
		/**
     * @description:快速切换
     * @return {*}
     */
		changeShowType (type) {
      if (type === 'left') {
        if (this.onlyTable) {
          this.onlyGantt = false
					this.onlyTable = false
        } else {
          this.onlyGantt = true
        }
      } else {
        if (this.onlyGantt) {
					this.onlyGantt = false
					this.onlyTable = false
        } else {
          this.onlyTable = true
        }
      }
			canvasTable.clearCanvas()
			this.renderTable()
    },
    /**
   * @description: 播放进度
   * @return {*}
   */
    onPlayGantt () {
      this.isPlay = !this.isPlay
      canvasTable.playGantt(this.isPlay)
    },
		/**
   * @description: 重置刷新
   * @return {*}
   */
    onReset () {
      canvasTable.clearCanvas()
			this.getData(this.requestParam.search,this.requestParam.params)
    },
		/**
		 * @description: 
		 * @return {*}
		 */		
		mouseleaveCanvas(){
			document.getElementById('toolTipCont').style.display = 'none';
		},
  /**
   * @description: 全选数据
   * @return {*}
   */		
		onCheckAll(){
			if(this.cols?.length && this.list?.length){
				const checkHeaderIndex = this.cols.findIndex(v => v.field === 'canvas_checked')
				this.checkedData = [...this.list]
				this.cols[checkHeaderIndex].canvas_checked = 1
				this.list.map(item => {
					item.canvas_checked = 1
				})
				if(canvasTable !== null){
					// canvasTable.requestRefresh()
					this.renderTable()
				}
				this.triggerEvents('checkTask',this.checkedData)
			}
		},
		//触发行为
		triggerEvents(funName,data){
			const eventList = this.element.actionConfig[funName]?.eventList || [];
			if (eventList && eventList.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							//resolveData参数传入
							this.$store.commit('modifyComponent', {
								component: {
									...this.element,
									resolveSelectData: data
								},
								containerId: null,
								isModify: true,
								pageUUID: this.EDITOR_pageUUID
							});
							ele.effects.forEach((effect) => {
								this.$store.commit('triggerEvents', {
									config: {
										...ele,
									...effect
									},
									element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID
								});
							});
							// 触发组件行为
							const { behaviors } = ele;
								behaviors.forEach(behavior => {
									this.$store.commit('triggerEvents', {
										config: {
											behavior,
											isBehavior: true
										},
										element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
									});
								});
						}
					})
				});
				setTimeout(()=>{
					this.clearResolveData()
				},2000)
			}		
		},
		clearResolveData(){
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveSelectData: []
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
		}
  },
	beforeDestroy(){
		// 一定要清除
		this.clearResolveData()
		if(canvasTable !== null){
			canvasTable.removeEventHandler()
			if(canvasTable.clearCanvas){
				canvasTable.clearCanvas() 
			}
			canvasTable = null
			this.show = false
		}
	}
}
</script>
<style>
.canvas-table{
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
	background: inherit;
}
#root {
  flex:inherit!important;
  overflow: hidden;
	height:100%;
}
#toolTipCont{
  width: 150px;
  height: 40px;
  background-color: #fff;
  box-shadow:  0px 0px 5px 0px rgba(0,0,0,0.1);
  position:absolute;
  left: 0;
  right: 0;
  color: #000;
  display: none;
  padding:10px;
  border-radius: 4px;
}
#toolTipCont p{
  margin-bottom: 10px;
  line-height: 15px;
	font-size:14px;
}
.outer-header{
  padding: 10px 0 10px 0;
  border-bottom: none!important;
  border-width: 1px;
  border-style: solid;
}

.fold-icon{
  position: absolute;
  font-size: 13px!important;
  padding: 5px;
  border-radius: 50%;
  background: #f6f6f6;
	line-height: 13px;
  box-shadow:  0px 0px 5px 0px rgba(0,0,0,0.1);
}
</style>
