import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { initParams, doEEActionHandle, getQueryValue, getComponentById } from '@/utils/tools';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';

import Viewer from './Viewer';
import ToolbarEvents from './Events/ToolbarEvents';
import SceneEvents from './Events/SceneEvents';
import ModelEvents from './Events/ModelEvents';
import MouseCustomEvents from './Events/MouseCustomEvents';
import CoordinateEvents from './Events/CoordinateEvents';
import VectorEvents from './Events/VectorEvents';
import MarkerEvents from './Events/MarkerEvents';
import PlotEvents from './Events/PlotEvents';
import ClusterEvents from './Events/ClusterEvents';

import ModelBehaviors from './Behaviors/ModelBehaviors';
import MarkerBehaviors from './Behaviors/MarkerBehaviors';
import MeasureBehaviors from "./Behaviors/MeasureBehaviors";
import PlotBehaviors from "./Behaviors/PlotBehaviors";
import GltfBehaviors from "./Behaviors/GltfBehaviors";

import { dataInterface } from '@/apis/data/index';

const defaultClientConfig = {
  local: {
    host: '192.168.2.235',
    port: '3333',
    appId: '0905019c65ee4266b95d1b6858cd2f6b',
    appSecret: '0mvBMkb00QJE',
    layerId: '7c31555ae1824d548704b4f8f4f00400'
  },
  online: {
    host: 'models.bimcc.net',
    isHttps: true,
    appId: 'bdafce12a2d9465d89821ec14214a382',
    appSecret: '3EYcdAjbnWxP',
    name: '主应用'
  }
};

export default class VueConfig {
  constructor() {
    this.name = 'ViewerGIS';

    this.viewer = null;
  }

  generate() {
    let data = {
      modelId: '',
      modelPosition: '',
      sourceConfig: {
        host: '',
        port: '',
        isHttps: '',
        appId: '',
        appSecret: ''
      },
      dataConfig: {
        modelList: []
      },
      setting: {
        showMask: true,
        defaultViewState: null,
        imageryList: [
          { id: 'TianDiTu', name: '天地图影像' },
          { id: 'AMapSatellite', name: '高德影像' },
          { id: 'AMapStreets', name: '高德街道' },
          { id: 'ArcGIS', name: 'ArcGIS暗黑' }
        ],
        imageryIndex: 0,
        mouseControllerList: [
          { id: 'bim', name: 'BIM操作模式' },
          { id: 'gis', name: 'GIS操作模式' },
          {id: 'plane', name: '大屏/2D操作模式'},
        ],
        mouseControllerIndex: 1,
        showGroundAtmosphere: true,
        terrain: false,
        depthTest: false,
        shadows: false,
        backgroundColor: '#000000'
      },
      specialScene: {
        globeLimitTypeList: [
          {id: 'none', name: '无'},
          {id: 'district', name: '以行政区划加载'},
          {id: 'draw', name: '手动绘制图形加载'}
        ],
        globeLimitTypeIndex: 0,
        globeLimitDivision: '',
        globeLimitDraw: null,
        globeLimitBound: true,
        globeLimitBoundColor: 'rgba(0,255,0,0.5)',
        globeLimitGlobe: false,
        globeLimitGlobeColor: 'rgba(0,255,0,0.2)',
      },

      _compEventBus: {},
      _EventBus: {},
      behaviorHandler: {},

      preload: null,  //参数设置-模型动态数据源
      viewerInitedFlag: false,
      dynamicLoadedFlag: true
    };

    let watch = {
      'element.GISSourceConfig'(val = {}, oldval = {}) {
        let flag = false;
        const list = [
          'host',
          'port',
          'isHttp',
          'appId',
          'appSecret',
          'modelId',
          'modelPosition',
          'isDynamicSource'
        ];

        for (let name of list) {
          if (val[name] !== oldval[name]) {
            flag = true;
            break;
          }
        }

        if (flag) {
          this.refresh(val);
        }
      },
      'element.GISSetting'(val = {}, old) {
        if (JSON.stringify(val) !== JSON.stringify(old)) {
          this.setSetting(val);
        }
      },
      'element.GISSpecialScene'(val = {}, old) {
        if (JSON.stringify(val) !== JSON.stringify(old)) {
          this.setSpecialScene(val);
        }
      }
    };

    let methods = {
      initCompEvent(){
        const baseEvents = {
          doComponentBehavior: ({ component, list }) => {
            if (component === this.element.id) {
              for (let { behaviors, params } of list) {
                for (let bid of behaviors) {
                  if (this.behaviorHandler[bid]) {
                    const { param = {} } = initParams(
                      params,
                      this.isGroup,
                      this.componentData,
                      this.groupComponents
                    );

                    this.behaviorHandler[bid](param);
                  }
                }
              }
            }
          },
          "databaseTrigger": ({ componentId }) => {
            if(!this.element.paramsConfig) return;
            for(let params of this.element.paramsConfig){
              if(params.componentId === componentId){
                //处理数据获取
                //需要初始化模型的数据获取 初始化已经完成就放弃了
                const element = this.element;
                const { param } = this.initParams(element.paramsConfig, element.isGroup, element.componentData, element.groupComponents);

                if(param.modelId){
                  if (this.element.GISSourceConfig && this.element.GISSourceConfig.isDynamicSource) {
                    if (param && param.modelId) {
                      this.preload = {
                        appId: param.appId
                            ? param.appId
                            : process.env.VUE_APP_MODEL_APPID,
                        appSecret: param.appSecret
                            ? param.appSecret
                            : process.env.VUE_APP_MODEL_APPSECRET
                      };

                      if (typeof param.modelId === 'string') {
                        this.preload['modelId'] = this.getParseParam(param.modelId);
                      } else if (param.modelId instanceof Array) {
                        this.preload['modelId'] = param.modelId;
                      }

                      if (param.modelPosition && typeof param.modelPosition === 'string') {
                        let modelPosition = JSON.parse(param.modelPosition);
                        if(modelPosition.lng && modelPosition.lat){
                          this.preload['modelPosition'] = {};
                          for (let modelId of this.preload['modelId']){
                            this.preload['modelPosition'][modelId] = modelPosition;
                          }
                        }else{
                          this.preload['modelPosition'] = modelPosition;
                        }
                      }

                      let dataConfig = this.initDataConfig();
                      if(this.viewerInitedFlag){
                        this.initViewerData(this.viewer, dataConfig).then(() => {
                          this.dynamicLoadedFlag = true;
                          eventBus.$emit('ViewerGIS.onViewerInited', this.element.id, {
                            viewerInited: this.viewerInitedFlag,
                            dynamicLoaded: this.dynamicLoadedFlag
                          });
                        });
                      }
                    }
                  }
                }

              }
            }
          },
          "ViewerGIS.getViewerInitedFlag" : (eid , cb)=>{
            if( eid !== this.element.id) return;

            cb && cb({
              viewerInited: this.viewerInitedFlag,
              dynamicLoaded: this.dynamicLoadedFlag
            });
          }
        };

        this._compEventBus = Object.assign(
            {},
            baseEvents
        );

        for (let id in this._compEventBus) {
          eventBus.$on(id, this._compEventBus[id]);
        }
      },
      initSourceConfig(props) {
        const SourceConfig = props ? props : this.element.GISSourceConfig;

        let config = {
              host: defaultClientConfig.online.host,
              port: defaultClientConfig.online.port,
              isHttps: defaultClientConfig.online.isHttps,
              appId: defaultClientConfig.online.appId,
              appSecret: defaultClientConfig.online.appSecret
            };

        if (SourceConfig) {
          config = Object.assign(config, {
            host: SourceConfig.host,
            port: SourceConfig.port,
            isHttps: SourceConfig.isHttps,
            appId: SourceConfig.appId,
            appSecret: SourceConfig.appSecret
          });
        }

        this.sourceConfig = config;
        return config;
      },
      initDataConfig(props){
        const SourceConfig = props ? props : this.element.GISSourceConfig;

        let data = {
          modelList: []
        };

        if (SourceConfig && !SourceConfig.isDynamicSource && SourceConfig.modelId) {
          data.modelList = [SourceConfig.modelId];

          if (SourceConfig.modelPosition !== '' && typeof SourceConfig.modelPosition === 'string') {
            data.modelPositions = {
              [SourceConfig.modelId]: JSON.parse(SourceConfig.modelPosition)
            };
          }
        }

        if (SourceConfig && SourceConfig.isDynamicSource && this.preload) {
          if (this.preload.modelId) {
            data.modelList = this.preload.modelId;

            if (this.preload.modelPosition) {
              data.modelPositions = this.preload.modelPosition;
            }
          }
        }

        this.dataConfig = data;

        return data;
      },
      async initViewer(opt) {
        const viewerShell = new Viewer(this.$refs.viewerGISDom, opt);
        const viewer = (this.viewer = await viewerShell.init());

        const Setting = this.element.GISSetting;
        const SpecialScene = this.element.GISSpecialScene;
        if (Setting) this.setSetting(Setting);
        if(SpecialScene) this.setSpecialScene(SpecialScene);

        return viewer;
      },
      initEvent(viewer, comp) {
        this._EventBus = Object.assign(
            ToolbarEvents.getEvents(viewer, comp),
            SceneEvents.getEvents(viewer, comp),
            ModelEvents.getEvents(viewer, comp),
            MouseCustomEvents.getEvents(viewer, comp),
            CoordinateEvents.getEvents(viewer, comp),
            VectorEvents.getEvents(viewer, comp),
            MarkerEvents.getEvents(viewer, comp),
            PlotEvents.getEvents(viewer, comp),
            ClusterEvents.getEvents(viewer, comp)
        );

        for (let id in this._EventBus) {
          eventBus.$on(id, this._EventBus[id]);
        }
      },
      initBehaviors(viewer, comp) {
        this.element.behaviors = [];

        new ModelBehaviors(viewer, comp);
        new MarkerBehaviors(viewer, comp);
        new MeasureBehaviors(viewer, comp);
        new PlotBehaviors(viewer, comp);
        new GltfBehaviors(viewer, comp);
      },
      initResolve(){
        this.element.resolveData = {};
        this.$store.commit('updatePageCustomStatus', {
          origin: this.element.id,
          resolveData: {}
        });
        this.element.GISResolveList = [];

        this.addResolveList('gis_modelUpdated_modelId' , '模型位置修改-模型id');
        this.addResolveList('gis_modelUpdated_lng' , '模型位置修改-经度');
        this.addResolveList('gis_modelUpdated_lat' , '模型位置修改-纬度');
        this.addResolveList('gis_modelUpdated_height' , '模型位置修改-海拔');
        this.addResolveList('gis_modelUpdated_rotate' , '模型位置修改-旋转度');

        this.addResolveList('gis_gltfAnimation_date' , 'Gltf轨迹动画当前时间');
        this.addResolveList('gis_gltfAnimation_linkNumber' , 'Gltf轨迹动画当前环数');

        this.addResolveList('gis_templateMarker_id' , 'GIS标记数据Id');
        this.addResolveList('gis_templateMarker_name' , 'GIS标记数据名称');
        this.addResolveList('gis_templateMarker_groupId' , 'GIS标记数据分组Id');
        this.addResolveList('gis_templateMarker_archiId' , 'GIS标记数据架构Id');
        this.addResolveList('gis_templateMarker_archiType' , 'GIS标记数据架构类型');
        this.addResolveList('gis_templateMarker_createdAt' , 'GIS标记数据创建时间');
      },
      initMetadata(){
        this.element.metadata = {};
        this.$store.commit('updatePageCustomStatus', {
          origin: this.element.id,
          metadata: {}
        });
        this.element.metadata = [];

        this.element.metadata = {
          'gis_modelUpdated_modelId': '模型位置修改-模型id',
          'gis_modelUpdated_lng': '模型位置修改-经度',
          'gis_modelUpdated_lat': '模型位置修改-纬度',
          'gis_modelUpdated_height': '模型位置修改-海拔',
          'gis_modelUpdated_rotate': '模型位置修改-旋转度'
        }
      },
      initActionConfig(){
        const map = [
          {id : 'onViewerInited', label : '初始化完成',},
          {id : 'onModelLoaded', label : '模型加载完成'},
          {id : 'onModelUpdated', label : '模型位置修改时'},
          {id : 'onTemplateMarkerClick', label : '标记组件点击时'},
          {id : 'onGltfAnimation', label : 'Gltf轨迹动画发生时'}
        ];

        for( let { id , label} of map){
          this.registerActionConfig( id , label );
        }
      },
      async initViewerData(viewer, opt){
        if (opt.modelList.length > 0)
          await viewer.Model.createModelList(opt.modelList, opt.modelPositions);
      },
      async refresh(opt) {
        for (let node of this.$refs.viewerGISDom.childNodes) {
          node.remove();
        }

        const sourceConfig = this.initSourceConfig(opt);
        const viewer = await this.initViewer(sourceConfig);
        this.initEvent(viewer, this);
        this.initBehaviors(viewer, this);
        this.initResolve();
        this.initMetadata();
        const dataConfig = this.initDataConfig(opt);
        await this.initViewerData(viewer, dataConfig);
      },
      setSetting(opt) {
        this.setting.showMask = !!opt.showMask;

        if (opt.defaultViewState) {
          const state = Object.assign({}, opt.defaultViewState);

          this.setting.defaultViewState = state;
          this.viewer.Scene.setCameraView(state);
        }

        this.setting.imageryList = opt.imageryList;
        this.setting.imageryIndex = opt.imageryIndex;
        this.viewer.Scene.setImageryLayer(opt.imageryList[opt.imageryIndex].id);

        this.setting.mouseControllerList = opt.mouseControllerList;
        this.setting.mouseControllerIndex = opt.mouseControllerIndex;
        if (opt.mouseControllerList[opt.mouseControllerIndex].id === 'bim') {
          this.viewer.MouseController.mouseStyleByBIM();
        } else if (opt.mouseControllerList[opt.mouseControllerIndex].id === 'gis'){
          this.viewer.MouseController.mouseStyleByGIS();
        }else if (opt.mouseControllerList[opt.mouseControllerIndex].id === 'plane'){
          this.viewer.MouseController.mouseStyleByColumbusView();
        }

        this.setting.showGroundAtmosphere = !!opt.showGroundAtmosphere;
        this.setting.terrain = !!opt.terrain;
        this.setting.depthTest = !!opt.depthTest;
        this.setting.shadows = !!opt.shadows;

        this.viewer.Scene.setGroundAtmosphere(this.setting.showGroundAtmosphere);
        this.viewer.Scene.setTerrain(this.setting.terrain);
        this.viewer.Scene.setDepthTest(this.setting.depthTest);
        this.viewer.Model.setShadows(this.setting.shadows);

        if (opt.backgroundColor) {
          this.setting.backgroundColor = opt.backgroundColor;

          this.viewer.Scene.setBackgroundColor(opt.backgroundColor);
        }
      },
      setSpecialScene(opt){
        this.specialScene.globeLimitTypeIndex = opt.globeLimitTypeIndex;
        this.specialScene.globeLimitDivision = opt.globeLimitDivision;
        this.specialScene.globeLimitDraw = opt.globeLimitDraw;

        this.specialScene.globeLimitBound = !!opt.globeLimitBound;
        this.specialScene.globeLimitBoundColor = opt.globeLimitBoundColor;

        this.specialScene.globeLimitGlobe = !!opt.globeLimitGlobe;
        this.specialScene.globeLimitGlobeColor = opt.globeLimitGlobeColor;

        let options = {
          isLocation: false,
          isBound: this.specialScene.globeLimitBound,
          boundColor: this.specialScene.globeLimitBoundColor,
          isGlobe: this.specialScene.globeLimitGlobe,
          globeColor: this.specialScene.globeLimitGlobeColor
        };

        if(opt.globeLimitTypeList[opt.globeLimitTypeIndex].id === 'none'){
          this.viewer.Scene.setGlobelLimit('remove');
        }else if(opt.globeLimitTypeList[opt.globeLimitTypeIndex].id === 'district'){
          if(!opt.globeLimitDivision) return;
          this.viewer.Scene.setGlobelLimit('district', Object.assign(options, {adName: opt.globeLimitDivision}));
        }else if(opt.globeLimitTypeList[opt.globeLimitTypeIndex].id === 'draw'){
          if(!opt.globeLimitDraw) return;
          this.viewer.Scene.setGlobelLimit(opt.globeLimitDraw.type, Object.assign(options, opt.globeLimitDraw.data));
        }
      },
      registerActionConfig( id , label ){
        const element = this.element;

        if(!element.actionConfig){
          element.actionConfig = {}
        }

        if(!element.actionConfig[id]){
          element.actionConfig[id] = {
            use: true,
            useDepend: false,
            dependLogic: [],
            type: id,
            name: label,
            color: '#409EFF',
            btnType: 'text',
            btnSize: '',
            margin: 5,
            // eventList: [],
            // permission: [], // 权限
            // archiLimit: [], // 架构限制
          }
        }
      },
      removeActionConfig(id){
        const element = this.element;

        if(!element.actionConfig){
          element.actionConfig = {}
        }

        if(element.actionConfig[id]){
          delete element.actionConfig[id]
        }
      },
      onAction( id ){
        const ac = this.element.actionConfig;
        if(!ac[id]) return;

        const eventList = ac[id].eventList || [];

        for(let el of eventList){
          const { pattern, eventList = [], specialEventList = []  } = el;
          const resultList = pattern === 'special' ? specialEventList : eventList;

          for(let ele of resultList){
            const { effects , behaviors, actionType } = ele;
            if (actionType === 'eeAction') {
              // 触发后端事件
              // 触发后端事件
              const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
              if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
                this.$message.error('事件配置错误！');
                return false;
              }
              const sourceParams = this.getBindParams(this.element);
              // 蓝图
              if (eeType === 'blueprint') {
                dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
                  if (res.status === 200 && res.data.code === 200) {
                    this.$message.success('操作成功！');
                    doEEActionHandle(res.data?.__adds__);
                  }
                }).catch(() => {
                  // 出错
                })
                return true;
              }
              // 数仓
              const data_id = sourceParams?.data_id;
              dataInterface({
                __method_name__: 'customEventCall',
                object_uuid: objectUUID,
                view_uuid: viewUUID,
                ...sourceParams,
                data_id, // 参数配置
                event: eventName
              })
                .then(res => {
                  if (res.status === 200 && res.data.code === 200) {
                    this.$message.success('操作成功！');
                    doEEActionHandle(res.data?.__adds__);
                  }
                })
                .catch(() => {
                  // 出错
                });
              return true;
            } else {
              effects.forEach((effect) => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...ele,
                    ...effect
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID
                });
              });
            }

            behaviors.forEach(behavior => {
              this.$store.commit('triggerEvents', {
                config: {
                  behavior,
                  isBehavior: true
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID
              });
            });
          }
        }

      },
      /**
       * @description: 获取绑定参数
       * @param {Object} comp
       * @return {Object}
       */
      getBindParams(comp) {
        if (!comp) return {};
        const sourceConfig = comp.sourceConfig || [];
        const sourceParams = {};
        for (let i = 0; i < sourceConfig.length; i++) {
          const {
            componentId,
            field,
            key,
            originType = '',
            urlParamKey = '',
            systemKey = '',
            systemCode = '',
            fixedValue = '',
            statusCode = ''
          } = sourceConfig[i];
          if (originType === 'url' && urlParamKey) {
            // 从url获取参数
            const result = getQueryValue(urlParamKey);
            this.$set(sourceParams, key, result);
          } else if (originType === 'system') {
            // 系统参数
            try {
              let obj = sessionStorage.getItem(systemKey);
              if (!obj) {
                obj = localStorage.getItem(systemKey);
              }
              if (!obj) break;
              const result = JSON.parse(obj);
              if (result && Object.prototype.toString.call(result) === '[object Object]') {
                const queryVal = result[systemCode];
                this.$set(sourceParams, key, queryVal);
              }
            } catch (err) {
              console.log(err, '99999999');
            }
          } else if (originType === 'fixed') {
            // 固定值
            this.$set(sourceParams, key, fixedValue);
          } else if (originType === 'pageStatus') {
            // 页面状态
            const statusCodeValue = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode];
            this.$set(sourceParams, key, statusCodeValue || '');
          } else if (componentId && field && key) {
            // 普通从组件获取
            let sourceComponent = getComponentById(this.subComponentData, componentId);
            if (!sourceComponent && this.isGroup && this.groupComponents.length) {
              sourceComponent = getComponentById(this.groupComponents, componentId);
            }
            if (field === 'DATAVIEWSELECT' && (componentId.includes('CommonTree') || componentId.includes('CommonTableContainer'))) {
              this.$set(sourceParams, key, sourceComponent?.resolveData || []);
            } else {
              const result = sourceComponent?.resolveData?.[field] || '';
              this.$set(sourceParams, key, result);
            }
          }
        }
        return sourceParams;
      },
      getParseParam(params) {
        return params.split(',').map((str) => {
          return str;
        });
      },
      setResolveData( id , data){
        this.element.resolveData[id] = data;
      },
      addResolveList( uuid , name){
        this.element.GISResolveList.push({
          uuid,
          name,
        });
      }
    };

    const state = {
      name: this.name,
      props: {
        element: {
          type: Object,
          required: true,
          default: () => {
            return {};
          }
        },
        // 是否在组合内
        isGroup: {
          type: Boolean
        },
        // 组合内组件列表
        groupComponents: {
          type: Array,
          default: () => []
        }
      },
      data() {
        return data;
      },
      computed: {
        ...mapState(['componentData', '_PageCustomStatus'])
      },
      watch,
      mixins: [CustomComponentMixin],
      created() {
        const element = this.element;
        const { param } = this.initParams(
          element.paramsConfig,
          element.isGroup,
          element.componentData,
          element.groupComponents
        );

        if(element.GISSourceConfig
            && element.GISSourceConfig.isDynamicSource
            && element.paramsConfig.length > 0
            && element.paramsConfig.findIndex(x => x.code === 'modelId') !== -1){
          this.dynamicLoadedFlag = false;
        }

        // 开启模型动态源且预设模型id
        if (element.GISSourceConfig && element.GISSourceConfig.isDynamicSource) {
          if (param && param.modelId) {
            this.preload = {
              // modelId: param.modelId,
              appId: param.appId ? param.appId : process.env.VUE_APP_MODEL_APPID,
              appSecret: param.appSecret ? param.appSecret : process.env.VUE_APP_MODEL_APPSECRET
            };

            if (typeof param.modelId === 'string') {
              this.preload['modelId'] = this.getParseParam(param.modelId);
            } else if (param.modelId instanceof Array) {
              this.preload['modelId'] = param.modelId;
            }

            if (param.modelPosition && typeof param.modelPosition === 'string') {
              let modelPosition = JSON.parse(param.modelPosition);
              if(modelPosition.lng && modelPosition.lat){
                this.preload['modelPosition'] = {};
                for (let modelId of this.preload['modelId']){
                  this.preload['modelPosition'][modelId] = modelPosition;
                }
              }else{
                this.preload['modelPosition'] = modelPosition;
              }
            }

            this.dynamicLoadedFlag = true;
          }
        }
      },
      async mounted() {
        this.initCompEvent();
        const sourceConfig = this.initSourceConfig();
        const viewer = await this.initViewer(sourceConfig);
        this.initEvent(viewer, this);
        this.initBehaviors(viewer, this);
        this.initResolve();
        this.initMetadata();
        this.initActionConfig();
        const dataConfig = this.initDataConfig();
        await this.initViewerData(viewer, dataConfig);

        this.viewerInitedFlag = true;
        eventBus.$emit('ViewerGIS.onViewerInited', this.element.id, {
          viewerInited: this.viewerInitedFlag,
          dynamicLoaded: this.dynamicLoadedFlag
        });
        this.onAction('onViewerInited');
      },
      methods,
      destroyed() {
        for (let id in this._compEventBus) {
          eventBus.$off(id, this._compEventBus[id]);
        }

        for (let id in this._EventBus) {
          eventBus.$off(id, this._EventBus[id]);
        }
      }
    };

    return state;
  }
}
