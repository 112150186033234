<!--
 * @Description: lucky sheet表格容器
 * @Author: luocheng
 * @Date: 2022-04-21 16:39:55
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-20 09:28:05
-->
<template>
  <div class="common-sheet-container" v-loading="loading">
    <!-- <article class="sheet-box" v-if="canInit"> -->
    <article class="sheet-box">
      <component
        v-if="statusConfig.isShow"
        :is="baseConfig.enableDialog ? 'el-dialog' : 'div'"
        :title="baseConfig.dialogTitle"
        :fullscreen="true"
        :style="{height:'100%',display:'flex',flexDirection:'column'}"
        :visible.sync="statusConfig.isShow"
        :custom-class="'common-sheet-container-dialog'"
        :modal="false"
      >
        <span class="actions" 
          v-if="!baseConfig.enableDialog && statusConfig && statusConfig.showAction && Array.isArray(actionConfig)">
          <template v-for="(btn, bIndex) in actionConfig">
            <el-button
              v-if="btn.show"
              :key="bIndex"
              :type="btn.buttonType"
              @click="onButton(btn)"
            >
              {{ btn.text }}
            </el-button>
          </template>
        </span>
        <Entry
          style="flex:1;"
          v-if="canInit"
          :loadType="baseConfig.type !== 'export' ? 'create' : 'preload'"
          :type="excelModel"
          :solveType="baseConfig.solveType"
          ref="excelPlus"
          :excelUuid="baseConfig.excelTemplate"
          :objectUuid="databaseList[0].objectUUID"
          :viewUuid="databaseList[0].viewUUID"
          :formUuid="databaseList[0].formUUID || databaseList[0].objectUUID"
          :dataIds="excelIds"
          :excelMetaData="detailsConfig.excelMetaData"
          :excelFormData="detailsConfig.excelFormData"
          :show="true"
          :needFlow="detailsConfig.needFlow"
          :displayData="detailsConfig.displayData"
          :elementId="element.id"
          @submit="onSubmit"
          @exportSuccess="onExportSuccess"
        ></Entry>
        <span slot="footer"
          class="dialog-footer" 
          v-if="baseConfig.enableDialog && statusConfig && statusConfig.showAction && actionConfig && Array.isArray(actionConfig)">
          <template v-for="(btn, bIndex) in actionConfig">
            <el-button
              v-if="btn.show"
              :key="bIndex"
              :type="btn.buttonType"
              @click="onButton(btn)"
            >
              {{ btn.text }}
            </el-button>
          </template>
        </span>
      </component>
    </article>
    <!-- <el-empty description="暂无数据" v-else></el-empty> -->
  </div>
</template>

<script>
// import { Empty } from 'element-ui';
import CommonContainer from '@/custom-component/container/CommonContainer';
import { Dialog } from 'element-ui';
import { getComponentById, checkPaginate, getQueryValue, removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';
import { dataInterface, transFieldsUUid } from '@/apis/data/index';
import Entry from '@/custom-component/common/Entry';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonSheetContainer',
  components: {
    // 'el-empty': Empty,
    'el-dialog': Dialog,
    CommonContainer,
    Entry
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
  },
  data() {
    return {
      loading: false,
      canInit: false,
      excelIds: '',
      detailsConfig: {
        excelFormData: {},
      },
      param: {},
      search: [],
    }
  },
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    // 组件属性
    statusConfig() {
      return this.element?.statusConfig || {};
    },
    // 基础配置
    baseConfig() {
      return this.element?.baseConfig || {};
    },
    // 数据仓库
    databaseList() {
      return this.element?.databaseList || [];
    },
    // 数据来源
    dataSource() {
      return this.element?.dataSource || [];
    },
    actionConfig() {
      return this.element?.actionConfig || [];
    },
    // 表格模式
		excelModel() {
      return this.baseConfig.type;
		},
    // 更新逻辑
    updateRulesConfig() {
      return this.element?.updateRulesConfig || {}
    }
  },
  created() {
    // 存在数据来源时或弹窗时做初始化操作，否则等待事件触发初始化
    if(this.baseConfig.enableDialog || this.dataSource.length < 1){
      this.initSheet();
    }
  },
  mounted() {
    // 弹窗不允许接收筛选事件
    if(!this.baseConfig.enableDialog){
      const databaseTrigger = {
        [this.element.id]: data => {
          if (data?.parentId !== this.element.id) return false;
          this.initSheet();
        }
      }
      eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    }
  },
  methods: {
    /**
     * @desc: 获取表格需要的配置
     * 详情的时候视为单个数据对象，按照弹窗中的配置传递参数
     * 列表的时候直接获取数据对象的值传递
     */
    async initSheet() {
      if(this.type=== 'export'){
        this.$loading();
      }
      const { search, param, canPost } = this.initParams();
      if (!canPost) return;
      this.param = param;
      this.search = search;
      console.log(param, '----search', search)
      this.loading = true;
      if(this.baseConfig.solveType === 'form'){
        // 详情
        const database = this.databaseList?.[0];
        if (!database) return;
        const result = await this.initFormDesign(database, this.baseConfig.type, param)
        this.detailsConfig = result;
      }else if(this.baseConfig.solveType === 'table'){
        this.getDataList(param, search);
      }
      
      if(!this.canInit){
        this.canInit = true;
      }

      this.loading = false;
    },
    /**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig = {}, type, params = {}) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        let result = {};
        const { objectUUID, formUUID } = contentConfig;
        transFieldsUUid(objectUUID, formUUID).then((res) => {
          if (res.data.code == 200) {
            result.formDisabled = type === 'info';
            result.needFlow = !!res.data.data.need_flow;
            result.needParentFlow = !!res.data.data.need_parent_flow;
            // 编辑或详情
            if (type === 'edit' || type === 'info') {
              // 编辑
              // 获取已经有的数据进行绑定
              let config = res.data.data;
              let ruuids = [];
              for (let i = 0; i < config.fields.length; i++) {
                let item = config.fields[i];
                if (item.__config__.tagIcon == 'form') {
                  ruuids.push({ relationship_uuid: item.__vModel__ });
                }
              }
              dataInterface({
                ...params,
                object_uuid: contentConfig.objectUUID,
                view_uuid:
                ['info', 'edit'].includes(type)
                    ? contentConfig.viewUUID
                    : '',
                __method_name__: 'dataInfo'
              }).then((res1) => {
                let data = res1.data.data;
                result.formDesignData = res.data.data;
                result.excelFormData = {
                  [contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
                };
                result.excelMetaData = JSON.parse(JSON.stringify(res1.data.metadata));
                result.displayData = data;
                this.loading = false;
                resolve(result);
              }).catch(err => {
                console.log(err);
                this.loading = false;
                reject(null);
              });
            } else {
              if (type === 'add' && res?.data?.data?.cache_data) {
                // 新增
                result.formDesignData = res.data.data;
                this.loading = false;
              }
              resolve(result);
            }
          }
        }).catch((err) => {
          console.log(err);
          this.loading = false;
          reject(null);
        });
      })
		},
    /**
     * @desc: 获取数据列表对象
     */
    getDataList(params, search) {
      const requestQueues = [];
      const maps = [];
      this.databaseList.forEach((contentConfig, index)=>{
        if(contentConfig.objectUUID && contentConfig.viewUUID){
          requestQueues.push(dataInterface({
            __method_name__: 'dataList',
            ...params,
            search,
            object_uuid: contentConfig.objectUUID,
            view_uuid: contentConfig.viewUUID,
          }));
          maps[index] = contentConfig.objectUUID;
        }
      });

      Promise.all(requestQueues).then(resArr => {
        const temp = {};
        resArr.forEach((item, index) => {
          let data = item.data.data;
          if (checkPaginate(data)) {
              data = data.data;
          }
          temp[maps[index]] = data;
        });

        this.detailsConfig.excelFormData = temp;
      }).catch(err=>{
        console.log(err);
      });
    },
    /**
     * @desc: 提交操作
     */
    onConfirm() {
      this.$refs.excelPlus.triggerSubmit();
    },
    /**
     * @desc: 取消操作
     */
    onCancel(){
      if(this.baseConfig.enableDialog && this.statusConfig.isShow){
        this.statusConfig.isShow = false;
      }
    },
    /**
		 * @desc: 点击按钮
		 * @param {Object} btn 按钮对象
		 */
		onButton(btn) {
      if(this.statusConfig.showAction){
        if (btn.key === 'confirm') {
				// 确认
          this.onConfirm();
        } else if (btn.key === 'cancel') {
          // 取消
          this.onCancel();
        } else {
          console.log(btn, '---自定义操作')
        }
      }
		},
    /**
		 * @desc: 初始化参数
     * code: '', // 字段名
    // isRequired: false, // 必须
    // source: 'component', // 值来源， component 组件，url url参数，system 系统参数
    // component: '', // 关联组件
    // componentField: '', // 组件取值字段
    // urlKey: '', // url参数字段
    // systemObject: '', // 系统取值对象
    // systemField: '' // 系统取值字段
		 */
		initParams() {
			const { dataSource = [] } = this.element;
			// 是否可以请求
			let canPost = true;
			if (!dataSource || !dataSource.length) return {
				canPost
			};
			let search = [];
			const param = {};
			for (let i = 0; i < dataSource.length;i++) {
				const ele = dataSource[i];
				let originComponent = getComponentById(this.componentData, ele.component);
        if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.code !== 'search') {
					if (ele.source === 'url') {
						// url参数
						const queryVal = getQueryValue(ele.urlKey);
						this.$set(param, ele.code, +queryVal);
						// 必填的不能为空，带ID 的不能为空 ??
						if ((ele.isRequired && !queryVal) || (ele.urlKey && ele.code.indexOf('id') > -1 && !queryVal)) {
							canPost = false;
							break;
						}
					} else if (ele.source === 'component') {
            // 区间
            if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
              canPost = false;
              break;
             } else if (originComponent?.resolveData) {
              const queryVal = originComponent?.resolveData[ele.componentField];
              this.$set(param, ele.code, queryVal);
              if ((ele.isRequired && !queryVal) || (ele.urlKey && ele.code.indexOf('id') > -1 && !queryVal)) {
                canPost = false;
                break;
              }
            }
          } else if (ele.source === 'system') {
            // 系统参数
            try {
              let obj = sessionStorage.getItem(ele.systemObject);
              if (!obj) {
                obj = localStorage.getItem(ele.systemObject);
              }
              if (!obj) break;
              const result = JSON.parse(obj);
              if (result && Object.prototype.toString.call(result) === '[object Object]') {
                const queryVal = result[ele.systemField];
                if (ele.isRequired && (queryVal === undefined || queryVal === null)) {
                  canPost = false;
                  break;
                }
                this.$set(param, ele.code, queryVal);
              }
            } catch (err) {
              console.log(err, '99999999');
            }
          } else if (ele.sourceType === 'fixed') {
            // 固定参数
            this.$set(param, ele.code, ele.fixedValue || '');
          } else if (ele.sourceType === 'pageStatus') {
            // 页面状态
            const statusCodeValue = this._PageCustomStatus[ele.statusCode] || this._APPCustomStatus[ele.statusCode];
            this.$set(param, ele.code, statusCodeValue || '');
          }
				} else {
					if (Array.isArray(originComponent?.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			}
			return { search, param, canPost};
		},
    onExportSuccess(){
      this.$loading().close();
    },
    /**
     * @desc: 表单提交
     * @param {} formData
     */
    onSubmit(formData) {
      this.$loading();
      if(this.baseConfig.solveType === 'form'){
        if(this.baseConfig.type === 'add'){
          dataInterface({
            __method_name__: "createData",
            object_uuid: this.databaseList[0].objectUUID,
            ...formData
          }).then((res) => {
            this.$loading().close();
            if (res.data.code == 200) {
              console.log('form add success', res.data);
              if(this.baseConfig.enableDialog){
                this.statusConfig.isShow = false;
              }else{
                this.initSheet();
              }
            } else {
              this.$message.error('新增数据失败');
            }
          }).catch(() => {
            this.$loading().close();
            // this.$message.error('请求数据失败，请稍后再试');
          });
        }else if(this.baseConfig.type === 'edit'){
          dataInterface({
            __method_name__: "updateData",
            object_uuid: this.databaseList[0].objectUUID,
            data_id: this.excelIds,
            ...formData
          }).then((res) => {
            this.$loading().close();
            if (res.data.code == 200) {
              console.log('form edit success', res.data);
              if(this.baseConfig.enableDialog){
                this.statusConfig.isShow = false;
              }else{
                this.initSheet();
              }
            } else {
              this.$message.error('编辑数据失败');
            }
          }).catch(() => {
            this.$loading().close();
            // this.$message.error('请求数据失败，请稍后再试');
          });
        }
      }else if(this.baseConfig.solveType === 'table'){
        // console.log(formData,111);
        // this.$loading().close();
        // return;
        // 固定接口提交
        dataInterface({
          __method_name__: "globalFunctionCall",
          typeName: "PublicFunc",
          type: "behavior",
          funcName: "SpreadsheetImport",
          payload: {
            data: {
              ...formData,
              attachFields: {
                ...this.param
              }
            },
            check_only: this.baseConfig.checkOnly
          }
        }).then((res) => {
          this.$loading().close();
          // console.log(res.data.code , '-----res.data.code -----')
          if (res.data.code === 200) {
            if(this.baseConfig.enableDialog) {
              // eventBus.$emit('databaseTrigger', {
              //   // 临时处理Excel容器刷新，后期用全局行为处理
              //   componentId: 'fakerId'
              // });
              this.doComponentBehavior();
              this.statusConfig.isShow = false;
            }else{
              this.initSheet();
            }
            this.$message.success('操作成功');
          } else {
            this.$message.error('导入数据失败');
          }
        }).catch((err) => {
          console.log(err);
          this.$loading().close();
          // this.$message.error('请求数据失败，请稍后再试');
        });
      }
    },
    /**
     * @desc: 触发组件行为
     */
    doComponentBehavior() {
      console.log('-如果是对表格中的数据进行编辑需要对数据进行更新')
      // 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.dataSource || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					if (
						sourceConfig[i]?.source === 'component' &&
						sourceConfig[i]?.component
					) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: sourceConfig[i].component,
							isInit: sourceConfig[i].component.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								sourceConfig[i].component.indexOf('CommonTabs-') > -1
									? this.getOutput(sourceConfig[i].component)
									: ''
						});
					}
				}
			}
			if (pattern === 'rules' || pattern === 'compatible') {
				rules.forEach(ele => {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: ele.componentId,
						isInit: ele.componentId?.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ele.componentId?.indexOf('CommonTabs-') > -1 ? this.getOutput(ele.componentId) : ''
					});
				})
			}
    },
    /**
		 * @desc: 获取数据数据
		 */
		getOutput(comId) {
			let componentItem = getComponentById(this.componentData, comId);
			if (!componentItem && this.isGroup && this.groupComponents.length) {
				componentItem = getComponentById(this.groupComponents, comId);
			}
			return componentItem?.resolveData || '';
		}
  },
  beforeDestroy() {
    if(!this.baseConfig.enableDialog){
      removeEventBus('databaseTrigger', this.element.id);
    }
  }
}
</script>

<style lang="less" scoped>
.common-sheet-container{
  height: 100%;
	width: 100%;
  .sheet-box{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    :deep(.el-dialog__wrapper) {
      .el-dialog__header {
        height: 61px;
        box-sizing: border-box;
        margin-bottom: 0;
        border-bottom: 1px solid #f2f3f5;
        padding-bottom: 20px;
        display: flex;
        .el-dialog__title {
          flex: 1;
        }
        .el-dialog__headerbtn {
          top: 22px;
          .el-dialog__close {
            color: #72767b;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .el-dialog__body {
        padding: 15px 16px;
      }
    }
    .common-sheet-main{
      flex: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
    .actions{
      text-align: right;
      padding: 5px 0;
    }
    .footer{
      text-align: center;
      padding: 5px 0;
    }
  }
}
</style>

<style>
.common-sheet-container-dialog{
  display: flex;
  flex-direction: column;
}
.common-sheet-container-dialog .el-dialog__body{
  flex: 1;
}
</style>