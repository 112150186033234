import { render, staticRenderFns } from "./OperationFile.vue?vue&type=template&id=3198c566&scoped=true&"
import script from "./OperationFile.vue?vue&type=script&lang=js&"
export * from "./OperationFile.vue?vue&type=script&lang=js&"
import style0 from "./OperationFile.vue?vue&type=style&index=0&id=3198c566&prod&lang=less&scoped=true&"
import style1 from "./OperationFile.vue?vue&type=style&index=1&id=3198c566&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3198c566",
  null
  
)

export default component.exports